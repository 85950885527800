@charset "utf-8";

.floating_btn {
	position: fixed;
	bottom: 55px;
	left: 50%;
	z-index: 10;
	margin-left: -80px;
}
.floating_btn ul li {
	float: left;
}
.floating_btn ul li.fb1 a {
	display: block;
	width: 160px;
	height: 48px;
	line-height: 48px;
	background: #222;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	font-size: 14px;
	color: #fff;
	letter-spacing: -0.5px;
	text-align: center;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.5);
}
.floating_btn ul li.fb2 a {
	display: block;
	position: relative;
	width: 44px;
	height: 44px;
	-webkit-border-radius: 22px;
	-moz-border-radius: 22px;
	border-radius: 22px;
	background: #5774db;
	box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.5);
	margin-left: 22px;
}
.floating_btn ul li.fb2 a img {
	position: absolute;
	top: 7px;
	left: 50%;
	width: 30px;
	height: auto;
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
}

/* 230615 */
.code_pu, .code_re_pu, .noti_pu{display: none;}

.code_pu {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 10;
	width:300px;
	height:350px;
	background-color: #fff;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	border: 1px solid #ccc;
	border-radius: 30px;
}

.code_pu h3{
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	text-align: center;
	font-size: 18px;
	padding: 50px 10px 0;
	word-break: keep-all;
}

.code_pu h3 span{
	display: block;
}

.code_pu .form_cont{
	position: relative;
	width: 80%;
	height: 50px;
	line-height: 50px;
	margin: 50px auto;
}

.code_pu .form_cont input{
	width: 100%;
	height: 100%;
	border: 1px solid #ccc;
	font-size: 16px;
	background-color: #fff;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	box-sizing: border-box;
	border-radius: 8px;
	text-align: center;
}

.code_pu button{
	display: block;
	width: 160px;
	height: 40px;
	border-radius: 20px;
	background-color: #5774db;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
}


.code_re_pu {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 10;
	width:300px;
	height:350px;
	background-color: #fff;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	border: 1px solid #ccc;
	border-radius: 30px;
}

.code_re_pu h3{
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	text-align: center;
	font-size: 22px;
	padding: 50px 10px 0;
	word-break: keep-all;
	line-height: 1.5;
}

.code_re_pu h3 span{
	display: block;
}

.code_re_pu button{
	display: block;
	width: 160px;
	height: 40px;
	border-radius: 20px;
	background-color: #5774db;
	margin: 80px auto 0;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
}

.noti_pu {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 10;
	width:300px;
	height:250px;
	background-color: #fff;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	border: 1px solid #ccc;
	border-radius: 30px;
}

.noti_pu h3{
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	text-align: center;
	font-size: 22px;
	padding: 45px 10px 0;
	word-break: keep-all;
	line-height: 1.5;
}

.noti_pu h3 span{
	display: block;
}

.noti_pu strong{
	display: block;
	text-align: center;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 26px;
	padding-top: 30px;
}

.cnt1 .chn_util_area {
	position: relative;
	max-width: 1920px;
	margin: 0 auto;
	width: 1544px;
}
.cnt1 .chn_util_area .chn_util {
	position: absolute;
	top: 26px;
	left: 0;
	width: 160px;
	height: 40px;
	border: 1px solid #ccc;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	background: #fff;
	z-index: 2;
	overflow: hidden;
}
.cnt1 .chn_util_area .chn_util .chn_df {
	display: block;
	width: 100%;
	height: 40px;
}
.cnt1 .chn_util_area .chn_util .chn_df > * {
	float: left;
}
.cnt1 .chn_util_area .chn_util .chn_df p {
	margin-top: 2px;
	margin-left: 21px;
}
.cnt1 .chn_util_area .chn_util .chn_df span {
	margin-left: 18px;
}
.cnt1 .chn_util_area .chn_util .chn_df span img {
	-ms-transform: rotate(0deg);
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}
.cnt1 .chn_util_area .chn_util .chn_df.on span img {
	-ms-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}
.cnt1 .chn_util_area .chn_util > a {
	display: block;
	width: 100%;
	height: 40px;
}
.cnt1 .chn_util_area .chn_util > a p {
	margin-left: 21px;
	padding-top: 8px;
}

#main,
#main2 {
	font-family: 'Spoqa Han Sans Neo', 'sans-serif';
}
#main .area,
#main2 .area {
	width: 1400px;
}
#main .cnt1 {
	width: 100%;
	overflow: hidden;
	position: relative;
	max-width: 1920px;
	margin: 0 auto;
}
#main .cnt1 .visual_area {
	position: relative;
	max-width: 1920px;
	margin: 0 auto;
	height: 100%;
}
#main .cnt1 .visual {
	width: 100%;
	height: 100%;
}
#main .cnt1 .visual ul {
	width: 100%;
	height: 100%;
}
#main .cnt1 .visual ul li {
	float: left;
	position: relative;
	width: 100%;
	height: 100%;
}
#main .cnt1 .visual ul li .txt {
	position: relative;
	width: 1400px;
	margin: 0 auto;
	z-index: 1;
}
#main .cnt1 .visual ul li .txt h2 {
	font-size: 60px;
	line-height: 1.3;
	font-weight: 600;
	letter-spacing: -0.3px;
	padding-top: 210px;
	padding-bottom: 21px;
}
#main .cnt1 .visual ul li .txt h2 span {
	display: block;
	padding-left: 178px;
}
#main .cnt1 .visual ul li .txt p {
	font-size: 24px;
	letter-spacing: -0.3px;
	padding-bottom: 55px;
	line-height: 1.5;
}
#main .cnt1 .visual ul li .txt a {
	display: inline-block;
	padding-right: 50px;
	position: relative;
	height: 55px;
	line-height: 55px;
	background: #e23325;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	font-size: 18px;
	font-weight: 500;
	color: #fff;
	padding-left: 31px;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
}
#main .cnt1 .visual ul li .txt a img {
	position: absolute;
	width: 6px;
	height: auto;
	top: 50%;
	right: 25px;
	margin-top: -6px;
}
#main .cnt1 .visual ul li .slide_bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
#main .cnt1 .visual ul li .slide_bg > div {
	width: 100%;
	height: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	background-position: center;
}
#main .cnt1 .visual ul li .slide_bg .mo_none {
	display: block;
}
#main .cnt1 .visual ul li .slide_bg .mo_only {
	display: none;
}
#main .cnt1 .visual ul li.visual1 .slide_bg .mo_none {
	background-image: url('../images/main_c1_visual1.jpg');
}
#main .cnt1 .visual ul li.visual1 .slide_bg .mo_only {
	background-image: url('../images/main_c1_visual1_mb.jpg');
}
#main .cnt1 .visual ul li.visual2 .slide_bg .mo_none {
	background-image: url('../images/main_c1_visual2.jpg');
}
#main .cnt1 .visual ul li.visual2 .slide_bg .mo_only {
	background-image: url('../images/main_c1_visual2_mb.jpg');
}
#main .cnt1 .visual ul li.visual3 .slide_bg .mo_none {
	background-image: url('../images/main_c1_visual3.jpg');
}
#main .cnt1 .visual ul li.visual3 .slide_bg .mo_only {
	background-image: url('../images/main_c1_visual3_mb.jpg');
}
#main .cnt1 .owl-stage-outer {
	height: 100%;
}
#main .cnt1 .owl-stage {
	height: 100%;
}
#main .cnt1 .owl-item {
	float: left;
	height: 100%;
	width: 100%;
}
#main .cnt1 .owl-nav {
	display: none;
}
#main .cnt1 .owl-dots {
	position: absolute;
	top: 37px;
	right: 10%;
}
#main .cnt1 .owl-dots button {
	width: 10px;
	height: 10px;
	background: #fff;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	margin-left: 10px;
}
#main .cnt1 .owl-dots button:first-child {
	margin-left: 0;
}
#main .cnt1 .owl-dots button.active {
	background: #e23325;
}


#main .cnt2 {
	padding-bottom: 174px;
	overflow: hidden;
}
#main .cnt2 .area .tit {
	letter-spacing: -0.3px;
	padding-top: 107px;
}
#main .cnt2 .area .tit h3 {
	font-size: 60px;
	font-weight: 600;
	text-align: center;
	padding-bottom: 10px;
}
#main .cnt2 .area .tit p {
	font-size: 20px;
	font-weight: 300;
	text-align: center;
	font-family: 'Noto Sans KR', 'Spoqa Han Sans Neo', sans-serif;
	opacity: 0.6;
	filter: Alpha(opacity=60);
}
#main .cnt2 .area .txt {
	width: 1280px;
	margin: 0 auto;
	padding-top: 77px;
}

#main .cnt2 .area .txt .oc_list .slick-list .slick-slider{
	width:100%
}
#main .cnt2 .area .txt .oc_list .slick-list .slick-slide > div{
	padding: 0 30px;
}
#main .cnt2 .area .txt .oc_list a {
	position: relative;
	width: 100%;
	height: 100%;
	display: block;
	cursor: default;
}
#main .cnt2 .area .txt ul li a .img {
	position: relative;
	width: 100%;
	overflow: hidden;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
}
#main .cnt2 .area .txt ul li a .img img {
	width: 100%;
	height: auto;
}
#main .cnt2 .area .txt ul li a .img .mark {
	position: absolute;
	top: 23px;
	left: 25px;
	display: flex;
}
#main .cnt2 .area .txt ul li a .img .mark p {
	font-size: 14px;
	font-weight: 500;
	height: 24px;
	line-height: 24px;
	background-color: rgba(255,255,255,0.5);
	border-radius: 12px;
	padding: 0 13px;
	margin-right: 6px;
}
#main .cnt2 .area .txt ul li a .img .mark1 p {
	color: #CDA484;
}
#main .cnt2 .area .txt ul li a .img .mark2 p {
	color: #577EAD;
}
#main .cnt2 .area .txt ul li a .img .mark3 p {
	color: #B4635E;
}
#main .cnt2 .area .txt .oc_list .slick-arrow {
	display: none;
}
#main .cnt2 .area .txt ul li a .hwrap {
	min-height: 136px;
}
#main .cnt2 .area .txt ul li a .hwrap > h4 {
	font-size: 32px;
	font-weight: 700;
	padding-top: 21px;
	padding-left: 10px;
	line-height: 1.3;
}
#main .cnt2 .area .txt ul li a .hwrap .p_wrap {
	padding-left: 10px;
	margin-top: 10px;
}
#main .cnt2 .area .txt ul li a .hwrap .p_wrap p {
	opacity: 0.7;
	filter: Alpha(opacity=70);
	font-size: 20px;
	line-height: 1.5;
	word-break: keep-all;
}
#main .cnt2 .area .txt ul li a .hwrap .p_wrap p:nth-child(2) {
	padding-top: 3px;
}
#main .cnt2 .area .txt .btn_wrap {
	margin-top: 113px;
}
#main .cnt2 .area .txt .btn_wrap ul {
	display: flex;
	margin: 0 auto;
	justify-content: center;
}
#main .cnt2 .area .txt .btn_wrap ul li {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 270px;
	height: 70px;
	border-radius: 35px;
}
#main .cnt2 .area .txt .btn_wrap ul li.btn1 {
	background-color: #E23325;
}
#main .cnt2 .area .txt .btn_wrap ul li.btn2 {
	border: 2px solid #e23325;
	box-sizing: border-box;
	margin-left: 37px;
}
#main .cnt2 .area .txt .btn_wrap ul li p {
	font-size: 26px;
	color: #fff;
	font-weight: 700;
	text-align: center;
}
#main .cnt2 .area .txt .btn_wrap ul li.btn2 p {
	color: #e23325;
}



#main .cnt3 .area .tit .bar {
	display: block;
	width: 1px;
	height: 100px;
	background-color: #d9d9d9;
	margin: 0 auto ;
}

#main .cnt3 .area .tit .c3_logo {
	text-align: center;
	margin-top: 33px;
}
#main .cnt3 .area .tit h3 {
	font-size: 60px;
	font-weight: 200;
	text-align: center;
	padding-top: 30px;
}
#main .cnt3 .area .tit h3 strong {
	display: block;
}
#main .cnt3 .area .txt {
	display: flex;
	justify-content: center;
	margin-top: 66px;
}
#main .cnt3 .area .txt .right {
	margin-left: 123px;
	margin-top: 86px;
}
#main .cnt3 .area .txt .right h6 {
	font-size: 20px;
	color: #e23325;
	font-family: poppins;
	font-weight: 600;
}
#main .cnt3 .area .txt .right h4 {
	font-size: 44px;
	font-weight: 200;
	line-height: 1.5;
}
#main .cnt3 .area .txt .right h4 span {
	font-weight: 700;
}
#main .cnt3 .area .txt .right h4 span.span_color1 {
	color: #DBB560;
}
#main .cnt3 .area .txt .right h4 span.span_color2 {
	color: #2C66A6;
}
#main .cnt3 .area .txt .right h4 span.span_color3 {
	color: #E23325;
}

#main .cnt4 {
	background-color: #F8F8F8;
	padding: 127px 0;
	margin-top: 150px;
}

#main .cnt4 .area .wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

#main .cnt4 .area .wrap .box {
	position: relative;
	width: 450px;
	height: 405px;
	background-color: #fff;
	border-radius: 20px;
}

#main .cnt4 .area .wrap .box4,
#main .cnt4 .area .wrap .box5 {
	width: 680px;
	margin-top: 23px;
}


#main .cnt4 .area .wrap .box h4 {
	font-size: 26px;
	font-weight: 700;
	padding-left: 38px;
	padding-top: 34px;
	line-height: 1.5;
}

#main .cnt4 .area .wrap .box .img {
	position: absolute;
} 



#main .cnt4 .area .wrap .box1 .img {
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}

#main .cnt4 .area .wrap .box2 .img {
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}

#main .cnt4 .area .wrap .box3 .img {
	bottom: 34px;
	left: 50%;
	transform: translateX(-50%);
}

#main .cnt4 .area .wrap .box4 .img {
	bottom: 33px;
	right: 48px;
}
#main .cnt4 .area .wrap .box5 .img {
	bottom: 43px;
	right: 69px;
}
#main .cnt5 {
	padding-top: 140px;
}
#main .cnt5 .area .box {
	display: flex;
	align-items: center;
	justify-content: center;
}

#main .cnt5 .area .box1 .left img {
	width: 845px;
	height: auto;
}
#main .cnt5 .area .box1 .right h3 {
	font-size: 60px;
	line-height: 1.3;
	font-weight: 200;
	margin-left: 64px;
}
#main .cnt5 .area .box1 .right h3 strong {
	display: block;
	font-weight: 700;
}
#main .cnt5 .area .box2 {
	margin-top: 72px;
}
#main .cnt5 .area .box2 .left {
	margin-right: 166px;
}
#main .cnt5 .area .box2 .left h3 {
	font-size: 60px;
	line-height: 1.3;
	font-weight: 200;
}
#main .cnt5 .area .box2 .left h3 strong {
	display: block;
	font-weight: 700;
}
#main .cnt5 .area .box2 .left p{
	font-size: 34px;
	padding-top: 42px;
	color: #666;
}

#main .cnt6 {
	padding-top: 213px;
}
#main .cnt6 .area .tit .bar {
	display: block;
	width: 1px;
	height: 100px;
	margin: 0 auto;
	background-color: #d9d9d9;
}
#main .cnt6 .area .tit h3 {
	font-size: 60px;
	font-weight: 700;
	text-align: center;
	padding-top: 29px;
}


#main .cnt6 .area .txt {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 47px;
}

#main .cnt6 .area .txt .box {
	position: relative;
	width: 680px;
	height: 400px;
	background-color: #f5f5fa;
	border-radius: 10px;
	margin-bottom: 35px;
}

#main .cnt6 .area .txt .box h4 {
	font-size: 26px;
	font-weight: 700;
	line-height: 1.5;
	padding-top: 36px;
	padding-left: 45px;
}


#main .cnt6 .area .txt .box1 .box_content {
	position: absolute;
	bottom: 0;
	right: 30px;
}

#main .cnt6 .area .txt .box2 .box_content {
	width: 80%;
	position: relative;
	left: 15%;
	margin-top: 45px;
}

#main .cnt6 .area .txt .box2 .box_content .div_table {
	width: 100%;
}

#main .cnt6 .area .txt .box2 .box_content .div_table ul li {
	display: flex;
	height: 56px;
}

#main .cnt6 .area .txt .box2 .box_content .div_table ul li h5 {
	width: 40%;
	height: 100%;
	line-height: 56px;
	background-color: #bbbbd2;
	color: #fff;
	font-size: 16px;
	font-weight: 700;
	text-align: center;
	border-bottom: 1px solid #fff;
}

#main .cnt6 .area .txt .box2 .box_content .div_table ul li:first-child {
	height: 40px;
}

#main .cnt6 .area .txt .box2 .box_content .div_table ul li:first-child h5 {
	height: 100%;
	line-height: 40px;
}


#main .cnt6 .area .txt .box2 .box_content .div_table ul li h6 {
	width: 60%;
	height: 40px;
	line-height: 40px;
	background-color: #fff;
	font-size: 16px;
	font-family: poppins;
	font-weight: 600;
	text-align: center;
	border-right: 1px solid #F5F5FA;
}

#main .cnt6 .area .txt .box2 .box_content .div_table ul li p {
	width: 60%;
	height: 100%;
	line-height: 56px;
	background-color: #fff;
	font-size: 16px;
	font-weight: 700;
	text-align: center;
	color: #666;
	border-top: 1px solid #F5F5FA;
	border-right: 1px solid #F5F5FA;
}



#main .cnt6 .area .txt .box2 .box_content .div_table ul li h6:last-child,
#main .cnt6 .area .txt .box2 .box_content .div_table ul li p:last-child {
	border-right: 0;
}

#main .cnt6 .area .txt .box2 .box_content .div_table ul li p.db_line {
	line-height: 1.3;
	padding-top: 8px;
	height: 48px;
}
#main .cnt6 .area .txt .box2 .box_content .div_table ul li p.db_line span {
	font-size: 12px;
	color: #999;
}


#main .cnt6 .area .txt .box3 .box_content {
	position: absolute;
	left: 50%;
	bottom: 33px;
	transform: translateX(-50%);
}

#main .cnt6 .area .txt .box3 .box_content img {
	width: 598px;
	height: auto;
}

#main .cnt6 .area .txt .box4 .box_content {
	position: absolute;
	right: 39px;
	bottom: 34px;
}


#main .cnt6 .area .txt .box5 {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

#main .cnt6 .area .txt .box5 h4 {
	padding-top: 0;
	padding-left: 0;
}
#main .cnt6 .area .txt .box5 .box_content {
	margin-left: 202px;
}


#main .cnt7 {
	background-color: #f9f9f9;
	padding: 127px 0;
	margin-top: 150px;
}

#main .cnt7 .area .tit h3 {
	font-size: 60px;
	font-weight: 700;
	text-align: center;
}

#main .cnt7 .area .txt {
	display: flex;
	justify-content: space-between;
	margin-top: 59px;
}

#main .cnt7 .area .txt .box {
	width: 333px;
	height: 498px;
	background-color: #f5f5fa;
	border-radius: 10px;
}

#main .cnt7 .area .txt .box .box_content {
	text-align: center;
	padding-top: 63px;
}

#main .cnt7 .area .txt .box h4 {
	font-size: 26px;
	font-weight: 700;
	padding-top: 18px;
	text-align: center;
	line-height: 1.5;
}


#main .cnt8 {
	padding-top: 125px;
	padding-bottom: 166px;
}
#main .cnt8 .area .tit h3 {
	font-size: 60px;
	font-weight: 700;
	text-align: center;
}

#main .cnt8 .area .txt {
	display: flex;
	justify-content: space-between;
	margin-top: 47px;
}

#main .cnt8 .area .txt .box {
	position: relative;
	width: 680px;
	height: 381px;
	background-color: #f5f5fa;
	border-radius: 10px;
}

#main .cnt8 .area .txt .box h4 {
	font-size: 26px;
	font-weight: 700;
	line-height: 1.5;
	padding-top: 44px;
	padding-left: 51px;
}

#main .cnt8 .area .txt .box .box_content {
	position: absolute;
}

#main .cnt8 .area .txt .box1 .box_content {
	bottom: 12px;
	right: 29px;
}
#main .cnt8 .area .txt .box2 .box_content {
	bottom: 27px;
	right: 41px;
}


#main .cnt9 {
	padding-top: 125px;
	padding-bottom: 139px;
	background-color: #f9f9f9;
}
#main .cnt9 .area .tit h3 {
	font-size: 60px;
	font-weight: 700;
	text-align: center;
}

#main .cnt9 .area .txt {
	display: flex;
	justify-content: space-between;
	margin-top: 47px;
}

#main .cnt9 .area .txt .box {
	position: relative;
	width: 680px;
	height: 381px;
	background-color: #f5f5fa;
	border-radius: 10px;
}

#main .cnt9 .area .txt .box h4 {
	font-size: 26px;
	font-weight: 700;
	line-height: 1.5;
	padding-top: 44px;
	padding-left: 51px;
}

#main .cnt9 .area .txt .box .box_content {
	position: absolute;
}

#main .cnt9 .area .txt .box1 .box_content {
	bottom: 0;
	right: 0;
}
#main .cnt9 .area .txt .box2 .box_content {
	bottom: 64px;
	right: 81px;
}
#main .cnt10 {
	position: relative;
	padding-top: 145px;
	padding-bottom: 134px;
	overflow: hidden;
}
#main .cnt10 .area .c10_2 {
	position: relative;
	z-index: 1;
}
#main .cnt10 .area .c10_2 .txt ul li {
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	-webkit-border-radius: 21px;
	-moz-border-radius: 21px;
	border-radius: 21px;
	overflow: hidden;
}
#main .cnt10 .area .c10_2 .txt ul li a {
	display: block;
	width: 100%;
	height: 100%;
}
#main .cnt10 .area .c10_2 .txt ul li a h4,
#main .cnt10 .area .c10_2 .txt ul li a p {
	font-size: 0;
	line-height: 0;
	text-indent: -9999px;
}
#main .cnt10 .area .c10_2 .txt ul li a .img {
	display: block;
	width: 100%;
	height: 100%;
}
#main .cnt10 .area .c10_2 .txt ul li a .img img {
	width: 100%;
	height: auto;
}
#main .cnt10 .area .c10_2 .txt ul li.svc1 {
	margin-left: 0;
}
#main .cnt10 .owl-item {
	float: left;
	height: 100%;
}
#main .cnt10 .owl-nav {
	display: none;
}
#main .cnt10 .area .c10_2 .btn_wrap {
	margin-top: 113px;
}
#main .cnt10 .area .c10_2 .btn_wrap ul {
	display: flex;
	margin: 0 auto;
	justify-content: center;
}
#main .cnt10 .area .c10_2 .btn_wrap ul li {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 270px;
	height: 70px;
	border-radius: 35px;
}
#main .cnt10 .area .c10_2 .btn_wrap ul li.btn1 {
	background-color: #E23325;
}
#main .cnt10 .area .c10_2 .btn_wrap ul li.btn2, #main .cnt10 .area .c10_2 .btn_wrap ul li.btn3 {
	border: 2px solid #e23325;
	box-sizing: border-box;
	margin-left: 37px;
}
#main .cnt10 .area .c10_2 .btn_wrap ul li p {
	font-size: 26px;
	color: #fff;
	font-weight: 700;
	text-align: center;
}
#main .cnt10 .area .c10_2 .btn_wrap ul li.btn2 p, #main .cnt10 .area .c10_2 .btn_wrap ul li.btn3 p {
	color: #e23325;
}
#main .cnt10 .top_btn {
	width: 70px;
	height: 70px;
	position: absolute;
	bottom: 31px;
	right: 40px;
}
#main2 .cnt1 {
	width: 100%;
	overflow: hidden;
	position: relative;
	max-width: 1920px;
	margin: 0 auto;
}
#main2 .cnt1 .visual_area {
	position: relative;
	max-width: 1920px;
	margin: 0 auto;
	height: 100%;
}
#main2 .cnt1 .visual {
	width: 100%;
	height: 100%;
}
#main2 .cnt1 .visual ul {
	width: 100%;
	height: 100%;
}
#main2 .cnt1 .visual ul li {
	float: left;
	position: relative;
	width: 100%;
	height: 100%;
}
#main2 .cnt1 .visual ul li .txt {
	position: relative;
	width: 1400px;
	margin: 0 auto;
	z-index: 1;
}
#main2 .cnt1 .visual ul li .txt h2 {
	font-size: 60px;
	line-height: 1.3;
	font-weight: 600;
	letter-spacing: -0.3px;
	padding-top: 210px;
	padding-bottom: 21px;
}
#main2 .cnt1 .visual ul li .txt h2 span {
	display: block;
	padding-left: 178px;
}
#main2 .cnt1 .visual ul li .txt p {
	font-size: 24px;
	letter-spacing: -0.3px;
	padding-bottom: 55px;
	line-height: 1.5;
}
#main2 .cnt1 .visual ul li .txt a {
	display: inline-block;
	padding-right: 50px;
	position: relative;
	height: 55px;
	line-height: 55px;
	background: #3e75ba;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	font-size: 18px;
	font-weight: 500;
	color: #fff;
	padding-left: 31px;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
}
#main2 .cnt1 .visual ul li .txt a img {
	position: absolute;
	width: 6px;
	height: auto;
	top: 50%;
	right: 25px;
	margin-top: -6px;
}
#main2 .cnt1 .visual ul li .slide_bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
#main2 .cnt1 .visual ul li .slide_bg > div {
	width: 100%;
	height: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	background-position: center;
}
#main2 .cnt1 .visual ul li .slide_bg .mo_none {
	display: block;
}
#main2 .cnt1 .visual ul li .slide_bg .mo_only {
	display: none;
}
#main2 .cnt1 .visual ul li.visual1 .slide_bg .mo_none {
	background-image: url('../images/main2_c1_visual1.jpg');
}
#main2 .cnt1 .visual ul li.visual1 .slide_bg .mo_only {
	background-image: url('../images/main2_c1_visual1_mb.jpg');
}
#main2 .cnt1 .visual ul li.visual2 .slide_bg .mo_none {
	background-image: url('../images/main2_c1_visual2.jpg');
}
#main2 .cnt1 .visual ul li.visual2 .slide_bg .mo_only {
	background-image: url('../images/main2_c1_visual2_mb.jpg');
}
#main2 .cnt1 .visual ul li.visual3 .slide_bg .mo_none {
	background-image: url('../images/main2_c1_visual3.jpg');
}
#main2 .cnt1 .visual ul li.visual3 .slide_bg .mo_only {
	background-image: url('../images/main2_c1_visual3_mb.jpg');
}
#main2 .cnt1 .owl-stage-outer {
	height: 100%;
}
#main2 .cnt1 .owl-stage {
	height: 100%;
}
#main2 .cnt1 .owl-item {
	float: left;
	height: 100%;
	width: 100%;
}
#main2 .cnt1 .owl-nav {
	display: none;
}
#main2 .cnt1 .owl-dots {
	position: absolute;
	top: 37px;
	right: 10%;
}
#main2 .cnt1 .owl-dots button {
	width: 10px;
	height: 10px;
	background: #fff;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	margin-left: 10px;
}
#main2 .cnt1 .owl-dots button:first-child {
	margin-left: 0;
}
#main2 .cnt1 .owl-dots button.active {
	background: #3e75ba;
}
#main2 .cnt2 .area .tit {
	padding-top: 102px;
}
#main2 .cnt2 .area .tit h3 {
	font-size: 50px;
	text-align: center;
}
#main2 .cnt2 .area .txt {
	margin-top: 138px;
}
#main2 .cnt2 .area .txt ul li {
	float: left;
	width: 33.333333%;
	margin-bottom: 131px;
	height: 100px;
}
#main2 .cnt2 .area .txt ul li > * {
	float: left;
}
#main2 .cnt2 .area .txt ul li p {
	font-size: 30px;
	line-height: 40px;
	letter-spacing: -1px;
}
#main2 .cnt2 .area .txt ul li:nth-child(1) .icon {
	margin-left: 31px;
}
#main2 .cnt2 .area .txt ul li:nth-child(1) p {
	padding-left: 20px;
	padding-top: 10px;
}
#main2 .cnt2 .area .txt ul li:nth-child(2) .icon {
	margin-left: 59px;
	margin-top: 10px;
}
#main2 .cnt2 .area .txt ul li:nth-child(2) p {
	padding-left: 19px;
	padding-top: 11px;
}
#main2 .cnt2 .area .txt ul li:nth-child(3) .icon {
	margin-left: 27px;
	margin-top: -8px;
}
#main2 .cnt2 .area .txt ul li:nth-child(3) p {
	padding-left: 17px;
	padding-top: 10px;
}
#main2 .cnt2 .area .txt ul li:nth-child(4) .icon {
	margin-left: 37px;
}
#main2 .cnt2 .area .txt ul li:nth-child(4) p {
	padding-left: 32px;
	padding-top: 19px;
}
#main2 .cnt2 .area .txt ul li:nth-child(5) .icon {
	margin-left: 64px;
	margin-top: 15px;
}
#main2 .cnt2 .area .txt ul li:nth-child(5) p {
	padding-left: 19px;
	padding-top: 19px;
}
#main2 .cnt2 .area .txt ul li:nth-child(6) .icon {
	margin-left: 28px;
	margin-top: 12px;
}
#main2 .cnt2 .area .txt ul li:nth-child(6) p {
	padding-left: 18px;
	padding-top: 19px;
}
#main2 .cnt3 {
	background: url('../images/main2_c3_bg.jpg') no-repeat top center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
	padding-bottom: 164px;
}
#main2 .cnt3 .area .tit {
	padding-top: 150px;
}
#main2 .cnt3 .area .tit h5 {
	font-size: 25px;
	text-align: center;
	font-family: 'Poppins', sans-serif;
	color: #3e75ba;
	letter-spacing: -0.7px;
}
#main2 .cnt3 .area .tit h3 {
	font-size: 60px;
	text-align: center;
	letter-spacing: -0.7px;
	padding-top: 36px;
}
#main2 .cnt3 .area .txt {
	margin-top: 103px;
}
#main2 .cnt3 .area .txt .left {
	float: left;
	padding-left: 150px;
	margin-top: 103px;
}
#main2 .cnt3 .area .txt .left h5 {
	font-size: 25px;
	font-family: 'Poppins', sans-serif;
	color: #3e75ba;
	letter-spacing: -0.7px;
}
#main2 .cnt3 .area .txt .left h4 {
	font-size: 55px;
	line-height: 80px;
	letter-spacing: -0.7px;
	padding-top: 29px;
}
#main2 .cnt3 .area .txt .right {
	float: right;
	width: 511px;
	margin-right: 64px;
}
#main2 .cnt3 .area .txt .right ul li {
	float: left;
	width: 245px;
	height: 245px;
	background: #fff;
	border: 1px solid #c6c6c6;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	margin-left: 21px;
	margin-bottom: 21px;
	overflow: hidden;
	box-shadow: 27px 24px 24px rgba(0, 0, 0, 0.14);
}
#main2 .cnt3 .area .txt .right ul li .icon {
	display: block;
	text-align: center;
	margin-top: 45px;
}
#main2 .cnt3 .area .txt .right ul li h5 {
	font-size: 30px;
	font-family: 'Poppins', sans-serif;
	color: #3e75ba;
	font-weight: 900;
	text-align: center;
	padding-top: 10px;
}
#main2 .cnt3 .area .txt .right ul li:nth-child(1),
#main2 .cnt3 .area .txt .right ul li:nth-child(3) {
	margin-left: 0;
}
#main2 .cnt3 .area .txt .right ul li:nth-child(1) .icon,
#main2 .cnt3 .area .txt .right ul li:nth-child(2) .icon {
	margin-top: 40px;
}
#main2 .cnt4 {
	background: #f7fafd;
	padding: 172px 0 158px;
}
#main2 .cnt4 .area .left {
	float: left;
	width: 642px;
}
#main2 .cnt4 .area .left ul li {
	position: relative;
	float: left;
	width: 202px;
	height: 202px;
	background: #fff;
	border: 1px solid #c6c6c6;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	overflow: hidden;
	margin-left: 18px;
	margin-bottom: 18px;
	box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.14);
}
#main2 .cnt4 .area .left ul li .icon {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
#main2 .cnt4 .area .left ul li h5 {
	font-size: 24px;
	text-align: center;
	line-height: 33px;
	letter-spacing: -0.7px;
	font-weight: 600;
	position: relative;
	z-index: 1;
	padding-top: 143px;
}
#main2 .cnt4 .area .left ul li h5 span {
	font-weight: normal;
}
#main2 .cnt4 .area .left ul li:nth-child(1),
#main2 .cnt4 .left ul li:nth-child(4),
#main2 .cnt4 .left ul li:nth-child(7) {
	margin-left: 0;
}
#main2 .cnt4 .area .left ul li.ln2 h5 {
	padding-top: 126px;
}
#main2 .cnt4 .area .right {
	float: right;
	padding-top: 141px;
}
#main2 .cnt4 .area .right h5 {
	font-size: 25px;
	font-family: 'Poppins', sans-serif;
	color: #3e75ba;
	letter-spacing: -0.7px;
}
#main2 .cnt4 .area .right h3 {
	font-size: 55px;
	line-height: 80px;
	letter-spacing: -0.7px;
	padding-top: 27px;
}
#main2 .cnt5 {
	width: 100%;
	background: #5774db;
	padding: 127px 0;
	overflow: hidden;
}
#main2 .cnt5 .area ul {
	width: 1103px;
	margin: 0 auto;
}
#main2 .cnt5 .area ul li {
	float: left;
	width: 100%;
	height: auto;
	background: #fff;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}
#main2 .cnt5 .area ul li:first-child {
	margin-left: 0;
}
#main2 .cnt5 .area ul li .img {
	display: block;
}
#main2 .cnt5 .area ul li .img img {
	width: 100%;
	height: auto;
}
#main2 .cnt5 .owl-item {
	float: left;
	height: 100%;
}
#main2 .cnt5 .owl-nav {
	display: none;
}
#main2 .cnt6 {
	background-color: #e6f2ff;
	padding-bottom: 203px;
	background-image: url('../images/main2_c6_bg.jpg');
	background-repeat: no-repeat;
	background-position: bottom center;
}
#main2 .cnt6 .area .top {
	padding-top: 202px;
}
#main2 .cnt6 .area .top .left {
	float: left;
}
#main2 .cnt6 .area .top .left h5 {
	font-size: 25px;
	font-family: 'Poppins', sans-serif;
	color: #3e75ba;
	letter-spacing: -0.7px;
}
#main2 .cnt6 .area .top .left h3 {
	font-size: 55px;
	line-height: 80px;
	letter-spacing: -0.7px;
	padding-top: 29px;
}
#main2 .cnt6 .area .top .right {
	float: right;
	padding-top: 53px;
}
#main2 .cnt6 .area .top .right ul li {
	float: left;
}
#main2 .cnt6 .area .top .right ul li.box {
	width: 241px;
	height: 241px;
	background: #fff;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.25);
}
#main2 .cnt6 .area .top .right ul li.box h4 {
	font-size: 26px;
	text-align: center;
	line-height: 36px;
	letter-spacing: -0.7px;
	padding-top: 88px;
}
#main2 .cnt6 .area .top .right ul li.box h4 strong {
	display: block;
}
#main2 .cnt6 .area .top .right ul li.box:last-child h4 {
	font-size: 34px;
	color: #5774db;
	line-height: 47px;
	font-weight: 600;
	padding-top: 75px;
}
#main2 .cnt6 .area .top .right ul li p {
	font-size: 66px;
	font-weight: 600;
	line-height: 241px;
	padding: 0 19px;
}
#main2 .cnt6 .area .btm {
	margin-top: 106px;
}
#main2 .cnt6 .area .btm ul {
	width: 1376px;
	margin: 0 auto;
}
#main2 .cnt6 .area .btm ul li {
	float: left;
	width: 336px;
	height: 336px;
	background: #fff;
	-webkit-border-radius: 168px;
	-moz-border-radius: 168px;
	border-radius: 168px;
	box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.25);
	margin-left: 184px;
}
#main2 .cnt6 .area .btm ul li:first-child {
	margin-left: 0;
}
#main2 .cnt6 .area .btm ul li .icon {
	display: block;
	text-align: center;
	padding-top: 58px;
}
#main2 .cnt6 .area .btm ul li h4 {
	font-size: 33px;
	line-height: 50px;
	letter-spacing: -1px;
	text-align: center;
	padding-top: 15px;
}
#main2 .cnt7 {
	position: relative;
	padding-bottom: 224px;
	overflow: hidden;
}
#main2 .cnt7 .area .tit {
	padding-top: 204px;
}
#main2 .cnt7 .area .tit h3 {
	font-size: 60px;
	text-align: center;
	line-height: 75px;
	letter-spacing: -0.7px;
}
#main2 .cnt7 .area .txt {
	margin-top: 103px;
}
#main2 .cnt7 .area .txt ul li {
	float: left;
	width: 430px;
	height: 391px;
	background: #fff;
	border: 2px solid #3e75ba;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 21px;
	-moz-border-radius: 21px;
	border-radius: 21px;
	margin-left: 55px;
	box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.25);
}
#main2 .cnt7 .area .txt ul li:first-child {
	margin-left: 0;
}
#main2 .cnt7 .area .txt ul li .icon {
	display: block;
	text-align: center;
	margin-top: 66px;
}
#main2 .cnt7 .area .txt ul li h4 {
	font-size: 30px;
	font-weight: 600;
	text-align: center;
	padding-top: 19px;
}
#main2 .cnt7 .area .txt ul li p {
	font-size: 22px;
	text-align: center;
	line-height: 30px;
	letter-spacing: -0.5px;
	padding-top: 7px;
}
#main2 .cnt7 .area .deco {
	position: absolute;
	top: 70px;
	right: 0;
	z-index: -1;
}
#main2 .cnt7 .area .deco img {
	width: 694px;
	height: auto;
}
#main2 .cnt8 {
	position: relative;
	background: #f7fafd;
	padding-bottom: 234px;
}
#main2 .cnt8 .area .tit {
	padding-top: 204px;
}
#main2 .cnt8 .area .tit h3 {
	font-size: 60px;
	text-align: center;
	line-height: 75px;
	letter-spacing: -0.7px;
}
#main2 .cnt8 .area .txt {
	margin-top: 103px;
}
#main2 .cnt8 .area .txt ul li {
	float: left;
	width: 430px;
	height: 391px;
	background: #fff;
	border: 2px solid #3e75ba;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 21px;
	-moz-border-radius: 21px;
	border-radius: 21px;
	margin-left: 55px;
	box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.25);
}
#main2 .cnt8 .area .txt ul li:first-child {
	margin-left: 0;
}
#main2 .cnt8 .area .txt ul li .icon {
	display: block;
	text-align: center;
	margin-top: 40px;
}
#main2 .cnt8 .area .txt ul li h4 {
	font-size: 30px;
	font-weight: 600;
	text-align: center;
	padding-top: 19px;
}
#main2 .cnt8 .area .txt ul li p {
	font-size: 22px;
	text-align: center;
	line-height: 30px;
	letter-spacing: -0.5px;
	padding-top: 7px;
}
#main2 .cnt8 .top_btn {
	width: 70px;
	height: 70px;
	position: absolute;
	bottom: 31px;
	right: 40px;
}

.sub_nav {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 52px;
	background: #fff;
	border-bottom: 1px solid #eee;
	z-index: 1;
}
.sub_nav .prev {
	float: left;
	width: 32px;
	height: 32px;
	margin: 10px 0 10px 12px;
}
.sub_nav .prev img {
	width: 100%;
	height: auto;
}
.sub_nav h2 {
	float: left;
	font-size: 18px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	line-height: 52px;
	padding-left: 16px;
}
.sub_nav .home {
	float: right;
	width: 32px;
	height: 32px;
	margin: 10px 10px 10px 0;
}
.sub_nav .home img {
	width: 100%;
	height: auto;
}
.sub_nav .close {
	float: right;
	width: 32px;
	height: 32px;
	margin: 10px 10px 10px 0;
}
.sub_nav .close img {
	width: 100%;
	height: auto;
}
.sub_nav h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 18px;
	line-height: 52px;
	padding-left: 10px;
}
.sub_nav .down {
	float: right;
	width: 24px;
	height: 24px;
	margin-top: 12px;
	margin-right: 24px;
}
.sub_nav .down img {
	width: 100%;
	height: auto;
}

#sub1 .blk_s1 {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.6;
	filter: Alpha(opacity=60);
	z-index: 10;
}
#sub1 .area .wrap .box {
	width: 600px;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	margin: 60px auto 0;
	padding-bottom: 60px;
}
#sub1 .area .wrap .box .tit > .icon {
	display: block;
	text-align: center;
	margin-top: 60px;
}
#sub1 .area .wrap .box .tit > .icon img {
	width: 30px;
	height: auto;
}
#sub1 .area .wrap .box .tit h3 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	text-align: center;
	font-size: 28px;
	line-height: 40px;
	padding-top: 10px;
}
#sub1 .area .wrap .box .tit h3 .icon {
	display: none;
}
#sub1 .area .wrap .box .txt {
	width: 400px;
	margin: 36px auto 0;
}
#sub1 .area .wrap .box .txt .top h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
}
#sub1 .area .wrap .box .txt .top h4 img {
	width: 20px;
	height: auto;
	margin-right: 6px;
}
#sub1 .area .wrap .box .txt .top .form_cont {
	position: relative;
	width: 100%;
	height: 116px;
	border: 1px solid #ddd;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	overflow: hidden;
	margin-top: 16px;
}
#sub1 .area .wrap .box .txt .top .form_cont textarea {
	font-family: SC4, SC5, 'Noto Sans KR', sans-serif;
	width: calc(100% - 40px);
	height: 80px;
	border: 0;
	resize: none;
	padding: 0 20px;
	color: #aaa;
	margin-top: 18px;
	background: none;
}
#sub1 .area .wrap .box .txt .top .form_cont textarea:focus {
	outline: none;
}
#sub1 .area .wrap .box .txt .top .form_cont label {
	position: absolute;
	top: 18px;
	left: 20px;
	cursor: text;
	font-size: 12px;
	line-height: 20px;
	color: #aaa;
	word-break: keep-all;
	width: 360px;
}
#sub1 .area .wrap .box .txt .top .w_num {
	margin-top: 16px;
}
#sub1 .area .wrap .box .txt .top .w_num p {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	text-align: right;
	color: #aaa;
}
#sub1 .area .wrap .box .txt .top .w_num p span {
	color: #5774db;
}
#sub1 .area .wrap .box .txt .btm {
	margin-top: 36px;
}
#sub1 .area .wrap .box .txt .btm h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
}
#sub1 .area .wrap .box .txt .btm h4 img {
	width: 20px;
	height: auto;
	margin-right: 6px;
}
#sub1 .area .wrap .box .txt .btm ul {
	margin-top: 16px;
}
#sub1 .area .wrap .box .txt .btm ul li {
	margin-top: 8px;
}
#sub1 .area .wrap .box .txt .btm ul li:first-child {
	margin-top: 0;
}
#sub1 .area .wrap .box .txt .btm ul li > * {
	float: left;
}
#sub1 .area .wrap .box .txt .btm ul li h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	width: 102px;
	margin-right: 5px;
	height: 50px;
	line-height: 50px;
}
#sub1 .area .wrap .box .txt .btm ul li h5 span {
	color: #5774db;
	margin-left: 5px;
}
#sub1 .area .wrap .box .txt .btm ul li.link h5 {
	line-height: 1.3;
	padding-top: 8px;
	height: auto;
}
#sub1 .area .wrap .box .txt .btm ul li .form_cont {
	position: relative;
	width: 289px;
	overflow: hidden;
	height: 50px;
	line-height: 48px;
}
#sub1 .area .wrap .box .txt .btm ul li .form_cont input {
	width: 100%;
	height: 100%;
	border: 1px solid #ddd;
	font-size: 12px;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
}
#sub1 .area .wrap .box .txt .btm ul li .form_cont fieldset{
	border: 0;
}
#sub1 .area .wrap .box .txt .btm ul li .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub1 .area .wrap .box .txt .btm ul li .form_cont input.error {
	border: 1px solid #df3c3c;
}
#sub1 .area .wrap .box .txt .btm ul li .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #aaa;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	padding-right: 20px;
	word-break: keep-all;
	line-height: 1.3;
}
#sub1 .area .wrap .box .txt .btm ul li strong {
	display: block;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 11px;
	color: #df3c3c;
	padding: 13px 0 5px 107px;
}
#sub1 .area .wrap .box .txt .btm .chk_wrap {
	margin-top: 16px;
}
#sub1 .area .wrap .box .txt .btm .chk_wrap > * {
	float: left;
}
#sub1 .area .wrap .box .txt .btm .chk_wrap > div input[type='checkbox'] {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
#sub1 .area .wrap .box .txt .btm .chk_wrap > div input[type='checkbox'] + label {
	display: inline-block;
	position: relative;
	cursor: pointer;
	font-size: 14px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	padding-left: 22px;
}
#sub1 .area .wrap .box .txt .btm .chk_wrap > div input[type='checkbox'] + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 16px;
	height: 16px;
	text-align: center;
	background: url('../images/checkbox_off.jpg') no-repeat center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
}
#sub1 .area .wrap .box .txt .btm .chk_wrap > div input[type='checkbox']:checked + label:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 16px;
	height: 16px;
	text-align: center;
	background: url('../images/checkbox_on.jpg') no-repeat center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
}
#sub1 .area .wrap .box .txt .btm .chk_wrap a {
	font-size: 13px;
	color: #999;
	text-decoration: underline;
	margin-left: 10px;
}
#sub1 .area .wrap .save_btn {
	display: block;
	width: 288px;
	height: 48px;
	line-height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #5774db;
	margin: 0 auto;
	color: #fff;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	margin-top: 24px;
	margin-bottom: 60px;
}
#sub1 .cnt1 .pvc_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	width: 600px;
	height: 80%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	background: #fff;
	z-index: 15;
	-webkit-border-radius: px;
	-moz-border-radius: px;
	border-radius: 20px;
}
#sub1 .cnt1 .pvc_pu .close_btn {
	position: absolute;
	top: 17px;
	right: 20px;
}
#sub1 .cnt1 .pvc_pu .wrap {
	width: 100%;
	height: 90%;
	overflow: auto;
	margin: 7% auto 0;
}
#sub1 .cnt1 .pvc_pu .wrap h2 {
	font-size: 26px;
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
	padding-bottom: 50px;
	text-align: center;
	padding-top: 30px;
}
#sub1 .cnt1 .pvc_pu .wrap .pvc {
	margin-bottom: 40px;
	padding: 0 5%;
}
#sub1 .cnt1 .pvc_pu .wrap .pvc h3 {
	font-size: 13px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub1 .cnt1 .pvc_pu .wrap .pvc p {
	font-size: 13px;
	line-height: 1.7;
	margin-bottom: 10px;
	margin-top: 10px;
	color: #666;
}
#sub1 .cnt1 .pvc_pu .wrap .pvc p.ml {
	padding-left: 10px;
	margin-bottom: 3px;
	margin-top: 0;
}
#sub1 .cnt1 .pvc_pu .wrap .pvc p.ml2 {
	padding-left: 20px;
}
#sub1 .cnt1 .pvc_pu .wrap > p {
	font-size: 13px;
	padding-bottom: 20px;
	padding-left: 5%;
}

.wd_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 280px;
	height: 174px;
	background: #fff;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	z-index: 12;
}
.wd_pu h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	text-align: center;
	font-size: 16px;
	line-height: 22px;
	padding: 36px 0 32px;
}
.wd_pu .btn_wrap {
	text-align: center;
}
.wd_pu .btn_wrap button {
	display: inline-block;
	width: 120px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #fff;
	font-size: 16px;
}
.wd_pu .btn_wrap .cancel {
	background: #666;
}
.wd_pu .btn_wrap .ok {
	background: #5774db;
	margin-left: 2px;
}

#sub2 .cnt1 {
	margin-top: 60px;
}
#sub2 .cnt1 .area .wrap {
	width: 900px;
	margin: 0 auto;
}
#sub2 .cnt1 .area .wrap .box {
	padding-bottom: 30px;
	margin-bottom: 30px;
	border-bottom: 1px solid #eee;
}
#sub2 .cnt1 .area .wrap .box > div {
	float: left;
}
#sub2 .cnt1 .area .wrap .box .visual {
	width: 384px;
}
#sub2 .cnt1 .area .wrap .box .visual img {
	width: 100%;
	height: auto;
}
#sub2 .cnt1 .area .wrap .box .tit {
	position: relative;
	margin-left: 50px;
	width: calc(100% - 434px);
	height: 384px;
}
#sub2 .cnt1 .area .wrap .box .tit > h2 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 26px;
	color: #222;
	padding-top: 5px;
}
#sub2 .cnt1 .area .wrap .box .tit .p_wrap {
	margin-top: 10px;
}
#sub2 .cnt1 .area .wrap .box .tit .p_wrap .pw_1 {
	width: 100%;
}
#sub2 .cnt1 .area .wrap .box .tit .p_wrap .pw_1 > p {
	float: left;
	font-size: 14px;
	color: #666;
	background: url('../images/s2_bar.jpg') no-repeat left center;
	-webkit-background-size: 1px auto;
	-moz-background-size: 1px auto;
	background-size: 1px auto;
	padding-left: 9px;
	margin-left: 9px;
	margin-top: 5px;
}
#sub2 .cnt1 .area .wrap .box .tit .p_wrap .pw_1 > p.pnm {
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
	padding-left: 0;
	margin-left: 0;
	background: none;
}
#sub2 .cnt1 .area .wrap .box .tit .p_wrap ul {
	margin-top: 30px;
}
#sub2 .cnt1 .area .wrap .box .tit .p_wrap ul li {
	float: left;
	margin-left: 4px;
	background: #f1f3f9;
	padding: 0 10px;
	height: 26px;
	line-height: 26px;
	-webkit-border-radius: 13px;
	-moz-border-radius: 13px;
	border-radius: 13px;
}
#sub2 .cnt1 .area .wrap .box .tit .p_wrap ul li:first-child {
	margin-left: 0;
}
#sub2 .cnt1 .area .wrap .box .tit .p_wrap ul li a {
	display: block;
	width: 100%;
	height: 100%;
	font-size: 11px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #677cc4;
	text-align: center;
}
#sub2 .cnt1 .area .wrap .box .info {
	padding-top: 25px;
	margin-top: 36px;
	border-top: 1px solid #eee;
}
#sub2 .cnt1 .area .wrap .box .info ul li {
	height: 38px;
	line-height: 38px;
}
#sub2 .cnt1 .area .wrap .box .info ul li > * {
	float: left;
	font-size: 16px;
}
#sub2 .cnt1 .area .wrap .box .info ul li h4 {
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
	color: #222;
}
#sub2 .cnt1 .area .wrap .box .info ul li p {
	color: #666;
	padding-left: 23px;
}
#sub2 .cnt1 .area .wrap .box .tit .s_util {
	position: absolute;
	bottom: 0;
	right: 0;
}
#sub2 .cnt1 .area .wrap .box .tit .s_util ul li {
	float: left;
	width: 36px;
	height: 36px;
	margin-left: 12px;
}
#sub2 .cnt1 .area .wrap .box .tit .s_util ul li:first-child {
	margin-left: 0;
}
#sub2 .cnt1 .area .wrap .box .tit .s_util ul li button {
	display: block;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
}
#sub2 .cnt1 .area .wrap .box .tit .s_util ul li.scrap_btn button {
	background-image: url('../images/btn_scrap.png');
}
#sub2 .cnt1 .area .wrap .box .tit .s_util ul li.scrap_btn.on button {
	background-image: url('../images/btn_scrap_on.png');
}
#sub2 .cnt1 .area .wrap .box .tit .s_util ul li.share_btn button {
	background-image: url('../images/btn_share.png');
}
#sub2 .cnt1 .area .wrap .wd_btn {
	display: block;
	width: 100%;
	height: 51px;
	line-height: 51px;
	background: #5774db;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	text-align: center;
	color: #fff;
	font-size: 18px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	margin-top: 16px;
}
#sub2 .cnt1 .area .wrap .txt {
	padding-top: 32px;
	padding-bottom: 41px;
}
#sub2 .cnt1 .area .wrap .txt > ul > li {
	margin-top: 48px;
}
#sub2 .cnt1 .area .wrap .txt > ul > li:first-child {
	margin-top: 0;
}
#sub2 .cnt1 .area .wrap .txt > ul > li h4 {
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
	font-size: 18px;
	color: #444;
	padding-bottom: 16px;
}
#sub2 .cnt1 .area .wrap .txt > ul > li p {
	font-size: 15px;
	line-height: 1.8;
	color: #666;
	word-break: keep-all;
}
#sub2 .cnt1 .area .wrap .txt .txt_util {
	width: 100px;
	margin: 52px auto 0;
}
#sub2 .cnt1 .area .wrap .txt .txt_util ul li {
	float: left;
	width: 44px;
	margin-left: 12px;
}
#sub2 .cnt1 .area .wrap .txt .txt_util ul li:first-child {
	margin-left: 0;
}
#sub2 .cnt1 .area .wrap .txt .txt_util ul li button .icon {
	position: relative;
	display: block;
	width: 44px;
	height: 44px;
	-webkit-border-radius: 22px;
	-moz-border-radius: 22px;
	border-radius: 22px;
	border: 1px solid #e4ecff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 28px auto;
	-moz-background-size: 28px auto;
	background-size: 28px auto;
}
#sub2 .cnt1 .area .wrap .txt .txt_util ul li.scrap_btn button .icon {
	background-image: url('../images/scrap.png');
}
#sub2 .cnt1 .area .wrap .txt .txt_util ul li.scrap_btn.on button .icon {
	background-image: url('../images/scrap_on.png');
}
#sub2 .cnt1 .area .wrap .txt .txt_util ul li.share_btn button .icon {
	background-image: url('../images/share.png');
}
#sub2 .cnt1 .area .wrap .txt .txt_util ul li button p {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	text-align: center;
	color: #5774db;
	font-size: 11px;
	padding-top: 5px;
}
#sub2 .cnt1 .area .wrap .bar {
	display: block;
	width: 100%;
	height: 8px;
	background: #f4f4f4;
}
#sub2 .cnt2 .area .sub_cmn {
	padding-top: 60px;
	width: 780px;
	margin: 0 auto;
	padding-bottom: 64px;
}
#sub2 .cnt2 .area .sub_cmn h4 {
	font-size: 20px;
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
	text-align: center;
}
#sub2 .cnt2 .area .sub_cmn ul {
	margin-top: 32px;
}
#sub2 .cnt2 .area .sub_cmn ul li {
	float: left;
	width: 180px;
	margin-left: 20px;
}
#sub2 .cnt2 .area .sub_cmn ul li:first-child {
	margin-left: 0;
}
#sub2 .cnt2 .area .sub_cmn ul li a {
	display: block;
	width: 100%;
	height: 100%;
}
#sub2 .cnt2 .area .sub_cmn ul li a .img img {
	width: 100%;
	height: auto;
}
#sub2 .cnt2 .area .sub_cmn ul li a h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #444;
	font-size: 14px;
	line-height: 17px;
	padding-right: 20px;
	word-break: keep-all;
	padding-top: 12px;
}
#sub2 .cnt2 .area .sub_cmn ul li a .p_wrap {
	margin-top: 10px;
}
#sub2 .cnt2 .area .sub_cmn ul li a .p_wrap > * {
	float: left;
}
#sub2 .cnt2 .area .sub_cmn ul li a .p_wrap h6 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #444;
	font-size: 13px;
}
#sub2 .cnt2 .area .sub_cmn ul li a .p_wrap p {
	font-size: 13px;
	color: #999;
	padding-left: 6px;
	margin-left: 5px;
	background: url('../images/s2_bar.jpg') no-repeat left center;
	-webkit-background-size: 1px auto;
	-moz-background-size: 1px auto;
	background-size: 1px auto;
}

#sub3 .sup_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 450px;
	height: 200px;
	background: #fff;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	z-index: 12;
}
#sub3 .sup_pu h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	text-align: center;
	font-size: 16px;
	line-height: 22px;
	padding: 36px 0 32px;
	line-height: 1.5;
}
#sub3 .sup_pu .btn_wrap {
	text-align: center;
}
#sub3 .sup_pu .btn_wrap button {
	display: inline-block;
	width: 120px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #fff;
	font-size: 16px;
}
#sub3 .sup_pu .btn_wrap .cancel {
	background: #666;
}
#sub3 .sup_pu .btn_wrap .ok {
	background: #5774db;
	margin-left: 2px;
}
#sub3 .cnt1 {
	margin-top: 60px;
}
#sub3 .cnt1 .area .wrap {
	width: 900px;
	margin: 0 auto;
}
#sub3 .cnt1 .area .wrap .box {
	padding-bottom: 30px;
	margin-bottom: 30px;
	border-bottom: 1px solid #eee;
}
#sub3 .cnt1 .area .wrap .box > div {
	float: left;
}
#sub3 .cnt1 .area .wrap .box .visual {
	width: 384px;
}
#sub3 .cnt1 .area .wrap .box .visual img {
	width: 100%;
	height: auto;
}
#sub3 .cnt1 .area .wrap .box .tit {
	position: relative;
	margin-left: 50px;
	width: calc(100% - 434px);
	height: 384px;
}
#sub3 .cnt1 .area .wrap .box .tit > h2 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 26px;
	color: #222;
	padding-top: 5px;
}
#sub3 .cnt1 .area .wrap .box .tit .p_wrap {
	margin-top: 10px;
}
#sub3 .cnt1 .area .wrap .box .tit .p_wrap .pw_1 {
	width: 100%;
}
#sub3 .cnt1 .area .wrap .box .tit .p_wrap .pw_1 > p {
	float: left;
	font-size: 14px;
	color: #666;
	background: url('../images/s2_bar.jpg') no-repeat left center;
	-webkit-background-size: 1px auto;
	-moz-background-size: 1px auto;
	background-size: 1px auto;
	padding-left: 9px;
	margin-left: 9px;
	margin-top: 5px;
}
#sub3 .cnt1 .area .wrap .box .tit .p_wrap .pw_1 > p.pnm {
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
	padding-left: 0;
	margin-left: 0;
	background: none;
}
#sub3 .cnt1 .area .wrap .box .tit .p_wrap ul {
	margin-top: 30px;
}
#sub3 .cnt1 .area .wrap .box .tit .p_wrap ul li {
	float: left;
	margin-left: 4px;
	background: #f1f3f9;
	padding: 0 10px;
	height: 26px;
	line-height: 26px;
	-webkit-border-radius: 13px;
	-moz-border-radius: 13px;
	border-radius: 13px;
}
#sub3 .cnt1 .area .wrap .box .tit .p_wrap ul li:first-child {
	margin-left: 0;
}
#sub3 .cnt1 .area .wrap .box .tit .p_wrap ul li a {
	display: block;
	width: 100%;
	height: 100%;
	font-size: 11px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #677cc4;
	text-align: center;
}
#sub3 .cnt1 .area .wrap .box .tit .info {
	padding-top: 25px;
	margin-top: 36px;
	border-top: 1px solid #eee;
}
#sub3 .cnt1 .area .wrap .box .tit .info ul li {
	height: 38px;
	line-height: 38px;
}
#sub3 .cnt1 .area .wrap .box .tit .info ul li > * {
	float: left;
	font-size: 16px;
}
#sub3 .cnt1 .area .wrap .box .tit .info ul li h4 {
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
	color: #222;
}
#sub3 .cnt1 .area .wrap .box .tit .info ul li p {
	color: #666;
	padding-left: 23px;
}
#sub3 .cnt1 .area .wrap .box .tit .s_util {
	position: absolute;
	bottom: 0;
	right: 0;
}
#sub3 .cnt1 .area .wrap .box .tit .s_util ul li {
	float: left;
	width: 36px;
	height: 36px;
	margin-left: 12px;
}
#sub3 .cnt1 .area .wrap .box .tit .s_util ul li:first-child {
	margin-left: 0;
}
#sub3 .cnt1 .area .wrap .box .tit .s_util ul li button {
	display: block;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
}
#sub3 .cnt1 .area .wrap .box .tit .s_util ul li.scrap_btn button {
	background-image: url('../images/btn_scrap.png');
}
#sub3 .cnt1 .area .wrap .box .tit .s_util ul li.scrap_btn.on button {
	background-image: url('../images/btn_scrap_on.png');
}
#sub3 .cnt1 .area .wrap .box .tit .s_util ul li.share_btn button {
	background-image: url('../images/btn_share.png');
}
#sub3 .cnt1 .area .wrap .sup_btn {
	display: block;
	width: 100%;
	height: 51px;
	line-height: 51px;
	background: #5774db;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	text-align: center;
	color: #fff;
	font-size: 18px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
}
#sub3 .cnt1 .area .wrap .ied_btn {
	display: block;
	width: 100%;
	height: 51px;
	line-height: 51px;
	background: #aaa;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	text-align: center;
	color: #fff;
	font-size: 18px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	margin-top: 16px;
}
#sub3 .cnt1 .area .wrap .wd_btn {
	display: block;
	text-align: center;
	font-size: 14px;
	color: #999;
	text-decoration: underline;
	margin: 10px auto 0;
}
#sub3 .cnt1 .area .wrap .txt {
	padding-top: 50px;
	padding-bottom: 45px;
}
#sub3 .cnt1 .area .wrap .txt .project h4 {
	font-size: 18px;
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
	color: #444;
}
#sub3 .cnt1 .area .wrap .txt .project p {
	font-size: 15px;
	line-height: 1.8;
	color: #666;
	word-break: keep-all;
	padding-top: 16px;
}
#sub3 .cnt1 .area .wrap .txt .project .img {
	width: 700px;
	margin: 32px auto;
}
#sub3 .cnt1 .area .wrap .txt .project .img img {
	width: 100%;
	height: auto;
}
#sub3 .cnt1 .area .wrap .txt .txt_util {
	width: 100px;
	margin: 50px auto 0;
}
#sub3 .cnt1 .area .wrap .txt .txt_util ul li {
	float: left;
	width: 44px;
	margin-left: 12px;
}
#sub3 .cnt1 .area .wrap .txt .txt_util ul li:first-child {
	margin-left: 0;
}
#sub3 .cnt1 .area .wrap .txt .txt_util ul li button .icon {
	position: relative;
	display: block;
	width: 44px;
	height: 44px;
	-webkit-border-radius: 22px;
	-moz-border-radius: 22px;
	border-radius: 22px;
	border: 1px solid #e4ecff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 28px auto;
	-moz-background-size: 28px auto;
	background-size: 28px auto;
}
#sub3 .cnt1 .area .wrap .txt .txt_util ul li.scrap_btn button .icon {
	background-image: url('../images/scrap.png');
}
#sub3 .cnt1 .area .wrap .txt .txt_util ul li.scrap_btn.on button .icon {
	background-image: url('../images/scrap_on.png');
}
#sub3 .cnt1 .area .wrap .txt .txt_util ul li.share_btn button .icon {
	background-image: url('../images/share.png');
}
#sub3 .cnt1 .area .wrap .txt .txt_util ul li button p {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	text-align: center;
	color: #5774db;
	font-size: 11px;
	padding-top: 5px;
}
#sub3 .cnt1 .area .wrap .bar {
	display: block;
	width: 100%;
	height: 8px;
	background: #f4f4f4;
}
#sub3 .cnt2 .area .sub_cmn {
	padding-top: 60px;
	width: 780px;
	margin: 0 auto;
	padding-bottom: 64px;
}
#sub3 .cnt2 .area .sub_cmn h4 {
	font-size: 20px;
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
	text-align: center;
}
#sub3 .cnt2 .area .sub_cmn ul {
	margin-top: 32px;
}
#sub3 .cnt2 .area .sub_cmn ul li {
	float: left;
	width: 180px;
	margin-left: 20px;
}
#sub3 .cnt2 .area .sub_cmn ul li:first-child {
	margin-left: 0;
}
#sub3 .cnt2 .area .sub_cmn ul li a {
	display: block;
	width: 100%;
	height: 100%;
}
#sub3 .cnt2 .area .sub_cmn ul li a .img img {
	width: 100%;
	height: auto;
}
#sub3 .cnt2 .area .sub_cmn ul li a h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #444;
	font-size: 14px;
	line-height: 17px;
	padding-right: 20px;
	word-break: keep-all;
	padding-top: 12px;
}
#sub3 .cnt2 .area .sub_cmn ul li a .p_wrap {
	margin-top: 10px;
}
#sub3 .cnt2 .area .sub_cmn ul li a .p_wrap > * {
	float: left;
}
#sub3 .cnt2 .area .sub_cmn ul li a .p_wrap h6 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #444;
	font-size: 13px;
}
#sub3 .cnt2 .area .sub_cmn ul li a .p_wrap p {
	font-size: 13px;
	color: #999;
	padding-left: 6px;
	margin-left: 5px;
	background: url('../images/s2_bar.jpg') no-repeat left center;
	-webkit-background-size: 1px auto;
	-moz-background-size: 1px auto;
	background-size: 1px auto;
}

#sub4 {
	font-family: 'Spoqa Han Sans Neo', 'sans-serif';
}
#sub4 .sup_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 450px;
	height: 200px;
	background: #fff;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	z-index: 12;
}
#sub4 .sup_pu h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	text-align: center;
	font-size: 16px;
	line-height: 22px;
	padding: 36px 0 32px;
	line-height: 1.5;
}
#sub4 .sup_pu .btn_wrap {
	text-align: center;
}
#sub4 .sup_pu .btn_wrap button {
	display: inline-block;
	width: 120px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #fff;
	font-size: 16px;
}
#sub4 .sup_pu .btn_wrap .cancel {
	background: #666;
}
#sub4 .sup_pu .btn_wrap .ok {
	background: #5774db;
	margin-left: 2px;
}
#sub4 .cnt1 {
	margin-top: 60px;
}
#sub4 .cnt1 .visual {
	position: relative;
	width: calc(100% - 100px);
	height: 500px;
	margin: 50px auto 0;
	background: url('../images/s4_visual.jpg') no-repeat center;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
}
#sub4 .cnt1 .visual .area .tit {
	width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
#sub4 .cnt1 .visual .area .tit h2 {
	font-size: 45px;
	color: #fff;
	text-align: center;
	font-weight: 700;
}
#sub4 .cnt1 .visual .area .tit ul {
	text-align: center;
	opacity: 0.5;
	filter: Alpha(opacity=50);
	margin-top: 25px;
}
#sub4 .cnt1 .visual .area .tit ul li {
	display: inline-block;
	padding-left: 20px;
	margin-left: 18px;
	background: url('../images/s2_bar.jpg') no-repeat left center;
}
#sub4 .cnt1 .visual .area .tit ul li:first-child {
	padding-left: 0;
	margin-left: 0;
	background: none;
}
#sub4 .cnt1 .visual .area .tit ul li p {
	font-size: 22px;
	color: #fff;
	font-size: 500;
}
#sub4 .cnt1 .visual .area .wrap {
	position: absolute;
	bottom: -35px;
	left: 50%;
	width: 900px;
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	z-index: 1;
}
#sub4 .cnt1 .visual .area .wrap .hash .p_wrap {
	float: right;
	margin-bottom: 15px;
	margin-right: 37px;
}
#sub4 .cnt1 .visual .area .wrap .hash .p_wrap p {
	float: left;
	font-size: 14px;
	color: #fff;
	padding-left: 15px;
}
#sub4 .cnt1 .visual .area .wrap .hash .p_wrap p:first-child {
	padding-left: 0;
}
#sub4 .cnt1 .visual .area .wrap .box {
	width: 100%;
	height: 70px;
	background: #fff;
	-webkit-border-radius: 35px;
	-moz-border-radius: 35px;
	border-radius: 35px;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}
#sub4 .cnt1 .visual .area .wrap .box .left {
	float: left;
	line-height: 70px;
	padding-left: 56px;
}
#sub4 .cnt1 .visual .area .wrap .box .left ul li {
	float: left;
	margin-left: 32px;
}
#sub4 .cnt1 .visual .area .wrap .box .left ul li:first-child {
	margin-left: 0;
}
#sub4 .cnt1 .visual .area .wrap .box .left ul li > * {
	float: left;
	font-size: 16px;
}
#sub4 .cnt1 .visual .area .wrap .box .left ul li h4 {
	color: #222;
	font-weight: 700;
}
#sub4 .cnt1 .visual .area .wrap .box .left ul li p {
	color: #666;
	padding-left: 12px;
}
#sub4 .cnt1 .visual .area .wrap .box .right {
	float: right;
	margin-right: 37px;
}
#sub4 .cnt1 .visual .area .wrap .box .right ul {
	margin-top: 17px;
}
#sub4 .cnt1 .visual .area .wrap .box .right ul li {
	float: left;
	width: 36px;
	height: 36px;
	margin-left: 12px;
}
#sub4 .cnt1 .visual .area .wrap .box .right ul li:first-child {
	margin-left: 0;
}
#sub4 .cnt1 .visual .area .wrap .box .right ul li button {
	display: block;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
}
#sub4 .cnt1 .visual .area .wrap .box .right ul li.scrap_btn button {
	background-image: url('../images/btn_scrap.png');
}
#sub4 .cnt1 .visual .area .wrap .box .right ul li.scrap_btn.on button {
	background-image: url('../images/btn_scrap_on.png');
}
#sub4 .cnt1 .visual .area .wrap .box .right ul li.share_btn button {
	background-image: url('../images/btn_share.png');
}

#sub4 .cnt1 .area .wrap {
	width: 900px;
	margin: 0 auto;
}
#sub4 .cnt1 .area .wrap .txt {
	padding-top: 32px;
	padding-bottom: 45px;
}
#sub4 .cnt1 .area .wrap .txt .project > h4 {
	font-size: 20px;
	font-weight: 700;
	color: #333;
	padding-top: 40px;
}
#sub4 .cnt1 .area .wrap .txt .project > p {
	font-size: 18px;
	color: #666;
	line-height: 1.5;
	padding-top: 20px;
}
#sub4 .cnt1 .area .wrap .txt .project .img {
	text-align: center;
	margin: 70px 0;
}
#sub4 .cnt1 .area .wrap .txt .project .img img {
	width: 100%;
	height: auto;
}
#sub4 .cnt1 .area .wrap .txt .project .img span {
	display: block;
	font-size: 14px;
	color: #999;
	text-align: center;
	padding-top: 10px;
}
#sub4 .cnt1 .area .wrap .txt .hash {
	text-align: center;
	margin-top: 50px;
}
#sub4 .cnt1 .area .wrap .txt .hash p {
	display: inline-block;
	font-size: 12px;
	color: #fff;
	height: 28px;
	line-height: 28px;
	overflow: hidden;
	padding: 0 15px;
	background: #677cc4;
	-webkit-border-radius: 14px;
	-moz-border-radius: 14px;
	border-radius: 14px;
	margin-left: 5px;
}
#sub4 .cnt1 .area .wrap .txt .hash p:first-child {
	margin-left: 0;
}
#sub4 .cnt1 .area .wrap .txt .parti_banner {
	width: 100%;
	background: #f4f7ff;
	margin-top: 32px;
	padding: 36px 0;
}
#sub4 .cnt1 .area .wrap .txt .parti_banner .illu {
	display: block;
	width: 164px;
	margin: 0 auto;
}
#sub4 .cnt1 .area .wrap .txt .parti_banner .illu img {
	width: 100%;
	height: auto;
}
#sub4 .cnt1 .area .wrap .txt .parti_banner h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	text-align: center;
	color: #222;
	font-size: 18px;
	line-height: 24px;
	padding-top: 20px;
}
#sub4 .cnt1 .area .wrap .txt .parti_banner h4 span {
	color: #5774db;
	text-decoration: underline;
}
#sub4 .cnt1 .area .wrap .txt .txt_util {
	width: 100px;
	margin: 36px auto 0;
}
#sub4 .cnt1 .area .wrap .txt .txt_util ul li {
	float: left;
	width: 44px;
	margin-left: 12px;
}
#sub4 .cnt1 .area .wrap .txt .txt_util ul li:first-child {
	margin-left: 0;
}
#sub4 .cnt1 .area .wrap .txt .txt_util ul li button .icon {
	position: relative;
	display: block;
	width: 44px;
	height: 44px;
	-webkit-border-radius: 22px;
	-moz-border-radius: 22px;
	border-radius: 22px;
	border: 1px solid #e4ecff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 28px auto;
	-moz-background-size: 28px auto;
	background-size: 28px auto;
}
#sub4 .cnt1 .area .wrap .txt .txt_util ul li.scrap_btn button .icon {
	background-image: url('../images/scrap.png');
}
#sub4 .cnt1 .area .wrap .txt .txt_util ul li.scrap_btn.on button .icon {
	background-image: url('../images/scrap_on.png');
}
#sub4 .cnt1 .area .wrap .txt .txt_util ul li.share_btn button .icon {
	background-image: url('../images/share.png');
}
#sub4 .cnt1 .area .wrap .txt .txt_util ul li button p {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	text-align: center;
	color: #5774db;
	font-size: 11px;
	padding-top: 5px;
}
#sub4 .cnt1 .area .wrap .bar {
	display: block;
	width: 100%;
	height: 8px;
	background: #f4f4f4;
}
#sub4 .cnt1 .area .wrap .bar2 {
	margin-top: 36px;
}
#sub4 .cnt1 .area .wrap .bar3 {
	margin-top: 48px;
}
#sub4 .cnt1 .area .wrap .comts {
	width: 652px;
	margin: 0 auto;
	padding-top: 44px;
}
#sub4 .cnt1 .area .wrap .comts > h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #222;
}
#sub4 .cnt1 .area .wrap .comts > h4 span {
	color: #5774db;
}
#sub4 .cnt1 .area .wrap .comts > .form_cont {
	position: relative;
	width: 100%;
	height: 53px;
	overflow: hidden;
	margin-top: 16px;
}
#sub4 .cnt1 .area .wrap .comts > .form_cont textarea {
	font-family: SC4, SC5, 'Noto Sans KR', sans-serif;
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	resize: none;
	padding: 10px 12px;
	color: #222;
	font-size: 12px;
	padding-right: 60px;
}
#sub4 .cnt1 .area .wrap .comts > .form_cont textarea:focus {
	outline: none;
	color: #222;
	border: 1px solid #5774db;
}
#sub4 .cnt1 .area .wrap .comts > .form_cont label {
	position: absolute;
	top: 10px;
	left: 12px;
	cursor: text;
	font-size: 12px;
	line-height: 20px;
	color: #999;
	word-break: keep-all;
	width: 360px;
}
#sub4 .cnt1 .area .wrap .comts > .form_cont .submit_btn {
	position: absolute;
	top: 50%;
	right: 12px;
	margin-top: -14px;
	width: 38px;
	height: 29px;
	background: #5774db;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #fff;
	font-size: 11px;
}
#sub4 .cnt1 .area .wrap .comts .w_num {
	width: 100%;
	margin: 5px auto 0;
}
#sub4 .cnt1 .area .wrap .comts .w_num p {
	text-align: right;
	color: #aaa;
	font-size: 11px;
}
#sub4 .cnt1 .area .wrap .comts .w_num p span {
	color: #5774db;
}
#sub4 .cnt1 .area .wrap .comts > ul {
	margin-top: 16px;
}
#sub4 .cnt1 .area .wrap .comts > ul > li {
	padding: 12px 0;
	border-top: 1px solid #f2f2f2;
}
#sub4 .cnt1 .area .wrap .comts > ul > li:first-child {
	border-top: 0;
	padding-top: 0;
}
#sub4 .cnt1 .area .wrap .comts ul li .top > * {
	float: left;
}
#sub4 .cnt1 .area .wrap .comts ul li .top .profile_img {
	width: 36px;
	height: 36px;
}
#sub4 .cnt1 .area .wrap .comts ul li .top .profile_img img {
	width: 100%;
	height: auto;
}
#sub4 .cnt1 .area .wrap .comts ul li .top .mem {
	margin-left: 12px;
}
#sub4 .cnt1 .area .wrap .comts ul li .top .mem .h_wrap > * {
	float: left;
}
#sub4 .cnt1 .area .wrap .comts ul li .top .mem .h_wrap h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #444;
	margin-right: 8px;
}
#sub4 .cnt1 .area .wrap .comts ul li .top .mem .h_wrap .don_pri {
	color: #444;
}
#sub4 .cnt1 .area .wrap .comts ul li .top .mem .date {
	font-size: 10px;
	color: #999;
	padding-top: 6px;
}
#sub4 .cnt1 .area .wrap .comts ul li .top .comts_util {
	float: right;
}
#sub4 .cnt1 .area .wrap .comts ul li .top .comts_util ul li {
	float: left;
	height: 14px;
	line-height: 14px;
}
#sub4 .cnt1 .area .wrap .comts ul li .top .comts_util ul li.cm_u_li1 > * {
	float: left;
}
#sub4 .cnt1 .area .wrap .comts ul li .top .comts_util ul li.cm_u_li1 .icon {
	width: 15px;
	height: 13px;
	background-image: url('../images/heart_off.png');
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
	margin-right: 4px;
	cursor: pointer;
}
#sub4 .cnt1 .area .wrap .comts ul li .top .comts_util ul li.cm_u_li1 .icon.on {
	background-image: url('../images/heart_on.png');
}
#sub4 .cnt1 .area .wrap .comts ul li .top .comts_util ul li.cm_u_li1 p {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #999;
	font-size: 11px;
}
#sub4 .cnt1 .area .wrap .comts ul li .top .comts_util ul li.cm_u_li2 {
	margin-left: 12px;
}
#sub4 .cnt1 .area .wrap .comts ul li .top .comts_util ul li.cm_u_li2 a {
	font-size: 11px;
	color: #999;
}
#sub4 .cnt1 .area .wrap .comts .btm {
	padding: 15px 0;
}
#sub4 .cnt1 .area .wrap .comts .btm p {
	font-size: 13px;
	line-height: 16px;
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap {
	margin-top: 15px;
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap ul li {
	position: relative;
	float: left;
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap ul li button {
	background: #f2f2f2;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	padding: 6px 10px;
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap ul li button > * {
	float: left;
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap ul li button .icon {
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap ul li button p {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 11px;
	color: #444;
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap ul li.on button {
	background: #5774db;
	color: #fff;
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap ul li.on button p {
	color: #fff;
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap ul li.comts_write1 button .icon {
	width: 12px;
	height: 9px;
	margin-top: 3px;
	margin-right: 4px;
	background-image: url('../images/commt_icon_off.png');
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap ul li.comts_write1.on button .icon {
	background-image: url('../images/commt_icon_on.png');
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap ul li.comts_write2 {
	margin-left: 8px;
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap ul li.comts_write2 button .icon {
	width: 10px;
	height: 9px;
	margin-top: 2px;
	margin-right: 6px;
	background-image: url('../images/good.png');
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap .comts_write1_fc {
	display: none;
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap .comts_write1_fc .form_cont {
	position: relative;
	width: 100%;
	height: 57px;
	overflow: hidden;
	margin-top: 12px;
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap .comts_write1_fc .form_cont textarea {
	font-family: SC4, SC5, 'Noto Sans KR', sans-serif;
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	resize: none;
	padding: 10px 12px;
	color: #222;
	font-size: 12px;
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap .comts_write1_fc .form_cont textarea:focus {
	outline: none;
	color: #222;
	border: 1px solid #5774db;
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap .comts_write1_fc .form_cont label {
	position: absolute;
	top: 10px;
	left: 12px;
	cursor: text;
	font-size: 12px;
	line-height: 20px;
	color: #999;
	word-break: keep-all;
	width: 360px;
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap .comts_write1_fc .form_cont .submit_btn {
	position: absolute;
	top: 50%;
	right: 12px;
	margin-top: -14px;
	width: 38px;
	height: 29px;
	background: #5774db;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #fff;
	font-size: 11px;
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap .comts_write2_fc {
	display: none;
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap .comts_write2_fc .form_cont {
	position: relative;
	width: 100%;
	height: 140px;
	overflow: hidden;
	margin-top: 12px;
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap .comts_write2_fc .form_cont textarea {
	font-family: SC4, SC5, 'Noto Sans KR', sans-serif;
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	resize: none;
	padding: 10px 12px;
	color: #222;
	font-size: 12px;
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap .comts_write2_fc .form_cont textarea:focus {
	outline: none;
	color: #222;
	border: 1px solid #5774db;
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap .comts_write2_fc .form_cont label {
	position: absolute;
	top: 10px;
	left: 12px;
	cursor: text;
	font-size: 12px;
	line-height: 20px;
	color: #999;
	word-break: keep-all;
	width: 360px;
}
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap .comts_write2_fc .form_cont .submit_btn {
	position: absolute;
	top: 15px;
	right: 12px;
	width: 38px;
	height: 29px;
	background: #5774db;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #fff;
	font-size: 11px;
}
#sub4 .cnt1 .area .wrap .thanks_banner {
	padding-top: 36px;
}
#sub4 .cnt1 .area .wrap .thanks_banner h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
	line-height: 26px;
	color: #5774db;
	text-align: center;
}
#sub4 .cnt1 .area .wrap .thanks_banner .box {
	width: 312px;
	margin: 36px auto 0;
	background: #fff2c3;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	padding: 14px 0 32px;
}
#sub4 .cnt1 .area .wrap .thanks_banner .box .illu {
	display: block;
	width: 174px;
	margin: 0 auto 16px;
}
#sub4 .cnt1 .area .wrap .thanks_banner .box .illu img {
	width: 100%;
	height: auto;
}
#sub4 .cnt1 .area .wrap .thanks_banner .box p {
	font-family: MaBuB, 'Noto Sans KR', sans-serif;
	font-size: 11px;
	line-height: 15px;
	padding: 0 20px;
	word-break: keep-all;
	color: #444;
}
#sub4 .cnt2 .area .sub_cmn {
	padding-top: 60px;
	width: 780px;
	margin: 0 auto;
	padding-bottom: 64px;
}
#sub4 .cnt2 .area .sub_cmn h4 {
	font-size: 20px;
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
	text-align: center;
}
#sub4 .cnt2 .area .sub_cmn ul {
	margin-top: 32px;
}
#sub4 .cnt2 .area .sub_cmn ul li {
	float: left;
	width: 180px;
	margin-left: 20px;
}
#sub4 .cnt2 .area .sub_cmn ul li:first-child {
	margin-left: 0;
}
#sub4 .cnt2 .area .sub_cmn ul li a {
	display: block;
	width: 100%;
	height: 100%;
}
#sub4 .cnt2 .area .sub_cmn ul li a .img img {
	width: 100%;
	height: auto;
}
#sub4 .cnt2 .area .sub_cmn ul li a h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #444;
	font-size: 14px;
	line-height: 17px;
	padding-right: 20px;
	word-break: keep-all;
	padding-top: 12px;
}
#sub4 .cnt2 .area .sub_cmn ul li a .p_wrap {
	margin-top: 10px;
}
#sub4 .cnt2 .area .sub_cmn ul li a .p_wrap > * {
	float: left;
}
#sub4 .cnt2 .area .sub_cmn ul li a .p_wrap h6 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #444;
	font-size: 13px;
}
#sub4 .cnt2 .area .sub_cmn ul li a .p_wrap p {
	font-size: 13px;
	color: #999;
	padding-left: 6px;
	margin-left: 5px;
	background: url('../images/s2_bar.jpg') no-repeat left center;
	-webkit-background-size: 1px auto;
	-moz-background-size: 1px auto;
	background-size: 1px auto;
}

#sub5 .cnt1 .area .wrap {
	width: 600px;
	margin: 24px auto;
}
#sub5 .cnt1 .area .wrap .bd_list ul li {
	padding: 20px 0;
	border-top: 1px solid #f2f2f2;
}
#sub5 .cnt1 .area .wrap .bd_list ul li:first-child {
	border-top: 0;
}
#sub5 .cnt1 .area .wrap .bd_list ul li:hover {
	background: #fbfbfb;
}
#sub5 .cnt1 .area .wrap .bd_list ul li a {
	display: block;
	padding-left: 24px;
}
#sub5 .cnt1 .area .wrap .bd_list ul li a h3 {
	font-size: 18px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	line-height: 1.5;
	color: #5774db;
}
#sub5 .cnt1 .area .wrap .bd_list ul li a p {
	font-size: 14px;
	line-height: 1.5;
	color: #444;
	padding-top: 4px;
}
#sub5 .cnt1 .area .wrap .bd_list ul li a .date {
	display: block;
	font-size: 13px;
	line-height: 16px;
	color: #999;
	padding-top: 8px;
}
#sub5 .cnt1 .area .wrap .bd_paging {
	margin-top: 40px;
}
#sub5 .cnt1 .area .wrap .bd_paging ul {
	text-align: center;
}
#sub5 .cnt1 .area .wrap .bd_paging ul li {
	display: inline-block;
	width: 32px;
	height: 32px;
	border: 1px solid #dfe3e8;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	overflow: hidden;
	margin-left: 5px;
}
#sub5 .cnt1 .area .wrap .bd_paging ul li:first-child {
	margin-left: 0;
}
#sub5 .cnt1 .area .wrap .bd_paging ul li a {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
	line-height: 32px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #212b36;
	text-align: center;
	font-size: 14px;
}
#sub5 .cnt1 .area .wrap .bd_paging ul li a .icon {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 7px;
	height: 12px;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: center;
}
#sub5 .cnt1 .area .wrap .bd_paging ul li.pn_off {
	background-color: rgba(145, 158, 171, 0.5);
	border: 0;
}
#sub5 .cnt1 .area .wrap .bd_paging ul li.prev a .icon {
	background-image: url('../images/paging_prev_on.png');
}
#sub5 .cnt1 .area .wrap .bd_paging ul li.prev.pn_off a .icon {
	background-image: url('../images/paging_prev_off.png');
}
#sub5 .cnt1 .area .wrap .bd_paging ul li.next a .icon {
	background-image: url('../images/paging_next_on.png');
}
#sub5 .cnt1 .area .wrap .bd_paging ul li.next.pn_off a .icon {
	background-image: url('../images/paging_next_off.png');
}
#sub5 .cnt1 .area .wrap .bd_paging ul li.on {
	border: 1px solid #5774db;
}
#sub5 .cnt1 .area .wrap .bd_paging ul li.on a {
	color: #5774db;
}

#sub6 .cnt1 .area .wrap {
	width: 600px;
	border: 1px solid #f2f2f2;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 60px auto;
	padding: 66px 0 60px;
}
#sub6 .cnt1 .area .wrap .tit {
	width: 288px;
	margin: 0 auto;
}
#sub6 .cnt1 .area .wrap .tit .ci {
	display: block;
	width: 190px;
}
#sub6 .cnt1 .area .wrap .tit .ci img {
	width: 100%;
	height: auto;
}
#sub6 .cnt1 .area .wrap .tit > h2 {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 30px;
	line-height: 1.3;
	padding-top: 22px;
}
#sub6 .cnt1 .area .wrap ul {
	margin-top: 60px;
}
#sub6 .cnt1 .area .wrap ul li {
	width: 288px;
	height: 48px;
	line-height: 48px;
	border: 1px solid #ddd;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 16px auto 0;
	overflow: hidden;
}
#sub6 .cnt1 .area .wrap ul li:first-child {
	margin-top: 0;
}
#sub6 .cnt1 .area .wrap ul li a {
	display: block;
	width: 100%;
	height: 100%;
}
#sub6 .cnt1 .area .wrap ul li a > * {
	float: left;
}
#sub6 .cnt1 .area .wrap ul li a h3 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	letter-spacing: -0.5px;
}
#sub6 .cnt1 .area .wrap ul li a .icon {
	width: 24px;
	margin-left: 24px;
	margin-right: 12px;
}
#sub6 .cnt1 .area .wrap ul li a .icon img {
	width: 100%;
	height: auto;
	margin-top: 12px;
}

#sub7 .blk_s7 {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.6;
	filter: Alpha(opacity=60);
	z-index: 10;
}
#sub7 .cnt1 .area .wrap {
	width: 600px;
	border: 1px solid #f2f2f2;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 60px auto;
	padding: 60px 0;
}
#sub7 .cnt1 .area .wrap .tit {
	width: 340px;
	margin: 0 auto;
}
#sub7 .cnt1 .area .wrap .tit h2 {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 30px;
	line-height: 1.3;
}
#sub7 .cnt1 .area .wrap .tit p {
	padding-top: 16px;
	font-size: 14px;
}
#sub7 .cnt1 .area .wrap .tit p span {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #5774db;
}
#sub7 .cnt1 .area .wrap ul {
	width: 340px;
	margin: 59px auto 0;
}
#sub7 .cnt1 .area .wrap ul li {
	position: relative;
	margin: 20px 0;
}
#sub7 .cnt1 .area .wrap ul li:first-child {
	margin-top: 0;
}
#sub7 .cnt1 .area .wrap ul li > div input[type='checkbox'] {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
#sub7 .cnt1 .area .wrap ul li > div input[type='checkbox'] + label {
	display: inline-block;
	position: relative;
	cursor: pointer;
	padding-left: 28px;
	line-height: 21px;
	font-size: 14px;
}
#sub7 .cnt1 .area .wrap ul li > div input[type='checkbox'] + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 21px;
	height: 21px;
	text-align: center;
	background: url('../images/checkbox2_off.png') no-repeat center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
}
#sub7 .cnt1 .area .wrap ul li > div input[type='checkbox']:checked + label:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 21px;
	height: 21px;
	text-align: center;
	background: url('../images/checkbox2_on.png') no-repeat center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
}
#sub7 .cnt1 .area .wrap ul li > div input[type='checkbox'] + label .aster {
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
	color: #5774db;
}
#sub7 .cnt1 .area .wrap ul li > div input[type='checkbox'] + label .cho_text {
	color: #bfbfbf;
}
#sub7 .cnt1 .area .wrap ul li.all_chk > div input[type='checkbox'] + label {
	font-size: 16px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub7 .cnt1 .area .wrap ul li .arrow_btn {
	position: absolute;
	top: 50%;
	right: 0;
	margin-top: -6px;
}
#sub7 .cnt1 .area .wrap ul li .arrow_btn img {
	width: 7px;
	height: auto;
}
#sub7 .cnt1 .area .wrap .submit_btn {
	display: block;
	width: 288px;
	height: 48px;
	line-height: 48px;
	background: #5774db;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	margin: 45px auto 0;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #fff;
	font-size: 16px;
	text-align: center;
}
#sub7 .cnt1 .terms_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	width: 600px;
	height: 80%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	background: #fff;
	z-index: 15;
	-webkit-border-radius: px;
	-moz-border-radius: px;
	border-radius: 20px;
}
#sub7 .cnt1 .terms_pu .close_btn {
	position: absolute;
	top: 17px;
	right: 20px;
}
#sub7 .cnt1 .terms_pu .wrap {
	width: 100%;
	height: 90%;
	overflow: auto;
	margin: 7% auto 0;
}
#sub7 .cnt1 .terms_pu .wrap h2 {
	font-size: 26px;
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
	padding-bottom: 50px;
	text-align: center;
	padding-top: 30px;
}
#sub7 .cnt1 .terms_pu .wrap .toc {
	margin-bottom: 40px;
	padding: 0 5%;
}
#sub7 .cnt1 .terms_pu .wrap .toc p {
	font-size: 13px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	padding-top: 15px;
}
#sub7 .cnt1 .terms_pu .wrap .toc p:first-child {
	padding-top: 0;
}
#sub7 .cnt1 .terms_pu .wrap .tms {
	margin-bottom: 40px;
	padding: 0 5%;
}
#sub7 .cnt1 .terms_pu .wrap .tms h3 {
	font-size: 13px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #333;
	margin-bottom: 15px;
}
#sub7 .cnt1 .terms_pu .wrap .tms h3 strong {
	font-size: 13px;
	display: block;
	padding-bottom: 10px;
	color: #000;
}
#sub7 .cnt1 .terms_pu .wrap .tms p {
	font-size: 13px;
	line-height: 1.7;
	margin-bottom: 5px;
	color: #666;
}
#sub7 .cnt1 .terms_pu .wrap .tms .ml_wrap {
	margin-bottom: 20px;
}
#sub7 .cnt1 .terms_pu .wrap .tms .ml_wrap p.ml {
	padding-left: 20px;
	margin-bottom: 3px;
	margin-top: 0;
}
#sub7 .cnt1 .terms_pu .wrap > p {
	font-size: 13px;
	padding: 0 5% 20px;
}
#sub7 .cnt1 .pvc_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	width: 600px;
	height: 80%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	background: #fff;
	z-index: 15;
	-webkit-border-radius: px;
	-moz-border-radius: px;
	border-radius: 20px;
}
#sub7 .cnt1 .pvc_pu .close_btn {
	position: absolute;
	top: 17px;
	right: 20px;
}
#sub7 .cnt1 .pvc_pu .wrap {
	width: 100%;
	height: 90%;
	overflow: auto;
	margin: 7% auto 0;
}
#sub7 .cnt1 .pvc_pu .wrap h2 {
	font-size: 26px;
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
	padding-bottom: 50px;
	text-align: center;
	padding-top: 30px;
}
#sub7 .cnt1 .pvc_pu .wrap .pvc {
	margin-bottom: 40px;
	padding: 0 5%;
}
#sub7 .cnt1 .pvc_pu .wrap .pvc h3 {
	font-size: 13px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub7 .cnt1 .pvc_pu .wrap .pvc p {
	font-size: 13px;
	line-height: 1.7;
	margin-bottom: 10px;
	margin-top: 10px;
	color: #666;
}
#sub7 .cnt1 .pvc_pu .wrap .pvc p.ml {
	padding-left: 10px;
	margin-bottom: 3px;
	margin-top: 0;
}
#sub7 .cnt1 .pvc_pu .wrap .pvc p.ml2 {
	padding-left: 20px;
}
#sub7 .cnt1 .pvc_pu .wrap > p {
	font-size: 13px;
	padding-bottom: 20px;
	padding-left: 5%;
}

.chk_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 344px;
	background: #fff;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	z-index: 13;
	padding-bottom: 36px;
}
.chk_pu h3 {
	display: block;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-weight: normal;
	text-align: center;
	font-size: 18px;
	padding: 40px 0;
}
.chk_pu button {
	display: block;
	width: 160px;
	height: 48px;
	background: #5774db;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
}

#sub8 .cnt1 .area .wrap {
	width: 600px;
	border: 1px solid #f2f2f2;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 60px auto;
	padding: 84px 0;
}
#sub8 .cnt1 .area .wrap .tit {
	width: 300px;
	margin: 0 auto;
}
#sub8 .cnt1 .area .wrap .tit h2 {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 30px;
	line-height: 1.3;
}
#sub8 .cnt1 .area .wrap .tit p {
	padding-top: 16px;
	font-size: 14px;
}
#sub8 .cnt1 .area .wrap .form_cont {
	position: relative;
	width: 300px;
	height: 50px;
	line-height: 50px;
	margin: 36px auto 0;
}
#sub8 .cnt1 .area .wrap .form_cont input {
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	font-size: 12px;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
}
#sub8 .cnt1 .area .wrap .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub8 .cnt1 .area .wrap .form_cont input.error {
	border: 1px solid #df3c3c;
}
#sub8 .cnt1 .area .wrap .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #aaa;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}
#sub8 .cnt1 .area .wrap .cnfm_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #5774db;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 36px;
}

.cnfm_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 344px;
	background: #fff;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	z-index: 13;
	padding-bottom: 36px;
}
.cnfm_pu strong {
	display: block;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-weight: normal;
	text-align: center;
	font-size: 18px;
	padding: 40px 0;
}
.cnfm_pu .re_cnfm {
	display: block;
	width: 160px;
	height: 48px;
	background: #5774db;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
}
.cnfm_ok_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 344px;
	background: #fff;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	z-index: 13;
	padding-bottom: 36px;
}
.cnfm_ok_pu strong {
	display: block;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-weight: normal;
	text-align: center;
	font-size: 18px;
	padding: 40px 0 12px;
	line-height: 24px;
}
.cnfm_ok_pu p {
	font-size: 14px;
	line-height: 19px;
	color: #444;
	text-align: center;
	padding-bottom: 40px;
}
.cnfm_ok_pu a {
	display: block;
	width: 160px;
	height: 48px;
	line-height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	text-align: center;
}
.cnfm_ok_pu .ok_cnfm {
	background: #5774db;
}
.cnfm_ok_pu .re_cnfm {
	background: #666;
	margin-top: 10px;
}

#sub9 .cnt1 .area .wrap {
	width: 600px;
	border: 1px solid #f2f2f2;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 60px auto;
	padding: 84px 0;
}
#sub9 .cnt1 .area .wrap .tit {
	width: 264px;
	margin: 0 auto;
}
#sub9 .cnt1 .area .wrap .tit h2 {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 30px;
	line-height: 1.3;
}
#sub9 .cnt1 .area .wrap .tit p {
	padding-top: 16px;
	font-size: 14px;
}
#sub9 .cnt1 .area .wrap .form_cont {
	position: relative;
	width: 264px;
	height: 50px;
	line-height: 50px;
	margin: 36px auto 0;
}
#sub9 .cnt1 .area .wrap .form_cont input {
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	font-size: 18px;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #444;
}
#sub9 .cnt1 .area .wrap .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub9 .cnt1 .area .wrap .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #444;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 18px;
}
#sub9 .cnt1 .area .wrap .auth_btn {
	display: block;
	width: 160px;
	height: 48px;
	line-height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #5774db;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 36px;
	text-align: center;
}

#sub10 .cnt1 .area .wrap {
	width: 600px;
	border: 1px solid #f2f2f2;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 60px auto;
	padding: 84px 0;
}
#sub10 .cnt1 .area .wrap .tit {
	width: 288px;
	margin: 0 auto;
}
#sub10 .cnt1 .area .wrap .tit h2 {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 30px;
	line-height: 1.3;
}
#sub10 .cnt1 .area .wrap .tit p {
	padding-top: 16px;
	font-size: 14px;
}
#sub10 .cnt1 .area .wrap ul {
	margin-top: 36px;
}
#sub10 .cnt1 .area .wrap ul li.s10_li1 {
	width: 288px;
	margin: 0 auto;
}
#sub10 .cnt1 .area .wrap ul li.s10_li1 > * {
	float: left;
}
#sub10 .cnt1 .area .wrap ul li.s10_li1 .form_cont {
	position: relative;
	width: 204px;
	height: 56px;
	line-height: 56px;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	overflow: hidden;
}
#sub10 .cnt1 .area .wrap ul li.s10_li1 .form_cont input {
	width: 120px;
	height: 54px;
	font-size: 18px;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #444;
	border: 0;
	margin-top: -3px;
}
#sub10 .cnt1 .area .wrap ul li.s10_li1 .form_cont input:focus {
	outline: 0;
	color: #222;
}
#sub10 .cnt1 .area .wrap ul li.s10_li1 .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #444;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 18px;
}
#sub10 .cnt1 .area .wrap ul li.s10_li1 .form_cont .time {
	position: absolute;
	top: 50%;
	right: 32px;
	margin-top: -4px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 11px;
	color: #5774db;
	line-height: 1;
}
#sub10 .cnt1 .area .wrap ul li.s10_li1 .form_cont .delete_btn {
	position: absolute;
	top: 50%;
	right: 11px;
	width: 17px;
	height: 17px;
	margin-top: -8px;
}
#sub10 .cnt1 .area .wrap ul li.s10_li1 .form_cont .delete_btn img {
	width: 100%;
	height: auto;
}
#sub10 .cnt1 .area .wrap ul li.s10_li1 .re_send {
	width: 78px;
	height: 56px;
	border: 1px solid #5774db;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	margin-left: 6px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #5774db;
}
#sub10 .cnt1 .area .wrap ul li.s10_li2 {
	width: 288px;
	margin: 12px auto 0;
}
#sub10 .cnt1 .area .wrap ul li.s10_li2 .form_cont {
	position: relative;
	width: 100%;
	height: 56px;
	line-height: 56px;
}
#sub10 .cnt1 .area .wrap ul li.s10_li2 .form_cont input {
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	font-size: 18px;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #444;
}
#sub10 .cnt1 .area .wrap ul li.s10_li2 .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub10 .cnt1 .area .wrap ul li.s10_li2 .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #444;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 18px;
}
#sub10 .cnt1 .area .wrap ul li.s10_li2 .re_send {
	width: 78px;
	height: 56px;
	border: 1px solid #5774db;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	margin-left: 6px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #5774db;
}
#sub10 .cnt1 .area .wrap .auth_btn {
	display: block;
	width: 160px;
	height: 48px;
	line-height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #5774db;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 36px;
	text-align: center;
}

#sub11 .cnt1 .area .wrap {
	width: 600px;
	border: 1px solid #f2f2f2;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 60px auto;
	padding: 84px 0;
}
#sub11 .cnt1 .area .wrap .tit {
	width: 330px;
	margin: 0 auto;
}
#sub11 .cnt1 .area .wrap .tit h2 {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 30px;
	line-height: 1.3;
}
#sub11 .cnt1 .area .wrap .tit p {
	padding-top: 16px;
	font-size: 14px;
}
#sub11 .cnt1 .area .wrap .form_cont {
	position: relative;
	width: 330px;
	height: 50px;
	line-height: 50px;
	margin: 36px auto 0;
}
#sub11 .cnt1 .area .wrap .form_cont input {
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	font-size: 18px;
	background: #fff;
	padding-left: 20px;
	padding-right: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #444;
}
#sub11 .cnt1 .area .wrap .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub11 .cnt1 .area .wrap .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #444;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 18px;
}
#sub11 .cnt1 .area .wrap .caution {
	width: 330px;
	margin: 10px auto 0;
	color: #999;
	font-size: 14px;
}
#sub11 .cnt1 .area .wrap .enter_btn {
	display: block;
	width: 160px;
	height: 48px;
	line-height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #5774db;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 36px;
	text-align: center;
}

#sub11_1 .cnt1 .area .wrap {
	width: 600px;
	border: 1px solid #f2f2f2;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 60px auto;
	padding: 84px 0;
}
#sub11_1 .cnt1 .area .wrap .tit {
	width: 210px;
	margin: 0 auto;
}
#sub11_1 .cnt1 .area .wrap .tit h2 {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 30px;
	line-height: 1.3;
}
#sub11_1 .cnt1 .area .wrap .tit p {
	padding-top: 16px;
	font-size: 14px;
}
#sub11_1 .cnt1 .area .wrap .txt ul {
	text-align: center;
	margin-top: 50px;
}
#sub11_1 .cnt1 .area .wrap .txt ul li {
	display: inline-block;
	margin-left: 30px;
}
#sub11_1 .cnt1 .area .wrap .txt ul li:first-child {
	margin-left: 0;
}
#sub11_1 .cnt1 .area .wrap .txt ul li div input[type='radio'] {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
#sub11_1 .cnt1 .area .wrap .txt ul li div input[type='radio'] + label {
	display: inline-block;
	position: relative;
	cursor: pointer;
	font-size: 18px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	padding-left: 36px;
}
#sub11_1 .cnt1 .area .wrap .txt ul li div input[type='radio'] + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 24px;
	height: 24px;
	text-align: center;
	border: 1px solid #5774db;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	border-radius: 12px;
}
#sub11_1 .cnt1 .area .wrap .txt ul li div input[type='radio']:checked + label:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 5px;
	width: 14px;
	height: 14px;
	margin-top: -7px;
	text-align: center;
	background: #5774db;
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	border-radius: 7px;
}
#sub11_1 .cnt1 .area .wrap .enter_btn {
	display: block;
	width: 160px;
	height: 48px;
	line-height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #5774db;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 50px;
	text-align: center;
}

#sub12 .cnt1 .area .wrap {
	width: 600px;
	border: 1px solid #f2f2f2;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 60px auto;
	padding: 60px 0;
}
#sub12 .cnt1 .area .wrap .tit h2 {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 30px;
	line-height: 1.3;
	text-align: center;
}
#sub12 .cnt1 .area .wrap .box {
	width: 520px;
	background: #f4f7ff;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	margin: 36px auto 0;
	padding: 50px 0;
}
#sub12 .cnt1 .area .wrap .box .icon {
	display: block;
	width: 62px;
	margin: 0 auto 36px;
}
#sub12 .cnt1 .area .wrap .box .icon img {
	width: 100%;
	height: auto;
}
#sub12 .cnt1 .area .wrap .box p {
	text-align: center;
	font-size: 18px;
	line-height: 1.3;
	color: #222;
}
#sub12 .cnt1 .area .wrap .box p strong {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-weight: normal;
}
#sub12 .cnt1 .area .wrap .box .cau {
	display: block;
	color: #666;
	text-align: center;
	padding-top: 32px;
	font-size: 14px;
}
#sub12 .cnt1 .area .wrap .write_btn {
	display: block;
	width: 191px;
	height: 48px;
	line-height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #5774db;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 36px;
	text-align: center;
}

#sub12_1 .cnt1 .area .car_pg {
	width: 600px;
	border: 1px solid #f2f2f2;
	-webkit-border-radius: px;
	-moz-border-radius: px;
	border-radius: 30px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 84px 0;
	margin: 60px auto;
}
#sub12_1 .cnt1 .area .car_pg .tit {
	width: 360px;
	margin: 0 auto;
	padding-bottom: 44px;
}
#sub12_1 .cnt1 .area .car_pg .tit h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 26px;
}
#sub12_1 .cnt1 .area .car_pg > p {
	font-size: 16px;
	width: 360px;
	margin: 0 auto;
	padding-bottom: 9px;
}
#sub12_1 .cnt1 .area .car_pg .career_wrap {
	position: relative;
	width: 360px;
	height: 55px;
	margin: 0 auto;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	overflow: hidden;
}
#sub12_1 .cnt1 .area .car_pg .career_wrap p {
	width: calc(100% - 10px);
	height: 55px;
	line-height: 53px;
	color: #aaa;
	font-size: 16px;
	font-weight: normal;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	padding-left: 20px;
	background-color: #fff;
	border: 0;
	cursor: pointer;
}
#sub12_1 .cnt1 .area .car_pg .career_wrap .arrow {
	position: absolute;
	top: 50%;
	right: 20px;
	margin-top: -6px;
}
#sub12_1 .cnt1 .area .car_pg .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	line-height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 36px;
	text-align: center;
}
#sub12_1 .cnt1 .area .car_pg .save_btn.on {
	background: #5774db;
}
#rngBlk {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.5;
	filter: Alpha(opacity=50);
	z-index: 12;
}
#sub12_1 .cnt1 .area .car_pg .range_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	width: 480px;
	background: #fff;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 13;
	-webkit-border-radius: px;
	-moz-border-radius: px;
	border-radius: 30px;
	padding-bottom: 50px;
}
#sub12_1 .cnt1 .area .car_pg .range_pu h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
	width: calc(100% - 70px);
	margin: 0 auto;
	padding-top: 40px;
}
#sub12_1 .cnt1 .area .car_pg .range_pu .close_btn {
	position: absolute;
	top: 20px;
	right: 20px;
}
#sub12_1 .cnt1 .area .car_pg .range_pu .range-slider {
	width: 100%;
	margin-top: 20px;
}
#sub12_1 .cnt1 .area .car_pg .range_pu .range-slider__range {
	display: block;
	-webkit-appearance: none;
	width: calc(100% - 70px);
	height: 10px;
	border-radius: 5px;
	background: #d7dcdf;
	outline: none;
	padding: 0;
	margin: 10px auto 0;
}
#sub12_1 .cnt1 .area .car_pg .range_pu .range-slider__range::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #2c3e50;
	cursor: pointer;
	-webkit-transition: background 0.15s ease-in-out;
	transition: background 0.15s ease-in-out;
}
#sub12_1 .cnt1 .area .car_pg .range_pu .range-slider__range::-webkit-slider-thumb:hover {
	background: #1abc9c;
}
#sub12_1 .cnt1 .area .car_pg .range_pu .range-slider__range:active::-webkit-slider-thumb {
	background: #1abc9c;
}
#sub12_1 .cnt1 .area .car_pg .range_pu .range-slider__range::-moz-range-thumb {
	width: 20px;
	height: 20px;
	border: 0;
	border-radius: 50%;
	background: #5774db;
	cursor: pointer;
	-moz-transition: background 0.15s ease-in-out;
	transition: background 0.15s ease-in-out;
}
#sub12_1 .cnt1 .area .car_pg .range_pu .range-slider__range::-moz-range-thumb:hover {
	background: #1abc9c;
}
#sub12_1 .cnt1 .area .car_pg .range_pu .range-slider__range:active::-moz-range-thumb {
	background: #1abc9c;
}
#sub12_1 .cnt1 .area .car_pg .range_pu .range-slider__range:focus::-webkit-slider-thumb {
	box-shadow: 0 0 0 3px #fff, 0 0 0 6px #1abc9c;
}
#sub12_1 .cnt1 .area .car_pg .range_pu .range-slider__value {
	display: inline-block;
	position: relative;
	text-align: center;
	border-radius: 3px;
	padding: 5px 10px;
	margin-left: 35px;
	font-family: SC4, SC5, 'Noto Sans KR', sans-serif;
	font-size: 16px;
}
::-moz-range-track {
	background: #d7dcdf;
	border: 0;
}
input::-moz-focus-inner,
input::-moz-focus-outer {
	border: 0;
}
#sub12_1 .cnt1 .area .car_pg .range_pu .ok_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #5774db;
	margin: 50px auto 0;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
}

#sub12_2 .cnt1 .field0_pg {
	width: 600px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	padding: 84px 0;
	margin: 60px auto;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
#sub12_2 .cnt1 .field0_pg .tit {
	width: 360px;
	margin: 0 auto;
	padding-bottom: 44px;
}
#sub12_2 .cnt1 .field0_pg .tit h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 26px;
}
#sub12_2 .cnt1 .field0_pg > p {
	font-size: 16px;
	width: 360px;
	margin: 0 auto;
	padding-bottom: 9px;
}
#sub12_2 .cnt1 .field0_pg .input_box {
	position: relative;
	width: 360px;
	height: 52px;
	line-height: 52px;
	margin: 0 auto;
	border: 1px solid #eee;
	background: #fff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: px;
	-moz-border-radius: px;
	border-radius: 8px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #000;
	font-size: 13px;
}
#sub12_2 .cnt1 .field0_pg .input_box p {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub12_2 .cnt1 .field0_pg .form_cont {
	position: relative;
	width: 360px;
	height: 52px;
	line-height: 52px;
	margin: 0 auto;
}
#sub12_2 .cnt1 .field0_pg .form_cont input {
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	font-size: 13px;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #222;
}
#sub12_2 .cnt1 .field0_pg .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub12_2 .cnt1 .field0_pg .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub12_2 .cnt1 .field0_pg .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	line-height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 36px;
	text-align: center;
}
#sub12_2 .cnt1 .field0_pg .save_btn.on {
	background: #5774db;
}
#sub12_2 .cnt1 .field_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 11;
	padding-bottom: 36px;
	max-height: 383px;
}
#sub12_2 .cnt1 .field_pu .tit {
	width: 400px;
	margin: 0 auto;
	padding-top: 36px;
}
#sub12_2 .cnt1 .field_pu .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub12_2 .cnt1 .field_pu .form_cont {
	position: relative;
	width: 400px;
	height: 48px;
	line-height: 48px;
	margin: 20px auto 0;
	border-bottom: 1px solid #eee;
	overflow: hidden;
}
#sub12_2 .cnt1 .field_pu .form_cont input {
	width: calc(100% - 30px);
	height: 100%;
	font-size: 14px;
	background: #fff;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	border: 0;
}
#sub12_2 .cnt1 .field_pu .form_cont input:focus {
	outline: 0;
	color: #222;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub12_2 .cnt1 .field_pu .form_cont label {
	position: absolute;
	top: 50%;
	left: 0;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
}
#sub12_2 .cnt1 .field_pu .des_box {
	width: 400px;
	background: #f2f2f2;
	margin: 0 auto;
	padding: 12px 0;
}
#sub12_2 .cnt1 .field_pu .des_box ul li {
	margin-top: 7px;
}
#sub12_2 .cnt1 .field_pu .des_box ul li:first-child {
	margin-top: 0;
}
#sub12_2 .cnt1 .field_pu .des_box ul li > * {
	float: left;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #999;
	font-size: 11px;
}
#sub12_2 .cnt1 .field_pu .des_box ul li span {
	padding-left: 20px;
	padding-right: 5px;
}
#sub12_2 .cnt1 .field_pu .des_box ul li p {
	width: calc(100% - 55px);
	line-height: 17px;
}
#sub12_2 .cnt1 .field_pu .field_list {
	display: none;
	width: 400px;
	height: 160px;
	overflow: auto;
	margin: 24px auto 0;
}
#sub12_2 .cnt1 .field_pu .field_list ul li {
	width: 100%;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
}
#sub12_2 .cnt1 .field_pu .field_list ul li.on {
	background: #f0f4ff;
}
#sub12_2 .cnt1 .field_pu .field_list ul li p {
	font-size: 14px;
	color: #999;
	padding-left: 24px;
}
#sub12_2 .cnt1 .field_pu .field_list ul li p span {
	color: #5774db;
}
#sub12_2 .cnt1 .field_pu .save_btn2 {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 64px;
}
#sub12_2 .cnt1 .field_pu .save_btn2.on {
	background: #5774db;
}

#sub12_3 .cnt1 .area .car0_pg {
	width: 600px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 9;
	padding: 84px 0;
	margin: 60px auto;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
#sub12_3 .cnt1 .area .car0_pg .tit {
	width: 400px;
	margin: 0 auto;
	padding-bottom: 15px;
}
#sub12_3 .cnt1 .area .car0_pg .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 26px;
}
#sub12_3 .cnt1 .area .car0_pg > p {
	font-size: 16px;
	width: 400px;
	margin: 0 auto;
	padding-bottom: 9px;
}
#sub12_3 .cnt1 .area .car0_pg .wrap {
	width: 400px;
	margin: 30px auto 0;
}
#sub12_3 .cnt1 .area .car0_pg .wrap ul li {
	margin-top: 24px;
}
#sub12_3 .cnt1 .area .car0_pg .wrap ul li:first-child {
	margin-top: 0;
}
#sub12_3 .cnt1 .area .car0_pg .wrap ul li h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 17px;
	color: #222;
	padding-bottom: 9px;
}
#sub12_3 .cnt1 .area .car0_pg .wrap ul li .input_box {
	position: relative;
	width: 400px;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
	border: 1px solid #eee;
	background: #fff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: px;
	-moz-border-radius: px;
	border-radius: 8px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #000;
}
#sub12_3 .cnt1 .area .car0_pg .wrap ul li .input_box p {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub12_3 .cnt1 .area .car0_pg .wrap ul li .form_cont {
	position: relative;
	width: 400px;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
#sub12_3 .cnt1 .area .car0_pg .wrap ul li .form_cont input {
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #222;
}
#sub12_3 .cnt1 .area .car0_pg .wrap ul li .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub12_3 .cnt1 .area .car0_pg .wrap ul li .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub12_3 .cnt1 .area .car0_pg .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	line-height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 40px;
	text-align: center;
}
#sub12_3 .cnt1 .area .car0_pg .save_btn.on {
	background: #5774db;
}
#sub12_3 .cnt1 .car1_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
	max-height: 284px;
}
#sub12_3 .cnt1 .car1_pu .tit {
	width: 400px;
	margin: 0 auto;
	padding-top: 36px;
}
#sub12_3 .cnt1 .car1_pu .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub12_3 .cnt1 .car1_pu .form_cont {
	position: relative;
	width: 400px;
	height: 48px;
	line-height: 48px;
	margin: 20px auto 0;
	border-bottom: 1px solid #eee;
	overflow: hidden;
}
#sub12_3 .cnt1 .car1_pu .form_cont input {
	width: calc(100% - 30px);
	height: 100%;
	font-size: 14px;
	background: #fff;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	border: 0;
}
#sub12_3 .cnt1 .car1_pu .form_cont input:focus {
	outline: 0;
	color: #222;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub12_3 .cnt1 .car1_pu .form_cont label {
	position: absolute;
	top: 50%;
	left: 0;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
}
#sub12_3 .cnt1 .car1_pu .drp {
	height: 40px;
	line-height: 40px;
}
#sub12_3 .cnt1 .car1_pu .drp p {
	font-size: 13px;
	width: 400px;
	margin: 0 auto;
}
#sub12_3 .cnt1 .car1_pu .car1_list {
	display: none;
	width: 400px;
	height: 160px;
	overflow: auto;
	margin: 24px auto 0;
}
#sub12_3 .cnt1 .car1_pu .car1_list ul li {
	width: 100%;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
}
#sub12_3 .cnt1 .car1_pu .car1_list ul li.on {
	background: #f0f4ff;
}
#sub12_3 .cnt1 .car1_pu .car1_list ul li p {
	font-size: 14px;
	color: #999;
	padding-left: 24px;
}
#sub12_3 .cnt1 .car1_pu .car1_list ul li p span {
	color: #5774db;
}
#sub12_3 .cnt1 .car1_pu .save_btn2 {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 64px;
}
#sub12_3 .cnt1 .car1_pu .save_btn2.on {
	background: #5774db;
}

#sub12_4 .cnt1 .area .edu0_pg {
	width: 600px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	padding: 84px 0;
	margin: 60px auto;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
#sub12_4 .cnt1 .area .edu0_pg .tit {
	width: 400px;
	margin: 0 auto;
	padding-bottom: 15px;
}
#sub12_4 .cnt1 .area .edu0_pg .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 26px;
}
#sub12_4 .cnt1 .area .edu0_pg > p {
	font-size: 16px;
	width: 400px;
	margin: 0 auto;
	padding-bottom: 9px;
}
#sub12_4 .cnt1 .area .edu0_pg .wrap {
	width: 400px;
	margin: 30px auto 0;
}
#sub12_4 .cnt1 .area .edu0_pg .wrap ul li {
	margin-top: 24px;
}
#sub12_4 .cnt1 .area .edu0_pg .wrap ul li:first-child {
	margin-top: 0;
}
#sub12_4 .cnt1 .area .edu0_pg .wrap ul li h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 17px;
	color: #222;
	padding-bottom: 9px;
}
#sub12_4 .cnt1 .area .edu0_pg .wrap ul li .input_box {
	position: relative;
	width: 400px;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
	border: 1px solid #eee;
	background: #fff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #000;
}
#sub12_4 .cnt1 .area .edu0_pg .wrap ul li .input_box p {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub12_4 .cnt1 .area .edu0_pg .wrap ul li .form_cont {
	position: relative;
	width: 400px;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
#sub12_4 .cnt1 .area .edu0_pg .wrap ul li .form_cont input {
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #222;
}
#sub12_4 .cnt1 .area .edu0_pg .wrap ul li .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub12_4 .cnt1 .area .edu0_pg .wrap ul li .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub12_4 .cnt1 .area .edu0_pg .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	line-height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 40px;
	text-align: center;
}
#sub12_4 .cnt1 .area .edu0_pg .save_btn.on {
	background: #5774db;
}
#sub12_4 .cnt1 .edu1_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
	max-height: 284px;
}
#sub12_4 .cnt1 .edu1_pu .tit {
	width: 400px;
	margin: 0 auto;
	padding-top: 36px;
}
#sub12_4 .cnt1 .edu1_pu .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub12_4 .cnt1 .edu1_pu .form_cont {
	position: relative;
	width: 400px;
	height: 48px;
	line-height: 48px;
	margin: 20px auto 0;
	border-bottom: 1px solid #eee;
	overflow: hidden;
}
#sub12_4 .cnt1 .edu1_pu .form_cont input {
	width: calc(100% - 30px);
	height: 100%;
	font-size: 14px;
	background: #fff;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	border: 0;
}
#sub12_4 .cnt1 .edu1_pu .form_cont input:focus {
	outline: 0;
	color: #222;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub12_4 .cnt1 .edu1_pu .form_cont label {
	position: absolute;
	top: 50%;
	left: 0;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
}
#sub12_4 .cnt1 .edu1_pu .drp {
	height: 40px;
	line-height: 40px;
}
#sub12_4 .cnt1 .edu1_pu .drp p {
	font-size: 13px;
	padding-left: 0;
	width: 400px;
	margin: 0 auto;
}
#sub12_4 .cnt1 .edu1_pu .edu1_list {
	display: none;
	width: 400px;
	height: 160px;
	overflow: auto;
	margin: 24px auto 0;
}
#sub12_4 .cnt1 .edu1_pu .edu1_list ul li {
	width: 100%;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
}
#sub12_4 .cnt1 .edu1_pu .edu1_list ul li.on {
	background: #f0f4ff;
}
#sub12_4 .cnt1 .edu1_pu .edu1_list ul li p {
	font-size: 14px;
	color: #999;
	padding-left: 24px;
}
#sub12_4 .cnt1 .edu1_pu .edu1_list ul li p span {
	color: #5774db;
}
#sub12_4 .cnt1 .edu1_pu .save_btn2 {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 64px;
}
#sub12_4 .cnt1 .edu1_pu .save_btn2.on {
	background: #5774db;
}
#sub12_4 .cnt1 .degree_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 344px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
	padding-top: 40px;
}
#sub12_4 .cnt1 .degree_pu .txt {
	width: 288px;
	margin: 0 auto;
}
#sub12_4 .cnt1 .degree_pu .txt ul li {
	margin-top: 24px;
}
#sub12_4 .cnt1 .degree_pu .txt ul li:first-child {
	margin-top: 0;
}
#sub12_4 .cnt1 .degree_pu .txt ul li div input[type='radio'] {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
#sub12_4 .cnt1 .degree_pu .txt ul li div input[type='radio'] + label {
	display: inline-block;
	position: relative;
	cursor: pointer;
	font-size: 14px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	padding-left: 30px;
}
#sub12_4 .cnt1 .degree_pu .txt ul li div input[type='radio'] + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
	text-align: center;
	border: 1px solid #5774db;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
	border-radius: 9px;
}
#sub12_4 .cnt1 .degree_pu .txt ul li div input[type='radio']:checked + label:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 4px;
	width: 10px;
	height: 10px;
	margin-top: -5px;
	text-align: center;
	background: #5774db;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
	border-radius: 9px;
}
#sub12_4 .cnt1 .degree_pu .ok_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 32px;
}
#sub12_4 .cnt1 .degree_pu .ok_btn.on {
	background: #5774db;
}
#sub12_4 .cnt1 .area .edu2_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
	max-height: 284px;
}
#sub12_4 .cnt1 .area .edu2_pu .tit {
	width: 400px;
	margin: 0 auto;
	padding-top: 36px;
}
#sub12_4 .cnt1 .area .edu2_pu .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub12_4 .cnt1 .area .edu2_pu .form_cont {
	position: relative;
	width: 400px;
	height: 48px;
	line-height: 48px;
	margin: 20px auto 0;
	border-bottom: 1px solid #eee;
	overflow: hidden;
}
#sub12_4 .cnt1 .area .edu2_pu .form_cont input {
	width: calc(100% - 30px);
	height: 100%;
	font-size: 14px;
	background: #fff;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	border: 0;
}
#sub12_4 .cnt1 .area .edu2_pu .form_cont input:focus {
	outline: 0;
	color: #222;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub12_4 .cnt1 .area .edu2_pu .form_cont label {
	position: absolute;
	top: 50%;
	left: 0;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
}
#sub12_4 .cnt1 .area .edu2_pu .drp {
	height: 40px;
	line-height: 40px;
}
#sub12_4 .cnt1 .area .edu2_pu .drp p {
	font-size: 13px;
	padding-left: 0;
	width: 400px;
	margin: 0 auto;
}
#sub12_4 .cnt1 .area .edu2_pu .edu2_list {
	display: none;
	width: 400px;
	margin: 24px auto 0;
}
#sub12_4 .cnt1 .area .edu2_pu .edu2_list ul li {
	width: 100%;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
}
#sub12_4 .cnt1 .area .edu2_pu .edu2_list ul li.on {
	background: #f0f4ff;
}
#sub12_4 .cnt1 .area .edu2_pu .edu2_list ul li p {
	font-size: 14px;
	color: #999;
	padding-left: 24px;
}
#sub12_4 .cnt1 .area .edu2_pu .edu2_list ul li p span {
	color: #5774db;
}
#sub12_4 .cnt1 .area .edu2_pu .save_btn2 {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 64px;
}
#sub12_4 .cnt1 .area .edu2_pu .save_btn2.on {
	background: #5774db;
}

#sub12_5 .cnt1 .good_pg {
	width: 600px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	padding: 84px 0;
	margin: 60px auto;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
#sub12_5 .cnt1 .good_pg .pg_wrap .illu {
	display: block;
	width: 172px;
	margin: 0 auto;
}
#sub12_5 .cnt1 .good_pg .pg_wrap .illu img {
	width: 100%;
	height: auto;
}
#sub12_5 .cnt1 .good_pg .pg_wrap h4 {
	font-size: 18px;
	line-height: 24px;
	text-align: center;
	padding-top: 24px;
}
#sub12_5 .cnt1 .good_pg .pg_wrap h4 strong {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-weight: normal;
}
#sub12_5 .cnt1 .good_pg .pg_wrap a {
	display: block;
	width: 190px;
	height: 48px;
	line-height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	text-align: center;
}
#sub12_5 .cnt1 .good_pg .pg_wrap .ing_btn {
	background: #5774db;
	margin-top: 40px;
}
#sub12_5 .cnt1 .good_pg .pg_wrap .end_btn {
	background: #666;
	margin-top: 10px;
}

#s126Blk1 {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.6;
	filter: Alpha(opacity=60);
	z-index: 12;
}
#s126Blk2 {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.6;
	filter: Alpha(opacity=60);
	z-index: 14;
}
#sub12_6 .cnt1 {
	margin: 60px auto;
}
#sub12_6 .cnt1 .area > .tit .illu {
	display: block;
	width: 51px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area > .tit .illu img {
	width: 100%;
	height: auto;
}
#sub12_6 .cnt1 .area > .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 22px;
	line-height: 1.3;
	text-align: center;
	padding-top: 24px;
}
#sub12_6 .cnt1 .area > .tit h4 span {
	display: inline-block;
	width: 20px;
	height: 20px;
	margin-right: 5px;
	vertical-align: middle;
	margin-top: -5px;
}
#sub12_6 .cnt1 .area > .tit h4 span.edit_icon img {
	width: 100%;
	height: auto;
}
#sub12_6 .cnt1 .area > .tit h4 span.plus_icon {
	position: relative;
	background: #5774db;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}
#sub12_6 .cnt1 .area > .tit h4 span.plus_icon img {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 10px;
	height: auto;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

#sub12_6 .cnt1 .area .txt {
	margin-top: 70px;
}
#sub12_6 .cnt1 .area .txt .bar {
	display: block;
	width: 100%;
	height: 1px;
	background: #e8e8ea;
	margin: 47px 0;
}
#sub12_6 .cnt1 .area .txt .join_info1 {
	width: 600px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .txt .join_info1 > h3 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 18px;
	color: #222;
	padding-bottom: 8px;
}
#sub12_6 .cnt1 .area .txt .join_info1 .box {
	position: relative;
	width: 100%;
	background: #f5fffa;
	border: 1px dashed #46b87a;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	border-radius: 16px;
	padding: 21px 0;
	margin-bottom: 12px;
}
#sub12_6 .cnt1 .area .txt .join_info1 .box:last-child {
	margin-bottom: 0;
}
#sub12_6 .cnt1 .area .txt .join_info1 .box h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #222;
	padding-left: 20px;
}
#sub12_6 .cnt1 .area .txt .join_info1 .box p {
	color: #444;
	padding: 12px 16px 0 20px;
	word-break: keep-all;
	font-size: 14px;
}
#sub12_6 .cnt1 .area .txt .join_info1 .box .info_box {
	width: calc(100% - 40px);
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .txt .join_info1 .box .info_box ul li {
	position: relative;
	padding-top: 24px;
	border-top: 1px solid rgba(70, 184, 122, 0.1);
	margin: 22px auto 24px;
}
#sub12_6 .cnt1 .area .txt .join_info1 .box .info_box ul li:first-child {
	margin-top: 0;
	margin-bottom: 0;
	border-top: 0;
}
#sub12_6 .cnt1 .area .txt .join_info1 .box .info_box ul li h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #444;
	padding-right: 20px;
	font-size: 14px;
}
#sub12_6 .cnt1 .area .txt .join_info1 .box .info_box ul li p.on {
	line-height: 1.5;
	padding-left: 0;
}
#sub12_6 .cnt1 .area .txt .join_info1 .box .info_box ul li p:first-child {
	padding-top: 0;
}
#sub12_6 .cnt1 .area .txt .join_info1 .box .info_box ul li .edit_btn_1 {
	position: absolute;
	top: 22px;
	right: -4px;
	width: 22px;
	height: 22px;
}
#sub12_6 .cnt1 .area .txt .join_info1 .box .info_box ul li .edit_btn_1 img {
	width: 100%;
	height: auto;
}
#sub12_6 .cnt1 .area .txt .join_info1 .box .edit_btn {
	display: none;
	position: absolute;
	top: 17px;
	right: 16px;
	width: 22px;
	height: 22px;
}
#sub12_6 .cnt1 .area .txt .join_info1 .box .edit_btn img {
	width: 100%;
	height: auto;
}
#sub12_6 .cnt1 .area .txt .join_info1 .box .add_btn {
	position: absolute;
	top: 20px;
	right: 16px;
	width: 22px;
	height: 22px;
	background: #46b87a;
	-webkit-border-radius: 11px;
	-moz-border-radius: 11px;
	border-radius: 11px;
}
#sub12_6 .cnt1 .area .txt .join_info1 .box .add_btn img {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 10px;
	height: auto;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
#sub12_6 .cnt1 .area .txt .join_info1 .ji1_1.on .add_btn {
	display: none;
}
#sub12_6 .cnt1 .area .txt .join_info2 {
	width: 600px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .txt .join_info2 > h3 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 18px;
	color: #222;
	padding-bottom: 8px;
}
#sub12_6 .cnt1 .area .txt .join_info2 .box {
	position: relative;
	width: 100%;
	background: #eef2ff;
	border: 1px dashed #5774db;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	border-radius: 16px;
	padding: 21px 0;
	margin-bottom: 12px;
}
#sub12_6 .cnt1 .area .txt .join_info2 .box:last-child {
	margin-bottom: 0;
}
#sub12_6 .cnt1 .area .txt .join_info2 .box h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #222;
	padding-left: 20px;
}
#sub12_6 .cnt1 .area .txt .join_info2 .box p {
	color: #444;
	padding-left: 20px;
	padding-top: 12px;
	font-size: 14px;
}
#sub12_6 .cnt1 .area .txt .join_info2 .box .info_box {
	width: calc(100% - 40px);
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .txt .join_info2 .box .info_box ul li {
	position: relative;
	padding-top: 24px;
	border-top: 1px solid rgba(70, 184, 122, 0.1);
	margin: 22px auto 24px;
}
#sub12_6 .cnt1 .area .txt .join_info2 .box .info_box ul li:first-child {
	margin-top: 0;
	margin-bottom: 0;
	border-top: 0;
}
#sub12_6 .cnt1 .area .txt .join_info2 .box .info_box ul li h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #444;
	padding-right: 20px;
	font-size: 14px;
}
#sub12_6 .cnt1 .area .txt .join_info2 .box .info_box ul li p.on {
	line-height: 16px;
	padding-left: 0;
}
#sub12_6 .cnt1 .area .txt .join_info2 .box .info_box ul li p:first-child {
	padding-top: 0;
}
#sub12_6 .cnt1 .area .txt .join_info2 .box .info_box ul li .edit_btn_1 {
	position: absolute;
	top: 22px;
	right: -4px;
	width: 22px;
	height: 22px;
}
#sub12_6 .cnt1 .area .txt .join_info2 .box .info_box ul li .edit_btn_1 img {
	width: 100%;
	height: auto;
}
#sub12_6 .cnt1 .area .txt .join_info2 .box .edit_btn {
	display: none;
	position: absolute;
	top: 17px;
	right: 16px;
	width: 22px;
	height: 22px;
}
#sub12_6 .cnt1 .area .txt .join_info2 .box .edit_btn img {
	width: 100%;
	height: auto;
}
#sub12_6 .cnt1 .area .txt .join_info2 .box .add_btn {
	position: absolute;
	top: 20px;
	right: 16px;
	width: 22px;
	height: 22px;
	background: #5774db;
	-webkit-border-radius: 11px;
	-moz-border-radius: 11px;
	border-radius: 11px;
}
#sub12_6 .cnt1 .area .txt .join_info2 .box .add_btn img {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 10px;
	height: auto;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
#sub12_6 .cnt1 .area .txt .join_info2 .ji2_6.on .add_btn {
	display: none;
}
#sub12_6 .cnt1 .area .txt > .submit_btn {
	display: block;
	width: 288px;
	height: 48px;
	line-height: 48px;
	background: #5774db;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	margin: 45px auto 0;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #fff;
	font-size: 16px;
	text-align: center;
}
#sub12_6 .cnt1 .area .intd_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	height: 610px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-top: 40px;
}
#sub12_6 .cnt1 .area .intd_pu .tit {
	width: 400px;
	margin: 0 auto 20px;
}
#sub12_6 .cnt1 .area .intd_pu .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub12_6 .cnt1 .area .intd_pu .form_cont {
	position: relative;
	width: 400px;
	height: 420px;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	overflow: hidden;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .intd_pu .form_cont textarea {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	width: calc(100% - 40px);
	height: 100%;
	border: 0;
	resize: none;
	padding: 0 20px;
	color: #222;
	margin-top: 18px;
	background: none;
	resize: none;
	line-height: 18px;
}
#sub12_6 .cnt1 .area .intd_pu .form_cont textarea:focus {
	outline: none;
}
#sub12_6 .cnt1 .area .intd_pu .form_cont label {
	position: absolute;
	top: 18px;
	left: 20px;
	cursor: text;
	font-size: 12px;
	line-height: 20px;
	color: #999;
	width: 360px;
}
#sub12_6 .cnt1 .area .intd_pu .form_cont label .label_wrap {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}
#sub12_6 .cnt1 .area .intd_pu .form_cont label .label_wrap .p_wrap {
	margin-top: 10px;
}
#sub12_6 .cnt1 .area .intd_pu .form_cont label .label_wrap .p_wrap:first-child {
	margin-top: 0;
}
#sub12_6 .cnt1 .area .intd_pu .form_cont label .label_wrap .p_wrap > * {
	float: left;
}
#sub12_6 .cnt1 .area .intd_pu .form_cont label .label_wrap .p_wrap .dot {
	margin: 0 5px;
}
#sub12_6 .cnt1 .area .intd_pu .form_cont label .label_wrap .p_wrap p {
	width: calc(100% - 20px);
}
#sub12_6 .cnt1 .area .intd_pu .w_num {
	width: 400px;
	margin: 9px auto 0;
}
#sub12_6 .cnt1 .area .intd_pu .w_num p {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	text-align: right;
	color: #aaa;
}
#sub12_6 .cnt1 .area .intd_pu .w_num p span {
	color: #5774db;
}
#sub12_6 .cnt1 .area .intd_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 40px;
}
#sub12_6 .cnt1 .area .intd_pu .save_btn.on {
	background: #5774db;
}
#sub12_6 .cnt1 .area .intd_pu.intd_pu_df {
	display: none;
}
#sub12_6 .cnt1 .area .intd_pu.intd_pu_on {
	display: none;
}
#sub12_6 .cnt1 .area .intd2_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 498px;
	height: 80%;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
	padding-top: 60px;
	max-height: 1044px;
}
#sub12_6 .cnt1 .area .intd2_pu .pu_wrap {
	height: 100%;
	overflow: auto;
}
#sub12_6 .cnt1 .area .intd2_pu .tit {
	width: 400px;
	margin: 0 auto 20px;
}
#sub12_6 .cnt1 .area .intd2_pu .tit h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub12_6 .cnt1 .area .intd2_pu .tit .btn_wrap {
	float: right;
}
#sub12_6 .cnt1 .area .intd2_pu .tit .btn_wrap button {
	float: left;
}
#sub12_6 .cnt1 .area .intd2_pu .tit .btn_wrap .rep_btn {
	width: 142px;
	height: 30px;
	background: #f2f2f2;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
}
#sub12_6 .cnt1 .area .intd2_pu .tit .btn_wrap .rep_btn > * {
	float: left;
}
#sub12_6 .cnt1 .area .intd2_pu .tit .btn_wrap .rep_btn .icon {
	width: 12px;
	height: 12px;
	background-image: url('../images/one_star_off.png');
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
	margin-top: 8px;
	margin-left: 14px;
	margin-right: 6px;
}
#sub12_6 .cnt1 .area .intd2_pu .tit .btn_wrap .rep_btn p {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #999;
	font-size: 12px;
	line-height: 30px;
}
#sub12_6 .cnt1 .area .intd2_pu .tit .btn_wrap .rep_btn.on {
	background: #5774db;
}
#sub12_6 .cnt1 .area .intd2_pu .tit .btn_wrap .rep_btn.on .icon {
	background-image: url('../images/one_star_on.png');
}
#sub12_6 .cnt1 .area .intd2_pu .tit .btn_wrap .rep_btn.on p {
	color: #fff;
}
#sub12_6 .cnt1 .area .intd2_pu .tit .btn_wrap .delete_btn {
	float: right;
	width: 69px;
	height: 30px;
	line-height: 30px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	border: 1px solid #ddd;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 13px;
	color: #999;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	margin-left: 8px;
}
#sub12_6 .cnt1 .area .intd2_pu .tit .btn_wrap .delete_btn img {
	width: 12px;
	height: auto;
	margin-top: 8px;
	margin-right: 6px;
}
#sub12_6 .cnt1 .area .intd2_pu .txt {
	width: 400px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li {
	position: relative;
	margin-top: 24px;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li:first-child {
	margin-top: 0;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	line-height: 17px;
	padding-bottom: 9px;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .form_cont {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .form_cont input {
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #000;
	font-size: 13px;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .form_cont textarea {
	width: 100%;
	height: 50px;
	border: 1px solid #eee;
	background: #fff;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #000;
	font-size: 13px;
	resize: none;
	padding-left: 20px;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .form_cont textarea:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .input_box {
	position: relative;
	width: 100%;
	height: 50px;
	border: 1px solid #eee;
	background: #fff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #000;
	font-size: 13px;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .input_box p {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li ul.depth2 li {
	position: relative;
	float: left;
	width: 196px;
	margin-left: 8px;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li ul.depth2 li:first-child {
	margin-left: 0;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap {
	position: relative;
	width: 100%;
	height: 50px;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap input {
	width: 100%;
	height: 100%;
	border: 0;
	font-size: 15px;
	color: #000 !important;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #aaa;
	font-size: 12px;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	padding-left: 20px;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li > strong {
	display: none;
	font-size: 10px;
	color: #ff0000;
	font-weight: normal;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap input::placeholder {
	color: #000;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap .cal_btn {
	position: absolute;
	top: 50%;
	right: 12px;
	margin-top: -6px;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li:last-child h5 {
	padding-bottom: 12px;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .edit_btn {
	position: absolute;
	top: -2px;
	right: 0;
	width: 22px;
	height: 22px;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .edit_btn img {
	width: 100%;
	height: auto;
}
#sub12_6 .cnt1 .area .intd2_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 60px;
}
#sub12_6 .cnt1 .area .intd2_pu .save_btn.on {
	background: #5774db;
}
#sub12_6 .cnt1 .area .intd2_pu_df {
	display: none;
}
#sub12_6 .cnt1 .area .intd2_pu_on {
	display: none;
}
#sub12_6 .cnt1 .area .intd2_pu_on .txt ul.depth1 > li .input_box p {
	color: #444;
}
#sub12_6 .cnt1 .area .intd2_pu_on .txt ul.depth1 > li .form_cont label {
	color: #444;
}
#sub12_6 .cnt1 .area .intd2_pu_on .txt ul.depth1 > li .form_cont textarea {
	height: auto;
	padding: 12px 20px;
}
#sub12_6 .cnt1 .area .car1_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 15;
	padding-bottom: 36px;
	max-height: 284px;
}
#sub12_6 .cnt1 .area .car1_pu .tit {
	width: 400px;
	margin: 0 auto;
	padding-top: 36px;
}
#sub12_6 .cnt1 .area .car1_pu .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub12_6 .cnt1 .area .car1_pu .form_cont {
	position: relative;
	width: 400px;
	height: 48px;
	line-height: 48px;
	margin: 20px auto 0;
	border-bottom: 1px solid #eee;
	overflow: hidden;
}
#sub12_6 .cnt1 .area .car1_pu .form_cont input {
	width: calc(100% - 30px);
	height: 100%;
	font-size: 14px;
	background: #fff;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	border: 0;
}
#sub12_6 .cnt1 .area .car1_pu .form_cont input:focus {
	outline: 0;
	color: #222;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub12_6 .cnt1 .area .car1_pu .form_cont label {
	position: absolute;
	top: 50%;
	left: 0;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
}
#sub12_6 .cnt1 .area .car1_pu .drp {
	height: 40px;
	line-height: 40px;
}
#sub12_6 .cnt1 .area .car1_pu .drp p {
	font-size: 13px;
	width: 400px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .car1_pu .car1_list {
	display: none;
	width: 400px;
	height: 160px;
	overflow: auto;
	margin: 24px auto 0;
}
#sub12_6 .cnt1 .area .car1_pu .car1_list ul li {
	width: 100%;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
}
#sub12_6 .cnt1 .area .car1_pu .car1_list ul li.on {
	background: #f0f4ff;
}
#sub12_6 .cnt1 .area .car1_pu .car1_list ul li p {
	font-size: 14px;
	color: #999;
	padding-left: 24px;
}
#sub12_6 .cnt1 .area .car1_pu .car1_list ul li p span {
	color: #5774db;
}
#sub12_6 .cnt1 .area .car1_pu .save_btn2 {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 64px;
}
#sub12_6 .cnt1 .area .car1_pu .save_btn2.on {
	background: #5774db;
}
#sub12_6 .cnt1 .area .emp_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 344px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 15;
	padding-bottom: 36px;
	padding-top: 40px;
}
#sub12_6 .cnt1 .area .emp_pu .txt {
	width: 288px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .emp_pu .txt ul li {
	margin-top: 24px;
}
#sub12_6 .cnt1 .area .emp_pu .txt ul li:first-child {
	margin-top: 0;
}
#sub12_6 .cnt1 .area .emp_pu .txt ul li div input[type='radio'] {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
#sub12_6 .cnt1 .area .emp_pu .txt ul li div input[type='radio'] + label {
	display: inline-block;
	position: relative;
	cursor: pointer;
	font-size: 14px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	padding-left: 30px;
}
#sub12_6 .cnt1 .area .emp_pu .txt ul li div input[type='radio'] + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
	text-align: center;
	border: 1px solid #5774db;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
	border-radius: 9px;
}
#sub12_6 .cnt1 .area .emp_pu .txt ul li div input[type='radio']:checked + label:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 4px;
	width: 10px;
	height: 10px;
	margin-top: -5px;
	text-align: center;
	background: #5774db;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
	border-radius: 9px;
}
#sub12_6 .cnt1 .area .emp_pu .ok_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 32px;
}
#sub12_6 .cnt1 .area .emp_pu .ok_btn.on {
	background: #5774db;
}
#sub12_6 .cnt1 .area .indus_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 15;
	padding-bottom: 36px;
	max-height: 284px;
}
#sub12_6 .cnt1 .area .indus_pu .tit {
	width: 400px;
	margin: 0 auto;
	padding-top: 36px;
}
#sub12_6 .cnt1 .area .indus_pu .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub12_6 .cnt1 .area .indus_pu .form_cont {
	position: relative;
	width: 400px;
	height: 48px;
	line-height: 48px;
	margin: 20px auto 0;
	border-bottom: 1px solid #eee;
	overflow: hidden;
}
#sub12_6 .cnt1 .area .indus_pu .form_cont input {
	width: calc(100% - 30px);
	height: 100%;
	font-size: 14px;
	background: #fff;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	border: 0;
}
#sub12_6 .cnt1 .area .indus_pu .form_cont input:focus {
	outline: 0;
	color: #222;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub12_6 .cnt1 .area .indus_pu .form_cont label {
	position: absolute;
	top: 50%;
	left: 0;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
}
#sub12_6 .cnt1 .area .indus_pu .form_cont .delete_btn {
	position: absolute;
	top: 50%;
	right: 0;
	margin-top: -10px;
	width: 20px;
	height: 20px;
}
#sub12_6 .cnt1 .area .indus_pu .form_cont .delete_btn img {
	width: 100%;
	height: auto;
}
#sub12_6 .cnt1 .area .indus_pu .drp {
	height: 40px;
	line-height: 40px;
}
#sub12_6 .cnt1 .area .indus_pu .drp p {
	font-size: 13px;
	padding-left: 0;
	width: 400px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .indus_pu .indus_list {
	display: none;
	width: 400px;
	margin: 24px auto 0;
}
#sub12_6 .cnt1 .area .indus_pu .indus_list ul li {
	width: 100%;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
}
#sub12_6 .cnt1 .area .indus_pu .indus_list ul li.on {
	background: #f0f4ff;
}
#sub12_6 .cnt1 .area .indus_pu .indus_list ul li p {
	font-size: 14px;
	color: #999;
	padding-left: 24px;
}
#sub12_6 .cnt1 .area .indus_pu .indus_list ul li p span {
	color: #5774db;
}
#sub12_6 .cnt1 .area .indus_pu .save_btn2 {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 64px;
}
#sub12_6 .cnt1 .area .indus_pu .save_btn2.on {
	background: #5774db;
}
#sub12_6 .cnt1 .area .bh_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 498px;
	height: 80%;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 15;
	padding-bottom: 36px;
	padding-top: 60px;
}
#sub12_6 .cnt1 .area .bh_pu .pu_wrap {
	height: 100%;
	overflow: auto;
}
#sub12_6 .cnt1 .area .bh_pu .tit {
	width: 360px;
	margin: 0 auto 40px;
}
#sub12_6 .cnt1 .area .bh_pu .tit h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub12_6 .cnt1 .area .bh_pu .tit .btn_wrap {
	float: right;
}
#sub12_6 .cnt1 .area .bh_pu .tit .btn_wrap button {
	float: left;
}
#sub12_6 .cnt1 .area .bh_pu .tit .btn_wrap .rep_btn {
	width: 142px;
	height: 30px;
	background: #f2f2f2;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
}
#sub12_6 .cnt1 .area .bh_pu .tit .btn_wrap .rep_btn > * {
	float: left;
}
#sub12_6 .cnt1 .area .bh_pu .tit .btn_wrap .rep_btn .icon {
	width: 12px;
	height: 12px;
	background-image: url('../images/one_star_off.png');
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
	margin-top: 8px;
	margin-left: 14px;
	margin-right: 6px;
}
#sub12_6 .cnt1 .area .bh_pu .tit .btn_wrap .rep_btn p {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #999;
	font-size: 12px;
	line-height: 30px;
}
#sub12_6 .cnt1 .area .bh_pu .tit .btn_wrap .rep_btn.on {
	background: #5774db;
}
#sub12_6 .cnt1 .area .bh_pu .tit .btn_wrap .rep_btn.on .icon {
	background-image: url('../images/one_star_on.png');
}
#sub12_6 .cnt1 .area .bh_pu .tit .btn_wrap .rep_btn.on p {
	color: #fff;
}
#sub12_6 .cnt1 .area .bh_pu .tit .btn_wrap .delete_btn {
	float: right;
	width: 69px;
	height: 30px;
	line-height: 30px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	border: 1px solid #ddd;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 13px;
	color: #999;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	margin-left: 8px;
}
#sub12_6 .cnt1 .area .bh_pu .tit .btn_wrap .delete_btn img {
	width: 12px;
	height: auto;
	margin-top: 8px;
	margin-right: 6px;
}
#sub12_6 .cnt1 .area .bh_pu .txt {
	width: 360px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .bh_pu .txt ul.depth1 > li {
	position: relative;
	margin-top: 24px;
}
#sub12_6 .cnt1 .area .bh_pu .txt ul.depth1 > li:first-child {
	margin-top: 0;
}
#sub12_6 .cnt1 .area .bh_pu .txt ul.depth1 > li h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	line-height: 17px;
	padding-bottom: 9px;
}
#sub12_6 .cnt1 .area .bh_pu .txt ul.depth1 > li .form_cont {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .bh_pu .txt ul.depth1 > li .form_cont input {
	width: 100%;
	height: 100%;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #000;
	font-size: 13px;
} /* border 삭제 */
#sub12_6 .cnt1 .area .bh_pu .txt ul.depth1 > li .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub12_6 .cnt1 .area .bh_pu .txt ul.depth1 > li .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub12_6 .cnt1 .area .bh_pu .txt ul.depth1 > li ul.depth2 li {
	float: left;
	width: 176px;
	margin-left: 8px;
}
#sub12_6 .cnt1 .area .bh_pu .txt ul.depth1 > li ul.depth2 li:first-child {
	margin-left: 0;
}
#sub12_6 .cnt1 .area .bh_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap {
	position: relative;
	width: 100%;
	height: 50px;
}
#sub12_6 .cnt1 .area .bh_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap input {
	width: 100%;
	height: 100%;
	border: 0;
	font-size: 15px;
	color: #000 !important;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #aaa;
	font-size: 12px;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	padding-left: 20px;
}
#sub12_6 .cnt1 .area .bh_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap input::placeholder {
	color: #000;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}
#sub12_6 .cnt1 .area .bh_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap .cal_btn {
	position: absolute;
	top: 50%;
	right: 12px;
	margin-top: -6px;
}
#sub12_6 .cnt1 .area .bh_pu .txt ul.depth1 > li > strong {
	display: none;
	font-size: 10px;
	color: #ff0000;
	font-weight: normal;
}
#sub12_6 .cnt1 .area .bh_pu .txt ul.depth1 > li.txtarea_li .form_cont {
	position: relative;
	width: 100%;
	height: 100px;
	overflow: hidden;
}
#sub12_6 .cnt1 .area .bh_pu .txt ul.depth1 > li.txtarea_li .form_cont textarea {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	resize: none;
	padding: 18px 20px;
	color: #222;
	font-size: 13px;
}
#sub12_6 .cnt1 .area .bh_pu .txt ul.depth1 > li.txtarea_li .form_cont textarea:focus {
	outline: none;
	color: #222;
	border: 1px solid #5774db;
}
#sub12_6 .cnt1 .area .bh_pu .txt ul.depth1 > li.txtarea_li .form_cont label {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	position: absolute;
	top: 24px;
	left: 20px;
	cursor: text;
	font-size: 13px;
	line-height: 15px;
	color: #999;
	word-break: keep-all;
}
#sub12_6 .cnt1 .area .bh_pu .save_btn2 {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 60px;
}
#sub12_6 .cnt1 .area .bh_pu .save_btn2.on {
	background: #5774db;
}
#sub12_6 .cnt1 .area .bh_pu_on {
	display: none;
}
#sub12_6 .cnt1 .area .bh_pu_on .txt ul.depth1 > li .form_cont input {
	color: #444;
}
#sub12_6 .cnt1 .area .bh2_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 498px;
	height: 80%;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
	padding-top: 60px;
	max-height: 758px;
}
#sub12_6 .cnt1 .area .bh2_pu .pu_wrap {
	height: 100%;
	overflow: auto;
}
#sub12_6 .cnt1 .area .bh2_pu .tit {
	width: 400px;
	margin: 0 auto 20px;
}
#sub12_6 .cnt1 .area .bh2_pu .tit h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub12_6 .cnt1 .area .bh2_pu .tit .btn_wrap {
	float: right;
}
#sub12_6 .cnt1 .area .bh2_pu .tit .btn_wrap button {
	float: left;
}
#sub12_6 .cnt1 .area .bh2_pu .tit .btn_wrap .rep_btn {
	width: 142px;
	height: 30px;
	background: #f2f2f2;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
}
#sub12_6 .cnt1 .area .bh2_pu .tit .btn_wrap .rep_btn > * {
	float: left;
}
#sub12_6 .cnt1 .area .bh2_pu .tit .btn_wrap .rep_btn .icon {
	width: 12px;
	height: 12px;
	background-image: url('../images/one_star_off.png');
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
	margin-top: 8px;
	margin-left: 14px;
	margin-right: 6px;
}
#sub12_6 .cnt1 .area .bh2_pu .tit .btn_wrap .rep_btn p {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #999;
	font-size: 12px;
	line-height: 30px;
}
#sub12_6 .cnt1 .area .bh2_pu .tit .btn_wrap .rep_btn.on {
	background: #5774db;
}
#sub12_6 .cnt1 .area .bh2_pu .tit .btn_wrap .rep_btn.on .icon {
	background-image: url('../images/one_star_on.png');
}
#sub12_6 .cnt1 .area .bh2_pu .tit .btn_wrap .rep_btn.on p {
	color: #fff;
}
#sub12_6 .cnt1 .area .bh2_pu .tit .btn_wrap .delete_btn {
	float: right;
	width: 69px;
	height: 30px;
	line-height: 30px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	border: 1px solid #ddd;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 13px;
	color: #999;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	margin-left: 8px;
}
#sub12_6 .cnt1 .area .bh2_pu .tit .btn_wrap .delete_btn img {
	width: 12px;
	height: auto;
	margin-top: 8px;
	margin-right: 6px;
}
#sub12_6 .cnt1 .area .bh2_pu .txt {
	width: 400px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li {
	position: relative;
	margin-top: 24px;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li:first-child {
	margin-top: 0;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	line-height: 17px;
	padding-bottom: 9px;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li .input_box {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
	border: 1px solid #eee;
	background: #fff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #000;
	font-size: 13px;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li .input_box p {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li .form_cont {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li .form_cont input {
	width: 100%;
	height: 100%;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #000;
	font-size: 13px;
} /* border 삭제 */
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li ul.depth2 li {
	float: left;
	width: 196px;
	margin-left: 8px;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li ul.depth2 li:first-child {
	margin-left: 0;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap {
	position: relative;
	width: 100%;
	height: 50px;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap input {
	width: 100%;
	height: 100%;
	border: 0;
	font-size: 15px;
	color: #000 !important;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #aaa;
	font-size: 12px;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	padding-left: 20px;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap input::placeholder {
	color: #000;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap .cal_btn {
	position: absolute;
	top: 50%;
	right: 12px;
	margin-top: -6px;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li > strong {
	display: none;
	font-size: 10px;
	color: #ff0000;
	font-weight: normal;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li.txtarea_li .form_cont {
	position: relative;
	width: 100%;
	height: 100px;
	overflow: hidden;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li.txtarea_li .form_cont textarea {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	resize: none;
	padding: 18px 20px;
	color: #222;
	font-size: 13px;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li.txtarea_li .form_cont textarea:focus {
	outline: none;
	color: #222;
	border: 1px solid #5774db;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li.txtarea_li .form_cont label {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	position: absolute;
	top: 24px;
	left: 20px;
	cursor: text;
	font-size: 13px;
	line-height: 15px;
	color: #999;
	word-break: keep-all;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li.chk_wrap {
	margin-top: 22px;
	height: 18px;
	line-height: 18px;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li.chk_wrap > div input[type='checkbox'] {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li.chk_wrap > div input[type='checkbox'] + label {
	display: inline-block;
	position: relative;
	cursor: pointer;
	color: #222;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	padding-left: 26px;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li.chk_wrap > div input[type='checkbox'] + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
	text-align: center;
	background: url('../images/checkbox_off.jpg') no-repeat center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
}
#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li.chk_wrap > div input[type='checkbox']:checked + label:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 18px;
	height: 18px;
	text-align: center;
	background: url('../images/checkbox_on.jpg') no-repeat center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
}
#sub12_6 .cnt1 .area .bh2_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 40px;
}
#sub12_6 .cnt1 .area .bh2_pu .save_btn.on {
	background: #5774db;
}
#sub12_6 .cnt1 .area .bh2_pu_on {
	display: none;
}
#sub12_6 .cnt1 .area .bh2_pu_on .txt ul.depth1 > li ul.depth2 li .cal_wrap input {
	color: #444;
}
#sub12_6 .cnt1 .area .bh2_pu_on .txt ul.depth1 > li .input_box p {
	color: #444;
}
#sub12_6 .cnt1 .area .edu1_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 15;
	padding-bottom: 36px;
	max-height: 284px;
}
#sub12_6 .cnt1 .area .edu1_pu .tit {
	width: 400px;
	margin: 0 auto;
	padding-top: 36px;
}
#sub12_6 .cnt1 .area .edu1_pu .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub12_6 .cnt1 .area .edu1_pu .form_cont {
	position: relative;
	width: 400px;
	height: 48px;
	line-height: 48px;
	margin: 20px auto 0;
	border-bottom: 1px solid #eee;
	overflow: hidden;
}
#sub12_6 .cnt1 .area .edu1_pu .form_cont input {
	width: calc(100% - 30px);
	height: 100%;
	font-size: 14px;
	background: #fff;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	border: 0;
}
#sub12_6 .cnt1 .area .edu1_pu .form_cont input:focus {
	outline: 0;
	color: #222;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub12_6 .cnt1 .area .edu1_pu .form_cont label {
	position: absolute;
	top: 50%;
	left: 0;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
}
#sub12_6 .cnt1 .area .edu1_pu .drp {
	height: 40px;
	line-height: 40px;
}
#sub12_6 .cnt1 .area .edu1_pu .drp p {
	font-size: 13px;
	padding-left: 0;
	width: 400px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .edu1_pu .edu1_list {
	display: none;
	width: 400px;
	margin: 24px auto 0;
}
#sub12_6 .cnt1 .area .edu1_pu .edu1_list ul li {
	width: 100%;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
}
#sub12_6 .cnt1 .area .edu1_pu .edu1_list ul li.on {
	background: #f0f4ff;
}
#sub12_6 .cnt1 .area .edu1_pu .edu1_list ul li p {
	font-size: 14px;
	color: #999;
	padding-left: 24px;
}
#sub12_6 .cnt1 .area .edu1_pu .edu1_list ul li p span {
	color: #5774db;
}
#sub12_6 .cnt1 .area .edu1_pu .save_btn2 {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 64px;
}
#sub12_6 .cnt1 .area .edu1_pu .save_btn2.on {
	background: #5774db;
}
#sub12_6 .cnt1 .area .degree_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 344px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 15;
	padding-bottom: 36px;
	padding-top: 40px;
}
#sub12_6 .cnt1 .area .degree_pu .txt {
	width: 288px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .degree_pu .txt ul li {
	margin-top: 24px;
}
#sub12_6 .cnt1 .area .degree_pu .txt ul li:first-child {
	margin-top: 0;
}
#sub12_6 .cnt1 .area .degree_pu .txt ul li div input[type='radio'] {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
#sub12_6 .cnt1 .area .degree_pu .txt ul li div input[type='radio'] + label {
	display: inline-block;
	position: relative;
	cursor: pointer;
	font-size: 14px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	padding-left: 30px;
}
#sub12_6 .cnt1 .area .degree_pu .txt ul li div input[type='radio'] + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
	text-align: center;
	border: 1px solid #5774db;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
	border-radius: 9px;
}
#sub12_6 .cnt1 .area .degree_pu .txt ul li div input[type='radio']:checked + label:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 4px;
	width: 10px;
	height: 10px;
	margin-top: -5px;
	text-align: center;
	background: #5774db;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
	border-radius: 9px;
}
#sub12_6 .cnt1 .area .degree_pu .ok_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 32px;
}
#sub12_6 .cnt1 .area .degree_pu .ok_btn.on {
	background: #5774db;
}
#sub12_6 .cnt1 .area .skill_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
}
#sub12_6 .cnt1 .area .skill_pu .tit {
	width: 400px;
	margin: 0 auto;
	padding-top: 36px;
}
#sub12_6 .cnt1 .area .skill_pu .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub12_6 .cnt1 .area .skill_pu .form_cont {
	position: relative;
	width: 400px;
	height: 48px;
	line-height: 48px;
	margin: 20px auto 0;
	border-bottom: 1px solid #eee;
	overflow: hidden;
}
#sub12_6 .cnt1 .area .skill_pu .form_cont input {
	width: 100%;
	height: 100%;
	font-size: 14px;
	background: #fff;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	border: 0;
}
#sub12_6 .cnt1 .area .skill_pu .form_cont input:focus {
	outline: 0;
	color: #222;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub12_6 .cnt1 .area .skill_pu .form_cont label {
	position: absolute;
	top: 50%;
	left: 0;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
}
#sub12_6 .cnt1 .area .skill_pu .des_box {
	width: 400px;
	background: #f2f2f2;
	margin: 0 auto;
	padding: 12px 0;
}
#sub12_6 .cnt1 .area .skill_pu .des_box ul li {
	margin-top: 7px;
}
#sub12_6 .cnt1 .area .skill_pu .des_box ul li:first-child {
	margin-top: 0;
}
#sub12_6 .cnt1 .area .skill_pu .des_box ul li > * {
	float: left;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #999;
	font-size: 11px;
}
#sub12_6 .cnt1 .area .skill_pu .des_box ul li span {
	padding-left: 20px;
	padding-right: 5px;
}
#sub12_6 .cnt1 .area .skill_pu .des_box ul li p {
	width: calc(100% - 55px);
	line-height: 17px;
}
#sub12_6 .cnt1 .area .skill_pu .skill_choice {
	width: 400px;
	margin: 20px auto 0;
}
#sub12_6 .cnt1 .area .skill_pu .skill_choice h4 {
	font-size: 13px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub12_6 .cnt1 .area .skill_pu .skill_choice > p {
	color: #999;
	padding-top: 10px;
}
#sub12_6 .cnt1 .area .skill_pu .skill_choice ul li {
	float: left;
	background: #5774db;
	height: 32px;
	line-height: 32px;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	border-radius: 16px;
	padding: 0 12px;
	margin-top: 8px;
	margin-right: 4px;
}
#sub12_6 .cnt1 .area .skill_pu .skill_choice ul li.off {
	display: none;
}
#sub12_6 .cnt1 .area .skill_pu .skill_choice ul li.on {
	display: block;
}
#sub12_6 .cnt1 .area .skill_pu .skill_choice ul li > * {
	float: left;
}
#sub12_6 .cnt1 .area .skill_pu .skill_choice ul li p {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #fff;
	margin-right: 5px;
}
#sub12_6 .cnt1 .area .skill_pu .skill_choice ul li .delete_btn {
	position: relative;
	width: 20px;
	height: 20px;
	overflow: hidden;
	margin-top: 5px;
}
#sub12_6 .cnt1 .area .skill_pu .skill_choice ul li .delete_btn img {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 26px;
	height: auto;
	margin-top: -13px;
	margin-left: -13px;
}

#sub12_6 .cnt1 .area .skill_pu .skill_list {
	display: none;
	width: 400px;
	margin: 16px auto 0;
	border-top: 1px solid #eee;
}
#sub12_6 .cnt1 .area .skill_pu .skill_list ul {
	padding-top: 24px;
}
#sub12_6 .cnt1 .area .skill_pu .skill_list ul li {
	width: 100%;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
}
#sub12_6 .cnt1 .area .skill_pu .skill_list ul li.on {
	background: #f0f4ff;
}
#sub12_6 .cnt1 .area .skill_pu .skill_list ul li p {
	float: left;
	font-size: 14px;
	color: #999;
}
#sub12_6 .cnt1 .area .skill_pu .skill_list ul li p span {
	color: #5774db;
}
#sub12_6 .cnt1 .area .skill_pu .skill_list ul li .add_btn {
	float: right;
	width: 20px;
	height: 20px;
	margin-top: 9px;
}
#sub12_6 .cnt1 .area .skill_pu .skill_list ul li .add_btn img {
	width: 100%;
	height: auto;
}
#sub12_6 .cnt1 .area .skill_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 64px;
}
#sub12_6 .cnt1 .area .skill_pu .save_btn.on {
	background: #5774db;
}
#sub12_6 .cnt1 .area .awd_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	overflow: auto;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
	padding-top: 60px;
}
#sub12_6 .cnt1 .area .awd_pu .tit {
	width: 400px;
	margin: 0 auto 40px;
}
#sub12_6 .cnt1 .area .awd_pu .tit h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub12_6 .cnt1 .area .awd_pu .delete_btn {
	position: absolute;
	top: 60px;
	right: 40px;
	width: 69px;
	height: 30px;
	line-height: 30px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	border: 1px solid #ddd;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 13px;
	color: #999;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	margin-left: 8px;
}
#sub12_6 .cnt1 .area .awd_pu .delete_btn img {
	width: 12px;
	height: auto;
	margin-top: 8px;
	margin-right: 6px;
}
#sub12_6 .cnt1 .area .awd_pu .txt {
	width: 400px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .awd_pu .txt ul.depth1 > li {
	position: relative;
	margin-top: 24px;
}
#sub12_6 .cnt1 .area .awd_pu .txt ul.depth1 > li:first-child {
	margin-top: 0;
}
#sub12_6 .cnt1 .area .awd_pu .txt ul.depth1 > li h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	line-height: 17px;
	padding-bottom: 9px;
}
#sub12_6 .cnt1 .area .awd_pu .txt ul.depth1 > li .form_cont {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .awd_pu .txt ul.depth1 > li .form_cont input {
	width: 100%;
	height: 100%;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #000;
	font-size: 13px;
} /* border 삭제 */
#sub12_6 .cnt1 .area .awd_pu .txt ul.depth1 > li .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub12_6 .cnt1 .area .awd_pu .txt ul.depth1 > li .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub12_6 .cnt1 .area .awd_pu .txt ul.depth1 > li .cal_wrap {
	position: relative;
	width: 152px;
	height: 50px;
}
#sub12_6 .cnt1 .area .awd_pu .txt ul.depth1 > li .cal_wrap input {
	width: 100%;
	height: 100%;
	border: 0;
	font-size: 15px;
	color: #000 !important;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #aaa;
	font-size: 12px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	padding-left: 20px;
} /* border 삭제 */
#sub12_6 .cnt1 .area .awd_pu .txt ul.depth1 > li .cal_wrap input::placeholder {
	color: #000;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}
#sub12_6 .cnt1 .area .awd_pu .txt ul.depth1 > li .cal_wrap .cal_btn {
	position: absolute;
	top: 50%;
	right: 12px;
	margin-top: -6px;
}
#sub12_6 .cnt1 .area .awd_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 40px;
}
#sub12_6 .cnt1 .area .awd_pu .save_btn.on {
	background: #5774db;
}
#sub12_6 .cnt1 .area .awd_pu_on {
	display: none;
}
#sub12_6 .cnt1 .area .tsis_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	height: 80%;
	max-height: 830px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
	padding-top: 60px;
}
#sub12_6 .cnt1 .area .tsis_pu .pu_wrap {
	height: 100%;
	overflow: auto;
}
#sub12_6 .cnt1 .area .tsis_pu .tit {
	width: 400px;
	margin: 0 auto 40px;
}
#sub12_6 .cnt1 .area .tsis_pu .tit h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub12_6 .cnt1 .area .tsis_pu .delete_btn {
	position: absolute;
	top: 60px;
	right: 40px;
	width: 69px;
	height: 30px;
	line-height: 30px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	border: 1px solid #ddd;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 13px;
	color: #999;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	margin-left: 8px;
}
#sub12_6 .cnt1 .area .tsis_pu .delete_btn img {
	width: 12px;
	height: auto;
	margin-top: 8px;
	margin-right: 6px;
}
#sub12_6 .cnt1 .area .tsis_pu .txt {
	width: 400px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .tsis_pu .txt ul.depth1 > li {
	position: relative;
	margin-top: 24px;
}
#sub12_6 .cnt1 .area .tsis_pu .txt ul.depth1 > li:first-child {
	margin-top: 0;
}
#sub12_6 .cnt1 .area .tsis_pu .txt ul.depth1 > li h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	line-height: 17px;
	padding-bottom: 9px;
}
#sub12_6 .cnt1 .area .tsis_pu .txt ul.depth1 > li .form_cont {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .tsis_pu .txt ul.depth1 > li .form_cont input {
	width: 100%;
	height: 100%;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #000;
	font-size: 13px;
} /* border 삭제 */
#sub12_6 .cnt1 .area .tsis_pu .txt ul.depth1 > li .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub12_6 .cnt1 .area .tsis_pu .txt ul.depth1 > li .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub12_6 .cnt1 .area .tsis_pu .txt ul.depth1 > li .form_cont textarea {
	width: 100%;
	height: 50px;
	border: 1px solid #eee;
	background: #fff;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #000;
	font-size: 13px;
	resize: none;
	padding-left: 20px;
}
#sub12_6 .cnt1 .area .tsis_pu .txt ul.depth1 > li .form_cont textarea:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub12_6 .cnt1 .area .tsis_pu .txt ul.depth1 > li .cal_wrap {
	position: relative;
	width: 152px;
	height: 50px;
}
#sub12_6 .cnt1 .area .tsis_pu .txt ul.depth1 > li .cal_wrap input {
	width: 100%;
	height: 100%;
	border: 0;
	font-size: 15px;
	color: #000 !important;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #aaa;
	font-size: 12px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	padding-left: 20px;
} /* border 삭제 */
#sub12_6 .cnt1 .area .tsis_pu .txt ul.depth1 > li .cal_wrap input::placeholder {
	color: #000;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}
#sub12_6 .cnt1 .area .tsis_pu .txt ul.depth1 > li .cal_wrap .cal_btn {
	position: absolute;
	top: 50%;
	right: 12px;
	margin-top: -6px;
}
#sub12_6 .cnt1 .area .tsis_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 40px;
}
#sub12_6 .cnt1 .area .tsis_pu .save_btn.on {
	background: #5774db;
}
#sub12_6 .cnt1 .area .tsis_pu_on {
	display: none;
}
#sub12_6 .cnt1 .area .tsis_pu_on .txt ul.depth1 > li .form_cont textarea {
	padding: 16px 20px;
}
#sub12_6 .cnt1 .area .pat_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	overflow: auto;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30pxx;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
	padding-top: 60px;
}
#sub12_6 .cnt1 .area .pat_pu .tit {
	width: 400px;
	margin: 0 auto 40px;
}
#sub12_6 .cnt1 .area .pat_pu .tit h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub12_6 .cnt1 .area .pat_pu .delete_btn {
	position: absolute;
	top: 60px;
	right: 40px;
	width: 69px;
	height: 30px;
	line-height: 30px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	border: 1px solid #ddd;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 13px;
	color: #999;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	margin-left: 8px;
}
#sub12_6 .cnt1 .area .pat_pu .delete_btn img {
	width: 12px;
	height: auto;
	margin-top: 8px;
	margin-right: 6px;
}
#sub12_6 .cnt1 .area .pat_pu .txt {
	width: 400px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .pat_pu .txt ul.depth1 > li {
	position: relative;
	margin-top: 24px;
}
#sub12_6 .cnt1 .area .pat_pu .txt ul.depth1 > li:first-child {
	margin-top: 0;
}
#sub12_6 .cnt1 .area .pat_pu .txt ul.depth1 > li h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	line-height: 17px;
	padding-bottom: 9px;
}
#sub12_6 .cnt1 .area .pat_pu .txt ul.depth1 > li .form_cont {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .pat_pu .txt ul.depth1 > li .form_cont input {
	width: 100%;
	height: 100%;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #000;
	font-size: 13px;
} /* border 삭제 */
#sub12_6 .cnt1 .area .pat_pu .txt ul.depth1 > li .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub12_6 .cnt1 .area .pat_pu .txt ul.depth1 > li .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub12_6 .cnt1 .area .pat_pu .txt ul.depth1 > li ul.depth2 li {
	float: left;
	width: 196px;
	margin-left: 8px;
}
#sub12_6 .cnt1 .area .pat_pu .txt ul.depth1 > li ul.depth2 li:first-child {
	margin-left: 0;
}
#sub12_6 .cnt1 .area .pat_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap {
	position: relative;
	width: 100%;
	height: 50px;
}
#sub12_6 .cnt1 .area .pat_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap input {
	width: 100%;
	height: 100%;
	border: 0;
	font-size: 15px;
	color: #000 !important;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #aaa;
	font-size: 12px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	padding-left: 20px;
} /* border 삭제 */
#sub12_6 .cnt1 .area .pat_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap input::placeholder {
	color: #000;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}
#sub12_6 .cnt1 .area .pat_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap .cal_btn {
	position: absolute;
	top: 50%;
	right: 12px;
	margin-top: -6px;
}
#sub12_6 .cnt1 .area .pat_pu .txt ul.depth1 > li > strong {
	display: none;
	font-size: 10px;
	color: #ff0000;
	font-weight: normal;
}
#sub12_6 .cnt1 .area .pat_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 40px;
}
#sub12_6 .cnt1 .area .pat_pu .save_btn.on {
	background: #5774db;
}
#sub12_6 .cnt1 .area .pat_pu_on {
	display: none;
}
#sub12_6 .cnt1 .area .fl_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	overflow: auto;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
	padding-top: 60px;
}
#sub12_6 .cnt1 .area .fl_pu .tit {
	width: 400px;
	margin: 0 auto 40px;
}
#sub12_6 .cnt1 .area .fl_pu .tit h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub12_6 .cnt1 .area .fl_pu .delete_btn {
	position: absolute;
	top: 60px;
	right: 40px;
	width: 69px;
	height: 30px;
	line-height: 30px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	border: 1px solid #ddd;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 13px;
	color: #999;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	margin-left: 8px;
}
#sub12_6 .cnt1 .area .fl_pu .delete_btn img {
	width: 12px;
	height: auto;
	margin-top: 8px;
	margin-right: 6px;
}
#sub12_6 .cnt1 .area .fl_pu .txt {
	width: 400px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li {
	position: relative;
	margin-top: 24px;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li:first-child {
	margin-top: 0;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li:last-child {
	margin-top: 42px;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	line-height: 17px;
	padding-bottom: 9px;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li .form_cont {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li .form_cont input {
	width: 100%;
	height: 100%;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #000;
	font-size: 13px;
} /* border 삭제 */
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li .input_box {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
	border: 1px solid #eee;
	background: #fff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #000;
	font-size: 13px;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li .input_box p {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li .add_btn {
	position: absolute;
	top: 0;
	right: 0;
	width: 16px;
	height: 16px;
	background: #5774db;
	-webkit-border-radius: 11px;
	-moz-border-radius: 11px;
	border-radius: 11px;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li .add_btn img {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 10px;
	height: auto;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li .info_box {
	width: 100%;
	margin: 0 auto;
	border: 1px solid #eee;
	background: #fff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li .info_box ul {
	width: calc(100% - 40px);
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li .info_box ul li {
	position: relative;
	padding: 18px 0;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li .info_box ul li .ib_top > * {
	float: left;
	color: #444;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li .info_box ul li .ib_top h6 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li .info_box ul li .ib_top p {
	font-family: SC4, SC5, 'Noto Sans KR', sans-serif;
	padding-left: 5px;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li .info_box ul li .ib_btm {
	margin-top: 4px;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li .info_box ul li .ib_btm > * {
	float: left;
	color: #444;
	padding-left: 5px;
	font-family: SC4, SC5, 'Noto Sans KR', sans-serif;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li .info_box ul li .ib_btm p:first-child {
	padding-left: 0;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li .info_box ul li .edit_btn {
	position: absolute;
	top: 18px;
	right: 0;
	width: 16px;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li .info_box ul li .edit_btn img {
	width: 100%;
	height: auto;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li ul.depth2 {
	padding-top: 16px;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li ul.depth2 li {
	float: left;
	height: 18px;
	margin-left: 16px;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li ul.depth2 li:first-child {
	margin-left: 0;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li ul.depth2 li div {
	height: 100%;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li ul.depth2 li div input[type='radio'] {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li ul.depth2 li div input[type='radio'] + label {
	display: inline-block;
	position: relative;
	cursor: pointer;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	padding-left: 26px;
	color: #666;
	line-height: 18px;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li ul.depth2 li div input[type='radio'] + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
	text-align: center;
	border: 1px solid #ddd;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
	border-radius: 9px;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li ul.depth2 li div input[type='radio']:checked + label:before {
	border: 1px solid #5774db;
}
#sub12_6 .cnt1 .area .fl_pu .txt ul.depth1 > li ul.depth2 li div input[type='radio']:checked + label:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 4px;
	width: 10px;
	height: 10px;
	margin-top: -5px;
	text-align: center;
	background: #5774db;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}
#sub12_6 .cnt1 .area .fl_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 40px;
}
#sub12_6 .cnt1 .area .fl_pu .save_btn.on {
	background: #5774db;
}
#sub12_6 .cnt1 .area .fl_pu_on {
	display: none;
}
#sub12_6 .cnt1 .area .fl2_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	overflow: auto;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 15;
	padding-bottom: 36px;
	padding-top: 60px;
}
#sub12_6 .cnt1 .area .fl2_pu .tit {
	width: 400px;
	margin: 0 auto 40px;
}
#sub12_6 .cnt1 .area .fl2_pu .tit h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub12_6 .cnt1 .area .fl2_pu .txt {
	width: 400px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .fl2_pu .txt ul.depth1 > li {
	position: relative;
	margin-top: 24px;
}
#sub12_6 .cnt1 .area .fl2_pu .txt ul.depth1 > li:first-child {
	margin-top: 0;
}
#sub12_6 .cnt1 .area .fl2_pu .txt ul.depth1 > li h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	line-height: 17px;
	padding-bottom: 9px;
}
#sub12_6 .cnt1 .area .fl2_pu .txt ul.depth1 > li .form_cont {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .fl2_pu .txt ul.depth1 > li .form_cont input {
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #000;
	font-size: 13px;
}
#sub12_6 .cnt1 .area .fl2_pu .txt ul.depth1 > li .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub12_6 .cnt1 .area .fl2_pu .txt ul.depth1 > li .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub12_6 .cnt1 .area .fl2_pu .txt ul.depth1 > li ul.depth2 {
	padding-top: 16px;
}
#sub12_6 .cnt1 .area .fl2_pu .txt ul.depth1 > li ul.depth2 li {
	float: left;
	height: 18px;
	margin-left: 16px;
}
#sub12_6 .cnt1 .area .fl2_pu .txt ul.depth1 > li ul.depth2 li:first-child {
	margin-left: 0;
}
#sub12_6 .cnt1 .area .fl2_pu .txt ul.depth1 > li ul.depth2 li div {
	height: 100%;
}
#sub12_6 .cnt1 .area .fl2_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 40px;
}
#sub12_6 .cnt1 .area .fl2_pu .save_btn.on {
	background: #5774db;
}
#sub12_6 .cnt1 .area .fl2_pu_on {
	display: none;
}
#sub12_6 .cnt1 .area .link_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	overflow: auto;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
	padding-top: 60px;
}
#sub12_6 .cnt1 .area .link_pu .tit {
	width: 400px;
	margin: 0 auto 40px;
}
#sub12_6 .cnt1 .area .link_pu .tit h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub12_6 .cnt1 .area .link_pu .delete_btn {
	position: absolute;
	top: 60px;
	right: 40px;
	width: 69px;
	height: 30px;
	line-height: 30px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	border: 1px solid #ddd;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 13px;
	color: #999;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	margin-left: 8px;
}
#sub12_6 .cnt1 .area .link_pu .delete_btn img {
	width: 12px;
	height: auto;
	margin-top: 8px;
	margin-right: 6px;
}
#sub12_6 .cnt1 .area .link_pu .txt h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	line-height: 17px;
	padding-bottom: 9px;
}
#sub12_6 .cnt1 .area .link_pu .txt {
	width: 400px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .link_pu .txt .form_cont {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .link_pu .txt .form_cont input {
	width: 100%;
	height: 100%;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #000;
	font-size: 13px;
} /* border 삭제 */
#sub12_6 .cnt1 .area .link_pu .txt .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub12_6 .cnt1 .area .link_pu .txt .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub12_6 .cnt1 .area .link_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 40px;
}
#sub12_6 .cnt1 .area .link_pu .save_btn.on {
	background: #5774db;
}
#sub12_6 .cnt1 .area .link_pu_on {
	display: none;
}
#sub12_6 .cnt1 .area .area_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
	max-height: 244px;
}
#sub12_6 .cnt1 .area .area_pu .tit {
	width: 400px;
	margin: 0 auto;
	padding-top: 36px;
}
#sub12_6 .cnt1 .area .area_pu .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub12_6 .cnt1 .area .area_pu .form_cont {
	position: relative;
	width: 400px;
	height: 48px;
	line-height: 48px;
	margin: 20px auto 0;
	border-bottom: 1px solid #eee;
	overflow: hidden;
}
#sub12_6 .cnt1 .area .area_pu .form_cont input {
	width: calc(100% - 30px);
	height: 100%;
	font-size: 14px;
	background: #fff;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	border: 0;
}
#sub12_6 .cnt1 .area .area_pu .form_cont input:focus {
	outline: 0;
	color: #222;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub12_6 .cnt1 .area .area_pu .form_cont label {
	position: absolute;
	top: 50%;
	left: 0;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
}
#sub12_6 .cnt1 .area .area_pu .area_list {
	display: none;
	width: 400px;
	margin: 24px auto 0;
}
#sub12_6 .cnt1 .area .area_pu .area_list ul li {
	width: 100%;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
}
#sub12_6 .cnt1 .area .area_pu .area_list ul li.on {
	background: #f0f4ff;
}
#sub12_6 .cnt1 .area .area_pu .area_list ul li p {
	font-size: 14px;
	color: #999;
	padding-left: 24px;
}
#sub12_6 .cnt1 .area .area_pu .area_list ul li p span {
	color: #5774db;
}
#sub12_6 .cnt1 .area .area_pu .save_btn2 {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 64px;
}
#sub12_6 .cnt1 .area .area_pu .save_btn2.on {
	background: #5774db;
}

#sub12_7 .cnt1 .good_pg {
	width: 600px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	padding: 84px 0;
	margin: 60px auto;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
#sub12_7 .cnt1 .good_pg .pg_wrap .illu {
	display: block;
	width: 172px;
	margin: 0 auto 24px;
}
#sub12_7 .cnt1 .good_pg .pg_wrap .illu img {
	width: 100%;
	height: auto;
}
#sub12_7 .cnt1 .good_pg .pg_wrap h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 22px;
	line-height: 1.3;
	text-align: center;
}
#sub12_7 .cnt1 .good_pg .pg_wrap p {
	font-size: 16px;
	color: #444;
	text-align: center;
	line-height: 19px;
	padding-top: 28px;
}
#sub12_7 .cnt1 .good_pg .pg_wrap .ing_btn {
	display: block;
	width: 160px;
	height: 48px;
	line-height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #5774db;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 40px;
	text-align: center;
}
#sub12_7 .cnt1 .good_pg .pg_wrap .pfgo_btn {
	display: block;
	width: 160px;
	height: 48px;
	line-height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #5774db;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 10px;
	text-align: center;
}

#sub13 .cnt1 {
	padding-bottom: 37px;
}
#sub13 .cnt1 .area .filter {
	margin-top: 37px;
	margin-right: 24px;
}
#sub13 .cnt1 .area .filter ul {
	float: right;
}
#sub13 .cnt1 .area .filter ul li {
	float: left;
	margin-left: 15px;
}
#sub13 .cnt1 .area .filter ul li:first-child {
	margin-left: 0;
}
#sub13 .cnt1 .area .filter ul li button {
	display: block;
}
#sub13 .cnt1 .area .filter ul li button > * {
	float: left;
}
#sub13 .cnt1 .area .filter ul li button p {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #999;
	font-size: 14px;
}
#sub13 .cnt1 .area .filter ul li button .icon {
	width: 12px;
	height: 10px;
	background-image: url('../images/filter_off.png');
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
	margin-top: 3px;
	margin-left: 6px;
}
#sub13 .cnt1 .area .filter ul li.on button p {
	color: #5774db;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub13 .cnt1 .area .filter ul li.on button .icon {
	background-image: url('../images/filter_on.png');
}
#sub13 .cnt1 .area .txt {
	width: 1032px;
	margin: 21px auto 0;
}
#sub13 .cnt1 .area .txt > ul > li {
	position: relative;
	float: left;
	width: 240px;
	margin-left: 24px;
	margin-bottom: 30px;
}
#sub13 .cnt1 .area .txt > ul > li:nth-child(4n + 1) {
	margin-left: 0;
}
#sub13 .cnt1 .area .txt > ul > li .wish_btn {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 1;
	width: 20px;
	height: 18px;
	background-image: url('../images/wish.png');
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
}
#sub13 .cnt1 .area .txt > ul > li.li_pa .wish_btn.on {
	background-image: url('../images/wish_on2.png');
}
#sub13 .cnt1 .area .txt > ul > li.li_kd .wish_btn.on {
	background-image: url('../images/wish_on.png');
}
#sub13 .cnt1 .area .txt > ul > li > a {
	display: block;
}
#sub13 .cnt1 .area .txt > ul > li > a .img {
	position: relative;
	width: 100%;
	height: 240px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	overflow: hidden;
}
#sub13 .cnt1 .area .txt > ul > li > a .img img {
	width: 100%;
	height: auto;
}
#sub13 .cnt1 .area .txt > ul > li > a .img .mark {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 78px;
	height: 78px;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
}
#sub13 .cnt1 .area .txt ul li a .img .mark1 {
	background-image: url('../images/main_c2_tri1.png');
}
#sub13 .cnt1 .area .txt ul li a .img .mark2 {
	background-image: url('../images/main_c2_tri2.png');
}
#sub13 .cnt1 .area .txt ul li a .img .mark3 {
	background-image: url('../images/main_c2_tri3.png');
}
#sub13 .cnt1 .area .txt ul li a .img .mark p {
	position: absolute;
	right: 10px;
	bottom: 10px;
	font-family: PTDb, PTDr, 'Noto Sans KR', sans-serif;
	color: #fff;
	font-size: 14px;
}
#sub13 .cnt1 .area .txt ul li a .img .end_blk {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.75);
	z-index: 1;
}
#sub13 .cnt1 .area .txt ul li a .img .end_blk .cc {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -50px;
	margin-left: -50px;
	width: 100px;
	height: 100px;
	border: 1px solid #fff;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	border-radius: 50px;
}
#sub13 .cnt1 .area .txt ul li a .img .end_blk .cc p {
	font-size: 14px;
	color: #fff;
	text-align: center;
	line-height: 100px;
}
#sub13 .cnt1 .area .txt ul li a > h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #222;
	font-size: 16px;
	padding-top: 12px;
	padding-bottom: 8px;
}
#sub13 .cnt1 .area .txt ul li a .p_wrap p {
	color: #999;
}
#sub13 .cnt1 .area .txt ul li a .p_wrap p:first-child {
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
}
#sub13 .cnt1 .area .txt ul li a .hash {
	margin-top: 13px;
}
#sub13 .cnt1 .area .txt ul li a .hash li {
	float: left;
	background: #f1f3f9;
	height: 26px;
	line-height: 26px;
	-webkit-border-radius: 13px;
	-moz-border-radius: 13px;
	border-radius: 13px;
	padding: 0 10px;
	margin-left: 4px;
}
#sub13 .cnt1 .area .txt ul li a .hash li:first-child {
	margin-left: 0;
}
#sub13 .cnt1 .area .txt ul li a .hash li p {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 11px;
	color: #677cc4;
}
#sub13 .cnt1 .area .txt ul li a .bar {
	display: block;
	width: 100%;
	height: 1px;
	background: #eee;
	margin: 12px 0 15px;
}
#sub13 .cnt1 .area .txt ul li a .info li {
	margin-top: 9px;
}
#sub13 .cnt1 .area .txt ul li a .info li:first-child {
	margin-top: 0;
}
#sub13 .cnt1 .area .txt ul li a .info li > * {
	float: left;
}
#sub13 .cnt1 .area .txt ul li a .info li h5 {
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
	color: #222;
	width: 50px;
}
#sub13 .cnt1 .area .txt ul li a .info li p {
	color: #666;
}

#sub14 .cnt1 {
	padding-bottom: 37px;
}
#sub14 .cnt1 .area .status {
	width: 1032px;
	height: 84px;
	margin: 23px auto 0;
	background: #f4f7ff;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	border-radius: 12px;
	padding-top: 24px;
}
#sub14 .cnt1 .area .status ul {
	width: 230px;
	margin: 0 auto;
}
#sub14 .cnt1 .area .status ul li {
	float: left;
}
#sub14 .cnt1 .area .status ul li.stt {
	width: 17%;
}
#sub14 .cnt1 .area .status ul li h4 {
	width: 100%;
	height: 22px;
	line-height: 22px;
	background: #5774db;
	-webkit-border-radius: 11px;
	-moz-border-radius: 11px;
	border-radius: 11px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #fff;
	text-align: center;
}
#sub14 .cnt1 .area .status ul li p {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	text-align: center;
	font-size: 22px;
	color: #222;
	padding-top: 12px;
}
#sub14 .cnt1 .area .status ul li.dash_line {
	width: 24.5%;
}
#sub14 .cnt1 .area .status ul li.dash_line span {
	display: block;
	width: 100%;
	height: 1px;
	border-top: 1px dashed #5774db;
	margin-top: 11px;
}
#sub14 .cnt1 .area .filter {
	margin-top: 23px;
	margin-right: 24px;
}
#sub14 .cnt1 .area .filter ul {
	float: right;
}
#sub14 .cnt1 .area .filter ul li {
	float: left;
	margin-left: 15px;
}
#sub14 .cnt1 .area .filter ul li:first-child {
	margin-left: 0;
}
#sub14 .cnt1 .area .filter ul li button {
	display: block;
}
#sub14 .cnt1 .area .filter ul li button > * {
	float: left;
}
#sub14 .cnt1 .area .filter ul li button p {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #999;
	font-size: 14px;
}
#sub14 .cnt1 .area .filter ul li button .icon {
	width: 12px;
	height: 10px;
	background-image: url('../images/filter_off.png');
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
	margin-top: 3px;
	margin-left: 6px;
}
#sub14 .cnt1 .area .filter ul li.on button p {
	color: #5774db;
	font-weight: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub14 .cnt1 .area .filter ul li.on button .icon {
	background-image: url('../images/filter_on.png');
}
#sub14 .cnt1 .area .txt {
	width: 1032px;
	margin: 21px auto 0;
}
#sub14 .cnt1 .area .txt > ul > li {
	float: left;
	width: 240px;
	margin-left: 24px;
	margin-bottom: 30px;
}
#sub14 .cnt1 .area .txt > ul > li:nth-child(4n + 1) {
	margin-left: 0;
}
#sub14 .cnt1 .area .txt > ul > li > a {
	display: block;
}
#sub14 .cnt1 .area .txt > ul > li > a .img {
	position: relative;
	width: 100%;
	height: 240px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	overflow: hidden;
}
#sub14 .cnt1 .area .txt > ul > li > a .img img {
	width: 100%;
	height: auto;
}
#sub14 .cnt1 .area .txt > ul > li > a .img .mark {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 78px;
	height: 78px;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
}
#sub14 .cnt1 .area .txt ul li a .img .mark1 {
	background-image: url('../images/main_c2_tri1.png');
}
#sub14 .cnt1 .area .txt ul li a .img .mark2 {
	background-image: url('../images/main_c2_tri2.png');
}
#sub14 .cnt1 .area .txt ul li a .img .mark3 {
	background-image: url('../images/main_c2_tri3.png');
}
#sub14 .cnt1 .area .txt ul li a .img .mark p {
	position: absolute;
	right: 10px;
	bottom: 10px;
	font-family: PTDb, PTDr, 'Noto Sans KR', sans-serif;
	color: #fff;
	font-size: 14px;
}
#sub14 .cnt1 .area .txt ul li a .tit {
	position: relative;
	margin-top: 12px;
}
#sub14 .cnt1 .area .txt ul li a .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #222;
	font-size: 16px;
	padding-bottom: 8px;
}
#sub14 .cnt1 .area .txt ul li a .tit .stt_mark {
	position: absolute;
	top: 0;
	right: 0;
	width: 45px;
	height: 20px;
	line-height: 20px;
	background: #5774db;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	color: #fff;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	text-align: center;
	font-size: 10px;
}
#sub14 .cnt1 .area .txt ul li a .tit .p_wrap p {
	color: #999;
}
#sub14 .cnt1 .area .txt ul li a .tit .p_wrap p:first-child {
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
}
#sub14 .cnt1 .area .txt ul li a .hash {
	margin-top: 13px;
}
#sub14 .cnt1 .area .txt ul li a .hash li {
	float: left;
	background: #f1f3f9;
	height: 26px;
	line-height: 26px;
	-webkit-border-radius: 13px;
	-moz-border-radius: 13px;
	border-radius: 13px;
	padding: 0 10px;
	margin-left: 4px;
}
#sub14 .cnt1 .area .txt ul li a .hash li:first-child {
	margin-left: 0;
}
#sub14 .cnt1 .area .txt ul li a .hash li p {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 11px;
	color: #677cc4;
}
#sub14 .cnt1 .area .txt ul li a .bar {
	display: block;
	width: 100%;
	height: 1px;
	background: #eee;
	margin: 12px 0 15px;
}
#sub14 .cnt1 .area .txt ul li a .info li {
	margin-top: 9px;
}
#sub14 .cnt1 .area .txt ul li a .info li:first-child {
	margin-top: 0;
}
#sub14 .cnt1 .area .txt ul li a .info li > * {
	float: left;
}
#sub14 .cnt1 .area .txt ul li a .info li h5 {
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
	color: #222;
	width: 50px;
}
#sub14 .cnt1 .area .txt ul li a .info li p {
	color: #666;
}
#sub14 .cnt1 .spt_cc_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 344px;
	background: #fff;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	z-index: 11;
	padding-bottom: 36px;
}
#sub14 .cnt1 .spt_cc_pu h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	text-align: center;
	font-size: 18px;
	padding: 36px 0 32px;
}
#sub14 .cnt1 .spt_cc_pu .btn_wrap {
	width: 246px;
	margin: 0 auto;
}
#sub14 .cnt1 .spt_cc_pu .btn_wrap button {
	float: left;
	width: 120px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	font-size: 16px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #fff;
}
#sub14 .cnt1 .spt_cc_pu .btn_wrap .cancel_btn {
	background: #666;
}
#sub14 .cnt1 .spt_cc_pu .btn_wrap .ok_btn {
	background: #5774db;
	margin-left: 6px;
}

#sub15 .cnt1 {
	margin-top: 48px;
	padding-bottom: 24px;
}
#sub15 .cnt1 .area .profile .pf_img {
	position: relative;
	width: 120px;
	margin: 0 auto;
}
#sub15 .cnt1 .area .profile .pf_img img {
	width: 100%;
	height: auto;
}
#sub15 .cnt1 .area .profile .pf_img .profile_modi {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 32px;
	height: 32px;
}
#sub15 .cnt1 .area .profile .pf_img .profile_modi img {
	width: 100%;
	height: auto;
}
#sub15 .cnt1 .area .profile .pf_img .ip_file {
	width: 0;
	height: 0;
	display: none;
}
#sub15 .cnt1 .area .profile .pf_name {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 22px;
	text-align: center;
	color: #222;
	padding-top: 12px;
}
#sub15 .cnt1 .area .profile .pf_comp {
	margin-top: 37px;
}
#sub15 .cnt1 .area .profile .pf_comp h3 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	text-align: center;
	color: #5774db;
	font-size: 18px;
}
#sub15 .cnt1 .area .profile .pf_comp ul {
	text-align: center;
	margin-top: 12px;
}
#sub15 .cnt1 .area .profile .pf_comp ul li {
	display: inline-block;
	width: 48px;
	height: 22px;
	line-height: 22px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
}
#sub15 .cnt1 .area .profile .pf_comp ul li a {
	display: block;
	width: 100%;
	height: 100%;
}
#sub15 .cnt1 .area .profile .pf_comp ul li a p {
	text-align: center;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .area .profile .pf_comp ul li.pf_comp_li1 {
	border: 1px dashed #666;
}
#sub15 .cnt1 .area .profile .pf_comp ul li.pf_comp_li1.on {
	background: #444;
	border: 0;
}
#sub15 .cnt1 .area .profile .pf_comp ul li.pf_comp_li1 p {
	color: #666;
}
#sub15 .cnt1 .area .profile .pf_comp ul li.pf_comp_li2 {
	border: 1px dashed #f8603f;
}
#sub15 .cnt1 .area .profile .pf_comp ul li.pf_comp_li2.on {
	background: #f3785d;
}
#sub15 .cnt1 .area .profile .pf_comp ul li.pf_comp_li2 p {
	color: #f8603f;
}
#sub15 .cnt1 .area .profile .pf_comp ul li.pf_comp_li3 {
	border: 1px dashed #46b87a;
}
#sub15 .cnt1 .area .profile .pf_comp ul li.pf_comp_li3.on {
	background: #46b87a;
}
#sub15 .cnt1 .area .profile .pf_comp ul li.pf_comp_li3 p {
	color: #46b87a;
}
#sub15 .cnt1 .area .profile .pf_comp ul li.pf_comp_li4 {
	border: 1px dashed #5774db;
}
#sub15 .cnt1 .area .profile .pf_comp ul li.pf_comp_li4.on {
	background: #5774db;
}
#sub15 .cnt1 .area .profile .pf_comp ul li.pf_comp_li4 p {
	color: #5774db;
}
#sub15 .cnt1 .area .profile .pf_comp ul li.on p {
	color: #fff;
}
#sub15 .cnt1 .area .bar {
	display: block;
	width: 100%;
	height: 1px;
	background: #e8e8ea;
	margin: 47px 0;
}
#sub15 .cnt1 .area .pf_info1 {
	width: 600px;
	margin: 0 auto;
}
#sub15 .cnt1 .area .pf_info1 > h3 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 18px;
	color: #222;
	padding-bottom: 8px;
}
#sub15 .cnt1 .area .pf_info1 .box {
	width: 100%;
	background: #f6f6f6;
	border: 1px dashed #ccc;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	border-radius: 16px;
	padding: 30px 0;
}
#sub15 .cnt1 .area .pf_info1 .box ul {
	padding-left: 20px;
}
#sub15 .cnt1 .area .pf_info1 .box ul li {
	position: relative;
	margin-top: 24px;
}
#sub15 .cnt1 .area .pf_info1 .box ul li:first-child {
	margin-top: 0;
}
#sub15 .cnt1 .area .pf_info1 .box ul li > * {
	float: left;
}
#sub15 .cnt1 .area .pf_info1 .box ul li .squ {
	width: 4px;
	height: 4px;
	background: #444;
	margin-right: 10px;
	margin-top: 6px;
}
#sub15 .cnt1 .area .pf_info1 .box ul li p {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #444;
	font-size: 14px;
}
#sub15 .cnt1 .area .pf_info1 .box ul li .edit_btn {
	position: absolute;
	top: 50%;
	right: 22px;
	margin-top: -7px;
	width: 13px;
}
#sub15 .cnt1 .area .pf_info1 .box ul li .edit_btn img {
	width: 100%;
	height: auto;
}
#sub15 .cnt1 .area .pf_info2 {
	width: 600px;
	margin: 0 auto;
}
#sub15 .cnt1 .area .pf_info2 > h3 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #222;
	padding-bottom: 8px;
}
#sub15 .cnt1 .area .pf_info2 .box {
	position: relative;
	width: 100%;
	background: #fff2ef;
	border: 1px dashed #f8603f;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	border-radius: 16px;
	padding: 21px 0;
	margin-bottom: 12px;
}
#sub15 .cnt1 .area .pf_info2 .box:last-child {
	margin-bottom: 0;
}
#sub15 .cnt1 .area .pf_info2 .box h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #222;
	padding-left: 20px;
}
#sub15 .cnt1 .area .pf_info2 .box p {
	color: #444;
	padding-left: 20px;
	padding-top: 12px;
	font-size: 14px;
}
#sub15 .cnt1 .area .pf_info2 .box p.on {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .area .pf_info2 .box .edit_btn {
	position: absolute;
	top: 20px;
	right: 16px;
	width: 22px;
	height: 22px;
}
#sub15 .cnt1 .area .pf_info2 .box .edit_btn img {
	width: 100%;
	height: auto;
}
#sub15 .cnt1 .area .pf_info2 .box .add_btn {
	display: none;
	position: absolute;
	top: 21px;
	right: 16px;
	width: 22px;
	height: 22px;
	background: #f8603f;
	-webkit-border-radius: 11px;
	-moz-border-radius: 11px;
	border-radius: 11px;
}
#sub15 .cnt1 .area .pf_info2 .box .add_btn img {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 10px;
	height: auto;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
#sub15 .cnt1 .area .pf_info2 > button {
	display: block;
	height: 50px;
}
#sub15 .cnt1 .area .pf_info3 {
	width: 600px;
	margin: 0 auto;
}
#sub15 .cnt1 .area .pf_info3 > h3 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #222;
	padding-bottom: 8px;
}
#sub15 .cnt1 .area .pf_info3 .box {
	position: relative;
	width: 100%;
	background: #f5fffa;
	border: 1px dashed #46b87a;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	border-radius: 16px;
	padding: 21px 0;
	margin-bottom: 12px;
}
#sub15 .cnt1 .area .pf_info3 .box:last-child {
	margin-bottom: 0;
}
#sub15 .cnt1 .area .pf_info3 .box h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #222;
	padding-left: 20px;
}
#sub15 .cnt1 .area .pf_info3 .box p {
	color: #444;
	padding: 12px 16px 0 20px;
	word-break: keep-all;
	font-size: 14px;
}
#sub15 .cnt1 .area .pf_info3 .box .info_box {
	width: calc(100% - 40px);
	margin: 0 auto;
}
#sub15 .cnt1 .area .pf_info3 .box .info_box ul li {
	position: relative;
	padding-top: 24px;
	border-top: 1px solid rgba(70, 184, 122, 0.1);
	margin: 22px auto 24px;
}
#sub15 .cnt1 .area .pf_info3 .box .info_box ul li:first-child {
	margin-top: 0;
	margin-bottom: 0;
	border-top: 0;
}
#sub15 .cnt1 .area .pf_info3 .box .info_box ul li h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #444;
	padding-right: 20px;
	font-size: 14px;
}
#sub15 .cnt1 .area .pf_info3 .box .info_box ul li p.on {
	line-height: 16px;
	padding-left: 0;
	font-size: 14px;
	line-height: 1.5;
}
#sub15 .cnt1 .area .pf_info3 .box .info_box ul li p:first-child {
	padding-top: 0;
}
#sub15 .cnt1 .area .pf_info3 .box .info_box ul li .edit_btn_1 {
	position: absolute;
	top: 21px;
	right: -4px;
	width: 22px;
	height: 22px;
}
#sub15 .cnt1 .area .pf_info3 .box .info_box ul li .edit_btn_1 img {
	width: 100%;
	height: auto;
}
#sub15 .cnt1 .area .pf_info3 .box .edit_btn {
	display: none;
	position: absolute;
	top: 17px;
	right: 16px;
	width: 22px;
	height: 22px;
}
#sub15 .cnt1 .area .pf_info3 .box .edit_btn img {
	width: 100%;
	height: auto;
}
#sub15 .cnt1 .area .pf_info3 .box .add_btn {
	position: absolute;
	top: 21px;
	right: 16px;
	width: 22px;
	height: 22px;
	background: #46b87a;
	-webkit-border-radius: 11px;
	-moz-border-radius: 11px;
	border-radius: 11px;
}
#sub15 .cnt1 .area .pf_info3 .box .add_btn img {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 10px;
	height: auto;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
#sub15 .cnt1 .area .pf_info4 {
	width: 600px;
	margin: 0 auto;
}
#sub15 .cnt1 .area .pf_info4 > h3 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 18px;
	color: #222;
	padding-bottom: 8px;
}
#sub15 .cnt1 .area .pf_info4 .box {
	position: relative;
	width: 100%;
}
#sub15 .cnt1 .area .pf_info4 .box ul li {
	height: 40px;
	line-height: 40px;
}
#sub15 .cnt1 .area .pf_info4 .box ul li > * {
	float: left;
}
#sub15 .cnt1 .area .pf_info4 .box ul li .icon {
	width: 20px;
	height: 20px;
	margin-right: 10px;
	margin-top: 9px;
}
#sub15 .cnt1 .area .pf_info4 .box ul li .icon img {
	width: 100%;
	height: auto;
}
#sub15 .cnt1 .area .pf_info4 .box ul li h4 {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #444;
	font-size: 14px;
}
#sub15 .cnt1 .area .pf_info4 .box ul li p {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #5774db;
	font-size: 18px;
	padding-left: 10px;
}
#sub15 .cnt1 .area .pf_info6 {
	width: 600px;
	margin: 0 auto;
}
#sub15 .cnt1 .area .pf_info6 > h3 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #222;
	padding-bottom: 8px;
}
#sub15 .cnt1 .area .pf_info6 .box {
	position: relative;
	width: 100%;
	background: #eef2ff;
	border: 1px dashed #5774db;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	border-radius: 16px;
	padding: 21px 0;
	margin-bottom: 12px;
}
#sub15 .cnt1 .area .pf_info6 .box:last-child {
	margin-bottom: 0;
}
#sub15 .cnt1 .area .pf_info6 .box h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #222;
	padding-left: 20px;
}
#sub15 .cnt1 .area .pf_info6 .box p {
	color: #444;
	padding-left: 20px;
	padding-top: 12px;
	font-size: 14px;
}
#sub15 .cnt1 .area .pf_info6 .box .info_box {
	width: calc(100% - 40px);
	margin: 0 auto;
}
#sub15 .cnt1 .area .pf_info6 .box .info_box ul li {
	position: relative;
	padding-top: 24px;
	border-top: 1px solid rgba(70, 184, 122, 0.1);
	margin: 22px auto 24px;
}
#sub15 .cnt1 .area .pf_info6 .box .info_box ul li:first-child {
	margin-top: 0;
	margin-bottom: 0;
	border-top: 0;
}
#sub15 .cnt1 .area .pf_info6 .box .info_box ul li h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #444;
	padding-right: 20px;
	font-size: 14px;
	line-height: 1.5;
}
#sub15 .cnt1 .area .pf_info6 .box .info_box ul li p.on {
	line-height: 16px;
	padding-left: 0;
	font-size: 14px;
}
#sub15 .cnt1 .area .pf_info6 .box .info_box ul li p:first-child {
	padding-top: 0;
}
#sub15 .cnt1 .area .pf_info6 .box .info_box ul li .edit_btn_1 {
	position: absolute;
	top: 21px;
	right: -4px;
	width: 22px;
	height: 22px;
}
#sub15 .cnt1 .area .pf_info6 .box .info_box ul li .edit_btn_1 img {
	width: 100%;
	height: auto;
}
#sub15 .cnt1 .area .pf_info6 .box .add_btn {
	position: absolute;
	top: 21px;
	right: 16px;
	width: 22px;
	height: 22px;
	background: #5774db;
	-webkit-border-radius: 11px;
	-moz-border-radius: 11px;
	border-radius: 11px;
}
#sub15 .cnt1 .area .pf_info6 .box .add_btn img {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 10px;
	height: auto;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
#sub15 .cnt1 .area .pf_info5 {
	width: 600px;
	margin: 0 auto;
}
#sub15 .cnt1 .area .pf_info5 .tit {
	margin-bottom: 28px;
}
#sub15 .cnt1 .area .pf_info5 .tit h3 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 24px;
	color: #222;
}
#sub15 .cnt1 .area .pf_info5 .tit .filter {
	padding-top: 8px;
}
#sub15 .cnt1 .area .pf_info5 .tit .filter ul {
	float: right;
}
#sub15 .cnt1 .area .pf_info5 .tit .filter ul li {
	float: left;
	margin-left: 12px;
}
#sub15 .cnt1 .area .pf_info5 .tit .filter ul li:first-child {
	margin-left: 0;
}
#sub15 .cnt1 .area .pf_info5 .tit .filter ul li button {
	display: block;
}
#sub15 .cnt1 .area .pf_info5 .tit .filter ul li button > * {
	float: left;
}
#sub15 .cnt1 .area .pf_info5 .tit .filter ul li button p {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #999;
	font-size: 12px;
}
#sub15 .cnt1 .area .pf_info5 .tit .filter ul li button .icon {
	width: 9px;
	height: 7px;
	background-image: url('../images/filter_off.png');
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
	margin-top: 4px;
	margin-left: 6px;
}
#sub15 .cnt1 .area .pf_info5 .tit .filter ul li.on button p {
	color: #5774db;
}
#sub15 .cnt1 .area .pf_info5 .tit .filter ul li.on button .icon {
	background-image: url('../images/filter_on.png');
}
#sub15 .cnt1 .area .pf_info5 .box > div {
	float: left;
	width: 292px;
}
#sub15 .cnt1 .area .pf_info5 .box > div ul li {
	margin-top: 5px;
}
#sub15 .cnt1 .area .pf_info5 .box > div ul li:first-child {
	margin-top: 0;
}
#sub15 .cnt1 .area .pf_info5 .box > div ul li > * {
	float: left;
	color: #444;
}
#sub15 .cnt1 .area .pf_info5 .box > div ul li h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .area .pf_info5 .box > div ul li p {
	padding-left: 4px;
}
#sub15 .cnt1 .area .pf_info5 .box > div .letter {
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	padding-top: 20px;
	padding-bottom: 32px;
	margin-top: 10px;
}
#sub15 .cnt1 .area .pf_info5 .box > div .letter .illu {
	display: block;
	width: 174px;
	margin: 0 auto 16px;
}
#sub15 .cnt1 .area .pf_info5 .box > div .letter .illu img {
	width: 100%;
	height: auto;
}
#sub15 .cnt1 .area .pf_info5 .box > div .letter p {
	font-family: MaBuB, 'Noto Sans KR', sans-serif;
	font-size: 11px;
	line-height: 15px;
	padding: 0 20px;
	word-break: keep-all;
	color: #444;
}
#sub15 .cnt1 .area .pf_info5 .box .box_1 {
	margin-right: 16px;
}
#sub15 .cnt1 .area .pf_info5 .box .box_1 .letter {
	background: #fff2c3;
}
#sub15 .cnt1 .area .pf_info5 .box .box_2 .letter {
	background: #ffdcd6;
}

.close_btn {
	position: absolute;
	top: 20px;
	right: 20px;
	width: 16px;
}
.close_btn img {
	width: 100%;
	height: auto;
}

.close_btn2 {
	position: absolute;
	top: 20px;
	right: 20px;
	width: 16px;
}
.close_btn2 img {
	width: 100%;
	height: auto;
}

.close_btn3 {
	position: absolute;
	top: 20px;
	right: 20px;
	width: 16px;
}
.close_btn3 img {
	width: 100%;
	height: auto;
}

.pu_nav {
	display: none;
	width: 100%;
	height: 34px;
	background: #fff;
	border-bottom: 1px solid #eee;
}
.pu_nav .down {
	float: right;
	width: 24px;
	height: 24px;
	margin-top: 6px;
	margin-right: 24px;
}
.pu_nav .down img {
	width: 100%;
	height: auto;
}

.pu_nav2 {
	display: none;
	width: 100%;
	height: 52px;
	background: #fff;
	border-bottom: 1px solid #eee;
}
.pu_nav2 .prev {
	float: left;
	width: 32px;
	height: 32px;
	margin: 10px 0 10px 12px;
}
.pu_nav2 .prev img {
	width: 100%;
	height: auto;
}
.pu_nav2 h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 18px;
	line-height: 52px;
	padding-left: 10px;
}
.pu_nav2 .down {
	float: right;
	width: 24px;
	height: 24px;
	margin-top: 12px;
	margin-right: 24px;
}
.pu_nav2 .down img {
	width: 100%;
	height: auto;
}

.pu_nav3 {
	display: none;
	width: 100%;
	height: 52px;
	background: #fff;
	border-bottom: 1px solid #eee;
}
.pu_nav3 .prev {
	float: left;
	width: 32px;
	height: 32px;
	margin: 10px 0 10px 12px;
}
.pu_nav3 .prev img {
	width: 100%;
	height: auto;
}
.pu_nav3 h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 18px;
	line-height: 52px;
	padding-left: 10px;
}

#sub15 .cnt1 .rep_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 11;
	padding-bottom: 36px;
}
#sub15 .cnt1 .rep_pu h4 {
	width: 400px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
	padding: 36px 0;
	margin: 0 auto;
}
#sub15 .cnt1 .rep_pu .form_cont {
	position: relative;
	width: 400px;
	height: 57px;
	line-height: 57px;
	margin: 0 auto;
}
#sub15 .cnt1 .rep_pu .form_cont input {
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	font-size: 18px;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #444;
}
#sub15 .cnt1 .rep_pu .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub15 .cnt1 .rep_pu .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #444;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 18px;
}
#sub15 .cnt1 .rep_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 36px;
}
#sub15 .cnt1 .rep_pu .save_btn.on {
	background: #5774db;
}
#sub15 .cnt1 .kd_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 400px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 11;
	padding: 50px 0;
}
#sub15 .cnt1 .kd_pu .tit {
	width: 180px;
	margin: 0 auto;
}
#sub15 .cnt1 .kd_pu .tit h2 {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 24px;
	line-height: 28px;
}
#sub15 .cnt1 .kd_pu .tit p {
	padding-top: 16px;
}
#sub15 .cnt1 .kd_pu .txt ul {
	text-align: center;
	margin-top: 30px;
}
#sub15 .cnt1 .kd_pu .txt ul li {
	display: inline-block;
	margin-left: 20px;
}
#sub15 .cnt1 .kd_pu .txt ul li:first-child {
	margin-left: 0;
}
#sub15 .cnt1 .kd_pu .txt ul li div input[type='radio'] {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
#sub15 .cnt1 .kd_pu .txt ul li div input[type='radio'] + label {
	display: inline-block;
	position: relative;
	cursor: pointer;
	font-size: 14px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	padding-left: 30px;
}
#sub15 .cnt1 .kd_pu .txt ul li div input[type='radio'] + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
	text-align: center;
	border: 1px solid #5774db;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
	border-radius: 9px;
}
#sub15 .cnt1 .kd_pu .txt ul li div input[type='radio']:checked + label:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 4px;
	width: 10px;
	height: 10px;
	margin-top: -5px;
	text-align: center;
	background: #5774db;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
	border-radius: 9px;
}
#sub15 .cnt1 .kd_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 36px;
}
#sub15 .cnt1 .kd_pu .save_btn.on {
	background: #5774db;
}
#sub15 .cnt1 .car_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 11;
	height: 350px;
	overflow: visible;
}
#sub15 .cnt1 .car_pu .tit {
	width: 360px;
	margin: 0 auto;
	padding: 50px 0 44px;
}
#sub15 .cnt1 .car_pu .tit h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub15 .cnt1 .car_pu > p {
	font-size: 13px;
	width: 360px;
	margin: 0 auto;
	padding-bottom: 9px;
}
#sub15 .cnt1 .car_pu .career_wrap {
	position: relative;
	width: 360px;
	height: 55px;
	margin: 0 auto;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	overflow: hidden;
}
#sub15 .cnt1 .car_pu .career_wrap p {
	width: calc(100% - 10px);
	height: 55px;
	line-height: 53px;
	color: #aaa;
	font-size: 16px;
	font-weight: normal;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	padding-left: 20px;
	background-color: #fff;
	border: 0;
	cursor: pointer;
}
#sub15 .cnt1 .car_pu .career_wrap .arrow {
	position: absolute;
	top: 50%;
	right: 20px;
	margin-top: -6px;
}
#sub15 .cnt1 .car_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	line-height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 36px;
	text-align: center;
}
#sub15 .cnt1 .car_pu .save_btn.on {
	background: #5774db;
}
#sub15 .cnt1 .range_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	width: 480px;
	background: #fff;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 13;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	padding-bottom: 50px;
}
#sub15 .cnt1 .range_pu h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
	width: calc(100% - 70px);
	margin: 0 auto;
	padding-top: 40px;
}
#sub15 .cnt1 .range_pu .close_btn {
	position: absolute;
	top: 20px;
	right: 20px;
}
#sub15 .cnt1 .range_pu .range-slider {
	width: 100%;
	margin-top: 20px;
}
#sub15 .cnt1 .range_pu .range-slider__range {
	display: block;
	-webkit-appearance: none;
	width: calc(100% - 70px);
	height: 6px;
	border-radius: 5px;
	background: #d7dcdf;
	outline: none;
	padding: 0;
	margin: 10px auto 0;
}
#sub15 .cnt1 .range_pu .range-slider__range::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background: #2c3e50;
	cursor: pointer;
	-webkit-transition: background 0.15s ease-in-out;
	transition: background 0.15s ease-in-out;
}
#sub15 .cnt1 .range_pu .range-slider__range::-webkit-slider-thumb:hover {
	background: #1abc9c;
}
#sub15 .cnt1 .range_pu .range-slider__range:active::-webkit-slider-thumb {
	background: #1abc9c;
}
#sub15 .cnt1 .range_pu .range-slider__range::-moz-range-thumb {
	width: 20px;
	height: 20px;
	border: 0;
	border-radius: 50%;
	background: #5774db;
	cursor: pointer;
	-moz-transition: background 0.15s ease-in-out;
	transition: background 0.15s ease-in-out;
}
#sub15 .cnt1 .range_pu .range-slider__range::-moz-range-thumb:hover {
	background: #1abc9c;
}
#sub15 .cnt1 .range_pu .range-slider__range:active::-moz-range-thumb {
	background: #1abc9c;
}
#sub15 .cnt1 .range_pu .range-slider__range:focus::-webkit-slider-thumb {
	box-shadow: 0 0 0 3px #fff, 0 0 0 6px #1abc9c;
}
#sub15 .cnt1 .range_pu .range-slider__value {
	display: inline-block;
	position: relative;
	text-align: center;
	border-radius: 3px;
	padding: 5px 10px;
	margin-left: 35px;
	font-family: SC4, SC5, 'Noto Sans KR', sans-serif;
	font-size: 16px;
}
::-moz-range-track {
	background: #d7dcdf;
	border: 0;
}
input::-moz-focus-inner,
input::-moz-focus-outer {
	border: 0;
}
#sub15 .cnt1 .range_pu .ok_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #5774db;
	margin: 50px auto 0;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
}
#sub15 .cnt1 .car_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 36px;
}
#sub15 .cnt1 .car_pu .save_btn.on {
	background: #5774db;
}
#sub15 .cnt1 .field0_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 11;
	padding-bottom: 36px;
}
#sub15 .cnt1 .field0_pu .tit {
	width: 360px;
	margin: 0 auto;
	padding: 36px 0 44px;
}
#sub15 .cnt1 .field0_pu .tit h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub15 .cnt1 .field0_pu > p {
	font-size: 13px;
	width: 360px;
	margin: 0 auto;
	padding-bottom: 9px;
}
#sub15 .cnt1 .field0_pu .input_box {
	position: relative;
	width: 360px;
	height: 52px;
	line-height: 52px;
	margin: 0 auto;
	border: 1px solid #eee;
	background: #fff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #000;
	font-size: 13px;
}
#sub15 .cnt1 .field0_pu .input_box p {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub15 .cnt1 .field0_pu .form_cont {
	position: relative;
	width: 360px;
	height: 52px;
	line-height: 52px;
	margin: 0 auto;
}
#sub15 .cnt1 .field0_pu .form_cont input {
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	font-size: 13px;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #222;
}
#sub15 .cnt1 .field0_pu .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub15 .cnt1 .field0_pu .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub15 .cnt1 .field0_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 36px;
}
#sub15 .cnt1 .field0_pu .save_btn.on {
	background: #5774db;
}
#sub15 .cnt1 .field_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
	max-height: 383px;
}
#sub15 .cnt1 .field_pu .tit {
	width: 400px;
	margin: 0 auto;
	padding-top: 36px;
}
#sub15 .cnt1 .field_pu .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub15 .cnt1 .field_pu .form_cont {
	position: relative;
	width: 400px;
	height: 48px;
	line-height: 48px;
	margin: 20px auto 0;
	border-bottom: 1px solid #eee;
	overflow: hidden;
}
#sub15 .cnt1 .field_pu .form_cont input {
	width: calc(100% - 30px);
	height: 100%;
	font-size: 14px;
	background: #fff;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	border: 0;
}
#sub15 .cnt1 .field_pu .form_cont input:focus {
	outline: 0;
	color: #222;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .field_pu .form_cont label {
	position: absolute;
	top: 50%;
	left: 0;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
}
#sub15 .cnt1 .field_pu .form_cont .delete_btn {
	position: absolute;
	top: 50%;
	right: 0;
	margin-top: -10px;
	width: 20px;
	height: 20px;
}
#sub15 .cnt1 .field_pu .form_cont .delete_btn img {
	width: 100%;
	height: auto;
}
#sub15 .cnt1 .field_pu .des_box {
	width: 400px;
	background: #f2f2f2;
	margin: 0 auto;
	padding: 12px 0;
}
#sub15 .cnt1 .field_pu .des_box ul li {
	margin-top: 7px;
}
#sub15 .cnt1 .field_pu .des_box ul li:first-child {
	margin-top: 0;
}
#sub15 .cnt1 .field_pu .des_box ul li > * {
	float: left;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #999;
	font-size: 11px;
}
#sub15 .cnt1 .field_pu .des_box ul li span {
	padding-left: 20px;
	padding-right: 5px;
}
#sub15 .cnt1 .field_pu .des_box ul li p {
	width: calc(100% - 55px);
	line-height: 17px;
}
#sub15 .cnt1 .field_pu .field_list {
	display: none;
	width: 400px;
	height: 160px;
	overflow: auto;
	margin: 24px auto 0;
}
#sub15 .cnt1 .field_pu .field_list ul li {
	width: 100%;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
}
#sub15 .cnt1 .field_pu .field_list ul li.on {
	background: #f0f4ff;
}
#sub15 .cnt1 .field_pu .field_list ul li p {
	font-size: 14px;
	color: #999;
	padding-left: 24px;
}
#sub15 .cnt1 .field_pu .field_list ul li p span {
	color: #5774db;
}
#sub15 .cnt1 .field_pu .save_btn2 {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 64px;
}
#sub15 .cnt1 .field_pu .save_btn2.on {
	background: #5774db;
}
#sub15 .cnt1 .intd_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	height: 610px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-top: 40px;
}
#sub15 .cnt1 .intd_pu .tit {
	width: 400px;
	margin: 0 auto 20px;
}
#sub15 .cnt1 .intd_pu .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub15 .cnt1 .intd_pu .form_cont {
	position: relative;
	width: 400px;
	height: 420px;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	overflow: hidden;
	margin: 0 auto;
}
#sub15 .cnt1 .intd_pu .form_cont textarea {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	width: calc(100% - 40px);
	height: 100%;
	border: 0;
	resize: none;
	padding: 0 20px;
	color: #222;
	margin-top: 18px;
	background: none;
	resize: none;
	line-height: 18px;
}
#sub15 .cnt1 .intd_pu .form_cont textarea:focus {
	outline: none;
}
#sub15 .cnt1 .intd_pu .form_cont label {
	position: absolute;
	top: 18px;
	left: 20px;
	cursor: text;
	font-size: 12px;
	line-height: 20px;
	color: #999;
	width: 360px;
}
#sub15 .cnt1 .intd_pu .form_cont label .label_wrap {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}
#sub15 .cnt1 .intd_pu .form_cont label .label_wrap .p_wrap {
	margin-top: 10px;
}
#sub15 .cnt1 .intd_pu .form_cont label .label_wrap .p_wrap:first-child {
	margin-top: 0;
}
#sub15 .cnt1 .intd_pu .form_cont label .label_wrap .p_wrap > * {
	float: left;
}
#sub15 .cnt1 .intd_pu .form_cont label .label_wrap .p_wrap .dot {
	margin: 0 5px;
}
#sub15 .cnt1 .intd_pu .form_cont label .label_wrap .p_wrap p {
	width: calc(100% - 20px);
}
#sub15 .cnt1 .intd_pu .w_num {
	width: 400px;
	margin: 9px auto 0;
}
#sub15 .cnt1 .intd_pu .w_num p {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	text-align: right;
	color: #aaa;
}
#sub15 .cnt1 .intd_pu .w_num p span {
	color: #5774db;
}
#sub15 .cnt1 .intd_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 40px;
}
#sub15 .cnt1 .intd_pu .save_btn.on {
	background: #5774db;
}
#sub15 .cnt1 .intd2_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 498px;
	height: 80%;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 11;
	padding-bottom: 36px;
	padding-top: 60px;
	max-height: 1044px;
}
#sub15 .cnt1 .intd2_pu .pu_wrap {
	height: 100%;
	overflow: auto;
}
#sub15 .cnt1 .intd2_pu .tit {
	width: 400px;
	margin: 0 auto 20px;
}
#sub15 .cnt1 .intd2_pu .tit h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub15 .cnt1 .intd2_pu .tit .btn_wrap {
	float: right;
}
#sub15 .cnt1 .intd2_pu .tit .btn_wrap button {
	float: left;
}
#sub15 .cnt1 .intd2_pu .tit .btn_wrap .rep_btn {
	width: 142px;
	height: 30px;
	background: #f2f2f2;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
}
#sub15 .cnt1 .intd2_pu .tit .btn_wrap .rep_btn > * {
	float: left;
}
#sub15 .cnt1 .intd2_pu .tit .btn_wrap .rep_btn .icon {
	width: 12px;
	height: 12px;
	background-image: url('../images/one_star_off.png');
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
	margin-top: 8px;
	margin-left: 14px;
	margin-right: 6px;
}
#sub15 .cnt1 .intd2_pu .tit .btn_wrap .rep_btn p {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #999;
	font-size: 12px;
	line-height: 30px;
}
#sub15 .cnt1 .intd2_pu .tit .btn_wrap .rep_btn.on {
	background: #5774db;
}
#sub15 .cnt1 .intd2_pu .tit .btn_wrap .rep_btn.on .icon {
	background-image: url('../images/one_star_on.png');
}
#sub15 .cnt1 .intd2_pu .tit .btn_wrap .rep_btn.on p {
	color: #fff;
}
#sub15 .cnt1 .intd2_pu .tit .btn_wrap .delete_btn {
	float: right;
	width: 69px;
	height: 30px;
	line-height: 30px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	border: 1px solid #ddd;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 13px;
	color: #999;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	margin-left: 8px;
}
#sub15 .cnt1 .intd2_pu .tit .btn_wrap .delete_btn img {
	width: 12px;
	height: auto;
	margin-top: 8px;
	margin-right: 6px;
}
#sub15 .cnt1 .intd2_pu .txt {
	width: 400px;
	margin: 0 auto;
}
#sub15 .cnt1 .intd2_pu .txt ul.depth1 > li {
	position: relative;
	margin-top: 24px;
}
#sub15 .cnt1 .intd2_pu .txt ul.depth1 > li:first-child {
	margin-top: 0;
}
#sub15 .cnt1 .intd2_pu .txt ul.depth1 > li h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	line-height: 17px;
	padding-bottom: 9px;
}
#sub15 .cnt1 .intd2_pu .txt ul.depth1 > li .form_cont {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
#sub15 .cnt1 .intd2_pu .txt ul.depth1 > li .form_cont input {
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #000;
	font-size: 13px;
}
#sub15 .cnt1 .intd2_pu .txt ul.depth1 > li .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub15 .cnt1 .intd2_pu .txt ul.depth1 > li .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub15 .cnt1 .intd2_pu .txt ul.depth1 > li .form_cont textarea {
	width: 100%;
	height: 50px;
	border: 1px solid #eee;
	background: #fff;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #000;
	font-size: 13px;
	resize: none;
	padding-left: 20px;
}
#sub15 .cnt1 .intd2_pu .txt ul.depth1 > li .form_cont textarea:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub15 .cnt1 .intd2_pu .txt ul.depth1 > li .input_box {
	position: relative;
	width: 100%;
	height: 50px;
	border: 1px solid #eee;
	background: #fff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #000;
	font-size: 13px;
}
#sub15 .cnt1 .intd2_pu .txt ul.depth1 > li .input_box p {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub15 .cnt1 .intd2_pu .txt ul.depth1 > li ul.depth2 li {
	position: relative;
	float: left;
	width: 196px;
	margin-left: 8px;
}
#sub15 .cnt1 .intd2_pu .txt ul.depth1 > li ul.depth2 li:first-child {
	margin-left: 0;
}
#sub15 .cnt1 .intd2_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap {
	position: relative;
	width: 100%;
	height: 50px;
}
#sub15 .cnt1 .intd2_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap input {
	width: 100%;
	height: 100%;
	border: 0;
	font-size: 15px;
	color: #000 !important;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #aaa;
	font-size: 12px;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	padding-left: 20px;
}
#sub15 .cnt1 .intd2_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap input::placeholder {
	color: #000;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .intd2_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap .cal_btn {
	position: absolute;
	top: 50%;
	right: 12px;
	margin-top: -6px;
}
#sub15 .cnt1 .intd2_pu .txt ul.depth1 > li > strong {
	display: none;
	font-size: 10px;
	color: #ff0000;
	font-weight: normal;
}
#sub15 .cnt1 .intd2_pu .txt ul.depth1 > li:last-child h5 {
	padding-bottom: 12px;
}
#sub15 .cnt1 .intd2_pu .txt ul.depth1 > li .edit_btn {
	position: absolute;
	top: -2px;
	right: 0;
	width: 22px;
	height: 22px;
}
#sub15 .cnt1 .intd2_pu .txt ul.depth1 > li .edit_btn img {
	width: 100%;
	height: auto;
}
#sub15 .cnt1 .intd2_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 60px;
}
#sub15 .cnt1 .intd2_pu .save_btn.on {
	background: #5774db;
}
#sub15 .cnt1 .intd2_pu_on .txt ul.depth1 > li .input_box p {
	color: #444;
}
#sub15 .cnt1 .intd2_pu_on .txt ul.depth1 > li .form_cont label {
	color: #444;
}
#sub15 .cnt1 .intd2_pu_on .txt ul.depth1 > li .form_cont textarea {
	height: auto;
	padding: 12px 20px;
}
#sub15 .cnt1 .emp_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 344px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
	padding-top: 40px;
}
#sub15 .cnt1 .emp_pu .txt {
	width: 288px;
	margin: 0 auto;
}
#sub15 .cnt1 .emp_pu .txt ul li {
	margin-top: 24px;
}
#sub15 .cnt1 .emp_pu .txt ul li:first-child {
	margin-top: 0;
}
#sub15 .cnt1 .emp_pu .txt ul li div input[type='radio'] {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
#sub15 .cnt1 .emp_pu .txt ul li div input[type='radio'] + label {
	display: inline-block;
	position: relative;
	cursor: pointer;
	font-size: 14px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	padding-left: 30px;
}
#sub15 .cnt1 .emp_pu .txt ul li div input[type='radio'] + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
	text-align: center;
	border: 1px solid #5774db;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
	border-radius: 9px;
}
#sub15 .cnt1 .emp_pu .txt ul li div input[type='radio']:checked + label:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 4px;
	width: 10px;
	height: 10px;
	margin-top: -5px;
	text-align: center;
	background: #5774db;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
	border-radius: 9px;
}
#sub15 .cnt1 .emp_pu .ok_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 32px;
}
#sub15 .cnt1 .emp_pu .ok_btn.on {
	background: #5774db;
}
#sub15 .cnt1 .indus_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
	max-height: 284px;
}
#sub15 .cnt1 .indus_pu .tit {
	width: 400px;
	margin: 0 auto;
	padding-top: 36px;
}
#sub15 .cnt1 .indus_pu .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub15 .cnt1 .indus_pu .form_cont {
	position: relative;
	width: 400px;
	height: 48px;
	line-height: 48px;
	margin: 20px auto 0;
	border-bottom: 1px solid #eee;
	overflow: hidden;
}
#sub15 .cnt1 .indus_pu .form_cont input {
	width: calc(100% - 30px);
	height: 100%;
	font-size: 14px;
	background: #fff;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	border: 0;
}
#sub15 .cnt1 .indus_pu .form_cont input:focus {
	outline: 0;
	color: #222;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .indus_pu .form_cont label {
	position: absolute;
	top: 50%;
	left: 0;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
}
#sub15 .cnt1 .indus_pu .form_cont .delete_btn {
	position: absolute;
	top: 50%;
	right: 0;
	margin-top: -10px;
	width: 20px;
	height: 20px;
}
#sub15 .cnt1 .indus_pu .form_cont .delete_btn img {
	width: 100%;
	height: auto;
}
#sub15 .cnt1 .indus_pu .drp {
	height: 40px;
	line-height: 40px;
}
#sub15 .cnt1 .indus_pu .drp p {
	font-size: 13px;
	padding-left: 0;
	width: 400px;
	margin: 0 auto;
}
#sub15 .cnt1 .indus_pu .indus_list {
	display: none;
	width: 400px;
	margin: 24px auto 0;
}
#sub15 .cnt1 .indus_pu .indus_list ul li {
	width: 100%;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
}
#sub15 .cnt1 .indus_pu .indus_list ul li.on {
	background: #f0f4ff;
}
#sub15 .cnt1 .indus_pu .indus_list ul li p {
	font-size: 14px;
	color: #999;
	padding-left: 24px;
}
#sub15 .cnt1 .indus_pu .indus_list ul li p span {
	color: #5774db;
}
#sub15 .cnt1 .indus_pu .save_btn2 {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 64px;
}
#sub15 .cnt1 .indus_pu .save_btn2.on {
	background: #5774db;
}
#sub15 .cnt1 .bh_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 498px;
	height: 80%;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
	padding-top: 60px;
}
#sub15 .cnt1 .bh_pu .pu_wrap {
	height: 100%;
	overflow: auto;
}
#sub15 .cnt1 .bh_pu .tit {
	width: 360px;
	margin: 0 auto 40px;
}
#sub15 .cnt1 .bh_pu .tit h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub15 .cnt1 .bh_pu .tit .btn_wrap {
	float: right;
}
#sub15 .cnt1 .bh_pu .tit .btn_wrap button {
	float: left;
}
#sub15 .cnt1 .bh_pu .tit .btn_wrap .rep_btn {
	width: 142px;
	height: 30px;
	background: #f2f2f2;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
}
#sub15 .cnt1 .bh_pu .tit .btn_wrap .rep_btn > * {
	float: left;
}
#sub15 .cnt1 .bh_pu .tit .btn_wrap .rep_btn .icon {
	width: 12px;
	height: 12px;
	background-image: url('../images/one_star_off.png');
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
	margin-top: 8px;
	margin-left: 14px;
	margin-right: 6px;
}
#sub15 .cnt1 .bh_pu .tit .btn_wrap .rep_btn p {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #999;
	font-size: 12px;
	line-height: 30px;
}
#sub15 .cnt1 .bh_pu .tit .btn_wrap .rep_btn.on {
	background: #5774db;
}
#sub15 .cnt1 .bh_pu .tit .btn_wrap .rep_btn.on .icon {
	background-image: url('../images/one_star_on.png');
}
#sub15 .cnt1 .bh_pu .tit .btn_wrap .rep_btn.on p {
	color: #fff;
}
#sub15 .cnt1 .bh_pu .tit .btn_wrap .delete_btn {
	float: right;
	width: 69px;
	height: 30px;
	line-height: 30px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	border: 1px solid #ddd;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 13px;
	color: #999;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	margin-left: 8px;
}
#sub15 .cnt1 .bh_pu .tit .btn_wrap .delete_btn img {
	width: 12px;
	height: auto;
	margin-top: 8px;
	margin-right: 6px;
}
#sub15 .cnt1 .bh_pu .txt {
	width: 360px;
	margin: 0 auto;
}
#sub15 .cnt1 .bh_pu .txt ul.depth1 > li {
	position: relative;
	margin-top: 24px;
}
#sub15 .cnt1 .bh_pu .txt ul.depth1 > li:first-child {
	margin-top: 0;
}
#sub15 .cnt1 .bh_pu .txt ul.depth1 > li h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	line-height: 17px;
	padding-bottom: 9px;
}
#sub15 .cnt1 .bh_pu .txt ul.depth1 > li .form_cont {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
#sub15 .cnt1 .bh_pu .txt ul.depth1 > li .form_cont input {
	width: 100%;
	height: 100%;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #000;
	font-size: 13px;
} /* border 삭제 */
#sub15 .cnt1 .bh_pu .txt ul.depth1 > li .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub15 .cnt1 .bh_pu .txt ul.depth1 > li .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub15 .cnt1 .bh_pu .txt ul.depth1 > li ul.depth2 li {
	float: left;
	width: 176px;
	margin-left: 8px;
}
#sub15 .cnt1 .bh_pu .txt ul.depth1 > li ul.depth2 li:first-child {
	margin-left: 0;
}
#sub15 .cnt1 .bh_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap {
	position: relative;
	width: 100%;
	height: 50px;
}
#sub15 .cnt1 .bh_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap input {
	width: 100%;
	height: 100%;
	border: 0;
	font-size: 15px;
	color: #000 !important;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #aaa;
	font-size: 12px;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	padding-left: 20px;
}
#sub15 .cnt1 .bh_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap input::placeholder {
	color: #000;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .bh_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap .cal_btn {
	position: absolute;
	top: 50%;
	right: 12px;
	margin-top: -6px;
}
#sub15 .cnt1 .bh_pu .txt ul.depth1 > li > strong {
	display: none;
	font-size: 10px;
	color: #ff0000;
	font-weight: normal;
}
#sub15 .cnt1 .bh_pu .txt ul.depth1 > li.txtarea_li .form_cont {
	position: relative;
	width: 100%;
	height: 100px;
	overflow: hidden;
}
#sub15 .cnt1 .bh_pu .txt ul.depth1 > li.txtarea_li .form_cont textarea {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	resize: none;
	padding: 18px 20px;
	color: #222;
	font-size: 13px;
}
#sub15 .cnt1 .bh_pu .txt ul.depth1 > li.txtarea_li .form_cont textarea:focus {
	outline: none;
	color: #222;
	border: 1px solid #5774db;
}
#sub15 .cnt1 .bh_pu .txt ul.depth1 > li.txtarea_li .form_cont label {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	position: absolute;
	top: 24px;
	left: 20px;
	cursor: text;
	font-size: 13px;
	line-height: 15px;
	color: #999;
	word-break: keep-all;
}
#sub15 .cnt1 .bh_pu .save_btn2 {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 60px;
}
#sub15 .cnt1 .bh_pu .save_btn2.on {
	background: #5774db;
}
#sub15 .cnt1 .bh2_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 498px;
	height: 80%;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 11;
	padding-bottom: 36px;
	padding-top: 60px;
	max-height: 758px;
}
#sub15 .cnt1 .bh2_pu .pu_wrap {
	height: 100%;
	overflow: auto;
}
#sub15 .cnt1 .bh2_pu .tit {
	width: 400px;
	margin: 0 auto 20px;
}
#sub15 .cnt1 .bh2_pu .tit h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub15 .cnt1 .bh2_pu .tit .btn_wrap {
	float: right;
}
#sub15 .cnt1 .bh2_pu .tit .btn_wrap button {
	float: left;
}
#sub15 .cnt1 .bh2_pu .tit .btn_wrap .rep_btn {
	width: 142px;
	height: 30px;
	background: #f2f2f2;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
}
#sub15 .cnt1 .bh2_pu .tit .btn_wrap .rep_btn > * {
	float: left;
}
#sub15 .cnt1 .bh2_pu .tit .btn_wrap .rep_btn .icon {
	width: 12px;
	height: 12px;
	background-image: url('../images/one_star_off.png');
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
	margin-top: 8px;
	margin-left: 14px;
	margin-right: 6px;
}
#sub15 .cnt1 .bh2_pu .tit .btn_wrap .rep_btn p {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #999;
	font-size: 12px;
	line-height: 30px;
}
#sub15 .cnt1 .bh2_pu .tit .btn_wrap .rep_btn.on {
	background: #5774db;
}
#sub15 .cnt1 .bh2_pu .tit .btn_wrap .rep_btn.on .icon {
	background-image: url('../images/one_star_on.png');
}
#sub15 .cnt1 .bh2_pu .tit .btn_wrap .rep_btn.on p {
	color: #fff;
}
#sub15 .cnt1 .bh2_pu .tit .btn_wrap .delete_btn {
	float: right;
	width: 69px;
	height: 30px;
	line-height: 30px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	border: 1px solid #ddd;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 13px;
	color: #999;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	margin-left: 8px;
}
#sub15 .cnt1 .bh2_pu .tit .btn_wrap .delete_btn img {
	width: 12px;
	height: auto;
	margin-top: 8px;
	margin-right: 6px;
}
#sub15 .cnt1 .bh2_pu .txt {
	width: 400px;
	margin: 0 auto;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li {
	position: relative;
	margin-top: 24px;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li:first-child {
	margin-top: 0;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	line-height: 17px;
	padding-bottom: 9px;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li .input_box {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
	border: 1px solid #eee;
	background: #fff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #000;
	font-size: 13px;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li .input_box p {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li .form_cont {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li .form_cont input {
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #000;
	font-size: 13px;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li ul.depth2 li {
	float: left;
	width: 196px;
	margin-left: 8px;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li ul.depth2 li:first-child {
	margin-left: 0;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap {
	position: relative;
	width: 100%;
	height: 50px;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap input {
	width: 100%;
	height: 100%;
	border: 0;
	font-size: 15px;
	color: #000 !important;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #aaa;
	font-size: 12px;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	padding-left: 20px;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap input::placeholder {
	color: #000;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap .cal_btn {
	position: absolute;
	top: 50%;
	right: 12px;
	margin-top: -6px;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li > strong {
	display: none;
	font-size: 10px;
	color: #ff0000;
	font-weight: normal;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li.txtarea_li .form_cont {
	position: relative;
	width: 100%;
	height: 100px;
	overflow: hidden;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li.txtarea_li .form_cont textarea {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	resize: none;
	padding: 18px 20px;
	color: #222;
	font-size: 13px;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li.txtarea_li .form_cont textarea:focus {
	outline: none;
	color: #222;
	border: 1px solid #5774db;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li.txtarea_li .form_cont label {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	position: absolute;
	top: 24px;
	left: 20px;
	cursor: text;
	font-size: 13px;
	line-height: 15px;
	color: #999;
	word-break: keep-all;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li.chk_wrap {
	margin-top: 22px;
	height: 18px;
	line-height: 18px;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li.chk_wrap > div input[type='checkbox'] {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li.chk_wrap > div input[type='checkbox'] + label {
	display: inline-block;
	position: relative;
	cursor: pointer;
	color: #222;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	padding-left: 26px;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li.chk_wrap > div input[type='checkbox'] + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
	text-align: center;
	background: url('../images/checkbox_off.jpg') no-repeat center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
}
#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li.chk_wrap > div input[type='checkbox']:checked + label:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 18px;
	height: 18px;
	text-align: center;
	background: url('../images/checkbox_on.jpg') no-repeat center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
}
#sub15 .cnt1 .bh2_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 40px;
}
#sub15 .cnt1 .bh2_pu .save_btn.on {
	background: #5774db;
}
#sub15 .cnt1 .bh2_pu_on .txt ul.depth1 > li .input_box p {
	color: #444;
}
#sub15 .cnt1 .degree_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 344px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
	padding-top: 40px;
}
#sub15 .cnt1 .degree_pu .txt {
	width: 288px;
	margin: 0 auto;
}
#sub15 .cnt1 .degree_pu .txt ul li {
	margin-top: 24px;
}
#sub15 .cnt1 .degree_pu .txt ul li:first-child {
	margin-top: 0;
}
#sub15 .cnt1 .degree_pu .txt ul li div input[type='radio'] {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
#sub15 .cnt1 .degree_pu .txt ul li div input[type='radio'] + label {
	display: inline-block;
	position: relative;
	cursor: pointer;
	font-size: 14px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	padding-left: 30px;
}
#sub15 .cnt1 .degree_pu .txt ul li div input[type='radio'] + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
	text-align: center;
	border: 1px solid #5774db;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
	border-radius: 9px;
}
#sub15 .cnt1 .degree_pu .txt ul li div input[type='radio']:checked + label:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 4px;
	width: 10px;
	height: 10px;
	margin-top: -5px;
	text-align: center;
	background: #5774db;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
	border-radius: 9px;
}
#sub15 .cnt1 .degree_pu .ok_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 32px;
}
#sub15 .cnt1 .degree_pu .ok_btn.on {
	background: #5774db;
}
#sub15 .cnt1 .skill_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 11;
	padding-bottom: 36px;
}
#sub15 .cnt1 .skill_pu .tit {
	width: 400px;
	margin: 0 auto;
	padding-top: 36px;
}
#sub15 .cnt1 .skill_pu .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub15 .cnt1 .skill_pu .form_cont {
	position: relative;
	width: 400px;
	height: 48px;
	line-height: 48px;
	margin: 20px auto 0;
	border-bottom: 1px solid #eee;
	overflow: hidden;
}
#sub15 .cnt1 .skill_pu .form_cont input {
	width: 100%;
	height: 100%;
	font-size: 14px;
	background: #fff;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	border: 0;
}
#sub15 .cnt1 .skill_pu .form_cont input:focus {
	outline: 0;
	color: #222;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .skill_pu .form_cont label {
	position: absolute;
	top: 50%;
	left: 0;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
}
#sub15 .cnt1 .skill_pu .form_cont .delete_btn {
	position: absolute;
	top: 50%;
	right: 0;
	margin-top: -10px;
	width: 20px;
	height: 20px;
}
#sub15 .cnt1 .skill_pu .form_cont .delete_btn img {
	width: 100%;
	height: auto;
}
#sub15 .cnt1 .skill_pu .des_box {
	width: 400px;
	background: #f2f2f2;
	margin: 0 auto;
	padding: 12px 0;
}
#sub15 .cnt1 .skill_pu .des_box ul li {
	margin-top: 7px;
}
#sub15 .cnt1 .skill_pu .des_box ul li:first-child {
	margin-top: 0;
}
#sub15 .cnt1 .skill_pu .des_box ul li > * {
	float: left;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #999;
	font-size: 11px;
}
#sub15 .cnt1 .skill_pu .des_box ul li span {
	padding-left: 20px;
	padding-right: 5px;
}
#sub15 .cnt1 .skill_pu .des_box ul li p {
	width: calc(100% - 55px);
	line-height: 17px;
}
#sub15 .cnt1 .skill_pu .skill_choice {
	width: 400px;
	margin: 20px auto 0;
}
#sub15 .cnt1 .skill_pu .skill_choice h4 {
	font-size: 13px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .skill_pu .skill_choice > p {
	color: #999;
	padding-top: 10px;
}
#sub15 .cnt1 .skill_pu .skill_choice ul li {
	float: left;
	background: #5774db;
	height: 32px;
	line-height: 32px;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	border-radius: 16px;
	padding: 0 12px;
	margin-top: 8px;
	margin-right: 4px;
}
#sub15 .cnt1 .skill_pu .skill_choice ul li.off {
	display: none;
}
#sub15 .cnt1 .skill_pu .skill_choice ul li.on {
	display: block;
}
#sub15 .cnt1 .skill_pu .skill_choice ul li > * {
	float: left;
}
#sub15 .cnt1 .skill_pu .skill_choice ul li p {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #fff;
	margin-right: 5px;
}
#sub15 .cnt1 .skill_pu .skill_choice ul li .delete_btn {
	position: relative;
	width: 20px;
	height: 20px;
	overflow: hidden;
	margin-top: 5px;
}
#sub15 .cnt1 .skill_pu .skill_choice ul li .delete_btn img {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 26px;
	height: auto;
	margin-top: -13px;
	margin-left: -13px;
}
#sub15 .cnt1 .skill_pu .skill_list {
	display: none;
	width: 400px;
	margin: 16px auto 0;
	border-top: 1px solid #eee;
	height: 160px;
	overflow: auto;
}
#sub15 .cnt1 .skill_pu .skill_list ul {
	padding-top: 10px;
}
#sub15 .cnt1 .skill_pu .skill_list ul li {
	width: 100%;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
}
#sub15 .cnt1 .skill_pu .skill_list ul li.on {
	background: #f0f4ff;
}
#sub15 .cnt1 .skill_pu .skill_list ul li p {
	float: left;
	font-size: 14px;
	color: #999;
}
#sub15 .cnt1 .skill_pu .skill_list ul li p span {
	color: #5774db;
}
#sub15 .cnt1 .skill_pu .skill_list ul li .add_btn {
	float: right;
	width: 20px;
	height: 20px;
	margin-top: 9px;
}
#sub15 .cnt1 .skill_pu .skill_list ul li .add_btn img {
	width: 100%;
	height: auto;
}
#sub15 .cnt1 .skill_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 64px;
}
#sub15 .cnt1 .skill_pu .save_btn.on {
	background: #5774db;
}
#sub15 .cnt1 .awd_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	overflow: auto;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 11;
	padding-bottom: 36px;
	padding-top: 60px;
}
#sub15 .cnt1 .awd_pu .tit {
	width: 400px;
	margin: 0 auto 40px;
}
#sub15 .cnt1 .awd_pu .tit h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}

#sub15 .cnt1 .awd_pu .delete_btn {
	position: absolute;
	top: 60px;
	right: 40px;
	width: 69px;
	height: 30px;
	line-height: 30px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	border: 1px solid #ddd;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 13px;
	color: #999;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	margin-left: 8px;
}
#sub15 .cnt1 .awd_pu .delete_btn img {
	width: 12px;
	height: auto;
	margin-top: 8px;
	margin-right: 6px;
}

#sub15 .cnt1 .awd_pu .txt {
	width: 400px;
	margin: 0 auto;
}
#sub15 .cnt1 .awd_pu .txt ul.depth1 > li {
	position: relative;
	margin-top: 24px;
}
#sub15 .cnt1 .awd_pu .txt ul.depth1 > li:first-child {
	margin-top: 0;
}
#sub15 .cnt1 .awd_pu .txt ul.depth1 > li h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	line-height: 17px;
	padding-bottom: 9px;
}
#sub15 .cnt1 .awd_pu .txt ul.depth1 > li .form_cont {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
#sub15 .cnt1 .awd_pu .txt ul.depth1 > li .form_cont input {
	width: 100%;
	height: 100%;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #000;
	font-size: 13px;
} /* border 삭제 */
#sub15 .cnt1 .awd_pu .txt ul.depth1 > li .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub15 .cnt1 .awd_pu .txt ul.depth1 > li .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub15 .cnt1 .awd_pu .txt ul.depth1 > li .cal_wrap {
	position: relative;
	width: 152px;
	height: 50px;
}
#sub15 .cnt1 .awd_pu .txt ul.depth1 > li .cal_wrap input {
	width: 100%;
	height: 100%;
	border: 0;
	font-size: 15px;
	color: #000 !important;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #aaa;
	font-size: 12px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	padding-left: 20px;
} /* border 삭제 */
#sub15 .cnt1 .awd_pu .txt ul.depth1 > li .cal_wrap input::placeholder {
	color: #000;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .awd_pu .txt ul.depth1 > li .cal_wrap .cal_btn {
	position: absolute;
	top: 50%;
	right: 12px;
	margin-top: -6px;
}
#sub15 .cnt1 .awd_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 40px;
}
#sub15 .cnt1 .awd_pu .save_btn.on {
	background: #5774db;
}
#sub15 .cnt1 .tsis_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	height: 80%;
	max-height: 830px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 11;
	padding-bottom: 36px;
	padding-top: 60px;
}
#sub15 .cnt1 .tsis_pu .pu_wrap {
	height: 100%;
	overflow: auto;
}
#sub15 .cnt1 .tsis_pu .tit {
	width: 400px;
	margin: 0 auto 40px;
}
#sub15 .cnt1 .tsis_pu .tit h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub15 .cnt1 .tsis_pu .delete_btn {
	position: absolute;
	top: 60px;
	right: 40px;
	width: 69px;
	height: 30px;
	line-height: 30px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	border: 1px solid #ddd;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 13px;
	color: #999;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	margin-left: 8px;
}
#sub15 .cnt1 .tsis_pu .delete_btn img {
	width: 12px;
	height: auto;
	margin-top: 8px;
	margin-right: 6px;
}
#sub15 .cnt1 .tsis_pu .txt {
	width: 400px;
	margin: 0 auto;
}
#sub15 .cnt1 .tsis_pu .txt ul.depth1 > li {
	position: relative;
	margin-top: 24px;
}
#sub15 .cnt1 .tsis_pu .txt ul.depth1 > li:first-child {
	margin-top: 0;
}
#sub15 .cnt1 .tsis_pu .txt ul.depth1 > li h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	line-height: 17px;
	padding-bottom: 9px;
}
#sub15 .cnt1 .tsis_pu .txt ul.depth1 > li .form_cont {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
#sub15 .cnt1 .tsis_pu .txt ul.depth1 > li .form_cont input {
	width: 100%;
	height: 100%;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #000;
	font-size: 13px;
} /* border 삭제 */
#sub15 .cnt1 .tsis_pu .txt ul.depth1 > li .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub15 .cnt1 .tsis_pu .txt ul.depth1 > li .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub15 .cnt1 .tsis_pu .txt ul.depth1 > li .form_cont textarea {
	width: 100%;
	height: 50px;
	border: 1px solid #eee;
	background: #fff;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #000;
	font-size: 13px;
	resize: none;
	padding-left: 20px;
}
#sub15 .cnt1 .tsis_pu .txt ul.depth1 > li .form_cont textarea:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub15 .cnt1 .tsis_pu .txt ul.depth1 > li .cal_wrap {
	position: relative;
	width: 152px;
	height: 50px;
}
#sub15 .cnt1 .tsis_pu .txt ul.depth1 > li .cal_wrap input {
	width: 100%;
	height: 100%;
	border: 0;
	font-size: 15px;
	color: #000 !important;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #aaa;
	font-size: 12px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	padding-left: 20px;
} /* border 삭제 */
#sub15 .cnt1 .tsis_pu .txt ul.depth1 > li .cal_wrap input::placeholder {
	color: #000;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .tsis_pu .txt ul.depth1 > li .cal_wrap .cal_btn {
	position: absolute;
	top: 50%;
	right: 12px;
	margin-top: -6px;
}
#sub15 .cnt1 .tsis_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 40px;
}
#sub15 .cnt1 .tsis_pu .save_btn.on {
	background: #5774db;
}
#sub15 .cnt1 .tsis_pu_on .txt ul.depth1 > li .form_cont textarea {
	padding: 16px 20px;
}
#sub15 .cnt1 .pat_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	overflow: auto;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30pxx;
	border-radius: 30px;
	z-index: 11;
	padding-bottom: 36px;
	padding-top: 60px;
}
#sub15 .cnt1 .pat_pu .tit {
	width: 400px;
	margin: 0 auto 40px;
}
#sub15 .cnt1 .pat_pu .tit h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub15 .cnt1 .pat_pu .delete_btn {
	position: absolute;
	top: 60px;
	right: 40px;
	width: 69px;
	height: 30px;
	line-height: 30px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	border: 1px solid #ddd;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 13px;
	color: #999;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	margin-left: 8px;
}
#sub15 .cnt1 .pat_pu .delete_btn img {
	width: 12px;
	height: auto;
	margin-top: 8px;
	margin-right: 6px;
}
#sub15 .cnt1 .pat_pu .txt {
	width: 400px;
	margin: 0 auto;
}
#sub15 .cnt1 .pat_pu .txt ul.depth1 > li {
	position: relative;
	margin-top: 24px;
}
#sub15 .cnt1 .pat_pu .txt ul.depth1 > li:first-child {
	margin-top: 0;
}
#sub15 .cnt1 .pat_pu .txt ul.depth1 > li h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	line-height: 17px;
	padding-bottom: 9px;
}
#sub15 .cnt1 .pat_pu .txt ul.depth1 > li .form_cont {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
#sub15 .cnt1 .pat_pu .txt ul.depth1 > li .form_cont input {
	width: 100%;
	height: 100%;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #000;
	font-size: 13px;
} /* border 삭제 */
#sub15 .cnt1 .pat_pu .txt ul.depth1 > li .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub15 .cnt1 .pat_pu .txt ul.depth1 > li .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub15 .cnt1 .pat_pu .txt ul.depth1 > li ul.depth2 li {
	float: left;
	width: 196px;
	margin-left: 8px;
}
#sub15 .cnt1 .pat_pu .txt ul.depth1 > li ul.depth2 li:first-child {
	margin-left: 0;
}
#sub15 .cnt1 .pat_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap {
	position: relative;
	width: 100%;
	height: 50px;
}
#sub15 .cnt1 .pat_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap input {
	width: 100%;
	height: 100%;
	border: 0;
	font-size: 15px;
	color: #000 !important;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #aaa;
	font-size: 12px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	padding-left: 20px;
} /* border 삭제 */
#sub15 .cnt1 .pat_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap input::placeholder {
	color: #000;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .pat_pu .txt ul.depth1 > li ul.depth2 li .cal_wrap .cal_btn {
	position: absolute;
	top: 50%;
	right: 12px;
	margin-top: -6px;
}
#sub15 .cnt1 .pat_pu .txt ul.depth1 > li > strong {
	display: none;
	font-size: 10px;
	color: #ff0000;
	font-weight: normal;
}
#sub15 .cnt1 .pat_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 40px;
}
#sub15 .cnt1 .pat_pu .save_btn.on {
	background: #5774db;
}
#sub15 .cnt1 .fl_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	overflow: auto;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 11;
	padding-bottom: 36px;
	padding-top: 60px;
}
#sub15 .cnt1 .fl_pu .tit {
	width: 400px;
	margin: 0 auto 40px;
}
#sub15 .cnt1 .fl_pu .tit h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub15 .cnt1 .fl_pu .delete_btn {
	position: absolute;
	top: 60px;
	right: 40px;
	width: 69px;
	height: 30px;
	line-height: 30px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	border: 1px solid #ddd;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 13px;
	color: #999;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	margin-left: 8px;
}
#sub15 .cnt1 .fl_pu .delete_btn img {
	width: 12px;
	height: auto;
	margin-top: 8px;
	margin-right: 6px;
}
#sub15 .cnt1 .fl_pu .txt {
	width: 400px;
	margin: 0 auto;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li {
	position: relative;
	margin-top: 24px;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li:first-child {
	margin-top: 0;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li:last-child {
	margin-top: 42px;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	line-height: 17px;
	padding-bottom: 9px;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li .form_cont {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li .form_cont input {
	width: 100%;
	height: 100%;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #000;
	font-size: 13px;
} /* border 삭제 */
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li .input_box {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
	border: 1px solid #eee;
	background: #fff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #000;
	font-size: 13px;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li .input_box p {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li .add_btn {
	position: absolute;
	top: 0;
	right: 0;
	width: 16px;
	height: 16px;
	background: #5774db;
	-webkit-border-radius: 11px;
	-moz-border-radius: 11px;
	border-radius: 11px;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li .add_btn img {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 10px;
	height: auto;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li .info_box {
	width: 100%;
	margin: 0 auto;
	border: 1px solid #eee;
	background: #fff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li .info_box ul {
	width: calc(100% - 40px);
	margin: 0 auto;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li .info_box ul li {
	position: relative;
	padding: 18px 0;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li .info_box ul li .ib_top > * {
	float: left;
	color: #444;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li .info_box ul li .ib_top h6 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li .info_box ul li .ib_top p {
	font-family: SC4, SC5, 'Noto Sans KR', sans-serif;
	padding-left: 5px;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li .info_box ul li .ib_btm {
	margin-top: 4px;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li .info_box ul li .ib_btm > * {
	float: left;
	color: #444;
	padding-left: 5px;
	font-family: SC4, SC5, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li .info_box ul li .ib_btm p:first-child {
	padding-left: 0;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li .info_box ul li .edit_btn {
	position: absolute;
	top: 18px;
	right: 0;
	width: 16px;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li .info_box ul li .edit_btn img {
	width: 100%;
	height: auto;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li ul.depth2 {
	padding-top: 16px;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li ul.depth2 li {
	float: left;
	height: 18px;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li ul.depth2 li {
	margin-left: 16px;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li ul.depth2 li:first-child {
	margin-left: 0;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li ul.depth2 li div {
	height: 100%;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li ul.depth2 li div input[type='radio'] {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li ul.depth2 li div input[type='radio'] + label {
	display: inline-block;
	position: relative;
	cursor: pointer;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	padding-left: 26px;
	color: #666;
	line-height: 18px;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li ul.depth2 li div input[type='radio'] + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
	text-align: center;
	border: 1px solid #ddd;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
	border-radius: 9px;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li ul.depth2 li div input[type='radio']:checked + label:before {
	border: 1px solid #5774db;
}
#sub15 .cnt1 .fl_pu .txt ul.depth1 > li ul.depth2 li div input[type='radio']:checked + label:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 4px;
	width: 10px;
	height: 10px;
	margin-top: -5px;
	text-align: center;
	background: #5774db;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}
#sub15 .cnt1 .fl_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 40px;
}
#sub15 .cnt1 .fl_pu .save_btn.on {
	background: #5774db;
}
#sub15 .cnt1 .fl2_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	overflow: auto;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
	padding-top: 60px;
}
#sub15 .cnt1 .fl2_pu .tit {
	width: 400px;
	margin: 0 auto 40px;
}
#sub15 .cnt1 .fl2_pu .tit h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub15 .cnt1 .fl2_pu .txt {
	width: 400px;
	margin: 0 auto;
}
#sub15 .cnt1 .fl2_pu .txt ul.depth1 > li {
	position: relative;
	margin-top: 24px;
}
#sub15 .cnt1 .fl2_pu .txt ul.depth1 > li:first-child {
	margin-top: 0;
}
#sub15 .cnt1 .fl2_pu .txt ul.depth1 > li h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	line-height: 17px;
	padding-bottom: 9px;
}
#sub15 .cnt1 .fl2_pu .txt ul.depth1 > li .form_cont {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
#sub15 .cnt1 .fl2_pu .txt ul.depth1 > li .form_cont input {
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #000;
	font-size: 13px;
}
#sub15 .cnt1 .fl2_pu .txt ul.depth1 > li .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub15 .cnt1 .fl2_pu .txt ul.depth1 > li .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub15 .cnt1 .fl2_pu .txt ul.depth1 > li ul.depth2 {
	padding-top: 16px;
}
#sub15 .cnt1 .fl2_pu .txt ul.depth1 > li ul.depth2 li {
	float: left;
	height: 18px;
	margin-left: 16px;
}
#sub15 .cnt1 .fl2_pu .txt ul.depth1 > li ul.depth2 li:first-child {
	margin-left: 0;
}
#sub15 .cnt1 .fl2_pu .txt ul.depth1 > li ul.depth2 li div {
	height: 100%;
}
#sub15 .cnt1 .fl2_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 40px;
}
#sub15 .cnt1 .fl2_pu .save_btn.on {
	background: #5774db;
}
#sub15 .cnt1 .link_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	overflow: auto;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 11;
	padding-bottom: 36px;
	padding-top: 60px;
}
#sub15 .cnt1 .link_pu .tit {
	width: 400px;
	margin: 0 auto 40px;
}
#sub15 .cnt1 .link_pu .tit h4 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub15 .cnt1 .link_pu .delete_btn {
	position: absolute;
	top: 60px;
	right: 40px;
	width: 69px;
	height: 30px;
	line-height: 30px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	border: 1px solid #ddd;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 13px;
	color: #999;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	margin-left: 8px;
}
#sub15 .cnt1 .link_pu .delete_btn img {
	width: 12px;
	height: auto;
	margin-top: 8px;
	margin-right: 6px;
}
#sub15 .cnt1 .link_pu .txt h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	line-height: 17px;
	padding-bottom: 9px;
}
#sub15 .cnt1 .link_pu .txt {
	width: 400px;
	margin: 0 auto;
}
#sub15 .cnt1 .link_pu .txt .form_cont {
	position: relative;
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
#sub15 .cnt1 .link_pu .txt .form_cont input {
	width: 100%;
	height: 100%;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #000;
	font-size: 13px;
} /* border 삭제 */
#sub15 .cnt1 .link_pu .txt .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub15 .cnt1 .link_pu .txt .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
}
#sub15 .cnt1 .link_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 40px;
}
#sub15 .cnt1 .link_pu .save_btn.on {
	background: #5774db;
}
#sub15 .cnt1 .area_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
	max-height: 244px;
}
#sub15 .cnt1 .area_pu .tit {
	width: 400px;
	margin: 0 auto;
	padding-top: 36px;
}
#sub15 .cnt1 .area_pu .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub15 .cnt1 .area_pu .form_cont {
	position: relative;
	width: 400px;
	height: 48px;
	line-height: 48px;
	margin: 20px auto 0;
	border-bottom: 1px solid #eee;
	overflow: hidden;
}
#sub15 .cnt1 .area_pu .form_cont input {
	width: calc(100% - 30px);
	height: 100%;
	font-size: 14px;
	background: #fff;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	border: 0;
}
#sub15 .cnt1 .area_pu .form_cont input:focus {
	outline: 0;
	color: #222;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .area_pu .form_cont label {
	position: absolute;
	top: 50%;
	left: 0;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
}
#sub15 .cnt1 .area_pu .form_cont .delete_btn {
	position: absolute;
	top: 50%;
	right: 0;
	margin-top: -10px;
	width: 20px;
	height: 20px;
}
#sub15 .cnt1 .area_pu .form_cont .delete_btn img {
	width: 100%;
	height: auto;
}
#sub15 .cnt1 .area_pu .area_list {
	display: none;
	width: 400px;
	margin: 24px auto 0;
}
#sub15 .cnt1 .area_pu .area_list ul li {
	width: 100%;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
}
#sub15 .cnt1 .area_pu .area_list ul li.on {
	background: #f0f4ff;
}
#sub15 .cnt1 .area_pu .area_list ul li p {
	font-size: 14px;
	color: #999;
	padding-left: 24px;
}
#sub15 .cnt1 .area_pu .area_list ul li p span {
	color: #5774db;
}
#sub15 .cnt1 .area_pu .save_btn2 {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 64px;
}
#sub15 .cnt1 .area_pu .save_btn2.on {
	background: #5774db;
}
#sub15 .cnt1 .edu2_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
}
#sub15 .cnt1 .edu2_pu .tit {
	width: 400px;
	margin: 0 auto;
	padding-top: 36px;
}
#sub15 .cnt1 .edu2_pu .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub15 .cnt1 .edu2_pu .form_cont {
	position: relative;
	width: 400px;
	height: 48px;
	line-height: 48px;
	margin: 20px auto 0;
	border-bottom: 1px solid #eee;
	overflow: hidden;
}
#sub15 .cnt1 .edu2_pu .form_cont input {
	width: calc(100% - 30px);
	height: 100%;
	font-size: 14px;
	background: #fff;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	border: 0;
}
#sub15 .cnt1 .edu2_pu .form_cont input:focus {
	outline: 0;
	color: #222;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .edu2_pu .form_cont label {
	position: absolute;
	top: 50%;
	left: 0;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
}
#sub15 .cnt1 .edu2_pu .form_cont .delete_btn {
	position: absolute;
	top: 50%;
	right: 0;
	margin-top: -10px;
	width: 20px;
	height: 20px;
}
#sub15 .cnt1 .edu2_pu .form_cont .delete_btn img {
	width: 100%;
	height: auto;
}
#sub15 .cnt1 .edu2_pu .drp {
	height: 40px;
	line-height: 40px;
}
#sub15 .cnt1 .edu2_pu .drp p {
	font-size: 13px;
	padding-left: 0;
	width: 400px;
	margin: 0 auto;
}
#sub15 .cnt1 .edu2_pu .edu2_list {
	display: none;
	width: 400px;
	margin: 24px auto 0;
}
#sub15 .cnt1 .edu2_pu .edu2_list ul li {
	width: 100%;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
}
#sub15 .cnt1 .edu2_pu .edu2_list ul li.on {
	background: #f0f4ff;
}
#sub15 .cnt1 .edu2_pu .edu2_list ul li p {
	font-size: 14px;
	color: #999;
	padding-left: 24px;
}
#sub15 .cnt1 .edu2_pu .edu2_list ul li p span {
	color: #5774db;
}
#sub15 .cnt1 .edu2_pu .save_btn2 {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 64px;
}
#sub15 .cnt1 .edu2_pu .save_btn2.on {
	background: #5774db;
}

#ui-datepicker-div {
	width: 300px;
	height: 320px;
	border: 0;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
	overflow: hidden;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}
#ui-datepicker-div .ui-datepicker-header .ui-datepicker-prev,
#ui-datepicker-div .ui-datepicker-header .ui-datepicker-next {
	top: 15px;
}
#ui-datepicker-div .ui-datepicker-header {
	border: 0;
	background: none;
	height: 50px;
}
#ui-datepicker-div .ui-datepicker-header .ui-datepicker-title {
	line-height: 50px;
	font-size: 16px;
}
#ui-datepicker-div .ui-icon-circle-triangle-w {
	width: 20px;
	height: 20px;
	background: url('../images/cal_prev.png') no-repeat center;
	-webkit-background-size: 20px auto;
	-moz-background-size: 20px auto;
	background-size: 20px auto;
}
#ui-datepicker-div .ui-icon-circle-triangle-e {
	width: 20px;
	height: 20px;
	background: url('../images/cal_next.png') no-repeat center;
	-webkit-background-size: 20px auto;
	-moz-background-size: 20px auto;
	background-size: 20px auto;
}
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
	background: #fff !important;
	text-align: center;
	border: 0 !important;
	height: 30px;
	line-height: 30px;
	font-size: 14px;
}
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
	background: #eee !important;
	color: #666 !important;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	border-radius: 50px;
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
	background: #5774db !important;
	color: #fff !important;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	border-radius: 50px;
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
}
.flatpickr-calendar {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}

#sub15 .cnt1 .car0_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 11;
	padding-bottom: 36px;
}
#sub15 .cnt1 .car0_pu .tit {
	width: 400px;
	margin: 0 auto;
	padding: 36px 0 10px;
}
#sub15 .cnt1 .car0_pu .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub15 .cnt1 .car0_pu > p {
	font-size: 13px;
	width: 400px;
	margin: 0 auto;
	padding-bottom: 9px;
}
#sub15 .cnt1 .car0_pu .wrap {
	width: 400px;
	margin: 30px auto 0;
}
#sub15 .cnt1 .car0_pu .wrap ul li {
	margin-top: 24px;
}
#sub15 .cnt1 .car0_pu .wrap ul li:first-child {
	margin-top: 0;
}
#sub15 .cnt1 .car0_pu .wrap ul li h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	color: #222;
	padding-bottom: 9px;
}
#sub15 .cnt1 .car0_pu .wrap ul li .input_box {
	position: relative;
	width: 400px;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
	border: 1px solid #eee;
	background: #fff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #000;
}
#sub15 .cnt1 .car0_pu .wrap ul li .input_box p {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .car0_pu .wrap ul li .form_cont {
	position: relative;
	width: 400px;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
#sub15 .cnt1 .car0_pu .wrap ul li .form_cont input {
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #222;
}
#sub15 .cnt1 .car0_pu .wrap ul li .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub15 .cnt1 .car0_pu .wrap ul li .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .car0_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 40px;
}
#sub15 .cnt1 .car0_pu .save_btn.on {
	background: #5774db;
}
#sub15 .cnt1 .car1_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
	max-height: 284px;
}
#sub15 .cnt1 .car1_pu .tit {
	width: 400px;
	margin: 0 auto;
	padding-top: 36px;
}
#sub15 .cnt1 .car1_pu .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub15 .cnt1 .car1_pu .form_cont {
	position: relative;
	width: 400px;
	height: 48px;
	line-height: 48px;
	margin: 20px auto 0;
	border-bottom: 1px solid #eee;
	overflow: hidden;
}
#sub15 .cnt1 .car1_pu .form_cont input {
	width: calc(100% - 30px);
	height: 100%;
	font-size: 14px;
	background: #fff;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	border: 0;
}
#sub15 .cnt1 .car1_pu .form_cont input:focus {
	outline: 0;
	color: #222;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .car1_pu .form_cont label {
	position: absolute;
	top: 50%;
	left: 0;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
}
#sub15 .cnt1 .car1_pu .form_cont .delete_btn {
	position: absolute;
	top: 50%;
	right: 0;
	margin-top: -10px;
	width: 20px;
	height: 20px;
}
#sub15 .cnt1 .car1_pu .form_cont .delete_btn img {
	width: 100%;
	height: auto;
}
#sub15 .cnt1 .car1_pu .drp {
	height: 40px;
	line-height: 40px;
}
#sub15 .cnt1 .car1_pu .drp p {
	font-size: 13px;
	width: 400px;
	margin: 0 auto;
}
#sub15 .cnt1 .car1_pu .car1_list {
	display: none;
	width: 400px;
	height: 160px;
	overflow: auto;
	margin: 24px auto 0;
}
#sub15 .cnt1 .car1_pu .car1_list ul li {
	width: 100%;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
}
#sub15 .cnt1 .car1_pu .car1_list ul li.on {
	background: #f0f4ff;
}
#sub15 .cnt1 .car1_pu .car1_list ul li p {
	font-size: 14px;
	color: #999;
	padding-left: 24px;
}
#sub15 .cnt1 .car1_pu .car1_list ul li p span {
	color: #5774db;
}
#sub15 .cnt1 .car1_pu .save_btn2 {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 64px;
}
#sub15 .cnt1 .car1_pu .save_btn2.on {
	background: #5774db;
}
#sub15 .cnt1 .edu0_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 11;
	padding-bottom: 36px;
}
#sub15 .cnt1 .edu0_pu .tit {
	width: 400px;
	margin: 0 auto;
	padding: 36px 0 10px;
}
#sub15 .cnt1 .edu0_pu .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub15 .cnt1 .edu0_pu > p {
	font-size: 13px;
	width: 400px;
	margin: 0 auto;
	padding-bottom: 9px;
}
#sub15 .cnt1 .edu0_pu .wrap {
	width: 400px;
	margin: 30px auto 0;
}
#sub15 .cnt1 .edu0_pu .wrap ul li {
	margin-top: 24px;
}
#sub15 .cnt1 .edu0_pu .wrap ul li:first-child {
	margin-top: 0;
}
#sub15 .cnt1 .edu0_pu .wrap ul li h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	color: #222;
	padding-bottom: 9px;
}
#sub15 .cnt1 .edu0_pu .wrap ul li .input_box {
	position: relative;
	width: 400px;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
	border: 1px solid #eee;
	background: #fff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #000;
}
#sub15 .cnt1 .edu0_pu .wrap ul li .input_box p {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .edu0_pu .wrap ul li .form_cont {
	position: relative;
	width: 400px;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
}
#sub15 .cnt1 .edu0_pu .wrap ul li .form_cont input {
	width: 100%;
	height: 100%;
	border: 1px solid #eee;
	background: #fff;
	padding-left: 20px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #222;
}
#sub15 .cnt1 .edu0_pu .wrap ul li .form_cont input:focus {
	outline: 0;
	color: #222;
	border: 1px solid #5774db;
}
#sub15 .cnt1 .edu0_pu .wrap ul li .form_cont label {
	position: absolute;
	top: 50%;
	left: 20px;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .edu0_pu .save_btn {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 40px;
}
#sub15 .cnt1 .edu0_pu .save_btn.on {
	background: #5774db;
}
#sub15 .cnt1 .edu1_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 480px;
	background: #fff;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	z-index: 13;
	padding-bottom: 36px;
	max-height: 284px;
}
#sub15 .cnt1 .edu1_pu .tit {
	width: 400px;
	margin: 0 auto;
	padding-top: 36px;
}
#sub15 .cnt1 .edu1_pu .tit h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
}
#sub15 .cnt1 .edu1_pu .form_cont {
	position: relative;
	width: 400px;
	height: 48px;
	line-height: 48px;
	margin: 20px auto 0;
	border-bottom: 1px solid #eee;
	overflow: hidden;
}
#sub15 .cnt1 .edu1_pu .form_cont input {
	width: calc(100% - 30px);
	height: 100%;
	font-size: 14px;
	background: #fff;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	border: 0;
}
#sub15 .cnt1 .edu1_pu .form_cont input:focus {
	outline: 0;
	color: #222;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub15 .cnt1 .edu1_pu .form_cont label {
	position: absolute;
	top: 50%;
	left: 0;
	color: #999;
	-webkit-transform: translate(0, -40%);
	transform: translate(0, -40%);
	cursor: text;
	line-height: 1;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
}
#sub15 .cnt1 .edu1_pu .form_cont .delete_btn {
	position: absolute;
	top: 50%;
	right: 0;
	margin-top: -10px;
	width: 20px;
	height: 20px;
}
#sub15 .cnt1 .edu1_pu .form_cont .delete_btn img {
	width: 100%;
	height: auto;
}
#sub15 .cnt1 .edu1_pu .drp {
	height: 40px;
	line-height: 40px;
}
#sub15 .cnt1 .edu1_pu .drp p {
	font-size: 13px;
	padding-left: 0;
	width: 400px;
	margin: 0 auto;
}
#sub15 .cnt1 .edu1_pu .edu1_list {
	display: none;
	width: 400px;
	margin: 24px auto 0;
}
#sub15 .cnt1 .edu1_pu .edu1_list ul li {
	width: 100%;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
}
#sub15 .cnt1 .edu1_pu .edu1_list ul li.on {
	background: #f0f4ff;
}
#sub15 .cnt1 .edu1_pu .edu1_list ul li p {
	font-size: 14px;
	color: #999;
	padding-left: 24px;
}
#sub15 .cnt1 .edu1_pu .edu1_list ul li p span {
	color: #5774db;
}
#sub15 .cnt1 .edu1_pu .save_btn2 {
	display: block;
	width: 160px;
	height: 48px;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background: #aaa;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	margin-top: 64px;
}
#sub15 .cnt1 .edu1_pu .save_btn2.on {
	background: #5774db;
}

/* 개발자도구에서 보이는 클래스명 css-dce7ih */
.ipmodi1 {
	overflow: hidden;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
}

/* 개발자도구에서 보이는 클래스명 css-igs3ac */
.ipmodi2 {
	margin-top: 5px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border-color: #eee;
}

.top_btn {
	position: fixed;
	bottom: 5%;
	right: 10%;
	width: 44px;
	height: 44px;
	z-index: 9;
}
.top_btn button img {
	width: 100%;
	height: auto;
}

#subMy .cnt1 {
	margin-top: 48px;
	padding-bottom: 24px;
}
#subMy .cnt1 .area > div {
	width: 800px;
	margin: 0 auto;
}
#subMy .cnt1 .area .profile1 .pf_img {
	position: relative;
	width: 120px;
	margin: 0 auto;
}
#subMy .cnt1 .area .profile1 .pf_img img {
	width: 100%;
	height: auto;
}
#subMy .cnt1 .area .profile1 .pf_img .profile_modi {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 32px;
	height: 32px;
}
#subMy .cnt1 .area .profile1 .pf_img .profile_modi img {
	width: 100%;
	height: auto;
}
#subMy .cnt1 .area .profile1 .pf_img .ip_file {
	width: 0;
	height: 0;
	display: none;
}
#subMy .cnt1 .area .profile1 .pf_name {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 20px;
	text-align: center;
	color: #222;
	padding-top: 8px;
}
#subMy .cnt1 .area .profile1 .pf_sum {
	text-align: center;
}
#subMy .cnt1 .area .profile1 .pf_sum p {
	display: inline-block;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	text-align: center;
	color: #222;
	padding-top: 20px;
}

#subMy .cnt1 .area .profile2 .tit h2 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 18px;
	line-height: 30px;
}
#subMy .cnt1 .area .profile2 .tit a {
	float: right;
	width: 150px;
	height: 30px;
	line-height: 30px;
	background: #5774db;
	text-align: center;
	color: #fff;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
}
#subMy .cnt1 .area .profile2 .pf_process {
	background: #f4f7ff;
	height: 120px;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	border-radius: 12px;
}
#subMy .cnt1 .area .profile2 .pf_process ul {
	width: 575px;
	margin: 20px auto 0;
	padding-top: 55px;
}
#subMy .cnt1 .area .profile2 .pf_process ul li {
	position: relative;
	float: left;
	width: 140px;
	margin-left: 5px;
}
#subMy .cnt1 .area .profile2 .pf_process ul li:first-child {
	margin-left: 0;
}
#subMy .cnt1 .area .profile2 .pf_process ul li .pcs_bar {
	display: block;
	width: 100%;
	height: 8px;
	background: #ddd;
}
#subMy .cnt1 .area .profile2 .pf_process ul li h3 {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 13px;
	color: #333;
	padding-top: 5px;
}
#subMy .cnt1 .area .profile2 .pf_process ul li p {
	font-size: 10px;
	position: absolute;
	top: -18px;
	right: -15px;
	color: #aaa;
}
#subMy .cnt1 .area .profile2 .pf_process ul li.on .pcs_bar {
	background: #5774db;
}
#subMy .cnt1 .area .profile2 .pf_date {
	margin-top: 15px;
}
#subMy .cnt1 .area .profile2 .pf_date p {
	text-align: right;
	color: #999;
	padding-top: 3px;
}
#subMy .cnt1 .area .profile2 > a {
	display: block;
	width: 360px;
	height: 40px;
	line-height: 42px;
	border: 1px solid #5774db;
	margin: 50px auto 0;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	border-radius: 25px;
	font-size: 16px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #5774db;
	text-align: center;
	overflow: hidden;
}
#subMy .cnt1 .area .profile3 .tit h2 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 18px;
	line-height: 30px;
}
#subMy .cnt1 .area .profile3 .status {
	width: 100%;
	height: 86px;
	margin: 23px auto 0;
	background: #f4f7ff;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	border-radius: 12px;
	padding-top: 34px;
}
#subMy .cnt1 .area .profile3 .status ul {
	width: 230px;
	margin: 0 auto;
}
#subMy .cnt1 .area .profile3 .status ul li {
	float: left;
}
#subMy .cnt1 .area .profile3 .status ul li.stt {
	width: 17%;
}
#subMy .cnt1 .area .profile3 .status ul li h4 {
	width: 100%;
	height: 22px;
	line-height: 22px;
	background: #5774db;
	-webkit-border-radius: 11px;
	-moz-border-radius: 11px;
	border-radius: 11px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #fff;
	text-align: center;
}
#subMy .cnt1 .area .profile3 .status ul li p {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	text-align: center;
	font-size: 22px;
	color: #222;
	padding-top: 12px;
}
#subMy .cnt1 .area .profile3 .status ul li.dash_line {
	width: 24.5%;
}
#subMy .cnt1 .area .profile3 .status ul li.dash_line span {
	display: block;
	width: 100%;
	height: 1px;
	border-top: 1px dashed #5774db;
	margin-top: 11px;
}
#subMy .cnt1 .area .profile3 > a {
	display: block;
	width: 360px;
	height: 40px;
	line-height: 42px;
	border: 1px solid #5774db;
	margin: 50px auto 0;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	border-radius: 25px;
	font-size: 16px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #5774db;
	text-align: center;
	overflow: hidden;
}
#subMy .cnt1 .area .profile4 .tit h2 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 18px;
	line-height: 30px;
}
#subMy .cnt1 .area .profile4 .like_wrap {
	position: relative;
	width: 100%;
	height: 120px;
	margin: 23px auto 0;
	background: #f4f7ff;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	border-radius: 12px;
}
#subMy .cnt1 .area .profile4 .like_wrap .box {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
#subMy .cnt1 .area .profile4 .like_wrap .box > * {
	float: left;
}
#subMy .cnt1 .area .profile4 .like_wrap .box .icon {
	width: 30px;
	height: 28px;
	background-image: url('../images/heart_on.png');
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
	margin-right: 4px;
}
#subMy .cnt1 .area .profile4 .like_wrap .box p {
	font-size: 18px;
	padding-top: 3px;
	padding-left: 10px;
}
#subMy .cnt1 .area .profile4 > a {
	display: block;
	width: 360px;
	height: 40px;
	line-height: 42px;
	border: 1px solid #5774db;
	margin: 50px auto 0;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	border-radius: 25px;
	font-size: 16px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #5774db;
	text-align: center;
	overflow: hidden;
}
#subMy .cnt1 .area .bar {
	display: block;
	width: 800px;
	height: 1px;
	background: #e8e8ea;
	margin: 47px auto;
}

#sub16 .cnt1 {
	padding-bottom: 37px;
}
#sub16 .cnt1 .area .filter {
	margin-top: 37px;
	margin-right: 24px;
}
#sub16 .cnt1 .area .filter ul {
	float: right;
}
#sub16 .cnt1 .area .filter ul li {
	float: left;
	margin-left: 15px;
}
#sub16 .cnt1 .area .filter ul li:first-child {
	margin-left: 0;
}
#sub16 .cnt1 .area .filter ul li button {
	display: block;
}
#sub16 .cnt1 .area .filter ul li button > * {
	float: left;
}
#sub16 .cnt1 .area .filter ul li button p {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #999;
	font-size: 14px;
}
#sub16 .cnt1 .area .filter ul li button .icon {
	width: 12px;
	height: 10px;
	background-image: url('../images/filter_off.png');
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
	margin-top: 3px;
	margin-left: 6px;
}
#sub16 .cnt1 .area .filter ul li.on button p {
	color: #5774db;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub16 .cnt1 .area .filter ul li.on button .icon {
	background-image: url('../images/filter_on.png');
}
#sub16 .cnt1 .area .txt {
	width: 1032px;
	margin: 21px auto 0;
}
#sub16 .cnt1 .area .txt > ul > li {
	position: relative;
	float: left;
	width: 240px;
	margin-left: 24px;
	margin-bottom: 30px;
}
#sub16 .cnt1 .area .txt > ul > li:nth-child(4n + 1) {
	margin-left: 0;
}
#sub16 .cnt1 .area .txt > ul > li .wish_btn {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 1;
	width: 20px;
	height: 18px;
	background-image: url('../images/wish.png');
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
}
#sub16 .cnt1 .area .txt > ul > li .wish_btn.on {
	background-image: url('../images/wish_on2.png');
}
#sub16 .cnt1 .area .txt > ul > li > a {
	display: block;
}
#sub16 .cnt1 .area .txt > ul > li > a .img {
	position: relative;
	width: 100%;
	height: 240px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	overflow: hidden;
}
#sub16 .cnt1 .area .txt > ul > li > a .img img {
	width: 100%;
	height: auto;
}
#sub16 .cnt1 .area .txt > ul > li > a .img .mark {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 78px;
	height: 78px;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
}
#sub16 .cnt1 .area .txt ul li a .img .mark1 {
	background-image: url('../images/main_c2_tri1.png');
}
#sub16 .cnt1 .area .txt ul li a .img .mark2 {
	background-image: url('../images/main_c2_tri2.png');
}
#sub16 .cnt1 .area .txt ul li a .img .mark3 {
	background-image: url('../images/main_c2_tri3.png');
}
#sub16 .cnt1 .area .txt ul li a .img .mark p {
	position: absolute;
	right: 10px;
	bottom: 10px;
	font-family: PTDb, PTDr, 'Noto Sans KR', sans-serif;
	color: #fff;
	font-size: 14px;
}
#sub16 .cnt1 .area .txt ul li a > h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #222;
	font-size: 16px;
	padding-top: 12px;
	padding-bottom: 8px;
}
#sub16 .cnt1 .area .txt ul li a .p_wrap p {
	color: #999;
}
#sub16 .cnt1 .area .txt ul li a .p_wrap p:first-child {
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
}
#sub16 .cnt1 .area .txt ul li a .hash {
	margin-top: 13px;
}
#sub16 .cnt1 .area .txt ul li a .hash li {
	float: left;
	background: #f1f3f9;
	height: 26px;
	line-height: 26px;
	-webkit-border-radius: 13px;
	-moz-border-radius: 13px;
	border-radius: 13px;
	padding: 0 10px;
	margin-left: 4px;
}
#sub16 .cnt1 .area .txt ul li a .hash li:first-child {
	margin-left: 0;
}
#sub16 .cnt1 .area .txt ul li a .hash li p {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 11px;
	color: #677cc4;
}
#sub16 .cnt1 .area .txt ul li a .bar {
	display: block;
	width: 100%;
	height: 1px;
	background: #eee;
	margin: 12px 0 15px;
}
#sub16 .cnt1 .area .txt ul li a .info li {
	margin-top: 9px;
}
#sub16 .cnt1 .area .txt ul li a .info li:first-child {
	margin-top: 0;
}
#sub16 .cnt1 .area .txt ul li a .info li > * {
	float: left;
}
#sub16 .cnt1 .area .txt ul li a .info li h5 {
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
	color: #222;
	width: 50px;
}
#sub16 .cnt1 .area .txt ul li a .info li p {
	color: #666;
}

#sub17 .cnt1 {
	padding-bottom: 37px;
	overflow: hidden;
}
#sub17 .cnt1 .area .filter {
	margin-top: 37px;
	margin-right: 24px;
}
#sub17 .cnt1 .area .filter ul {
	float: right;
}
#sub17 .cnt1 .area .filter ul li {
	float: left;
	margin-left: 15px;
}
#sub17 .cnt1 .area .filter ul li:first-child {
	margin-left: 0;
}
#sub17 .cnt1 .area .filter ul li button {
	display: block;
}
#sub17 .cnt1 .area .filter ul li button > * {
	float: left;
}
#sub17 .cnt1 .area .filter ul li button p {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #999;
	font-size: 14px;
}
#sub17 .cnt1 .area .filter ul li button .icon {
	width: 12px;
	height: 10px;
	background-image: url('../images/filter_off.png');
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
	margin-top: 3px;
	margin-left: 6px;
}
#sub17 .cnt1 .area .filter ul li.on button p {
	color: #5774db;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub17 .cnt1 .area .filter ul li.on button .icon {
	background-image: url('../images/filter_on.png');
}
#sub17 .cnt1 .area .txt {
	width: 1032px;
	margin: 21px auto 0;
}
#sub17 .cnt1 .area .txt > ul > li {
	position: relative;
	float: left;
	width: 240px;
	margin-left: 24px;
	margin-bottom: 30px;
}
#sub17 .cnt1 .area .txt > ul > li:nth-child(4n + 1) {
	margin-left: 0;
}
#sub17 .cnt1 .area .txt > ul > li .wish_btn {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 1;
	width: 20px;
	height: 18px;
	background-image: url('../images/wish.png');
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
}
#sub17 .cnt1 .area .txt > ul > li .wish_btn.on {
	background-image: url('../images/wish_on.png');
}
#sub17 .cnt1 .area .txt > ul > li > a {
	display: block;
}
#sub17 .cnt1 .area .txt > ul > li > a .img {
	position: relative;
	width: 100%;
	height: 240px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	overflow: hidden;
}
#sub17 .cnt1 .area .txt > ul > li > a .img img {
	width: 100%;
	height: auto;
}
#sub17 .cnt1 .area .txt > ul > li > a .img .mark {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 78px;
	height: 78px;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
}
#sub17 .cnt1 .area .txt ul li a .img .mark1 {
	background-image: url('../images/main_c2_tri1.png');
}
#sub17 .cnt1 .area .txt ul li a .img .mark2 {
	background-image: url('../images/main_c2_tri2.png');
}
#sub17 .cnt1 .area .txt ul li a .img .mark3 {
	background-image: url('../images/main_c2_tri3.png');
}
#sub17 .cnt1 .area .txt ul li a .img .mark p {
	position: absolute;
	right: 10px;
	bottom: 10px;
	font-family: PTDb, PTDr, 'Noto Sans KR', sans-serif;
	color: #fff;
	font-size: 14px;
}
#sub17 .cnt1 .area .txt ul li a > h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #222;
	font-size: 16px;
	padding-top: 12px;
	padding-bottom: 8px;
}
#sub17 .cnt1 .area .txt ul li a .p_wrap p {
	color: #999;
}
#sub17 .cnt1 .area .txt ul li a .p_wrap p:first-child {
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
}
#sub17 .cnt1 .area .txt ul li a .hash {
	margin-top: 13px;
}
#sub17 .cnt1 .area .txt ul li a .hash li {
	float: left;
	background: #f1f3f9;
	height: 26px;
	line-height: 26px;
	-webkit-border-radius: 13px;
	-moz-border-radius: 13px;
	border-radius: 13px;
	padding: 0 10px;
	margin-left: 4px;
}
#sub17 .cnt1 .area .txt ul li a .hash li:first-child {
	margin-left: 0;
}
#sub17 .cnt1 .area .txt ul li a .hash li p {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 11px;
	color: #677cc4;
}

#sub18 .cnt1 {
	padding-bottom: 37px;
	overflow: hidden;
}
#sub18 .cnt1 .area .filter {
	margin-top: 37px;
	margin-right: 24px;
}
#sub18 .cnt1 .area .filter ul {
	float: right;
}
#sub18 .cnt1 .area .filter ul li {
	float: left;
	margin-left: 15px;
}
#sub18 .cnt1 .area .filter ul li:first-child {
	margin-left: 0;
}
#sub18 .cnt1 .area .filter ul li button {
	display: block;
}
#sub18 .cnt1 .area .filter ul li button > * {
	float: left;
}
#sub18 .cnt1 .area .filter ul li button p {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #999;
	font-size: 14px;
}
#sub18 .cnt1 .area .filter ul li button .icon {
	width: 12px;
	height: 10px;
	background-image: url('../images/filter_off.png');
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: 100% auto;
	-moz-background-size: 100% auto;
	background-size: 100% auto;
	margin-top: 3px;
	margin-left: 6px;
}
#sub18 .cnt1 .area .filter ul li.on button p {
	color: #5774db;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub18 .cnt1 .area .filter ul li.on button .icon {
	background-image: url('../images/filter_on.png');
}
#sub18 .cnt1 .area .txt {
	width: 1032px;
	margin: 21px auto 0;
}
#sub18 .cnt1 .area .txt > ul > li {
	float: left;
	width: 240px;
	margin-left: 24px;
	margin-bottom: 30px;
}
#sub18 .cnt1 .area .txt > ul > li:nth-child(4n + 1) {
	margin-left: 0;
}
#sub18 .cnt1 .area .txt > ul > li > a {
	display: block;
}
#sub18 .cnt1 .area .txt > ul > li > a .img {
	position: relative;
	width: 100%;
	height: 240px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	overflow: hidden;
}
#sub18 .cnt1 .area .txt > ul > li > a .img img {
	width: 100%;
	height: auto;
}
#sub18 .cnt1 .area .txt > ul > li > a .img .mark {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 78px;
	height: 78px;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
}
#sub18 .cnt1 .area .txt ul li a .img .mark1 {
	background-image: url('../images/main_c2_tri1.png');
}
#sub18 .cnt1 .area .txt ul li a .img .mark2 {
	background-image: url('../images/main_c2_tri2.png');
}
#sub18 .cnt1 .area .txt ul li a .img .mark3 {
	background-image: url('../images/main_c2_tri3.png');
}
#sub18 .cnt1 .area .txt ul li a .img .mark p {
	position: absolute;
	right: 10px;
	bottom: 10px;
	font-family: PTDb, PTDr, 'Noto Sans KR', sans-serif;
	color: #fff;
	font-size: 14px;
}
#sub18 .cnt1 .area .txt ul li a > h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #222;
	font-size: 16px;
	padding-top: 12px;
	padding-bottom: 8px;
}
#sub18 .cnt1 .area .txt ul li a .p_wrap p {
	color: #999;
}
#sub18 .cnt1 .area .txt ul li a .p_wrap p:first-child {
	padding-left: 0;
	margin-left: 0;
	background: none;
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
}
#sub18 .cnt1 .area .txt ul li a .hash {
	margin-top: 13px;
}
#sub18 .cnt1 .area .txt ul li a .hash li {
	float: left;
	background: #f1f3f9;
	height: 26px;
	line-height: 26px;
	-webkit-border-radius: 13px;
	-moz-border-radius: 13px;
	border-radius: 13px;
	padding: 0 10px;
	margin-left: 4px;
}
#sub18 .cnt1 .area .txt ul li a .hash li:first-child {
	margin-left: 0;
}
#sub18 .cnt1 .area .txt ul li a .hash li p {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	font-size: 11px;
	color: #677cc4;
}
#sub18 .cnt1 .area .txt ul li a .bar {
	display: block;
	width: 100%;
	height: 1px;
	background: #eee;
	margin: 12px 0 15px;
}
#sub18 .cnt1 .area .txt ul li a .info li {
	margin-top: 9px;
}
#sub18 .cnt1 .area .txt ul li a .info li:first-child {
	margin-top: 0;
}
#sub18 .cnt1 .area .txt ul li a .info li > * {
	float: left;
}
#sub18 .cnt1 .area .txt ul li a .info li h5 {
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
	color: #222;
	width: 50px;
}
#sub18 .cnt1 .area .txt ul li a .info li p {
	color: #666;
}

#sub19 .cnt1 {
	margin-top: 48px;
	padding-bottom: 24px;
}
#sub19 .cnt1 .area > div {
	width: 600px;
	margin: 0 auto;
}
#sub19 .cnt1 .area .profile .pf_img {
	position: relative;
	width: 120px;
	margin: 0 auto;
}
#sub19 .cnt1 .area .profile .pf_img img {
	width: 100%;
	height: auto;
}
#sub19 .cnt1 .area .profile .pf_name {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 22px;
	text-align: center;
	color: #222;
	padding-top: 10px;
}
#sub19 .cnt1 .area .bar {
	display: block;
	width: 100%;
	height: 1px;
	background: #e8e8ea;
	margin: 47px 0;
}
#sub19 .cnt1 .area .pf_info1 > h3 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 18px;
	color: #222;
	padding-bottom: 8px;
}
#sub19 .cnt1 .area .pf_info1 .box {
	position: relative;
	width: 100%;
	background: #fff2ef;
	border: 1px dashed #f8603f;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	border-radius: 16px;
	padding: 21px 0;
	margin-bottom: 12px;
}
#sub19 .cnt1 .area .pf_info1 .box:last-child {
	margin-bottom: 0;
}
#sub19 .cnt1 .area .pf_info1 .box h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #222;
	padding-left: 20px;
}
#sub19 .cnt1 .area .pf_info1 .box p {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #444;
	padding-left: 20px;
	padding-top: 12px;
	font-size: 14px;
}
#sub19 .cnt1 .area .pf_info2 > h3 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 18px;
	color: #222;
	padding-bottom: 8px;
}
#sub19 .cnt1 .area .pf_info2 .box {
	width: 100%;
	background: #f5fffa;
	border: 1px dashed #46b87a;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	border-radius: 16px;
	padding: 21px 0;
	margin-bottom: 12px;
}
#sub19 .cnt1 .area .pf_info2 .box:last-child {
	margin-bottom: 0;
}
#sub19 .cnt1 .area .pf_info2 .box h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #222;
	padding-left: 20px;
}
#sub19 .cnt1 .area .pf_info2 .box .info_box {
	width: calc(100% - 40px);
	margin: 0 auto;
}
#sub19 .cnt1 .area .pf_info2 .box .info_box ul li {
	padding-top: 24px;
	border-top: 1px solid rgba(70, 184, 122, 0.1);
	margin: 22px auto 24px;
}
#sub19 .cnt1 .area .pf_info2 .box .info_box ul li:first-child {
	margin-top: 0;
	margin-bottom: 0;
	border-top: 0;
}
#sub19 .cnt1 .area .pf_info2 .box .info_box ul li h5 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #444;
	padding-right: 20px;
	font-size: 14px;
}
#sub19 .cnt1 .area .pf_info2 .box .info_box ul li p {
	line-height: 16px;
	color: #444;
	padding: 12px 16px 0 0;
	word-break: keep-all;
	font-size: 14px;
	line-height: 1.5;
}
#sub19 .cnt1 .area .pf_info2 .box .info_box ul li p:first-child {
	padding-top: 0;
}
#sub19 .cnt1 .area .pf_info3 > h3 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 18px;
	color: #222;
	padding-bottom: 8px;
}
#sub19 .cnt1 .area .pf_info3 .box {
	position: relative;
	width: 100%;
}
#sub19 .cnt1 .area .pf_info3 .box ul li {
	height: 40px;
	line-height: 40px;
}
#sub19 .cnt1 .area .pf_info3 .box ul li > * {
	float: left;
}
#sub19 .cnt1 .area .pf_info3 .box ul li .icon {
	width: 20px;
	height: 20px;
	margin-right: 10px;
	margin-top: 8px;
}
#sub19 .cnt1 .area .pf_info3 .box ul li .icon img {
	width: 100%;
	height: auto;
}
#sub19 .cnt1 .area .pf_info3 .box ul li h4 {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	color: #444;
	font-size: 14px;
}
#sub19 .cnt1 .area .pf_info3 .box ul li p {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #5774db;
	font-size: 18px;
	padding-left: 10px;
}
#sub19 .cnt1 .area .pf_info4 .tit {
	margin-bottom: 28px;
}
#sub19 .cnt1 .area .pf_info4 .tit h3 {
	float: left;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 24px;
	color: #222;
}
#sub19 .cnt1 .area .pf_info4 .box > div {
	float: left;
	width: 292px;
}
#sub19 .cnt1 .area .pf_info4 .box > div ul li {
	margin-top: 5px;
}
#sub19 .cnt1 .area .pf_info4 .box > div ul li:first-child {
	margin-top: 0;
}
#sub19 .cnt1 .area .pf_info4 .box > div ul li > * {
	float: left;
	color: #444;
}
#sub19 .cnt1 .area .pf_info4 .box > div ul li h4 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub19 .cnt1 .area .pf_info4 .box > div ul li p {
	padding-left: 4px;
}
#sub19 .cnt1 .area .pf_info4 .box > div .letter {
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	padding-top: 20px;
	padding-bottom: 32px;
	margin-top: 10px;
}
#sub19 .cnt1 .area .pf_info4 .box > div .letter .illu {
	display: block;
	width: 174px;
	margin: 0 auto 16px;
}
#sub19 .cnt1 .area .pf_info4 .box > div .letter .illu img {
	width: 100%;
	height: auto;
}
#sub19 .cnt1 .area .pf_info4 .box > div .letter p {
	font-family: MaBuB, 'Noto Sans KR', sans-serif;
	font-size: 11px;
	line-height: 15px;
	padding: 0 20px;
	word-break: keep-all;
	color: #444;
}
#sub19 .cnt1 .area .pf_info4 .box .box_1 {
	margin-right: 16px;
}
#sub19 .cnt1 .area .pf_info4 .box .box_1 .letter {
	background: #fff2c3;
}
#sub19 .cnt1 .area .pf_info4 .box .box_2 .letter {
	background: #ffdcd6;
}

@media all and (max-width: 1544px) {
	.cnt1 .chn_util_area {
		width: 100%;
	}
	.cnt1 .chn_util_area .chn_util {
		left: 2%;
	}
	#main .cnt1 .visual ul li .txt {
		width: 90%;
	}
	#main .cnt1 .visual ul li .txt h2 {
		font-size: 54px;
		padding-top: 15%;
	}
	#main .cnt1 .visual ul li .txt h2 span {
		padding-left: 160px;
	}
	#main .cnt1 .visual ul li .txt p {
		font-size: 22px;
	}
	#main .cnt10 .area .c10_2 .txt {
		width: 90%;
		margin: 90px auto 0;
	}
	#main2 .cnt1 .visual ul li .txt {
		width: 90%;
	}
	#main2 .cnt1 .visual ul li .txt h2 {
		font-size: 54px;
		padding-top: 15%;
	}
	#main2 .cnt1 .visual ul li .txt h2 span {
		padding-left: 160px;
	}
	#main2 .cnt1 .visual ul li .txt p {
		font-size: 22px;
	}
}

@media all and (max-width: 1400px) {
	#main .area {
		width: 100%;
	}

	#main .cnt4 .area .wrap {
		width: 96%;
		margin: 0 auto;
	}
	#main .cnt4 .area .wrap .box {
		width: 49%;
	}

	#main .cnt4 .area .wrap .box3,
	#main .cnt4 .area .wrap .box4,
	#main .cnt4 .area .wrap .box5 {
		margin-top: 2%;
	}

	#main .cnt4 .area .wrap .box4 {
		width: 49%;
	}

	#main .cnt4 .area .wrap .box5 {
		width: 100%;
		display: flex;
		align-items: center;
	}

	#main .cnt4 .area .wrap .box1 .img {
		left: auto;
		right: 10%;
		transform: translateX(0);
	}

	#main .cnt4 .area .wrap .box2 .img {
		left: auto;
		right: 10%;
		transform: translateX(0);
	}

	#main .cnt4 .area .wrap .box3 .img {
		left: auto;
		right: 10%;
		transform: translateX(0);
	}

	#main .cnt4 .area .wrap .box4 .img {
		width: 250px;
	}
	#main .cnt4 .area .wrap .box4 .img img {
		width: 100%;
		height: auto;
	}
	#main .cnt4 .area .wrap .box5 .img {
		bottom: auto;
		top: 50%;
		right: 69px;
		transform: translateY(-50%);
	}
	#main .cnt4 .area .wrap .box5 h4 {
		padding-top: 0;
		padding-left: 5%;
	}


	#main .cnt5 .area .box1 .left img {
		width: 550px;
	}
	#main .cnt5 .area .box1 .right h3 {
		font-size: 48px;
		margin-left: 50px;
	}
	#main .cnt5 .area .box2 .left {
		margin-right: 100px;
	}
	#main .cnt5 .area .box2 .left h3 {
		font-size: 48px;
	}
	#main .cnt5 .area .box2 .left p{
		font-size: 26px;
		padding-top: 32px;
	}
	#main .cnt5 .area .box2 .right img {
		width: 500px;
		height: auto;
	}



	#main .cnt6 .area .txt {
		width: 96%;
		margin: 40px auto 0;
	}

	#main .cnt6 .area .txt .box {
		width: 49%;
	}

	#main .cnt6 .area .txt .box1 .box_content {
		width: 350px;
	}
	#main .cnt6 .area .txt .box1 .box_content img {
		width: 100%;
		height: auto;
	}

	#main .cnt6 .area .txt .box3 .box_content {
		width: 80%;
	}

	#main .cnt6 .area .txt .box3 .box_content img {
		width: 100%;
		height: auto;
	}


	#main .cnt6 .area .txt .box5 .box_content {
		width: 550px;
		margin-left: 100px;
	}
	#main .cnt6 .area .txt .box5 .box_content img {
		width: 100%;
		height: auto;
	}




	#main .cnt6 .area .txt .box5 {
		width: 100%;
	}




	#main .cnt7 .area .txt {
		width: 96%;
		margin: 59px auto 0;
	}

	#main .cnt7 .area .txt .box {
		width: 24%;
		height: auto;
		padding-bottom: 80px;
	}

	#main .cnt7 .area .txt .box .box_content img{
		height: 200px;
		width: auto;
	}

	#main .cnt7 .area .txt .box h4 {
		font-size: 22px;
	}


	#main .cnt8 .area .txt {
		width: 96%;
		margin: 47px auto 0;
	}
	#main .cnt8 .area .txt .box {
		width: 49%;
		height: auto;
	}
	#main .cnt8 .area .txt .box h4 {
		font-size: 22px;
	}
	#main .cnt8 .area .txt .box .box_content {
		position: static;
		text-align: right;
	}
	#main .cnt8 .area .txt .box1 .box_content {
		padding-bottom: 12px;
		padding-right: 29px;
	}
	#main .cnt8 .area .txt .box1 .box_content img {
		height: 220px;
		width: auto;
	}
	#main .cnt8 .area .txt .box2 .box_content {
		padding-bottom: 27px;
		padding-right: 41px;
	}
	#main .cnt8 .area .txt .box2 .box_content img {
		height: 240px;
		width: auto;
	}



	#main .cnt9 .area .txt {
		width: 96%;
		margin: 47px auto 0;
	}
	#main .cnt9 .area .txt .box {
		width: 49%;
		height: auto;
	}

	#main .cnt9 .area .txt .box h4 {
		font-size: 22px;
	}
	#main .cnt9 .area .txt .box .box_content {
		position: static;
		text-align: right;
	}
	#main .cnt9 .area .txt .box1 .box_content img {
		height: 220px;
		width: auto;
	}
	#main .cnt9 .area .txt .box2 .box_content {
		padding-bottom: 27px;
		padding-right: 41px;
	}
	#main .cnt9 .area .txt .box2 .box_content img {
		height: 200px;
		width: auto;
	}




	#main2 .area {
		width: 100%;
	}
	#main2 .cnt2 .area .txt ul {
		width: 750px;
		margin: 0 auto;
	}
	#main2 .cnt2 .area .txt ul li {
		width: 50%;
		margin-bottom: 131px;
		height: auto;
	}
	#main2 .cnt2 .area .txt ul li > * {
		float: none;
	}
	#main2 .cnt2 .area .txt ul li .icon {
		margin-left: 0 !important;
		margin-top: 0 !important;
		text-align: center;
		display: block;
		height: 100px;
		margin-bottom: 15px;
	}
	#main2 .cnt2 .area .txt ul li p {
		text-align: center;
		line-height: 1.5;
		margin: 0 !important;
		padding: 0 !important;
	}
	#main2 .cnt4 .area .right {
		margin-right: 50px;
	}
	#main2 .cnt4 .area .left {
		margin-left: 50px;
	}
	#main2 .cnt6 .area .top {
		width: 90%;
		margin: 0 auto 0;
	}
	#main2 .cnt6 .area .top .left {
		float: none;
	}
	#main2 .cnt6 .area .btm {
		width: 100%;
		margin: 120px auto 0;
	}
	#main2 .cnt6 .area .btm ul {
		width: 100%;
		text-align: center;
	}
	#main2 .cnt6 .area .btm ul li {
		float: none;
		display: inline-block;
		margin-left: 50px;
	}
	#main2 .cnt7 .area .txt ul li {
		float: none;
		margin: 50px auto 0;
	}
	#main2 .cnt7 .area .txt ul li:first-child {
		margin: 0 auto;
	}
	#main2 .cnt8 .area .txt ul li {
		float: none;
		margin: 50px auto 0;
	}
	#main2 .cnt8 .area .txt ul li:first-child {
		margin: 0 auto;
	}

	#sub4 .cnt1 .visual {
		height: 400px;
	}
	#sub4 .cnt1 .visual .area .tit h2 {
		font-size: 35px;
	}
	#sub4 .cnt1 .visual .area .tit ul {
		margin-top: 20px;
	}
	#sub4 .cnt1 .visual .area .tit ul li {
		-webkit-background-size: auto 13px;
		-moz-background-size: auto 13px;
		background-size: auto 13px;
		padding-left: 15px;
		margin-left: 13px;
	}
	#sub4 .cnt1 .visual .area .tit ul li p {
		font-size: 17px;
	}
}

@media all and (max-width: 1280px) {
	.area {
		width: 96%;
	}
	#main .cnt10 {
		padding-bottom: 200px;
	}

	#main2 .cnt1 .visual ul li .txt {
		width: 90%;
	}
	#main2 .cnt4 .area .right {
		float: none;
		padding-top: 0;
		padding-left: 150px;
	}
	#main2 .cnt4 .area .left {
		float: right;
		width: 642px;
		margin-right: 64px;
		margin-top: 50px;
	}
	#main2 .cnt5 .area ul {
		width: 1043px;
	}
	#main2 .cnt5 .area ul li {
		margin-left: 30px;
	}

	#sub4 .cnt1 .visual {
		width: calc(100% - 40px);
		height: 350px;
		margin-top: 20px;
		-webkit-border-radius: px;
		-moz-border-radius: px;
		border-radius: 20px;
	}
	#sub4 .cnt1 .visual .area .tit ul {
		margin-top: 10px;
	}
	#sub4 .cnt1 .visual .area .tit ul li p {
		font-size: 15px;
	}
	#sub4 .cnt1 .visual .area .wrap .hash .p_wrap {
		margin-bottom: 10px;
	}
	#sub4 .cnt1 .visual .area .wrap .hash .p_wrap p {
		font-size: 12px;
	}
	#sub4 .cnt1 .visual .area .wrap {
		bottom: -25px;
	}
	#sub4 .cnt1 .visual .area .wrap .box {
		height: 50px;
	}
	#sub4 .cnt1 .visual .area .wrap .box .left {
		line-height: 50px;
	}
	#sub4 .cnt1 .visual .area .wrap .box .left ul li > * {
		font-size: 14px;
	}
	#sub4 .cnt1 .visual .area .wrap .box .right ul {
		margin-top: 10px;
	}
	#sub4 .cnt1 .visual .area .wrap .box .right ul li {
		width: 30px;
		height: 30px;
	}

	#sub13 .cnt1 .area {
		width: 100%;
	}
	#sub13 .cnt1 .area .filter {
		margin-right: 0;
		width: 90%;
		margin: 37px auto 0;
	}
	#sub13 .cnt1 .area .txt {
		width: 90%;
	}
	#sub13 .cnt1 .area .txt > ul > li {
		width: 23.5%;
		margin-left: 2%;
	}
	#sub13 .cnt1 .area .txt > ul > li > a .img {
		height: auto;
	}

	#sub14 .cnt1 .area {
		width: 100%;
	}
	#sub14 .cnt1 .area .status {
		width: 90%;
	}
	#sub14 .cnt1 .area .filter {
		margin-right: 0;
		width: 90%;
		margin: 37px auto 0;
	}
	#sub14 .cnt1 .area .txt {
		width: 90%;
	}
	#sub14 .cnt1 .area .txt > ul > li {
		width: 23.5%;
		margin-left: 2%;
	}
	#sub14 .cnt1 .area .txt > ul > li > a .img {
		height: auto;
	}

	#sub15 .cnt1 {
		margin-top: 60px;
	}
	#sub15 .cnt1 .area .left {
		width: 250px;
	}
	#sub15 .cnt1 .area .right {
		width: calc(100% - 250px);
	}
	#sub15 .cnt1 .area .right .right_wrap .bar,
	#sub15 .cnt1 .area .pf_info1,
	#sub15 .cnt1 .area .pf_info2,
	#sub15 .cnt1 .area .pf_info3,
	#sub15 .cnt1 .area .pf_info4,
	#sub15 .cnt1 .area .pf_info6 {
		width: 80%;
	}
	.top_btn {
		right: 5%;
	}

	#sub16 .cnt1 .area {
		width: 100%;
	}
	#sub16 .cnt1 .area .filter {
		margin-right: 0;
		width: 90%;
		margin: 37px auto 0;
	}
	#sub16 .cnt1 .area .txt {
		width: 90%;
	}
	#sub16 .cnt1 .area .txt > ul > li {
		width: 23.5%;
		margin-left: 2%;
	}
	#sub16 .cnt1 .area .txt > ul > li > a .img {
		height: auto;
	}

	#sub17 .cnt1 .area {
		width: 100%;
	}
	#sub17 .cnt1 .area .filter {
		margin-right: 0;
		width: 90%;
		margin: 37px auto 0;
	}
	#sub17 .cnt1 .area .txt {
		width: 90%;
	}
	#sub17 .cnt1 .area .txt > ul > li {
		width: 23.5%;
		margin-left: 2%;
	}
	#sub17 .cnt1 .area .txt > ul > li > a .img {
		height: auto;
	}

	#sub18 .cnt1 .area {
		width: 100%;
	}
	#sub18 .cnt1 .area .filter {
		margin-right: 0;
		width: 90%;
		margin: 37px auto 0;
	}
	#sub18 .cnt1 .area .txt {
		width: 90%;
	}
	#sub18 .cnt1 .area .txt > ul > li {
		width: 23.5%;
		margin-left: 2%;
	}
	#sub18 .cnt1 .area .txt > ul > li > a .img {
		height: auto;
	}

	#subMy .cnt1 .area > div,
	#subMy .cnt1 .area .bar {
		width: 100%;
	}
}

@media all and (max-width: 1080px) {
	#main .cnt1 .visual ul li .txt h2 {
		font-size: 40px;
	}
	#main .cnt1 .visual ul li .txt h2 span {
		padding-left: 118px;
	}
	#main .cnt1 .visual ul li .txt p {
		font-size: 18px;
		padding-bottom: 40px;
	}
	#main .cnt1 .visual ul li .txt a {
		height: 48px;
		line-height: 48px;
		font-size: 16px;
	}
	#main .cnt2 {
		padding-bottom: 100px;
	}
	#main .cnt2 .area .tit h3 {
		font-size: 48px;
	}
	#main .cnt2 .area .tit p {
		font-size: 16px;
	}
	#main .cnt2 .area .txt {
		width: 100%;
	}
	#main .cnt2 .area .txt .oc_list .slick-list .slick-slide > div{
		padding: 0 10px;
	}
	#main .cnt2 .area .txt ul li a .hwrap {
		min-height: 120px;
	}
	#main .cnt2 .area .txt ul li a .hwrap > h4 {
		font-size: 26px;
	}
	#main .cnt2 .area .txt ul li a .hwrap .p_wrap p {
		font-size: 16px;
	}

	#main .cnt3 .area .tit .bar {
		height: 50px;
	}
	#main .cnt3 .area .tit h3 {
		font-size: 48px;
		padding-top: 30px;
	}

	#main .cnt3 .area .txt .img {
		width: 280px;
	}
	#main .cnt3 .area .txt .img img {
		width: 100%;
		height: auto;
	}
	#main .cnt3 .area .txt .right {
		margin-left: 100px;
		margin-top: 60px;
	}
	#main .cnt3 .area .txt .right h6 {
		font-size: 16px;
	}
	#main .cnt3 .area .txt .right h4 {
		font-size: 34px;
		padding-top: 10px;
	}


	#main .cnt5 {
		padding-top: 80px;
	}
	#main .cnt5 .area .box1 .left img {
		width: 450px;
	}
	#main .cnt5 .area .box1 .right h3 {
		font-size: 38px;
		margin-left: 30px;
	}
	#main .cnt5 .area .box2 {
		margin-top: 50px;
	}
	#main .cnt5 .area .box2 .left {
		margin-right: 60px;
	}
	#main .cnt5 .area .box2 .left h3 {
		font-size: 38px;
	}
	#main .cnt5 .area .box2 .left p{
		font-size: 20px;
		padding-top: 22px;
	}
	#main .cnt5 .area .box2 .right img {
		width: 400px;
		height: auto;
	}


	#main .cnt6 .area .tit .bar {
		height: 50px;
	}

	#main .cnt6 .area .tit h3 {
		font-size: 48px;
	}

	#main .cnt6 .area .txt .box2 .box_content {
		width: 90%;
		left: 50%;
		transform: translateX(-50%);
	}

	#main .cnt6 .area .txt .box4 .box_content {
		width: 350px;
	}
	#main .cnt6 .area .txt .box4 .box_content img {
		width: 100%;
		height: auto;
	}
	#main .cnt6 .area .txt .box5 .box_content {
		width: 450px;
	}


	#main .cnt7 {
		padding: 90px 0;
		margin-top: 100px;
	}

	#main .cnt7 .area .tit h3 {
		font-size: 48px;
	}
	

	#main .cnt8 .area .tit h3 {
		font-size: 48px;
	}

	#main .cnt9 {
		padding: 90px 0;
	}
	#main .cnt9 .area .tit h3 {
		font-size: 48px;
	}


	#main .cnt10 {
		padding-top: 0px;
	}
	#main .cnt10 .area .c10_2 .btn_wrap {
		width: 90%;
		margin: 113px auto 0;
	}

	#main2 .cnt1 .visual ul li .txt h2 {
		font-size: 40px;
	}
	#main2 .cnt1 .visual ul li .txt h2 span {
		padding-left: 118px;
	}
	#main2 .cnt1 .visual ul li .txt p {
		font-size: 18px;
		padding-bottom: 40px;
	}
	#main2 .cnt1 .visual ul li .txt a {
		height: 48px;
		line-height: 48px;
		font-size: 16px;
	}
	#main2 .cnt3 .area .tit h5 {
		font-size: 22px;
	}
	#main2 .cnt3 .area .tit h3 {
		font-size: 48px;
	}
	#main2 .cnt3 .area .txt .left {
		float: none;
		padding-left: 100px;
	}
	#main2 .cnt3 .area .txt .left h5 {
		font-size: 22px;
	}
	#main2 .cnt3 .area .txt .left h4 {
		font-size: 42px;
		line-height: 1.5;
	}
	#main2 .cnt3 .area .txt .right {
		margin-top: 50px;
	}
	#main2 .cnt4 .area .right h5 {
		font-size: 22px;
	}
	#main2 .cnt4 .area .right h3 {
		font-size: 42px;
		line-height: 1.5;
	}
	#main2 .cnt5 .area ul {
		width: 90%;
	}
	#main2 .cnt5 .area ul li {
		width: 100%;
	}
	#main2 .cnt6 .area .top .left h5 {
		font-size: 22px;
	}
	#main2 .cnt6 .area .top .left h3 {
		font-size: 42px;
		line-height: 1.5;
	}
	#main2 .cnt6 .area .top .right ul li.box {
		width: 185px;
		height: 185px;
	}
	#main2 .cnt6 .area .top .right ul li.box h4 {
		font-size: 22px;
		line-height: 1.5;
		padding-top: 65px;
	}
	#main2 .cnt6 .area .top .right ul li.box:last-child h4 {
		font-size: 30px;
		line-height: 1.5;
		padding-top: 52px;
	}
	#main2 .cnt6 .area .top .right ul li p {
		font-size: 45px;
		line-height: 185px;
		padding: 0 12px;
	}
	#main2 .cnt6 .area .btm ul li {
		width: 250px;
		height: 250px;
	}
	#main2 .cnt6 .area .btm ul li .icon img {
		width: 70px;
		height: auto;
	}
	#main2 .cnt6 .area .btm ul li h4 {
		font-size: 24px;
		line-height: 1.3;
	}
	#main2 .cnt7 .area .tit h3 {
		font-size: 48px;
		line-height: 1.3;
	}
	#main2 .cnt8 .area .tit h3 {
		font-size: 48px;
		line-height: 1.3;
	}

	#sub4 .cnt1 .visual .area .wrap {
		width: 80%;
		bottom: -30px;
	}
	#sub4 .cnt1 .visual .area .wrap .box {
		height: 60px;
		-webkit-border-radius: px;
		-moz-border-radius: px;
		border-radius: 30px;
	}
	#sub4 .cnt1 .visual .area .wrap .box .left {
		padding-left: 30px;
		line-height: 1;
		margin-top: 15px;
	}
	#sub4 .cnt1 .visual .area .wrap .box .left ul li {
		float: none;
		margin-left: 0;
		margin-top: 5px;
	}
	#sub4 .cnt1 .visual .area .wrap .box .left ul li:first-child {
		margin-top: 0;
	}
	#sub4 .cnt1 .visual .area .wrap .box .right {
		margin-right: 20px;
	}
	#sub4 .cnt1 .visual .area .wrap .box .right ul {
		margin-top: 16px;
	}
}

@media all and (max-width: 950px) {
	#main .cnt4 .area .wrap {
		width: 80%;
	}
	#main .cnt4 .area .wrap .box {
		width: 100%;
		margin-top: 2%;
	}
	#main .cnt4 .area .wrap .box5 {
		display: block;
	}
	#main .cnt4 .area .wrap .box5 .img {
		bottom: 43px;
		top: auto;
		transform: translateY(0);
		width: 250px;
	}
	#main .cnt4 .area .wrap .box5 .img img {
		width: 100%;
		height: auto;
	}
	#main .cnt4 .area .wrap .box5 h4 {
		padding-top: 34px;
		padding-left: 38px;
	}


	#main .cnt6 .area .txt {
		display: block;
		width: 80%;
	}
	#main .cnt6 .area .txt .box {
		width: 100%;
	}
	#main .cnt6 .area .txt .box5 {
		display: block;
	}
	#main .cnt6 .area .txt .box5 h4 {
		padding-top: 36px;
		padding-left: 45px;
	}
	#main .cnt6 .area .txt .box5 .box_content {
		margin-left: 0;
		position: absolute;
		bottom: 34px;
		right: 39px;
	}


	#main .cnt7 .area .txt {
		flex-wrap: wrap;
		width: 80%;
	}
	#main .cnt7 .area .txt .box {
		width: 49%;
		margin-bottom: 2%;
	}


	#main .cnt8 .area .txt {
		flex-wrap: wrap;
		width: 80%;
	}
	#main .cnt8 .area .txt .box {
		width: 100%;
		margin-bottom: 2%;
	}
	


	#main .cnt9 .area .txt {
		flex-wrap: wrap;
		width: 80%;
	}

	#main .cnt9 .area .txt .box {
		width: 100%;
		height: auto;
		margin-bottom: 2%;
	}




	#sub2 .cnt1 .area .wrap {
		width: 100%;
	}
	#sub2 .cnt1 .area .wrap .box .visual {
		width: 300px;
	}
	#sub2 .cnt1 .area .wrap .box .tit {
		margin-left: 30px;
		width: calc(100% - 330px);
		height: 300px;
	}
	#sub2 .cnt1 .area .wrap .box .tit > h2 {
		font-size: 22px;
	}
	#sub2 .cnt1 .area .wrap .box .tit .p_wrap {
		margin-top: 5px;
	}
	#sub2 .cnt1 .area .wrap .box .tit .p_wrap .pw_1 > p {
		font-size: 13px;
	}
	#sub2 .cnt1 .area .wrap .box .tit .p_wrap ul {
		margin-top: 20px;
	}
	#sub2 .cnt1 .area .wrap .box .tit .info {
		padding-top: 20px;
		margin-top: 30px;
	}
	#sub2 .cnt1 .area .wrap .box .tit .info ul li {
		height: 30px;
		line-height: 30px;
	}
	#sub2 .cnt1 .area .wrap .box .tit .info ul li > * {
		font-size: 14px;
	}
	#sub2 .cnt2 .area .sub_cmn {
		width: 100%;
	}
	#sub2 .cnt2 .area .sub_cmn ul {
		width: 380px;
		margin: 32px auto 0;
	}
	#sub2 .cnt2 .area .sub_cmn ul li {
		margin-bottom: 32px;
	}
	#sub2 .cnt2 .area .sub_cmn ul li:nth-child(3) {
		margin-left: 0;
		margin-bottom: 0;
	}
	#sub2 .cnt2 .area .sub_cmn ul li:nth-child(4) {
		margin-bottom: 0;
	}

	#sub3 .cnt1 .area .wrap {
		width: 100%;
	}
	#sub3 .cnt1 .area .wrap .box .visual {
		width: 300px;
	}
	#sub3 .cnt1 .area .wrap .box .tit {
		margin-left: 30px;
		width: calc(100% - 330px);
		height: 300px;
	}
	#sub3 .cnt1 .area .wrap .box .tit > h2 {
		font-size: 22px;
	}
	#sub3 .cnt1 .area .wrap .box .tit .p_wrap {
		margin-top: 5px;
	}
	#sub3 .cnt1 .area .wrap .box .tit .p_wrap .pw_1 > p {
		font-size: 13px;
	}
	#sub3 .cnt1 .area .wrap .box .tit .p_wrap ul {
		margin-top: 20px;
	}
	#sub3 .cnt1 .area .wrap .box .tit .info {
		padding-top: 20px;
		margin-top: 30px;
	}
	#sub3 .cnt1 .area .wrap .box .tit .info ul li {
		height: 30px;
		line-height: 30px;
	}
	#sub3 .cnt1 .area .wrap .box .tit .info ul li > * {
		font-size: 14px;
	}
	#sub3 .cnt2 .area .sub_cmn {
		width: 100%;
	}
	#sub3 .cnt2 .area .sub_cmn ul {
		width: 380px;
		margin: 32px auto 0;
	}
	#sub3 .cnt2 .area .sub_cmn ul li {
		margin-bottom: 32px;
	}
	#sub3 .cnt2 .area .sub_cmn ul li:nth-child(3) {
		margin-left: 0;
		margin-bottom: 0;
	}
	#sub3 .cnt2 .area .sub_cmn ul li:nth-child(4) {
		margin-bottom: 0;
	}

	#sub4 .cnt1 .visual {
		height: 300px;
	}
	#sub4 .cnt1 .visual .area .tit h2 {
		font-size: 25px;
	}
	#sub4 .cnt1 .visual .area .tit ul li p {
		font-size: 15px;
	}
	#sub4 .cnt1 .area .wrap {
		width: 100%;
	}
	#sub4 .cnt1 .area .wrap .box .visual {
		width: 300px;
	}
	#sub4 .cnt1 .area .wrap .box .tit {
		margin-left: 30px;
		width: calc(100% - 330px);
		height: 300px;
	}
	#sub4 .cnt1 .area .wrap .box .tit > h2 {
		font-size: 22px;
	}
	#sub4 .cnt1 .area .wrap .box .tit .p_wrap {
		margin-top: 5px;
	}
	#sub4 .cnt1 .area .wrap .box .tit .p_wrap .pw_1 > p {
		font-size: 13px;
	}
	#sub4 .cnt1 .area .wrap .box .tit .p_wrap ul {
		margin-top: 20px;
	}
	#sub4 .cnt1 .area .wrap .box .tit .info {
		padding-top: 20px;
		margin-top: 30px;
	}
	#sub4 .cnt1 .area .wrap .box .tit .info ul li {
		height: 30px;
		line-height: 30px;
	}
	#sub4 .cnt1 .area .wrap .box .tit .info ul li > * {
		font-size: 14px;
	}
	#sub4 .cnt2 .area .sub_cmn {
		width: 100%;
	}
	#sub4 .cnt2 .area .sub_cmn ul {
		width: 380px;
		margin: 32px auto 0;
	}
	#sub4 .cnt2 .area .sub_cmn ul li {
		margin-bottom: 32px;
	}
	#sub4 .cnt2 .area .sub_cmn ul li:nth-child(3) {
		margin-left: 0;
		margin-bottom: 0;
	}
	#sub4 .cnt2 .area .sub_cmn ul li:nth-child(4) {
		margin-bottom: 0;
	}

	#sub13 .cnt1 .area .txt > ul > li {
		width: 32%;
	}
	#sub13 .cnt1 .area .txt > ul > li:nth-child(4n + 1) {
		margin-left: 2%;
	}
	#sub13 .cnt1 .area .txt > ul > li:nth-child(3n + 1) {
		margin-left: 0;
	}

	#sub14 .cnt1 .area .txt > ul > li {
		width: 32%;
	}
	#sub14 .cnt1 .area .txt > ul > li:nth-child(4n + 1) {
		margin-left: 2%;
	}
	#sub14 .cnt1 .area .txt > ul > li:nth-child(3n + 1) {
		margin-left: 0;
	}

	#sub16 .cnt1 .area .txt > ul > li {
		width: 32%;
	}
	#sub16 .cnt1 .area .txt > ul > li:nth-child(4n + 1) {
		margin-left: 2%;
	}
	#sub16 .cnt1 .area .txt > ul > li:nth-child(3n + 1) {
		margin-left: 0;
	}

	#sub17 .cnt1 .area .txt > ul > li {
		width: 32%;
	}
	#sub17 .cnt1 .area .txt > ul > li:nth-child(4n + 1) {
		margin-left: 2%;
	}
	#sub17 .cnt1 .area .txt > ul > li:nth-child(3n + 1) {
		margin-left: 0;
	}

	#sub18 .cnt1 .area .txt > ul > li {
		width: 32%;
	}
	#sub18 .cnt1 .area .txt > ul > li:nth-child(4n + 1) {
		margin-left: 2%;
	}
	#sub18 .cnt1 .area .txt > ul > li:nth-child(3n + 1) {
		margin-left: 0;
	}
}

@media all and (max-width: 750px) {
	.cnt1 .chn_util_area .chn_util {
		width: 134px;
		height: 34px;
	}
	.cnt1 .chn_util_area .chn_util .chn_df {
		height: 34px;
	}
	.cnt1 .chn_util_area .chn_util .chn_df p {
		margin-top: 0;
	}
	.cnt1 .chn_util_area .chn_util .chn_df p img {
		width: 70px;
		height: auto;
	}
	.cnt1 .chn_util_area .chn_util .chn_df span {
		margin-left: 14px;
		margin-top: -2px;
	}
	.cnt1 .chn_util_area .chn_util .chn_df span img {
		width: 20px;
		height: auto;
	}
	.cnt1 .chn_util_area .chn_util > a {
		height: 34px;
	}
	.cnt1 .chn_util_area .chn_util > a p img {
		width: 70px;
		height: auto;
	}

	#main .cnt1 .visual ul li .txt {
		width: 100%;
		padding: 0 20px;
	}
	#main .cnt1 .visual ul li .txt h2 {
		font-size: 30px;
		padding-top: 90px;
		padding-bottom: 10px;
	}
	#main .cnt1 .visual ul li .txt h2 span {
		padding-left: 89px;
	}
	#main .cnt1 .visual ul li .txt p {
		font-size: 14px;
		padding-bottom: 25px;
	}
	#main .cnt1 .visual ul li .txt a {
		height: 36px;
		line-height: 36px;
		font-size: 12px;
		padding-left: 21px;
		padding-right: 36px;
	}
	#main .cnt1 .visual ul li .txt a img {
		width: 4px;
		margin-top: -4px;
		right: 20px;
	}
	#main .cnt1 .visual ul li .slide_bg > div {
		background-position: bottom center;
	}
	#main .cnt1 .visual ul li .slide_bg .mo_none {
		display: none;
	}
	#main .cnt1 .visual ul li .slide_bg .mo_only {
		display: block;
	}
	#main .cnt2 .area .tit h3 span {
		display: block;
	}
	#main .cnt2 .area .txt {
		margin-left: 0;
		padding-top: 110px;
	}

	#main .cnt2 .area .txt .btn_wrap {
		margin-top: 50px;
	}

	#main .cnt2 .area .txt .btn_wrap ul li {
		width: 200px;
		height: 60px;
	}
	#main .cnt2 .area .txt .btn_wrap ul li p {
		font-size: 20px;
	}


	#main .cnt3 .area .txt {
		display: block;
	}
	#main .cnt3 .area .txt .img {
		margin: 0 auto;
	}
	#main .cnt3 .area .txt .right {
		margin: 44px auto 0;
	}
	#main .cnt3 .area .txt .right h6 {
		text-align: center;
	}
	#main .cnt3 .area .txt .right h4 {
		text-align: center;
		padding-top: 21px;
	}

	

	#main .cnt4 .area .wrap .box1 .img {
		width: 220px;
	}
	#main .cnt4 .area .wrap .box1 .img img {
		width: 100%;
		height: auto;
	}
	#main .cnt4 .area .wrap .box4 .img,
	#main .cnt4 .area .wrap .box5 .img {
		width: 200px;
	}


	#main .cnt5 .area .box {
		flex-wrap: wrap;
		justify-content: flex-start;
	}
	#main .cnt5 .area .box > div {
		width: 100%;
	}
	#main .cnt5 .area .box1 .left {
		order: 2;
		margin-top: 18px;
	}
	#main .cnt5 .area .box1 .left img {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	#main .cnt5 .area .box1 .right {
		order: 1;
	}
	#main .cnt5 .area .box1 .right h3 {
		margin-left: 0;
		width: 450px;
		margin: 0 auto;
	}

	#main .cnt5 .area .box2 {
		margin-top: 64px;
	}
	#main .cnt5 .area .box2 .left h3,
	#main .cnt5 .area .box2 .left p {
		width: 400px;
		margin: 0 auto;
	}

	#main .cnt5 .area .box2 .right {
		margin-top: 35px;
	}
	#main .cnt5 .area .box2 .right img {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}


	#main .cnt10 .top_btn {
		width: 40px;
		height: 40px;
		bottom: 20px;
		right: 3%;
	}
	#main .cnt10 .top_btn img {
		width: 100%;
		height: auto;
	}
	#main .cnt10 .area .c10_2 .btn_wrap {
		margin-top: 50px;
	}

	#main .cnt10 .area .c10_2 .btn_wrap ul li {
		height: 60px;
	}
	#main .cnt10 .area .c10_2 .btn_wrap ul li.btn2, #main .cnt10 .area .c10_2 .btn_wrap ul li.btn3 {
		margin-left: 20px;
	}
	#main .cnt10 .area .c10_2 .btn_wrap ul li p {
		font-size: 20px;
	}

	#main2 .cnt1 .visual ul li .txt {
		width: 100%;
		padding: 0 20px;
	}
	#main2 .cnt1 .visual ul li .txt h2 {
		font-size: 30px;
		padding-top: 90px;
		padding-bottom: 10px;
	}
	#main2 .cnt1 .visual ul li .txt h2 span {
		padding-left: 89px;
	}
	#main2 .cnt1 .visual ul li .txt p {
		font-size: 14px;
		padding-bottom: 25px;
	}
	#main2 .cnt1 .visual ul li .txt a {
		height: 36px;
		line-height: 36px;
		font-size: 12px;
		padding-left: 21px;
		padding-right: 36px;
	}
	#main2 .cnt1 .visual ul li .txt a img {
		width: 4px;
		margin-top: -4px;
		right: 20px;
	}
	#main2 .cnt1 .visual ul li .slide_bg > div {
		background-position: bottom center;
	}
	#main2 .cnt1 .visual ul li .slide_bg .mo_none {
		display: none;
	}
	#main2 .cnt1 .visual ul li .slide_bg .mo_only {
		display: block;
	}
	#main2 .cnt2 .area .tit {
		padding-top: 80px;
	}
	#main2 .cnt2 .area .tit h3 {
		font-size: 38px;
	}
	#main2 .cnt2 .area .txt {
		margin-top: 80px;
	}
	#main2 .cnt2 .area .txt ul {
		width: 90%;
	}
	#main2 .cnt2 .area .txt ul li {
		margin-bottom: 15%;
	}
	#main2 .cnt2 .area .txt ul li .icon {
		height: 70px;
	}
	#main2 .cnt2 .area .txt ul li .icon img {
		height: 100%;
		width: auto;
	}
	#main2 .cnt2 .area .txt ul li p {
		font-size: 22px;
		line-height: 1.5;
	}
	#main2 .cnt3 .area .tit {
		padding-top: 100px;
	}
	#main2 .cnt3 .area .tit h5 {
		font-size: 18px;
	}
	#main2 .cnt3 .area .tit h3 {
		font-size: 38px;
		padding-top: 30px;
	}
	#main2 .cnt3 .area .txt {
		width: 80%;
		margin: 0 auto;
	}
	#main2 .cnt3 .area .txt .left {
		padding-left: 0;
	}
	#main2 .cnt3 .area .txt .left h5 {
		font-size: 18px;
	}
	#main2 .cnt3 .area .txt .left h4 {
		font-size: 32px;
		padding-top: 20px;
	}
	#main2 .cnt3 .area .txt .right {
		float: none;
		width: 100%;
		margin: 50px auto 0;
	}
	#main2 .cnt3 .area .txt .right ul li {
		position: relative;
		width: 49%;
		margin-left: 2%;
		height: auto;
		margin-bottom: 2%;
	}
	#main2 .cnt3 .area .txt .right ul li:after {
		content: '';
		display: block;
		padding-bottom: 100%;
	}
	#main2 .cnt3 .area .txt .right ul li .inner {
		position: absolute;
		width: 100%;
		height: 100%;
	}
	#main2 .cnt3 .area .txt .right ul li .icon {
		width: 40%;
		margin: 19% auto 0;
	}
	#main2 .cnt3 .area .txt .right ul li .icon img {
		width: 100%;
		height: auto;
	}
	#main2 .cnt3 .area .txt .right ul li h5 {
		font-size: 24px;
	}
	#main2 .cnt3 .area .txt .right ul li:nth-child(1) .icon,
	#main2 .cnt3 .area .txt .right ul li:nth-child(2) .icon {
		margin: 17% auto 0;
	}
	#main2 .cnt4 {
		padding: 100px 0;
	}
	#main2 .cnt4 .area .right {
		padding-left: 0;
		width: 80%;
		margin: 0 auto;
	}
	#main2 .cnt4 .area .right h5 {
		font-size: 18px;
	}
	#main2 .cnt4 .area .right h3 {
		font-size: 32px;
		padding-top: 20px;
	}
	#main2 .cnt4 .area .left {
		float: none;
		width: 80%;
		margin: 50px auto 0;
	}
	#main2 .cnt4 .area .left ul li {
		position: relative;
		width: 32%;
		margin-left: 2%;
		height: auto;
		margin-bottom: 2%;
	}
	#main2 .cnt4 .area .left ul li:after {
		content: '';
		display: block;
		padding-bottom: 100%;
	}
	#main2 .cnt4 .area .left ul li .inner {
		position: absolute;
		width: 100%;
		height: 100%;
	}
	#main2 .cnt4 .area .left ul li .icon {
		width: 90%;
	}
	#main2 .cnt4 .area .left ul li .icon img {
		width: 100%;
		height: auto;
	}
	#main2 .cnt4 .area .left ul li h5 {
		font-size: 20px;
		line-height: 1.3;
		padding-top: 75%;
	}
	#main2 .cnt4 .area .left ul li.ln2 h5 {
		padding-top: 65%;
	}
	#main2 .cnt4 .area .left ul li:nth-child(1),
	#main2 .cnt4 .left ul li:nth-child(4),
	#main2 .cnt4 .left ul li:nth-child(7) {
		margin-left: 0;
	}
	#main2 .cnt6 {
		padding-bottom: 100px;
	}
	#main2 .cnt6 .area .top {
		padding-top: 100px;
	}
	#main2 .cnt6 .area .top .left h5 {
		font-size: 18px;
	}
	#main2 .cnt6 .area .top .left h3 {
		font-size: 32px;
		padding-top: 20px;
	}
	#main2 .cnt6 .area .top .right {
		float: none;
	}
	#main2 .cnt6 .area .top .right ul {
		width: 428px;
		margin: 0 auto;
	}
	#main2 .cnt6 .area .top .right ul li.box {
		width: 120px;
		height: 120px;
	}
	#main2 .cnt6 .area .top .right ul li.box h4 {
		font-size: 16px;
		padding-top: 40px;
	}
	#main2 .cnt6 .area .top .right ul li.box:last-child h4 {
		font-size: 20px;
		padding-top: 35px;
	}
	#main2 .cnt6 .area .top .right ul li p {
		font-size: 30px;
		line-height: 120px;
		padding: 0 8px;
	}
	#main2 .cnt6 .area .btm ul li {
		display: block;
		margin-left: 0;
		margin: 30px auto 0;
		width: 200px;
		height: 200px;
	}
	#main2 .cnt6 .area .btm ul li:first-child {
		margin-left: 0;
		margin: 0 auto;
	}
	#main2 .cnt6 .area .btm ul li .icon {
		padding-top: 44px;
	}
	#main2 .cnt6 .area .btm ul li .icon img {
		width: 50px;
	}
	#main2 .cnt6 .area .btm ul li h4 {
		font-size: 20px;
	}
	#main2 .cnt7 .area .tit {
		padding-top: 120px;
	}
	#main2 .cnt7 .area .tit h3 {
		font-size: 38px;
	}
	#main2 .cnt7 .area .txt {
		margin-top: 50px;
	}
	#main2 .cnt7 .area .txt ul li {
		width: 300px;
		height: 300px;
		margin-top: 30px;
	}
	#main2 .cnt7 .area .txt ul li .icon {
		width: 100px;
		margin: 50px auto 0;
	}
	#main2 .cnt7 .area .txt ul li:nth-child(2) .icon {
		margin-top: 30px;
	}
	#main2 .cnt7 .area .txt ul li .icon img {
		width: 100%;
		height: auto;
	}
	#main2 .cnt7 .area .txt ul li h4 {
		font-size: 24px;
	}
	#main2 .cnt7 .area .txt ul li p {
		font-size: 18px;
		line-height: 1.5;
	}
	#main2 .cnt7 .area .deco {
		right: -75px;
	}
	#main2 .cnt7 .area .deco img {
		width: 450px;
	}
	#main2 .cnt8 .area .tit {
		padding-top: 120px;
	}
	#main2 .cnt8 .area .tit h3 {
		font-size: 38px;
	}
	#main2 .cnt8 .area .txt {
		margin-top: 50px;
	}
	#main2 .cnt8 .area .txt ul li {
		width: 300px;
		height: 300px;
		margin-top: 30px;
	}
	#main2 .cnt8 .area .txt ul li .icon {
		width: 140px;
		margin: 50px auto 0;
	}
	#main2 .cnt8 .area .txt ul li:nth-child(2) .icon {
		margin-top: 30px;
	}
	#main2 .cnt8 .area .txt ul li .icon img {
		width: 100%;
		height: auto;
	}
	#main2 .cnt8 .area .txt ul li h4 {
		font-size: 24px;
	}
	#main2 .cnt8 .area .txt ul li p {
		font-size: 18px;
		line-height: 1.5;
	}
	#main2 .cnt8 .top_btn {
		width: 40px;
		height: 40px;
		bottom: 20px;
		right: 3%;
	}
	#main2 .cnt8 .top_btn img {
		width: 100%;
		height: auto;
	}

	#sub1 .area .wrap .box {
		width: 100%;
	}
	#sub1 .cnt1 .pvc_pu {
		width: 90%;
	}

	#sub4 .cnt1 .visual .area .tit {
		width: 80%;
	}

	#sub7 .cnt1 .terms_pu,
	#sub7 .cnt1 .pvc_pu {
		width: 90%;
	}

	#sub13 .cnt1 .area .txt > ul > li {
		width: 49%;
	}
	#sub13 .cnt1 .area .txt > ul > li:nth-child(3n + 1) {
		margin-left: 2%;
	}
	#sub13 .cnt1 .area .txt > ul > li:nth-child(2n + 1) {
		margin-left: 0;
	}

	#sub14 .cnt1 .area .txt > ul > li {
		width: 49%;
	}
	#sub14 .cnt1 .area .txt > ul > li:nth-child(3n + 1) {
		margin-left: 2%;
	}
	#sub14 .cnt1 .area .txt > ul > li:nth-child(2n + 1) {
		margin-left: 0;
	}

	#sub16 .cnt1 .area .txt > ul > li {
		width: 49%;
	}
	#sub16 .cnt1 .area .txt > ul > li:nth-child(3n + 1) {
		margin-left: 2%;
	}
	#sub16 .cnt1 .area .txt > ul > li:nth-child(2n + 1) {
		margin-left: 0;
	}

	#sub17 .cnt1 .area .txt > ul > li {
		width: 49%;
	}
	#sub17 .cnt1 .area .txt > ul > li:nth-child(3n + 1) {
		margin-left: 2%;
	}
	#sub17 .cnt1 .area .txt > ul > li:nth-child(2n + 1) {
		margin-left: 0;
	}

	#sub18 .cnt1 .area .txt > ul > li {
		width: 49%;
	}
	#sub18 .cnt1 .area .txt > ul > li:nth-child(3n + 1) {
		margin-left: 2%;
	}
	#sub18 .cnt1 .area .txt > ul > li:nth-child(2n + 1) {
		margin-left: 0;
	}

	#subMy .cnt1 .area .profile2 .pf_process ul {
		width: 85%;
	}
	#subMy .cnt1 .area .profile2 .pf_process ul li {
		width: 23.875%;
		margin-left: 1.5%;
	}
	#subMy .cnt1 .area .profile2 > a,
	#subMy .cnt1 .area .profile3 > a,
	#subMy .cnt1 .area .profile4 > a {
		width: 260px;
		font-size: 14px;
		margin-top: 35px;
	}
}

@media all and (max-width: 650px) {
	#main .cnt2 .area .tit {
		padding-top: 80px;
	}
	#main .cnt2 .area .tit h3 {
		font-size: 38px;
	}
	#main .cnt2 .area .txt {
		padding-top: 70px;
	}
	#main2 .cnt4 .area .left ul li .icon {
		width: 80%;
	}
	#main2 .cnt4 .area .left ul li h5 {
		font-size: 15px;
		line-height: 1.1;
	}

	#main .cnt6 .area .txt .box {
		height: auto;
		margin-bottom: 20px;
	}

	#main .cnt6 .area .txt .box .box_content {
		position: relative;
		right: auto;
		left: auto;
		bottom: auto;
		transform: translateX(0);
		margin: 0 auto;
		padding: 50px 0;
		width: 80%;
	}
	#main .cnt6 .area .txt .box1 .box_content {
		padding-bottom: 0;
	}



	#sub2 .cnt1 .area,
	#sub3 .cnt1 .area,
	#sub4 .cnt1 .area,
	#sub5 .cnt1 .area,
	#sub5 .cnt1 .area .wrap,
	#sub6 .cnt1 .area .wrap,
	#sub7 .cnt1 .area .wrap,
	#sub8 .cnt1 .area .wrap,
	#sub9 .cnt1 .area .wrap,
	#sub10 .cnt1 .area .wrap,
	#sub11 .cnt1 .area .wrap,
	#sub11_1 .cnt1 .area .wrap,
	#sub12 .cnt1 .area .wrap,
	#sub12_1 .cnt1 .area .car_pg,
	#sub12_2 .cnt1 .field0_pg,
	#sub12_3 .cnt1 .area .car0_pg,
	#sub12_4 .cnt1 .area .edu0_pg,
	#sub12_5 .cnt1 .good_pg,
	#sub12_6 .cnt1,
	#sub12_6 .cnt1 .area .txt .join_info1,
	#sub12_6 .cnt1 .area .txt .join_info2,
	#sub12_7 .cnt1 .good_pg,
	#sub15 .cnt1 .area .pf_info1,
	#sub15 .cnt1 .area .pf_info2,
	#sub15 .cnt1 .area .pf_info3,
	#sub15 .cnt1 .area .pf_info4,
	#sub15 .cnt1 .area .pf_info5,
	#sub15 .cnt1 .area .pf_info6,
	#sub19 .cnt1 .area > div {
		width: 100%;
	}

	#sub2 .cnt1 {
		margin-top: 30px;
	}
	#sub2 .cnt1 .area .wrap .box {
		width: calc(100% - 40px);
		margin: 0 auto 30px;
		padding-bottom: 20px;
	}
	#sub2 .cnt1 .area .wrap .box > div {
		float: none;
	}
	#sub2 .cnt1 .area .wrap .box .visual {
		width: 100%;
		height: auto;
	}
	#sub2 .cnt1 .area .wrap .box .tit {
		width: 100%;
		height: auto;
		margin-left: 0;
		padding-top: 22px;
		padding-bottom: 40px;
	}
	#sub2 .cnt1 .area .wrap .wd_btn {
		width: calc(100% - 40px);
		margin: 0 auto;
		height: 50px;
		line-height: 50px;
		font-size: 16px;
	}
	#sub2 .cnt1 .area .wrap .txt {
		margin: 0 20px;
	}
	#sub2 .cnt2 .area .sub_cmn {
		padding-top: 44px;
		padding-bottom: 44px;
	}
	#sub2 .cnt2 .area .sub_cmn h4 {
		text-align: left;
		font-size: 14px;
	}
	#sub2 .cnt2 .area .sub_cmn ul {
		width: 100%;
		margin: 16px auto 0;
	}
	#sub2 .cnt2 .area .sub_cmn ul li {
		width: 49%;
		margin-left: 2%;
	}
	#sub2 .cnt2 .area .sub_cmn ul li a h5 {
		font-size: 12px;
		line-height: 14px;
	}
	#sub2 .cnt2 .area .sub_cmn ul li a .p_wrap h6 {
		font-size: 11px;
	}
	#sub2 .cnt2 .area .sub_cmn ul li a .p_wrap p {
		font-size: 11px;
	}
	#sub2 .cnt2 .area .sub_cmn ul li:nth-child(3),
	#sub2 .cnt2 .area .sub_cmn ul li:nth-child(4) {
		display: none;
	}
	#sub2 .cnt2 .area .sub_cmn ul li:nth-child(1),
	#sub2 .cnt2 .area .sub_cmn ul li:nth-child(2) {
		margin-bottom: 0;
	}
	#sub2 .cnt2 .area .sub_cmn ul li a .price {
		font-size: 11px;
		padding-top: 10px;
	}

	#sub3 .cnt1 {
		margin-top: 30px;
	}
	#sub3 .cnt1 .area .wrap .box {
		width: calc(100% - 40px);
		margin: 0 auto 30px;
		padding-bottom: 20px;
	}
	#sub3 .cnt1 .area .wrap .box > div {
		float: none;
	}
	#sub3 .cnt1 .area .wrap .box .visual {
		width: 100%;
		height: auto;
	}
	#sub3 .cnt1 .area .wrap .box .tit {
		width: 100%;
		height: auto;
		margin-left: 0;
		padding-top: 22px;
		padding-bottom: 40px;
	}
	#sub3 .cnt1 .area .wrap .sup_btn {
		width: calc(100% - 40px);
		margin: 0 auto;
		height: 50px;
		line-height: 50px;
		font-size: 16px;
	}
	#sub3 .cnt1 .area .wrap .ied_btn {
		width: calc(100% - 40px);
		margin: 0 auto;
		height: 50px;
		line-height: 50px;
		font-size: 16px;
	}
	#sub3 .cnt1 .area .wrap .txt {
		margin: 0 20px;
	}
	#sub3 .cnt1 .area .wrap .txt .project .img {
		width: 100%;
	}
	#sub3 .cnt2 .area .sub_cmn {
		padding-top: 44px;
		padding-bottom: 44px;
	}
	#sub3 .cnt2 .area .sub_cmn h4 {
		text-align: left;
		font-size: 14px;
	}
	#sub3 .cnt2 .area .sub_cmn ul {
		width: 100%;
		margin: 16px auto 0;
	}
	#sub3 .cnt2 .area .sub_cmn ul li {
		width: 49%;
		margin-left: 2%;
	}
	#sub3 .cnt2 .area .sub_cmn ul li a h5 {
		font-size: 12px;
		line-height: 14px;
	}
	#sub3 .cnt2 .area .sub_cmn ul li a .p_wrap h6 {
		font-size: 11px;
	}
	#sub3 .cnt2 .area .sub_cmn ul li a .p_wrap p {
		font-size: 11px;
	}
	#sub3 .cnt2 .area .sub_cmn ul li:nth-child(3),
	#sub3 .cnt2 .area .sub_cmn ul li:nth-child(4) {
		display: none;
	}
	#sub3 .cnt2 .area .sub_cmn ul li:nth-child(1),
	#sub3 .cnt2 .area .sub_cmn ul li:nth-child(2) {
		margin-bottom: 0;
	}
	#sub3 .cnt2 .area .sub_cmn ul li a .price {
		font-size: 11px;
		padding-top: 10px;
	}

	#sub4 .cnt1 {
		margin-top: 30px;
	}
	#sub4 .cnt1 .area .wrap .txt {
		margin: 0 20px;
	}
	#sub4 .cnt1 .area .wrap .txt .project .img {
		width: 100%;
	}
	#sub4 .cnt1 .area .wrap .comts {
		width: 90%;
	}
	#sub4 .cnt1 .area .wrap .thanks_banner h4 {
		font-size: 18px;
		line-height: 24px;
	}
	#sub4 .cnt2 .area .sub_cmn {
		padding-top: 44px;
		padding-bottom: 44px;
	}
	#sub4 .cnt2 .area .sub_cmn h4 {
		text-align: left;
		font-size: 14px;
	}
	#sub4 .cnt2 .area .sub_cmn ul {
		width: 100%;
		margin: 16px auto 0;
	}
	#sub4 .cnt2 .area .sub_cmn ul li {
		width: 49%;
		margin-left: 2%;
	}
	#sub4 .cnt2 .area .sub_cmn ul li a h5 {
		font-size: 12px;
		line-height: 14px;
	}
	#sub4 .cnt2 .area .sub_cmn ul li a .p_wrap h6 {
		font-size: 11px;
	}
	#sub4 .cnt2 .area .sub_cmn ul li a .p_wrap p {
		font-size: 11px;
	}
	#sub4 .cnt2 .area .sub_cmn ul li:nth-child(3),
	#sub4 .cnt2 .area .sub_cmn ul li:nth-child(4) {
		display: none;
	}
	#sub4 .cnt2 .area .sub_cmn ul li:nth-child(1),
	#sub4 .cnt2 .area .sub_cmn ul li:nth-child(2) {
		margin-bottom: 0;
	}
	#sub4 .cnt2 .area .sub_cmn ul li a .price {
		font-size: 11px;
		padding-top: 10px;
	}

	#sub12 .cnt1 .area .wrap .box {
		width: 90%;
	}

	#sub15 .cnt1 .area .pf_info5 .box > div {
		width: 49%;
	}
	#sub15 .cnt1 .area .pf_info5 .box .box_1 {
		margin-right: 2%;
	}
	.top_btn {
		display: none;
	}

	#sub19 .cnt1 .area .pf_info4 .box > div {
		width: 49%;
	}
	#sub19 .cnt1 .area .pf_info4 .box .box_1 {
		margin-right: 2%;
	}
}

@media all and (max-width: 550px) {
	#main .cnt2 .area .txt .btn_wrap ul {
		display: block;
	}
	#main .cnt2 .area .txt .btn_wrap ul li {
		margin: 0 auto;
		width: 170px;
		height: 50px;
	}
	#main .cnt2 .area .txt .btn_wrap ul li.btn2 {
		margin-left: 0;
		margin: 11px auto 0;
	}
	#main .cnt2 .area .txt .btn_wrap ul li p {
		font-size: 16px;
	}

	#main .cnt3 .area .tit h3 {
		font-size: 32px;
	}

	#main .cnt3 .area .txt .right h6 {
		font-size: 12px;
	}
	#main .cnt3 .area .txt .right h4 {
		font-size: 28px;
	}


	#main .cnt4 {
		padding: 59px 0;
		margin-top: 100px;
	}
	#main .cnt4 .area .wrap {
		width: 90%;
	}
	#main .cnt4 .area .wrap .box {
		height: auto;
		padding-bottom: 37px;
		margin-top: 5%;
	}
	#main .cnt4 .area .wrap .box h4 {
		font-size: 20px;
		padding-left: 23px;
		padding-top: 27px;
	}
	#main .cnt4 .area .wrap .box .img{
		position: relative;
		right: auto;
		bottom: auto;
		margin: 25px auto 0;
	}

	#main .cnt4 .area .wrap .box1 {
		padding-bottom: 0;
	}
	#main .cnt4 .area .wrap .box2 {
		padding-bottom: 0;
	}
	#main .cnt4 .area .wrap .box2 .img {
		width: 150px;
	}
	#main .cnt4 .area .wrap .box2 .img img {
		width: 100%;
		height: auto;
	}
	#main .cnt4 .area .wrap .box3 .img {
		width: 130px;
	}
	#main .cnt4 .area .wrap .box3 .img img {
		width: 100%;
		height: auto;
	}


	#main .cnt5 .area .box1 .right h3 {
		font-size: 32px;
		width: 90%;
	}
	#main .cnt5 .area .box1 .left img {
		width: 90%;
	}
	#main .cnt5 .area .box2 .left h3 {
		font-size: 32px;
		width: 90%;
	}
	#main .cnt5 .area .box2 .left p {
		font-size: 16px;
		width: 90%;
	}
	#main .cnt5 .area .box2 .right img {
		width: 80%;
	}


	#main .cnt6 {
		padding-top: 80px;
	}
	#main .cnt6 .area .tit .bar {
		height: 50px;
	}
	#main .cnt6 .area .tit h3 {
		font-size: 32px;
		padding-top: 20px;
	}
	#main .cnt6 .area .txt {
		width: 90%;
		margin-top: 22px;
	}
	#main .cnt6 .area .txt .box h4 {
		font-size: 20px;
		padding-top: 27px;
		padding-left: 23px;
	}
	#main .cnt6 .area .txt .box2 .box_content {
		width: 90%;
	}
	#main .cnt6 .area .txt .box2 .box_content .div_table ul li h5,
	#main .cnt6 .area .txt .box2 .box_content .div_table ul li h6,
	#main .cnt6 .area .txt .box2 .box_content .div_table ul li p {
		font-size: 10px;
	}

	#main .cnt6 .area .txt .box2 .box_content .div_table ul li p.db_line span {
		font-size: 8px;
	}

	#main .cnt6 .area .txt .box2 .box_content .div_table ul li p.db_line {
		padding-top: 15px;
		height: 41px;
	}


	#main .cnt7 {
		padding: 60px 0;
	}
	#main .cnt7 .area .tit h3 {
		font-size: 32px;
	}

	#main .cnt7 .area .txt {
		margin-top: 30px;
		width: 90%;
	}


	#main .cnt7 .area .txt .box {
		padding-bottom: 40px;
	}
	#main .cnt7 .area .txt .box .box_content {
		padding-top: 40px;
	}

	#main .cnt7 .area .txt .box .box_content img {
		height: 120px;
	}

	#main .cnt7 .area .txt .box h4 {
		font-size: 16px;
		line-height: 1.3;
		padding-top: 10px;
	}


	#main .cnt8 {
		padding-top: 64px;
		padding-bottom: 92px;
	}

	#main .cnt8 .area .tit h3 {
		font-size: 32px;
	}

	#main .cnt8 .area .txt {
		margin-top: 22px;
		width: 90%;
	}

	#main .cnt8 .area .txt .box {
		margin-bottom: 5%;
	}
	#main .cnt8 .area .txt .box h4 {
		font-size: 20px;
		padding-top: 27px;
		padding-left: 23px;
	}

	#main .cnt8 .area .txt .box1 {
		padding-bottom: 28px;
	}
	#main .cnt8 .area .txt .box1 .box_content {
		margin-top: 30px;
	}

	#main .cnt8 .area .txt .box2 {
		padding-bottom: 22px;
	}

	#main .cnt8 .area .txt .box2 .box_content {
		margin-top: 21px;
	}



	#main .cnt8 .area .txt .box .box_content {
		text-align: center;
	}
	#main .cnt8 .area .txt .box1 .box_content,
	#main .cnt8 .area .txt .box2 .box_content {
		padding-bottom: 0;
		padding-right: 0;
	}


	#main .cnt8 .area .txt .box1 .box_content img {
		height: 210px;
	}



	#main .cnt9 {
		padding-top: 70px;
	}
	#main .cnt9 .area .tit h3 {
		font-size: 32px;
	}
	#main .cnt9 .area .txt {
		margin-top: 22px;
		width: 90%;
	}
	#main .cnt9 .area .txt .box h4 {
		font-size: 20px;
		padding-top: 27px;
		padding-left: 24px;
	}
	#main .cnt9 .area .txt .box1 {
		margin-bottom: 5%;
	}
	#main .cnt9 .area .txt .box1 h4 br {
		display: none;
	}
	#main .cnt9 .area .txt .box1 h4 span {
		display: block;
	}
	#main .cnt9 .area .txt .box1 .box_content {
		padding-top: 50px;
	}
	#main .cnt9 .area .txt .box2 {
		padding-bottom: 45px;
	}
	#main .cnt9 .area .txt .box2 .box_content {
		text-align: center;
		padding-bottom: 0;
		padding-right: 0;
		padding-top: 25px;
	}





	#main .cnt10 {
		padding-bottom: 100px;
	}
	#main .cnt10 .area .c10_2 {
		margin-top: 80px;
	}
	#main .cnt10 .area .c10_2 .txt {
		margin-top: 50px;
	}
	#main .cnt10 .area .c10_2 .btn_wrap ul {
		display: block;
	}
	#main .cnt10 .area .c10_2 .btn_wrap ul li {
		margin: 0 auto;
		width: 170px;
		height: 50px;
	}
	#main .cnt10 .area .c10_2 .btn_wrap ul li.btn2, #main .cnt10 .area .c10_2 .btn_wrap ul li.btn3 {
		margin-left: 0;
		margin: 11px auto 0;
	}
	#main .cnt10 .area .c10_2 .btn_wrap ul li p {
		font-size: 16px;
	}

	#main2 .cnt2 .area .tit h3 {
		font-size: 32px;
	}
	#main2 .cnt2 .area .txt {
		margin-top: 60px;
	}
	#main2 .cnt2 .area .txt ul li .icon {
		height: 50px;
	}
	#main2 .cnt2 .area .txt ul li p {
		font-size: 18px;
	}
	#main2 .cnt3 {
		padding-bottom: 100px;
	}
	#main2 .cnt5 {
		padding: 20% 0;
	}
	#main2 .cnt7 {
		padding-bottom: 150px;
	}
	#main2 .cnt7 .area .tit {
		padding-top: 120px;
	}
	#main2 .cnt7 .area .tit h3 {
		font-size: 28px;
		text-align: left;
		width: 300px;
		margin: 0 auto;
		word-break: keep-all;
	}
	#main2 .cnt7 .area .tit h3 span {
		display: block;
	}
	#main2 .cnt7 .area .txt {
		margin-top: 50px;
	}
	#main2 .cnt7 .area .txt ul li {
		width: 250px;
		height: 250px;
		margin-top: 30px;
	}
	#main2 .cnt7 .area .txt ul li .icon {
		width: 70px;
		margin: 40px auto 0;
	}
	#main2 .cnt7 .area .txt ul li h4 {
		font-size: 20px;
	}
	#main2 .cnt7 .area .txt ul li p {
		font-size: 14px;
	}
	#main2 .cnt8 {
		padding-bottom: 150px;
	}
	#main2 .cnt8 .area .tit {
		padding-top: 120px;
	}
	#main2 .cnt8 .area .tit h3 {
		font-size: 28px;
		text-align: left;
		width: 300px;
		margin: 0 auto;
		word-break: keep-all;
	}
	#main2 .cnt8 .area .tit h3 span {
		display: block;
	}
	#main2 .cnt8 .area .txt {
		margin-top: 50px;
	}
	#main2 .cnt8 .area .txt ul li {
		width: 250px;
		height: 250px;
		margin-top: 30px;
	}
	#main2 .cnt8 .area .txt ul li .icon {
		width: 120px;
		margin: 35px auto 0;
	}
	#main2 .cnt8 .area .txt ul li h4 {
		font-size: 20px;
	}
	#main2 .cnt8 .area .txt ul li p {
		font-size: 14px;
	}

	.sub_nav {
		display: block;
	}
	#sub6 .cnt1 .area .wrap,
	#sub7 .cnt1 .area .wrap,
	#sub8 .cnt1 .area .wrap,
	#sub9 .cnt1 .area .wrap,
	#sub10 .cnt1 .area .wrap,
	#sub11 .cnt1 .area .wrap,
	#sub11_1 .cnt1 .area .wrap,
	#sub12 .cnt1 .area .wrap,
	#sub12_1 .cnt1 .area .car_pg,
	#sub12_2 .cnt1 .field0_pg,
	#sub12_3 .cnt1 .area .car0_pg,
	#sub12_4 .cnt1 .area .edu0_pg,
	#sub12_5 .cnt1 .good_pg {
		border: 0;
	}

	#sub1 .area {
		width: 100%;
	}
	#sub1 .area .wrap .box {
		border: 0;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
	}
	#sub1 .area .wrap .box .tit {
		padding-left: 36px;
	}
	#sub1 .area .wrap .box .tit > .icon {
		display: none;
	}
	#sub1 .area .wrap .box .tit h3 {
		text-align: left;
		font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
		padding-top: 0;
	}
	#sub1 .area .wrap .box .tit h3 .icon {
		display: inline-block;
		width: 30px;
	}
	#sub1 .area .wrap .box .tit h3 .icon img {
		width: 100%;
		height: auto;
		margin-top: 3px;
	}
	#sub1 .area .wrap .box .txt {
		width: calc(100% - 72px);
	}
	#sub1 .area .wrap .box .txt .top .form_cont {
		height: 144px;
	}
	#sub1 .area .wrap .box .txt .top .form_cont label {
		width: 85%;
	}
	#sub1 .area .wrap .box .txt .top .form_cont textarea {
		height: 108px;
	}
	#sub1 .area .wrap .box .txt .btm ul li h5 {
		width: 110px;
		margin-right: 0;
	}
	#sub1 .area .wrap .box .txt .btm ul li .form_cont {
		width: calc(100% - 110px);
	}
	#sub1 .area .wrap .box .txt .btm ul li strong {
		padding-left: 90px;
	}
	#sub1 .area .wrap .box .txt .btm .chk_wrap > div input[type='checkbox'] + label {
		font-size: 12px;
	}
	#sub1 .area .wrap .box .txt .btm .chk_wrap a {
		font-size: 11px;
		margin-top: 2px;
	}
	#sub1 .area .wrap .save_btn {
		width: calc(100% - 72px);
		margin: 0 auto;
	}

	#sub4 .cnt1 .visual {
		width: calc(100% - 20px);
		margin-top: 10px;
		-webkit-border-radius: px;
		-moz-border-radius: px;
		border-radius: 10px;
	}
	#sub4 .cnt1 .visual .area .wrap {
		width: 90%;
	}
	#sub4 .cnt1 .visual .area .wrap .box .left {
		padding-left: 20px;
	}
	#sub4 .cnt1 .visual .area .wrap .box .right {
		margin-right: 15px;
	}
	#sub4 .cnt1 .visual .area .wrap .hash .p_wrap {
		margin-bottom: 5px;
		margin-right: 5%;
	}
	#sub4 .cnt1 .visual .area .wrap .hash .p_wrap p {
		padding-left: 7px;
		font-size: 11px;
	}
	#sub4 .cnt1 .visual .area .wrap .box {
		-webkit-border-radius: px;
		-moz-border-radius: px;
		border-radius: 10px;
	}
	#sub4 .cnt1 .area .wrap {
		margin-top: 10%;
	}
	#sub4 .cnt1 .area .wrap .txt {
		padding: 0 5% 5%;
	}
	#sub4 .cnt1 .area .wrap .txt .project > h4 {
		font-size: 18px;
		padding-top: 35px;
	}
	#sub4 .cnt1 .area .wrap .txt .project > p {
		font-size: 16px;
		padding-top: 15px;
	}
	#sub4 .cnt1 .area .wrap .txt .project .img {
		margin: 40px 0;
	}
	#sub4 .cnt1 .area .wrap .txt .hash p {
		height: 24px;
		line-height: 26px;
		padding: 0 10px;
	}

	#sub5 .cnt1 .area .wrap .bd_list ul li a {
		padding-right: 24px;
	}

	#sub11 .cnt1 .area .wrap .tit h2 br {
		display: none;
	}

	#sub12_1 .cnt1 .area .car_pg .range_pu {
		width: 90%;
	}

	#sub12_2 .cnt1 .field_pu {
		width: 90%;
	}
	#sub12_2 .cnt1 .field_pu .tit,
	#sub12_2 .cnt1 .field_pu .form_cont,
	#sub12_2 .cnt1 .field_pu .des_box,
	#sub12_2 .cnt1 .field_pu .field_list {
		width: 80%;
	}

	#sub12_3 .cnt1 .area .car0_pg .tit,
	#sub12_3 .cnt1 .area .car0_pg > p,
	#sub12_3 .cnt1 .area .car0_pg .wrap {
		width: 80%;
	}
	#sub12_3 .cnt1 .area .car0_pg .wrap ul li .form_cont,
	#sub12_3 .cnt1 .area .car0_pg .wrap ul li .input_box {
		width: 100%;
	}

	#sub12_3 .cnt1 .car1_pu {
		width: 90%;
	}
	#sub12_3 .cnt1 .car1_pu .tit,
	#sub12_3 .cnt1 .car1_pu .form_cont,
	#sub12_3 .cnt1 .car1_pu .drp p {
		width: 80%;
	}

	#sub12_4 .cnt1 .area .edu0_pg .tit,
	#sub12_4 .cnt1 .area .edu0_pg > p,
	#sub12_4 .cnt1 .area .edu0_pg .wrap {
		width: 80%;
	}
	#sub12_4 .cnt1 .area .edu0_pg .wrap ul li .form_cont,
	#sub12_4 .cnt1 .area .edu0_pg .wrap ul li .input_box {
		width: 100%;
	}

	#sub12_4 .cnt1 .area .edu1_pu {
		width: 90%;
	}
	#sub12_4 .cnt1 .area .edu1_pu .tit,
	#sub12_4 .cnt1 .area .edu1_pu .form_cont,
	#sub12_4 .cnt1 .area .edu1_pu .drp p {
		width: 80%;
	}

	#sub12_4 .cnt1 .area .edu2_pu {
		width: 90%;
	}
	#sub12_4 .cnt1 .area .edu2_pu .tit,
	#sub12_4 .cnt1 .area .edu2_pu .form_cont,
	#sub12_4 .cnt1 .area .edu2_pu .drp p {
		width: 80%;
	}

	#sub12_6 .cnt1 .area {
		width: 100%;
	}
	#sub12_6 .cnt1 .area .txt .join_info1,
	#sub12_6 .cnt1 .area .txt .join_info2 {
		width: calc(100% - 48px);
	}
	#sub12_6 .cnt1 .area .txt .join_info1 > h3,
	#sub12_6 .cnt1 .area .txt .join_info2 > h3 {
		font-size: 16px;
	}
	#sub12_6 .cnt1 .area .txt .bar {
		height: 8px;
		background: #f4f4f4;
		margin: 24px 0;
	}

	#sub12_6 .cnt1 .area .intd_pu {
		width: 90%;
	}
	#sub12_6 .cnt1 .area .intd_pu .tit,
	#sub12_6 .cnt1 .area .intd_pu .form_cont,
	#sub12_6 .cnt1 .area .intd_pu .w_num {
		width: 80%;
	}
	#sub12_6 .cnt1 .area .intd_pu .form_cont label {
		width: 90%;
	}

	#sub12_6 .cnt1 .area .intd2_pu {
		width: 90%;
	}
	#sub12_6 .cnt1 .area .intd2_pu .tit,
	#sub12_6 .cnt1 .area .intd2_pu .txt {
		width: 80%;
	}
	#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li ul.depth2 li {
		width: 49%;
		margin-left: 2%;
	}

	#sub12_6 .cnt1 .area .car1_pu {
		width: 90%;
	}
	#sub12_6 .cnt1 .area .car1_pu .tit,
	#sub12_6 .cnt1 .area .car1_pu .form_cont,
	#sub12_6 .cnt1 .area .car1_pu .drp p {
		width: 80%;
	}

	#sub12_6 .cnt1 .area .indus_pu {
		width: 90%;
	}
	#sub12_6 .cnt1 .area .indus_pu .tit,
	#sub12_6 .cnt1 .area .indus_pu .form_cont,
	#sub12_6 .cnt1 .area .indus_pu .drp p {
		width: 80%;
	}

	#sub12_6 .cnt1 .area .bh_pu {
		width: 90%;
	}
	#sub12_6 .cnt1 .area .bh_pu .tit,
	#sub12_6 .cnt1 .area .bh_pu .txt {
		width: 80%;
	}
	#sub12_6 .cnt1 .area .bh_pu .txt ul.depth1 > li ul.depth2 li {
		width: 49%;
		margin-left: 2%;
	}

	#sub12_6 .cnt1 .area .bh2_pu {
		width: 90%;
	}
	#sub12_6 .cnt1 .area .bh2_pu .tit,
	#sub12_6 .cnt1 .area .bh2_pu .txt {
		width: 80%;
	}
	#sub12_6 .cnt1 .area .bh2_pu .txt ul.depth1 > li ul.depth2 li {
		width: 49%;
		margin-left: 2%;
	}

	#sub12_6 .cnt1 .area .edu1_pu {
		width: 90%;
	}
	#sub12_6 .cnt1 .area .edu1_pu .tit,
	#sub12_6 .cnt1 .area .edu1_pu .form_cont,
	#sub12_6 .cnt1 .area .edu1_pu .drp p {
		width: 80%;
	}

	#sub12_6 .cnt1 .area .skill_pu {
		width: 90%;
	}
	#sub12_6 .cnt1 .area .skill_pu .tit,
	#sub12_6 .cnt1 .area .skill_pu .form_cont,
	#sub12_6 .cnt1 .area .skill_pu .des_box,
	#sub12_6 .cnt1 .area .skill_pu .skill_choice {
		width: 80%;
	}

	#sub12_6 .cnt1 .area .awd_pu {
		width: 90%;
	}
	#sub12_6 .cnt1 .area .awd_pu .tit,
	#sub12_6 .cnt1 .area .awd_pu .txt {
		width: 80%;
	}

	#sub12_6 .cnt1 .area .tsis_pu {
		width: 90%;
		height: 80%;
	}
	#sub12_6 .cnt1 .area .tsis_pu .tit,
	#sub12_6 .cnt1 .area .tsis_pu .txt {
		width: 80%;
	}

	#sub12_6 .cnt1 .area .pat_pu {
		width: 90%;
	}
	#sub12_6 .cnt1 .area .pat_pu .tit,
	#sub12_6 .cnt1 .area .pat_pu .txt {
		width: 80%;
	}
	#sub12_6 .cnt1 .area .pat_pu .txt ul.depth1 > li ul.depth2 li {
		width: 49%;
		margin-left: 2%;
	}

	#sub12_6 .cnt1 .area .fl_pu {
		width: 90%;
	}
	#sub12_6 .cnt1 .area .fl_pu .tit,
	#sub12_6 .cnt1 .area .fl_pu .txt {
		width: 80%;
	}

	#sub12_6 .cnt1 .area .fl2_pu {
		width: 90%;
	}
	#sub12_6 .cnt1 .area .fl2_pu .tit,
	#sub12_6 .cnt1 .area .fl2_pu .txt {
		width: 80%;
	}

	#sub12_6 .cnt1 .area .link_pu {
		width: 90%;
	}
	#sub12_6 .cnt1 .area .link_pu .tit,
	#sub12_6 .cnt1 .area .link_pu .txt {
		width: 80%;
	}

	#sub12_6 .cnt1 .area .area_pu {
		width: 90%;
	}
	#sub12_6 .cnt1 .area .area_pu .tit,
	#sub12_6 .cnt1 .area .area_pu .form_cont,
	#sub12_6 .cnt1 .area .area_pu .area_list {
		width: 80%;
	}
	#sub12_6 .cnt1 .area .area_pu .area_list ul li p {
		padding-left: 0;
	}

	#sub13 .cnt1 {
		padding-bottom: 0;
	}
	#sub13 .cnt1 .area .txt {
		width: 100%;
		margin-top: 17px;
	}
	#sub13 .cnt1 .area .txt > ul > li {
		width: 100%;
		margin-left: 0;
		border-bottom: 8px solid #f4f4f4;
		padding-bottom: 30px;
		margin-bottom: 24px;
	}
	#sub13 .cnt1 .area .txt > ul > li:nth-child(3n + 1) {
		margin-left: 0;
	}
	#sub13 .cnt1 .area .txt > ul > li .wish_btn {
		right: 34px;
	}
	#sub13 .cnt1 .area .txt > ul > li > a {
		margin: 0 24px;
	}
	#sub13 .cnt1 .area .txt ul li a > h4 {
		font-size: 20px;
	}
	#sub13 .cnt1 .area .txt ul li a .p_wrap p {
		font-size: 14px;
	}
	#sub13 .cnt1 .area .txt ul li a .info li h5,
	#sub13 .cnt1 .area .txt ul li a .info li p {
		font-size: 15px;
	}
	#sub13 .cnt1 .area .txt ul li a .img .end_blk .cc {
		margin-top: -100px;
		margin-left: -100px;
		width: 200px;
		height: 200px;
		-webkit-border-radius: 100px;
		-moz-border-radius: 100px;
		border-radius: 100px;
	}
	#sub13 .cnt1 .area .txt ul li a .img .end_blk .cc p {
		font-size: 20px;
		line-height: 200px;
	}

	#sub14 .cnt1 {
		padding-bottom: 0;
	}
	#sub14 .cnt1 .area .status {
		padding-top: 17px;
		height: 75px;
	}
	#sub14 .cnt1 .area .status ul {
		width: 210px;
	}
	#sub14 .cnt1 .area .status ul li h4 {
		font-size: 10px;
		height: 20px;
		line-height: 20px;
	}
	#sub14 .cnt1 .area .status ul li.dash_line span {
		margin-top: 10px;
	}
	#sub14 .cnt1 .area .txt {
		width: 100%;
		margin-top: 17px;
	}
	#sub14 .cnt1 .area .txt > ul > li {
		width: 100%;
		margin-left: 0;
		border-bottom: 8px solid #f4f4f4;
		padding-bottom: 30px;
		margin-bottom: 24px;
	}
	#sub14 .cnt1 .area .txt > ul > li:nth-child(3n + 1) {
		margin-left: 0;
	}
	#sub14 .cnt1 .area .txt > ul > li > a {
		margin: 0 24px;
	}

	#sub15 .area {
		width: 100%;
	}
	#sub15 .cnt1 .area .profile .pf_comp h3 {
		font-size: 15px;
	}
	#sub15 .cnt1 .area .profile .pf_comp ul li {
		width: 44px;
	}
	#sub15 .cnt1 .area .profile .pf_comp ul li p {
		font-size: 10px;
	}
	#sub15 .cnt1 .area .bar {
		height: 8px;
		background: #f4f4f4;
		margin: 24px 0;
	}
	#sub15 .cnt1 .area .pf_info1,
	#sub15 .cnt1 .area .pf_info2,
	#sub15 .cnt1 .area .pf_info3,
	#sub15 .cnt1 .area .pf_info4,
	#sub15 .cnt1 .area .pf_info6 {
		width: calc(100% - 48px);
	}
	#sub15 .cnt1 .area .pf_info5 .box > div {
		float: none;
		width: 100%;
	}
	#sub15 .cnt1 .area .pf_info5 .box .box_1 {
		margin-right: 0;
		padding-bottom: 24px;
		margin-bottom: 24px;
		border-bottom: 1px solid #e8e8ea;
	}
	#sub15 .cnt1 .area .pf_info5 .tit,
	#sub15 .cnt1 .area .pf_info5 .box > div ul,
	#sub15 .cnt1 .area .pf_info5 .box > div .letter {
		width: calc(100% - 48px);
		margin: 0 auto;
	}
	#sub15 .cnt1 .area .pf_info5 .tit {
		margin-bottom: 24px;
	}
	#sub15 .cnt1 .area .pf_info5 .box > div .letter {
		margin-top: 10px;
	}
	#sub15 .cnt1 .area .pf_info1 > h3,
	#sub15 .cnt1 .area .pf_info2 > h3,
	#sub15 .cnt1 .area .pf_info3 > h3,
	#sub15 .cnt1 .area .pf_info4 > h3,
	#sub15 .cnt1 .area .pf_info5 .tit h3,
	#sub15 .cnt1 .area .pf_info6 > h3 {
		font-size: 16px;
	}

	#sub15 .cnt1 .rep_pu {
		width: 90%;
	}
	#sub15 .cnt1 .rep_pu h4,
	#sub15 .cnt1 .rep_pu .form_cont {
		width: 90%;
	}

	#sub15 .cnt1 .kd_pu {
		width: 90%;
	}

	#sub15 .cnt1 .car_pu {
		width: 90%;
	}
	#sub15 .cnt1 .range_pu {
		width: 90%;
	}
	#sub15 .cnt1 .field0_pu {
		width: 90%;
	}
	#sub15 .cnt1 .field_pu {
		width: 90%;
	}
	#sub15 .cnt1 .field_pu .tit,
	#sub15 .cnt1 .field_pu .form_cont,
	#sub15 .cnt1 .field_pu .des_box,
	#sub15 .cnt1 .field_pu .field_list {
		width: 80%;
	}
	#sub15 .cnt1 .car0_pu {
		width: 90%;
	}
	#sub15 .cnt1 .car0_pu .tit,
	#sub15 .cnt1 .car0_pu > p,
	#sub15 .cnt1 .car0_pu .wrap {
		width: 80%;
	}
	#sub15 .cnt1 .car0_pu .wrap ul li .form_cont,
	#sub15 .cnt1 .car0_pu .wrap ul li .input_box {
		width: 100%;
	}
	#sub15 .cnt1 .car1_pu {
		width: 90%;
	}
	#sub15 .cnt1 .car1_pu .tit,
	#sub15 .cnt1 .car1_pu .form_cont,
	#sub15 .cnt1 .car1_pu .drp p {
		width: 80%;
	}
	#sub15 .cnt1 .edu0_pu {
		width: 90%;
	}
	#sub15 .cnt1 .edu0_pu .tit,
	#sub15 .cnt1 .edu0_pu > p,
	#sub15 .cnt1 .edu0_pu .wrap {
		width: 80%;
	}
	#sub15 .cnt1 .edu0_pu .wrap ul li .form_cont,
	#sub15 .cnt1 .edu0_pu .wrap ul li .input_box {
		width: 100%;
	}
	#sub15 .cnt1 .edu1_pu {
		width: 90%;
	}
	#sub15 .cnt1 .edu1_pu .tit,
	#sub15 .cnt1 .edu1_pu .form_cont,
	#sub15 .cnt1 .edu1_pu .drp p {
		width: 80%;
	}
	#sub15 .cnt1 .edu2_pu {
		width: 90%;
	}
	#sub15 .cnt1 .edu2_pu .tit,
	#sub15 .cnt1 .edu2_pu .form_cont,
	#sub15 .cnt1 .edu2_pu .drp p {
		width: 80%;
	}
	#sub15 .cnt1 .intd_pu {
		width: 90%;
	}
	#sub15 .cnt1 .intd_pu .tit,
	#sub15 .cnt1 .intd_pu .form_cont,
	#sub15 .cnt1 .intd_pu .w_num {
		width: 80%;
	}
	#sub15 .cnt1 .intd_pu .form_cont label {
		width: 90%;
	}
	#sub15 .cnt1 .intd2_pu {
		width: 90%;
	}
	#sub15 .cnt1 .intd2_pu .tit,
	#sub15 .cnt1 .intd2_pu .txt {
		width: 80%;
	}
	#sub15 .cnt1 .intd2_pu .txt ul.depth1 > li ul.depth2 li {
		width: 49%;
		margin-left: 2%;
	}
	#sub15 .cnt1 .indus_pu {
		width: 90%;
	}
	#sub15 .cnt1 .indus_pu .tit,
	#sub15 .cnt1 .indus_pu .form_cont,
	#sub15 .cnt1 .indus_pu .drp p {
		width: 80%;
	}
	#sub15 .cnt1 .bh_pu {
		width: 90%;
	}
	#sub15 .cnt1 .bh_pu .tit,
	#sub15 .cnt1 .bh_pu .txt {
		width: 80%;
	}
	#sub15 .cnt1 .bh_pu .txt ul.depth1 > li ul.depth2 li {
		width: 49%;
		margin-left: 2%;
	}
	#sub15 .cnt1 .bh2_pu {
		width: 90%;
	}
	#sub15 .cnt1 .bh2_pu .tit,
	#sub15 .cnt1 .bh2_pu .txt {
		width: 80%;
	}
	#sub15 .cnt1 .bh2_pu .txt ul.depth1 > li ul.depth2 li {
		width: 49%;
		margin-left: 2%;
	}
	#sub15 .cnt1 .skill_pu {
		width: 90%;
	}
	#sub15 .cnt1 .skill_pu .tit,
	#sub15 .cnt1 .skill_pu .form_cont,
	#sub15 .cnt1 .skill_pu .des_box,
	#sub15 .cnt1 .skill_pu .skill_choice {
		width: 80%;
	}
	#sub15 .cnt1 .awd_pu {
		width: 90%;
	}
	#sub15 .cnt1 .awd_pu .tit,
	#sub15 .cnt1 .awd_pu .txt {
		width: 80%;
	}
	#sub15 .cnt1 .tsis_pu {
		width: 90%;
		height: 80%;
	}
	#sub15 .cnt1 .tsis_pu .tit,
	#sub15 .cnt1 .tsis_pu .txt {
		width: 80%;
	}
	#sub15 .cnt1 .pat_pu {
		width: 90%;
	}
	#sub15 .cnt1 .pat_pu .tit,
	#sub15 .cnt1 .pat_pu .txt {
		width: 80%;
	}
	#sub15 .cnt1 .pat_pu .txt ul.depth1 > li ul.depth2 li {
		width: 49%;
		margin-left: 2%;
	}
	#sub15 .cnt1 .fl_pu {
		width: 90%;
	}
	#sub15 .cnt1 .fl_pu .tit,
	#sub15 .cnt1 .fl_pu .txt {
		width: 80%;
	}
	#sub15 .cnt1 .fl2_pu {
		width: 90%;
	}
	#sub15 .cnt1 .fl2_pu .tit,
	#sub15 .cnt1 .fl2_pu .txt {
		width: 80%;
	}
	#sub15 .cnt1 .link_pu {
		width: 90%;
	}
	#sub15 .cnt1 .link_pu .tit,
	#sub15 .cnt1 .link_pu .txt {
		width: 80%;
	}
	#sub15 .cnt1 .area_pu {
		width: 90%;
	}
	#sub15 .cnt1 .area_pu .tit,
	#sub15 .cnt1 .area_pu .form_cont,
	#sub15 .cnt1 .area_pu .area_list {
		width: 80%;
	}
	#sub15 .cnt1 .area_pu .area_list ul li p {
		padding-left: 0;
	}

	#sub16 .cnt1 {
		padding-bottom: 0;
	}
	#sub16 .cnt1 .area .txt {
		width: 100%;
		margin-top: 17px;
	}
	#sub16 .cnt1 .area .txt > ul > li {
		width: 100%;
		margin-left: 0;
		border-bottom: 8px solid #f4f4f4;
		padding-bottom: 30px;
		margin-bottom: 24px;
	}
	#sub16 .cnt1 .area .txt > ul > li:nth-child(3n + 1) {
		margin-left: 0;
	}
	#sub16 .cnt1 .area .txt > ul > li .wish_btn {
		right: 34px;
	}
	#sub16 .cnt1 .area .txt > ul > li > a {
		margin: 0 24px;
	}
	#sub16 .cnt1 .area .txt ul li a > h4 {
		font-size: 20px;
	}
	#sub16 .cnt1 .area .txt ul li a .p_wrap p {
		font-size: 14px;
	}
	#sub16 .cnt1 .area .txt ul li a .info li h5,
	#sub16 .cnt1 .area .txt ul li a .info li p {
		font-size: 15px;
	}
	#sub16 .cnt1 .area .txt ul li a .bar {
		margin: 20px 0;
	}

	#sub17 .cnt1 {
		padding-bottom: 0;
	}
	#sub17 .cnt1 .area .txt {
		width: 100%;
		margin-top: 17px;
	}
	#sub17 .cnt1 .area .txt > ul > li {
		width: 100%;
		margin-left: 0;
		border-bottom: 8px solid #f4f4f4;
		padding-bottom: 30px;
		margin-bottom: 24px;
	}
	#sub17 .cnt1 .area .txt > ul > li:nth-child(3n + 1) {
		margin-left: 0;
	}
	#sub17 .cnt1 .area .txt > ul > li .wish_btn {
		right: 34px;
	}
	#sub17 .cnt1 .area .txt > ul > li > a {
		margin: 0 24px;
	}
	#sub17 .cnt1 .area .txt ul li a > h4 {
		font-size: 20px;
	}
	#sub17 .cnt1 .area .txt ul li a .p_wrap p {
		font-size: 14px;
	}
	#sub17 .cnt1 .area .txt ul li a .info li h5,
	#sub17 .cnt1 .area .txt ul li a .info li p {
		font-size: 15px;
	}
	#sub17 .cnt1 .area .txt ul li a .bar {
		margin: 20px 0;
	}

	#sub18 .cnt1 {
		padding-bottom: 0;
	}
	#sub18 .cnt1 .area .filter {
		margin-top: 13px;
	}
	#sub18 .cnt1 .area .txt {
		width: 100%;
		margin-top: 17px;
	}
	#sub18 .cnt1 .area .txt > ul > li {
		width: 100%;
		margin-left: 0;
		border-bottom: 8px solid #f4f4f4;
		padding-bottom: 30px;
		margin-bottom: 24px;
	}
	#sub18 .cnt1 .area .txt > ul > li:nth-child(3n + 1) {
		margin-left: 0;
	}
	#sub18 .cnt1 .area .txt > ul > li > a {
		margin: 0 24px;
	}
	#sub18 .cnt1 .area .txt ul li a > h4 {
		font-size: 20px;
	}
	#sub18 .cnt1 .area .txt ul li a .p_wrap p {
		font-size: 14px;
	}
	#sub18 .cnt1 .area .txt ul li a .info li h5,
	#sub18 .cnt1 .area .txt ul li a .info li p {
		font-size: 15px;
	}
	#sub18 .cnt1 .area .txt ul li a .bar {
		margin: 20px 0;
	}

	#sub19 .cnt1 .area {
		width: 100%;
	}
	#sub19 .cnt1 .area .bar {
		height: 8px;
		background: #f4f4f4;
		margin: 24px 0;
	}
	#sub19 .cnt1 .area > div {
		width: calc(100% - 48px);
	}
	#sub19 .cnt1 .area .pf_info4 {
		width: 100%;
	}
	#sub19 .cnt1 .area .pf_info4 .box > div {
		float: none;
		width: 100%;
	}
	#sub19 .cnt1 .area .pf_info4 .box .box_1 {
		margin-right: 0;
		padding-bottom: 24px;
		margin-bottom: 24px;
		border-bottom: 1px solid #e8e8ea;
	}
	#sub19 .cnt1 .area .pf_info4 .tit,
	#sub19 .cnt1 .area .pf_info4 .box > div ul,
	#sub19 .cnt1 .area .pf_info4 .box > div .letter {
		width: calc(100% - 48px);
		margin: 0 auto;
	}
	#sub19 .cnt1 .area .pf_info4 .tit {
		margin-bottom: 24px;
	}
	#sub19 .cnt1 .area .pf_info4 .box > div .letter {
		margin-top: 10px;
	}
	#sub19 .cnt1 .area .pf_info4 .tit h3 {
		font-size: 16px;
	}

	#subMy .cnt1 .area {
		width: 90%;
	}
	#subMy .cnt1 .area .profile2 .pf_process ul li h3 {
		font-size: 11px;
	}
	#subMy .cnt1 .area .profile2 .pf_date {
		margin-top: 10px;
	}
	#subMy .cnt1 .area .profile2 .pf_date p {
		font-size: 10px;
		padding-top: 1px;
	}
}

@media all and (max-width: 450px) {
	.floating_btn {
		bottom: 50px;
	}
	#main .cnt2 {
		padding-bottom: 170px;
	}
	#main .cnt2 .area .tit h3 {
		font-size: 32px;
	}
	#main .cnt2 .area .tit p span {
		display: block;
		font-size: 14px;
	}
	#main .cnt2 .area .txt {
		padding-top: 40px;
	}
	#main .cnt10 .area .c10_2 .txt ul li.svc1 .illu img {
		width: 170px;
	}

	#main2 .cnt2 .area .txt ul {
		width: 100%;
	}
	#main2 .cnt2 .area .txt ul li .icon {
		height: 50px;
	}
	#main2 .cnt2 .area .txt ul li p {
		font-size: 17px;
	}
	#main2 .cnt3 .area .txt .left {
		margin-top: 80px;
	}
	#main2 .cnt3 .area .txt .right {
		margin: 30px auto 0;
	}
	#main2 .cnt3 .area .txt .right ul li h5 {
		font-size: 15px;
	}
	#main2 .cnt4 .area .left {
		margin-top: 30px;
	}
	#main2 .cnt4 .area .left ul li h5 {
		font-size: 13px;
	}
	#main2 .cnt6 .area .top .right ul {
		width: auto;
	}
	#main2 .cnt6 .area .top .right ul li {
		float: none;
	}
	#main2 .cnt6 .area .top .right ul li.box {
		margin: 0 auto;
		width: 200px;
		height: 140px;
	}
	#main2 .cnt6 .area .top .right ul li.box h4 {
		font-size: 20px;
		padding-top: 40px;
	}
	#main2 .cnt6 .area .top .right ul li.box:last-child h4 {
		font-size: 24px;
		padding-top: 35px;
	}
	#main2 .cnt6 .area .top .right ul li p {
		font-size: 30px;
		line-height: 1;
		padding: 12px 0 10px;
		text-align: center;
	}
	#main2 .cnt7 .area .deco {
		right: -85px;
	}
	#main2 .cnt7 .area .deco img {
		width: 400px;
	}

	#sub1 .cnt1 .pvc_pu .close_btn {
		display: block;
	}

	#sub3 .sup_pu {
		width: 90%;
		height: auto;
		padding-bottom: 32px;
	}
	#sub3 .sup_pu h4 {
		padding-left: 10%;
		padding-right: 10%;
		word-break: keep-all;
	}

	#sub4 .sup_pu {
		width: 90%;
		height: auto;
		padding-bottom: 32px;
	}
	#sub4 .sup_pu h4 {
		padding-left: 10%;
		padding-right: 10%;
		word-break: keep-all;
	}

	#sub5 .cnt1 .area .wrap .bd_paging ul li {
		width: 28px;
		height: 28px;
	}
	#sub5 .cnt1 .area .wrap .bd_paging ul li a {
		line-height: 28px;
		font-size: 12px;
	}

	#sub6 .cnt1 .area {
		width: 100%;
	}
	#sub6 .cnt1 .area .wrap {
		border: 0;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		margin-top: 0;
		padding-top: 61px;
	}
	#sub6 .cnt1 .area .wrap ul {
		margin-top: 36px;
	}
	#sub6 .cnt1 .area .wrap .tit,
	#sub6 .cnt1 .area .wrap ul li {
		width: calc(100% - 72px);
	}

	#sub7 .cnt1 .area {
		width: 100%;
	}
	#sub7 .cnt1 .area .wrap {
		border: 0;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		margin-top: 0;
		padding-top: 61px;
	}
	#sub7 .cnt1 .area .wrap .tit,
	#sub7 .cnt1 .area .wrap ul,
	#sub7 .cnt1 .area .wrap .submit_btn {
		width: calc(100% - 72px);
	}

	#sub8 .cnt1 .area {
		width: 100%;
	}
	#sub8 .cnt1 .area .wrap {
		border: 0;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		margin-top: 0;
		padding-top: 61px;
	}
	#sub8 .cnt1 .area .wrap .tit,
	#sub8 .cnt1 .area .wrap .form_cont {
		width: calc(100% - 72px);
	}

	.cnfm_pu {
		width: 280px;
	}
	.cnfm_pu strong {
		font-size: 16px;
		padding: 36px 0 32px;
	}
	.cnfm_ok_pu {
		width: 90%;
	}

	#sub9 .cnt1 .area {
		width: 100%;
	}
	#sub9 .cnt1 .area .wrap {
		border: 0;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		margin-top: 0;
		padding-top: 61px;
	}
	#sub9 .cnt1 .area .wrap .tit,
	#sub9 .cnt1 .area .wrap .form_cont {
		width: calc(100% - 72px);
	}

	#sub10 .cnt1 .area {
		width: 100%;
	}
	#sub10 .cnt1 .area .wrap {
		border: 0;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		margin-top: 0;
		padding-top: 61px;
	}
	#sub10 .cnt1 .area .wrap .tit,
	#sub10 .cnt1 .area .wrap ul li.s10_li1,
	#sub10 .cnt1 .area .wrap ul li.s10_li2 {
		width: calc(100% - 72px);
	}
	#sub10 .cnt1 .area .wrap ul li.s10_li1 .form_cont {
		width: calc(100% - 84px);
	}

	#sub11 .cnt1 .area {
		width: 100%;
	}
	#sub11 .cnt1 .area .wrap {
		border: 0;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		margin-top: 0;
		padding-top: 61px;
	}
	#sub11 .cnt1 .area .wrap .tit,
	#sub11 .cnt1 .area .wrap .form_cont,
	#sub11 .cnt1 .area .wrap .caution {
		width: calc(100% - 72px);
	}

	#sub11_1 .cnt1 .area {
		width: 100%;
	}
	#sub11_1 .cnt1 .area .wrap {
		border: 0;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		margin-top: 0;
		padding-top: 61px;
	}

	#sub12 .cnt1 .area {
		width: 100%;
	}
	#sub12 .cnt1 .area .wrap {
		border: 0;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		margin-top: 0;
		margin-bottom: 0;
		padding-bottom: 0;
	}
	#sub12 .cnt1 .area .wrap .tit {
		width: calc(100% - 72px);
		margin: 0 auto;
	}
	#sub12 .cnt1 .area .wrap .tit h2 {
		text-align: left;
	}
	#sub12 .cnt1 .area .wrap .tit h2 span {
		display: block;
		padding-top: 25px;
	}
	#sub12 .cnt1 .area .wrap .box p {
		line-height: 1.5;
	}
	#sub12 .cnt1 .area .wrap .box p span {
		display: block;
	}
	#sub12 .cnt1 .area .wrap .box .cau {
		font-size: 12px;
	}

	#sub12_1 .cnt1 .area .car_pg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		border: 0;
		padding: 0;
		margin: 0;
		z-index: 11;
		background: #fff;
	}
	#sub12_1 .cnt1 .area .car_pg .tit {
		display: none;
	}
	#sub12_1 .cnt1 .area .car_pg > p,
	#sub12_1 .cnt1 .area .car_pg .career_wrap {
		width: calc(100% - 48px);
	}
	#sub12_1 .cnt1 .area .car_pg > p {
		padding-top: 90px;
	}
	#sub12_1 .cnt1 .area .car_pg .save_btn {
		position: absolute;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub12_2 .cnt1 .field0_pg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		border: 0;
		padding: 0;
		margin: 0;
		z-index: 9;
		background: #fff;
	}
	#sub12_2 .cnt1 .field0_pg .tit {
		display: none;
	}
	#sub12_2 .cnt1 .field0_pg > p {
		padding-top: 90px;
	}
	#sub12_2 .cnt1 .field0_pg .tit,
	#sub12_2 .cnt1 .field0_pg > p,
	#sub12_2 .cnt1 .field0_pg .form_cont,
	#sub12_2 .cnt1 .field0_pg .input_box {
		width: calc(100% - 48px);
	}
	#sub12_2 .cnt1 .field0_pg .save_btn {
		position: absolute;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}
	#sub12_2 .cnt1 .field_pu {
		top: auto;
		bottom: -80%;
		left: 0;
		width: 100%;
		height: 90%;
		-webkit-border-radius: 32px 32px 0 0;
		-moz-border-radius: 32px 32px 0 0;
		border-radius: 32px 32px 0 0;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		padding-bottom: 0;
		overflow: hidden;
		max-height: 90% !important;
	}
	#sub12_2 .cnt1 .field_pu .close_btn {
		display: none;
	}
	#sub12_2 .cnt1 .field_pu .pu_nav {
		border-bottom: 0;
	}
	#sub12_2 .cnt1 .field_pu .tit {
		display: none;
	}
	#sub12_2 .cnt1 .field_pu .form_cont {
		overflow: hidden;
		width: 100%;
		margin-top: 0;
	}
	#sub12_2 .cnt1 .field_pu .form_cont input {
		width: calc(100% - 78px);
		margin-left: 24px;
	}
	#sub12_2 .cnt1 .field_pu .form_cont label {
		left: 24px;
	}
	#sub12_2 .cnt1 .field_pu .form_cont .delete_btn {
		right: 24px;
	}
	#sub12_2 .cnt1 .field_pu .field_list {
		width: 100%;
		height: calc(100% - 149px);
		margin-top: 10px;
	}
	#sub12_2 .cnt1 .field_pu .des_box {
		width: 100%;
	}
	#sub12_2 .cnt1 .field_pu .des_box ul li p {
		line-height: 16px;
	}
	#sub12_2 .cnt1 .field_pu .save_btn2 {
		position: absolute;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub12_3 .cnt1 .area .car0_pg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		border: 0;
		padding: 0;
		margin: 0;
		background: #fff;
	}
	#sub12_3 .cnt1 .area .car0_pg .tit {
		display: none;
	}
	#sub12_3 .cnt1 .area .car0_pg > p {
		width: auto;
		font-size: 14px;
		font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
		color: #999;
		line-height: 24px;
		padding: 22px 24px 0 24px;
	}
	#sub12_3 .cnt1 .area .car0_pg .wrap {
		margin-top: 40px;
		width: calc(100% - 48px);
		padding-bottom: 100px;
	}
	#sub12_3 .cnt1 .area .car0_pg .save_btn {
		position: fixed;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}
	#sub12_3 .cnt1 .car1_pu {
		top: auto;
		bottom: -90%;
		left: 0;
		width: 100%;
		height: 90%;
		-webkit-border-radius: 32px 32px 0 0;
		-moz-border-radius: 32px 32px 0 0;
		border-radius: 32px 32px 0 0;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		padding-bottom: 0;
		overflow: hidden;
		max-height: 90% !important;
	}
	#sub12_3 .cnt1 .car1_pu .close_btn {
		display: none;
	}
	#sub12_3 .cnt1 .car1_pu .pu_nav {
		border-bottom: 0;
	}
	#sub12_3 .cnt1 .car1_pu .tit {
		display: none;
	}
	#sub12_3 .cnt1 .car1_pu .form_cont {
		overflow: hidden;
		width: 100%;
		margin-top: 0;
	}
	#sub12_3 .cnt1 .car1_pu .form_cont input {
		width: calc(100% - 78px);
		margin-left: 24px;
	}
	#sub12_3 .cnt1 .car1_pu .form_cont label {
		left: 24px;
	}
	#sub12_3 .cnt1 .car1_pu .form_cont .delete_btn {
		right: 24px;
	}
	#sub12_3 .cnt1 .car1_pu .drp p {
		width: calc(100% - 48px);
	}
	#sub12_3 .cnt1 .car1_pu .car1_list {
		width: 100%;
		margin-top: 0;
		height: calc(100% - 179px);
	}
	#sub12_3 .cnt1 .car1_pu .save_btn2 {
		position: absolute;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub12_4 .cnt1 .area .edu0_pg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		border: 0;
		padding: 0;
		margin: 0;
		z-index: 9;
		background: #fff;
	}
	#sub12_4 .cnt1 .area .edu0_pg .tit {
		display: none;
	}
	#sub12_4 .cnt1 .area .edu0_pg > p {
		width: auto;
		font-size: 14px;
		font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
		color: #999;
		line-height: 24px;
		padding: 22px 24px 0 24px;
	}
	#sub12_4 .cnt1 .area .edu0_pg .wrap {
		margin-top: 40px;
		width: calc(100% - 48px);
		padding-bottom: 100px;
	}
	#sub12_4 .cnt1 .area .edu0_pg .save_btn {
		position: fixed;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}
	#sub12_4 .cnt1 .area .edu1_pu {
		top: auto;
		bottom: -90%;
		left: 0;
		width: 100%;
		height: 90%;
		-webkit-border-radius: 32px 32px 0 0;
		-moz-border-radius: 32px 32px 0 0;
		border-radius: 32px 32px 0 0;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		padding-bottom: 0;
		overflow: hidden;
		max-height: 90% !important;
	}
	#sub12_4 .cnt1 .area .edu1_pu .close_btn {
		display: none;
	}
	#sub12_4 .cnt1 .area .edu1_pu .pu_nav {
		border-bottom: 0;
	}
	#sub12_4 .cnt1 .area .edu1_pu .tit {
		display: none;
	}
	#sub12_4 .cnt1 .area .edu1_pu .form_cont {
		overflow: hidden;
		width: 100%;
		margin-top: 0;
	}
	#sub12_4 .cnt1 .area .edu1_pu .form_cont input {
		width: calc(100% - 78px);
		margin-left: 24px;
	}
	#sub12_4 .cnt1 .area .edu1_pu .form_cont label {
		left: 24px;
	}
	#sub12_4 .cnt1 .area .edu1_pu .form_cont .delete_btn {
		right: 24px;
	}
	#sub12_4 .cnt1 .area .edu1_pu .drp p {
		width: calc(100% - 48px);
	}
	#sub12_4 .cnt1 .area .edu1_pu .edu1_list {
		width: 100%;
		margin-top: 0;
		height: calc(100% - 179px);
	}
	#sub12_4 .cnt1 .area .edu1_pu .save_btn2 {
		position: absolute;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}
	#sub12_4 .cnt1 .degree_pu {
		width: 90%;
	}
	#sub12_4 .cnt1 .degree_pu .txt {
		width: 80%;
	}
	#sub12_4 .cnt1 .area .edu2_pu {
		top: auto;
		bottom: -90%;
		left: 0;
		width: 100%;
		height: 90%;
		-webkit-border-radius: 32px 32px 0 0;
		-moz-border-radius: 32px 32px 0 0;
		border-radius: 32px 32px 0 0;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		padding-bottom: 0;
		overflow: hidden;
		max-height: 90% !important;
	}
	#sub12_4 .cnt1 .area .edu2_pu .close_btn {
		display: none;
	}
	#sub12_4 .cnt1 .area .edu2_pu .pu_nav {
		border-bottom: 0;
	}
	#sub12_4 .cnt1 .area .edu2_pu .tit {
		display: none;
	}
	#sub12_4 .cnt1 .area .edu2_pu .form_cont {
		overflow: hidden;
		width: 100%;
		margin-top: 0;
	}
	#sub12_4 .cnt1 .area .edu2_pu .form_cont input {
		width: calc(100% - 78px);
		margin-left: 24px;
	}
	#sub12_4 .cnt1 .area .edu2_pu .form_cont label {
		left: 24px;
	}
	#sub12_4 .cnt1 .area .edu2_pu .form_cont .delete_btn {
		right: 24px;
	}
	#sub12_4 .cnt1 .area .edu2_pu .drp p {
		width: calc(100% - 48px);
	}
	#sub12_4 .cnt1 .area .edu2_pu .edu2_list {
		width: 100%;
		margin-top: 0;
		height: calc(100% - 179px);
	}
	#sub12_4 .cnt1 .area .edu2_pu .save_btn2 {
		position: absolute;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub12_5 .cnt1 .good_pg {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		border: 0;
		padding: 0;
		margin: 0;
		z-index: 11;
		background: #fff;
	}
	#sub12_5 .cnt1 .good_pg .pg_wrap {
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		width: 100%;
	}

	#sub12_5 .cnt1 .good_pg .pg_wrap .ing_btn {
		margin-top: 32px;
	}

	#sub12_6 .cnt1 {
		margin-bottom: 0;
	}
	#sub12_6 .cnt1 .area .tit h4 {
		font-size: 16px;
		line-height: 22px;
	}
	#sub12_6 .cnt1 .area .txt > .submit_btn {
		width: calc(100% - 72px);
	}
	#sub12_6 .cnt1 .area .intd_pu {
		top: auto;
		bottom: -90%;
		left: 0;
		width: 100%;
		height: 90%;
		-webkit-border-radius: 32px 32px 0 0;
		-moz-border-radius: 32px 32px 0 0;
		border-radius: 32px 32px 0 0;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		padding-bottom: 0;
		overflow: hidden;
		padding-top: 0;
	}
	#sub12_6 .cnt1 .area .intd_pu .close_btn {
		display: none;
	}
	#sub12_6 .cnt1 .area .intd_pu .pu_nav {
		border-bottom: 0;
	}
	#sub12_6 .cnt1 .area .intd_pu .tit {
		width: auto;
		margin: 0 24px 16px;
	}
	#sub12_6 .cnt1 .area .intd_pu .tit h4 {
		font-size: 18px;
	}
	#sub12_6 .cnt1 .area .intd_pu .form_cont {
		height: 60%;
	}
	#sub12_6 .cnt1 .area .intd_pu .form_cont,
	#sub12_6 .cnt1 .area .intd_pu .w_num {
		width: calc(100% - 48px);
	}
	#sub12_6 .cnt1 .area .intd_pu .save_btn {
		position: absolute;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub12_6 .cnt1 .area .intd2_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: none;
		transform: none;
		padding-bottom: 0;
		padding-top: 0;
	}
	#sub12_6 .cnt1 .area .intd2_pu .close_btn {
		display: none;
	}
	#sub12_6 .cnt1 .area .intd2_pu .tit {
		margin-top: 20px;
		width: calc(100% - 48px);
	}
	#sub12_6 .cnt1 .area .intd2_pu .tit h4 {
		display: none;
	}
	#sub12_6 .cnt1 .area .intd2_pu .txt {
		width: calc(100% - 48px);
		padding-bottom: 100px;
	}
	#sub12_6 .cnt1 .area .intd2_pu .save_btn {
		position: fixed;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub12_6 .cnt1 .area .car1_pu {
		top: auto;
		bottom: -90%;
		left: 0;
		width: 100%;
		height: 90%;
		-webkit-border-radius: 32px 32px 0 0;
		-moz-border-radius: 32px 32px 0 0;
		border-radius: 32px 32px 0 0;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		padding-bottom: 0;
		overflow: hidden;
		max-height: 90% !important;
	}
	#sub12_6 .cnt1 .area .car1_pu .close_btn {
		display: none;
	}
	#sub12_6 .cnt1 .area .car1_pu .pu_nav {
		border-bottom: 0;
	}
	#sub12_6 .cnt1 .area .car1_pu .tit {
		display: none;
	}
	#sub12_6 .cnt1 .area .car1_pu .form_cont {
		overflow: hidden;
		width: 100%;
		margin-top: 0;
	}
	#sub12_6 .cnt1 .area .car1_pu .form_cont input {
		width: calc(100% - 78px);
		margin-left: 24px;
	}
	#sub12_6 .cnt1 .area .car1_pu .form_cont label {
		left: 24px;
	}
	#sub12_6 .cnt1 .area .car1_pu .form_cont .delete_btn {
		right: 24px;
	}
	#sub12_6 .cnt1 .area .car1_pu .drp p {
		width: calc(100% - 48px);
	}
	#sub12_6 .cnt1 .area .car1_pu .car1_list {
		width: 100%;
		margin-top: 0;
		height: calc(100% - 179px);
	}
	#sub12_6 .cnt1 .area .car1_pu .save_btn2 {
		position: absolute;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub12_6 .cnt1 .area .emp_pu {
		width: 90%;
	}
	#sub12_6 .cnt1 .area .emp_pu .txt {
		width: 80%;
	}

	#sub12_6 .cnt1 .area .indus_pu {
		top: auto;
		bottom: -90%;
		left: 0;
		width: 100%;
		height: 90%;
		-webkit-border-radius: 32px 32px 0 0;
		-moz-border-radius: 32px 32px 0 0;
		border-radius: 32px 32px 0 0;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		padding-bottom: 0;
		overflow: hidden;
		max-height: 90% !important;
	}
	#sub12_6 .cnt1 .area .indus_pu .close_btn {
		display: none;
	}
	#sub12_6 .cnt1 .area .indus_pu .pu_nav {
		border-bottom: 0;
	}
	#sub12_6 .cnt1 .area .indus_pu .tit {
		display: none;
	}
	#sub12_6 .cnt1 .area .indus_pu .form_cont {
		overflow: hidden;
		width: 100%;
		margin-top: 0;
	}
	#sub12_6 .cnt1 .area .indus_pu .form_cont input {
		width: calc(100% - 78px);
		margin-left: 24px;
	}
	#sub12_6 .cnt1 .area .indus_pu .form_cont label {
		left: 24px;
	}
	#sub12_6 .cnt1 .area .indus_pu .form_cont .delete_btn {
		right: 24px;
	}
	#sub12_6 .cnt1 .area .indus_pu .drp p {
		width: calc(100% - 48px);
	}
	#sub12_6 .cnt1 .area .indus_pu .indus_list {
		width: 100%;
		margin-top: 0;
		height: calc(100% - 179px);
	}
	#sub12_6 .cnt1 .area .indus_pu .save_btn2 {
		position: absolute;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub12_6 .cnt1 .area .bh_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: none;
		transform: none;
		padding-bottom: 0;
		padding-top: 0;
	}
	#sub12_6 .cnt1 .area .bh_pu .close_btn {
		display: none;
	}
	#sub12_6 .cnt1 .area .bh_pu .tit {
		margin: 20px auto;
		width: calc(100% - 48px);
	}
	#sub12_6 .cnt1 .area .bh_pu .tit h4 {
		display: none;
	}
	#sub12_6 .cnt1 .area .bh_pu .txt {
		width: calc(100% - 48px);
		padding-bottom: 100px;
	}
	#sub12_6 .cnt1 .area .bh_pu .save_btn2 {
		position: fixed;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub12_6 .cnt1 .area .bh2_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: none;
		transform: none;
		padding-bottom: 0;
		padding-top: 0;
		max-height: 100%;
	}
	#sub12_6 .cnt1 .area .bh2_pu .close_btn {
		display: none;
	}
	#sub12_6 .cnt1 .area .bh2_pu .tit {
		margin: 20px auto;
		width: calc(100% - 48px);
	}
	#sub12_6 .cnt1 .area .bh2_pu .tit h4 {
		display: none;
	}
	#sub12_6 .cnt1 .area .bh2_pu .txt {
		width: calc(100% - 48px);
		padding-bottom: 100px;
	}
	#sub12_6 .cnt1 .area .bh2_pu .save_btn {
		position: fixed;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub12_6 .cnt1 .area .edu1_pu {
		top: auto;
		bottom: -90%;
		left: 0;
		width: 100%;
		height: 90%;
		-webkit-border-radius: 32px 32px 0 0;
		-moz-border-radius: 32px 32px 0 0;
		border-radius: 32px 32px 0 0;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		padding-bottom: 0;
		overflow: hidden;
		max-height: 90% !important;
	}
	#sub12_6 .cnt1 .area .edu1_pu .close_btn {
		display: none;
	}
	#sub12_6 .cnt1 .area .edu1_pu .pu_nav {
		border-bottom: 0;
	}
	#sub12_6 .cnt1 .area .edu1_pu .tit {
		display: none;
	}
	#sub12_6 .cnt1 .area .edu1_pu .form_cont {
		overflow: hidden;
		width: 100%;
		margin-top: 0;
	}
	#sub12_6 .cnt1 .area .edu1_pu .form_cont input {
		width: calc(100% - 78px);
		margin-left: 24px;
	}
	#sub12_6 .cnt1 .area .edu1_pu .form_cont label {
		left: 24px;
	}
	#sub12_6 .cnt1 .area .edu1_pu .form_cont .delete_btn {
		right: 24px;
	}
	#sub12_6 .cnt1 .area .edu1_pu .drp p {
		width: calc(100% - 48px);
	}
	#sub12_6 .cnt1 .area .edu1_pu .edu1_list {
		width: 100%;
		margin-top: 0;
		height: calc(100% - 179px);
	}
	#sub12_6 .cnt1 .area .edu1_pu .save_btn2 {
		position: absolute;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub12_6 .cnt1 .area .degree_pu {
		width: 90%;
	}
	#sub12_6 .cnt1 .area .degree_pu .txt {
		width: 80%;
	}

	#sub12_6 .cnt1 .area .skill_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: none;
		transform: none;
		padding-bottom: 0;
		padding-top: 0;
	}
	#sub12_6 .cnt1 .area .skill_pu .close_btn {
		display: none;
	}
	#sub12_6 .cnt1 .area .skill_pu .pu_nav2 h4 {
		font-size: 14px;
	}
	#sub12_6 .cnt1 .area .skill_pu .tit {
		display: none;
	}
	#sub12_6 .cnt1 .area .skill_pu .form_cont {
		width: calc(100% - 48px);
		margin-top: 0;
		border-bottom: 1px solid #eee;
		overflow: hidden;
	}
	#sub12_6 .cnt1 .area .skill_pu .des_box {
		width: 100%;
	}
	#sub12_6 .cnt1 .area .skill_pu .des_box ul li p {
		line-height: 16px;
	}
	#sub12_6 .cnt1 .area .skill_pu .skill_choice {
		width: calc(100% - 48px);
	}
	#sub12_6 .cnt1 .area .skill_pu .skill_list {
		width: 100%;
	}
	#sub12_6 .cnt1 .area .skill_pu .skill_list ul li p {
		padding-left: 24px;
	}
	#sub12_6 .cnt1 .area .skill_pu .skill_list ul li .add_btn {
		margin-right: 24px;
	}
	#sub12_6 .cnt1 .area .skill_pu .save_btn {
		position: fixed;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub12_6 .cnt1 .area .awd_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: none;
		transform: none;
		padding-bottom: 0;
		padding-top: 0;
		overflow: auto;
	}
	#sub12_6 .cnt1 .area .awd_pu .close_btn {
		display: none;
	}
	#sub12_6 .cnt1 .area .awd_pu .tit {
		margin: 20px auto;
		width: calc(100% - 48px);
	}
	#sub12_6 .cnt1 .area .awd_pu .tit h4 {
		display: none;
	}
	#sub12_6 .cnt1 .area .awd_pu .delete_btn {
		top: 11px;
		right: 24px;
	}
	#sub12_6 .cnt1 .area .awd_pu .txt {
		width: calc(100% - 48px);
		padding-bottom: 100px;
	}
	#sub12_6 .cnt1 .area .awd_pu .txt ul.depth1 > li .cal_wrap {
		width: 50%;
	}
	#sub12_6 .cnt1 .area .awd_pu .save_btn {
		position: fixed;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub12_6 .cnt1 .area .tsis_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: none;
		transform: none;
		padding-bottom: 0;
		padding-top: 0;
		max-height: 100%;
	}
	#sub12_6 .cnt1 .area .tsis_pu .close_btn {
		display: none;
	}
	#sub12_6 .cnt1 .area .tsis_pu .tit {
		margin: 20px auto;
		width: calc(100% - 48px);
	}
	#sub12_6 .cnt1 .area .tsis_pu .tit h4 {
		display: none;
	}
	#sub12_6 .cnt1 .area .tsis_pu .delete_btn {
		top: 11px;
		right: 24px;
	}
	#sub12_6 .cnt1 .area .tsis_pu .txt {
		width: calc(100% - 48px);
		padding-bottom: 100px;
	}
	#sub12_6 .cnt1 .area .tsis_pu .txt ul.depth1 > li .cal_wrap {
		width: 50%;
	}
	#sub12_6 .cnt1 .area .tsis_pu .save_btn {
		position: fixed;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub12_6 .cnt1 .area .pat_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: none;
		transform: none;
		padding-bottom: 0;
		padding-top: 0;
	}
	#sub12_6 .cnt1 .area .pat_pu .close_btn {
		display: none;
	}
	#sub12_6 .cnt1 .area .pat_pu .tit {
		margin: 20px auto;
		width: calc(100% - 48px);
	}
	#sub12_6 .cnt1 .area .pat_pu .tit h4 {
		display: none;
	}
	#sub12_6 .cnt1 .area .pat_pu .delete_btn {
		top: 11px;
		right: 24px;
	}
	#sub12_6 .cnt1 .area .pat_pu .txt {
		width: calc(100% - 48px);
		padding-bottom: 100px;
	}
	#sub12_6 .cnt1 .area .pat_pu .txt ul.depth1 > li .cal_wrap {
		width: 50%;
	}
	#sub12_6 .cnt1 .area .pat_pu .save_btn {
		position: fixed;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub12_6 .cnt1 .area .fl_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: none;
		transform: none;
		padding-bottom: 0;
		padding-top: 0;
		overflow: auto;
	}
	#sub12_6 .cnt1 .area .fl_pu .close_btn {
		display: none;
	}
	#sub12_6 .cnt1 .area .fl_pu .tit {
		margin: 20px auto;
		width: calc(100% - 48px);
	}
	#sub12_6 .cnt1 .area .fl_pu .tit h4 {
		display: none;
	}
	#sub12_6 .cnt1 .area .fl_pu .delete_btn {
		top: 11px;
		right: 24px;
	}
	#sub12_6 .cnt1 .area .fl_pu .txt {
		width: calc(100% - 48px);
		padding-bottom: 100px;
	}
	#sub12_6 .cnt1 .area .fl_pu .save_btn {
		position: fixed;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub12_6 .cnt1 .area .fl2_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: none;
		transform: none;
		padding-bottom: 0;
		padding-top: 0;
		overflow: auto;
	}
	#sub12_6 .cnt1 .area .fl2_pu .close_btn {
		display: none;
	}
	#sub12_6 .cnt1 .area .fl2_pu .tit {
		margin: 20px auto;
		width: calc(100% - 48px);
	}
	#sub12_6 .cnt1 .area .fl2_pu .tit h4 {
		display: none;
	}
	#sub12_6 .cnt1 .area .fl2_pu .txt {
		width: calc(100% - 48px);
		padding-bottom: 100px;
	}
	#sub12_6 .cnt1 .area .fl2_pu .save_btn {
		position: fixed;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub12_6 .cnt1 .area .link_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: none;
		transform: none;
		padding-bottom: 0;
		padding-top: 0;
	}
	#sub12_6 .cnt1 .area .link_pu .close_btn {
		display: none;
	}
	#sub12_6 .cnt1 .area .link_pu .tit {
		margin: 20px auto;
		width: calc(100% - 48px);
	}
	#sub12_6 .cnt1 .area .link_pu .tit h4 {
		display: none;
	}
	#sub12_6 .cnt1 .area .link_pu .delete_btn {
		top: 11px;
		right: 24px;
	}
	#sub12_6 .cnt1 .area .link_pu .txt {
		width: calc(100% - 48px);
	}
	#sub12_6 .cnt1 .area .link_pu .save_btn {
		position: fixed;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub12_6 .cnt1 .area .area_pu {
		top: auto;
		bottom: -90%;
		left: 0;
		width: 100%;
		height: 90%;
		-webkit-border-radius: 32px 32px 0 0;
		-moz-border-radius: 32px 32px 0 0;
		border-radius: 32px 32px 0 0;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		padding-bottom: 0;
		overflow: hidden;
		max-height: 90%;
	}
	#sub12_6 .cnt1 .area .area_pu .tit {
		display: none;
		padding-top: 0;
	}
	#sub12_6 .cnt1 .area .area_pu .form_cont {
		margin-top: 0;
		width: 100%;
	}
	#sub12_6 .cnt1 .area .area_pu .form_cont label {
		left: 24px;
	}
	#sub12_6 .cnt1 .area .area_pu .form_cont input {
		width: calc(100% - 24px);
		margin-left: 24px;
	}
	#sub12_6 .cnt1 .area .area_pu .area_list {
		width: 100%;
		margin-top: 10px;
		height: calc(100% - 149px);
	}
	#sub12_6 .cnt1 .area .area_pu .area_list ul li p {
		padding-left: 24px;
	}
	#sub12_6 .cnt1 .area .area_pu .close_btn {
		display: none;
	}
	#sub12_6 .cnt1 .area .area_pu .pu_nav {
		border-bottom: 0;
	}
	#sub12_6 .cnt1 .area .area_pu .save_btn2 {
		position: fixed;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub12_7 .cnt1 .good_pg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		border: 0;
		padding: 0;
		margin: 0;
		z-index: 11;
		background: #fff;
		padding-top: 53px;
	}
	#sub12_7 .cnt1 .good_pg .pg_wrap {
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		width: 100%;
	}

	#sub14 .cnt1 {
		padding-bottom: 0;
	}

	.pu_nav {
		display: block;
	}
	.pu_nav2 {
		display: block;
	}
	.pu_nav3 {
		display: block;
	}

	#sub15 .cnt1 .rep_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		padding-bottom: 0;
	}
	#sub15 .cnt1 .rep_pu .close_btn {
		display: none;
	}
	#sub15 .cnt1 .rep_pu h4 {
		padding-top: 61px;
		font-size: 24px;
		font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
		width: auto;
		padding-left: 36px;
		padding-right: 36px;
	}
	#sub15 .cnt1 .rep_pu .form_cont {
		width: calc(100% - 72px);
	}

	#sub15 .cnt1 .kd_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		padding-bottom: 0;
	}
	#sub15 .cnt1 .kd_pu .close_btn {
		display: none;
	}
	#sub15 .cnt1 .kd_pu .tit {
		margin: 61px 36px 0 36px;
		width: calc(100% - 72px);
	}
	#sub15 .cnt1 .kd_pu .tit p br {
		display: none;
	}
	#sub15 .cnt1 .kd_pu .txt ul {
		margin-top: 50px;
	}

	#sub15 .cnt1 .car_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		padding-bottom: 0;
	}
	#sub15 .cnt1 .car_pu .close_btn {
		display: none;
	}
	#sub15 .cnt1 .car_pu .tit {
		display: none;
	}
	#sub15 .cnt1 .car_pu > p,
	#sub15 .cnt1 .car_pu .career_wrap {
		width: calc(100% - 48px);
	}
	#sub15 .cnt1 .car_pu > p {
		padding-top: 40px;
	}
	#sub15 .cnt1 .car_pu .save_btn {
		position: absolute;
		bottom: 44px;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub15 .cnt1 .field0_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		padding-bottom: 0;
	}
	#sub15 .cnt1 .field0_pu .close_btn {
		display: none;
	}
	#sub15 .cnt1 .field0_pu .tit {
		display: none;
	}
	#sub15 .cnt1 .field0_pu > p {
		padding-top: 40px;
	}
	#sub15 .cnt1 .field0_pu .tit,
	#sub15 .cnt1 .field0_pu > p,
	#sub15 .cnt1 .field0_pu .form_cont,
	#sub15 .cnt1 .field0_pu .input_box {
		width: calc(100% - 48px);
	}
	#sub15 .cnt1 .field0_pu .save_btn {
		position: absolute;
		bottom: 44px;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}
	#sub15 .cnt1 .field_pu {
		top: auto;
		bottom: -80%;
		left: 0;
		width: 100%;
		height: 90%;
		-webkit-border-radius: 32px 32px 0 0;
		-moz-border-radius: 32px 32px 0 0;
		border-radius: 32px 32px 0 0;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		padding-bottom: 0;
		overflow: hidden;
		max-height: 90%;
	}
	#sub15 .cnt1 .field_pu .close_btn2 {
		display: none;
	}
	#sub15 .cnt1 .field_pu .pu_nav {
		border-bottom: 0;
	}
	#sub15 .cnt1 .field_pu .tit {
		display: none;
	}
	#sub15 .cnt1 .field_pu .form_cont {
		overflow: hidden;
		width: 100%;
		margin-top: 0;
	}
	#sub15 .cnt1 .field_pu .form_cont input {
		width: calc(100% - 78px);
		margin-left: 24px;
	}
	#sub15 .cnt1 .field_pu .form_cont label {
		left: 24px;
	}
	#sub15 .cnt1 .field_pu .form_cont .delete_btn {
		right: 24px;
	}
	#sub15 .cnt1 .field_pu .field_list {
		width: 100%;
		margin-top: 10px;
		height: calc(100% - 193px);
	}
	#sub15 .cnt1 .field_pu .des_box {
		width: 100%;
	}
	#sub15 .cnt1 .field_pu .des_box ul li p {
		line-height: 16px;
	}
	#sub15 .cnt1 .field_pu .save_btn2 {
		position: absolute;
		bottom: 44px;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}
	#sub15 .cnt1 .car1_pu {
		top: auto;
		bottom: -90%;
		left: 0;
		width: 100%;
		height: 90%;
		-webkit-border-radius: 32px 32px 0 0;
		-moz-border-radius: 32px 32px 0 0;
		border-radius: 32px 32px 0 0;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		padding-bottom: 0;
		overflow: hidden;
		max-height: 90%;
	}
	#sub15 .cnt1 .car1_pu .close_btn2 {
		display: none;
	}
	#sub15 .cnt1 .car1_pu .pu_nav {
		border-bottom: 0;
	}
	#sub15 .cnt1 .car1_pu .tit {
		display: none;
	}
	#sub15 .cnt1 .car1_pu .form_cont {
		overflow: hidden;
		width: 100%;
		margin-top: 0;
	}
	#sub15 .cnt1 .car1_pu .form_cont input {
		width: calc(100% - 78px);
		margin-left: 24px;
	}
	#sub15 .cnt1 .car1_pu .form_cont label {
		left: 24px;
	}
	#sub15 .cnt1 .car1_pu .form_cont .delete_btn {
		right: 24px;
	}
	#sub15 .cnt1 .car1_pu .drp p {
		width: calc(100% - 48px);
	}
	#sub15 .cnt1 .car1_pu .car1_list {
		width: 100%;
		margin-top: 10px;
		height: calc(100% - 233px);
	}
	#sub15 .cnt1 .car1_pu .save_btn2 {
		position: absolute;
		bottom: 44px;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}
	#sub15 .cnt1 .edu0_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		padding-bottom: 0;
	}
	#sub15 .cnt1 .edu0_pu .tit {
		display: none;
	}
	#sub15 .cnt1 .edu0_pu > p {
		width: auto;
		font-size: 14px;
		font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
		color: #999;
		line-height: 24px;
		padding: 22px 24px 0 24px;
	}
	#sub15 .cnt1 .edu0_pu .wrap {
		margin-top: 20px;
		width: calc(100% - 48px);
	}
	#sub15 .cnt1 .edu0_pu .save_btn {
		position: absolute;
		bottom: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}
	#sub15 .cnt1 .edu1_pu {
		top: auto;
		bottom: -90%;
		left: 0;
		width: 100%;
		height: 90%;
		-webkit-border-radius: 32px 32px 0 0;
		-moz-border-radius: 32px 32px 0 0;
		border-radius: 32px 32px 0 0;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		padding-bottom: 0;
		overflow: hidden;
		max-height: 90%;
	}
	#sub15 .cnt1 .edu1_pu .close_btn2 {
		display: none;
	}
	#sub15 .cnt1 .edu1_pu .pu_nav {
		border-bottom: 0;
	}
	#sub15 .cnt1 .edu1_pu .tit {
		display: none;
	}
	#sub15 .cnt1 .edu1_pu .form_cont {
		overflow: hidden;
		width: 100%;
		margin-top: 0;
	}
	#sub15 .cnt1 .edu1_pu .form_cont input {
		width: calc(100% - 78px);
		margin-left: 24px;
	}
	#sub15 .cnt1 .edu1_pu .form_cont label {
		left: 24px;
	}
	#sub15 .cnt1 .edu1_pu .form_cont .delete_btn {
		right: 24px;
	}
	#sub15 .cnt1 .edu1_pu .drp p {
		width: calc(100% - 48px);
	}
	#sub15 .cnt1 .edu1_pu .edu1_list {
		width: 100%;
		margin-top: 0;
		height: calc(100% - 223px);
	}
	#sub15 .cnt1 .edu1_pu .save_btn2 {
		position: absolute;
		bottom: 44px;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}
	#sub15 .cnt1 .edu2_pu {
		top: auto;
		bottom: -90%;
		left: 0;
		width: 100%;
		height: 90%;
		-webkit-border-radius: 32px 32px 0 0;
		-moz-border-radius: 32px 32px 0 0;
		border-radius: 32px 32px 0 0;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		padding-bottom: 0;
		overflow: hidden;
		max-height: 90%;
	}
	#sub15 .cnt1 .edu2_pu .close_btn2 {
		display: none;
	}
	#sub15 .cnt1 .edu2_pu .pu_nav {
		border-bottom: 0;
	}
	#sub15 .cnt1 .edu2_pu .tit {
		display: none;
	}
	#sub15 .cnt1 .edu2_pu .form_cont {
		overflow: hidden;
		width: 100%;
		margin-top: 0;
	}
	#sub15 .cnt1 .edu2_pu .form_cont input {
		width: calc(100% - 78px);
		margin-left: 24px;
	}
	#sub15 .cnt1 .edu2_pu .form_cont label {
		left: 24px;
	}
	#sub15 .cnt1 .edu2_pu .form_cont .delete_btn {
		right: 24px;
	}
	#sub15 .cnt1 .edu2_pu .drp p {
		width: calc(100% - 48px);
	}
	#sub15 .cnt1 .edu2_pu .edu2_list {
		width: 100%;
		margin-top: 0;
		height: calc(100% - 223px);
	}
	#sub15 .cnt1 .edu2_pu .save_btn2 {
		position: absolute;
		bottom: 44px;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}
	#sub15 .cnt1 .intd_pu {
		top: auto;
		bottom: -90%;
		left: 0;
		width: 100%;
		height: 90%;
		-webkit-border-radius: 32px 32px 0 0;
		-moz-border-radius: 32px 32px 0 0;
		border-radius: 32px 32px 0 0;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		padding-bottom: 0;
		overflow: hidden;
		padding-top: 0;
	}
	#sub15 .cnt1 .intd_pu .close_btn3 {
		display: none;
	}
	#sub15 .cnt1 .intd_pu .pu_nav {
		border-bottom: 0;
	}
	#sub15 .cnt1 .intd_pu .tit {
		width: auto;
		margin: 0 24px 16px;
	}
	#sub15 .cnt1 .intd_pu .tit h4 {
		font-size: 18px;
	}
	#sub15 .cnt1 .intd_pu .form_cont {
		height: 60%;
	}
	#sub15 .cnt1 .intd_pu .form_cont,
	#sub15 .cnt1 .intd_pu .w_num {
		width: calc(100% - 48px);
	}
	#sub15 .cnt1 .intd_pu .save_btn {
		position: absolute;
		bottom: 44px;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub15 .cnt1 .intd2_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: none;
		transform: none;
		padding-bottom: 0;
		padding-top: 0;
	}
	#sub15 .cnt1 .intd2_pu .close_btn {
		display: none;
	}
	#sub15 .cnt1 .intd2_pu .tit {
		margin-top: 20px;
		width: calc(100% - 48px);
	}
	#sub15 .cnt1 .intd2_pu .tit h4 {
		display: none;
	}
	#sub15 .cnt1 .intd2_pu .txt {
		width: calc(100% - 48px);
		padding-bottom: 144px;
	}
	#sub15 .cnt1 .intd2_pu .save_btn {
		position: fixed;
		bottom: 44px;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}
	#sub15 .cnt1 .emp_pu {
		width: 90%;
	}
	#sub15 .cnt1 .emp_pu .txt {
		width: 80%;
	}

	#sub15 .cnt1 .indus_pu {
		top: auto;
		bottom: -90%;
		left: 0;
		width: 100%;
		height: 90%;
		-webkit-border-radius: 32px 32px 0 0;
		-moz-border-radius: 32px 32px 0 0;
		border-radius: 32px 32px 0 0;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		padding-bottom: 0;
		overflow: hidden;
		max-height: 90%;
	}
	#sub15 .cnt1 .indus_pu .close_btn2 {
		display: none;
	}
	#sub15 .cnt1 .indus_pu .pu_nav {
		border-bottom: 0;
	}
	#sub15 .cnt1 .indus_pu .tit {
		display: none;
	}
	#sub15 .cnt1 .indus_pu .form_cont {
		overflow: hidden;
		width: 100%;
		margin-top: 0;
	}
	#sub15 .cnt1 .indus_pu .form_cont input {
		width: calc(100% - 78px);
		margin-left: 24px;
	}
	#sub15 .cnt1 .indus_pu .form_cont label {
		left: 24px;
	}
	#sub15 .cnt1 .indus_pu .form_cont .delete_btn {
		right: 24px;
	}
	#sub15 .cnt1 .indus_pu .drp p {
		width: calc(100% - 48px);
	}
	#sub15 .cnt1 .indus_pu .indus_list {
		width: 100%;
		margin-top: 0;
		height: calc(100% - 223px);
	}
	#sub15 .cnt1 .indus_pu .save_btn2 {
		position: absolute;
		bottom: 44px;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub15 .cnt1 .bh_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: none;
		transform: none;
		padding-bottom: 0;
		padding-top: 0;
	}
	#sub15 .cnt1 .bh_pu .close_btn2 {
		display: none;
	}
	#sub15 .cnt1 .bh_pu .tit {
		margin: 20px auto;
		width: calc(100% - 48px);
	}
	#sub15 .cnt1 .bh_pu .tit h4 {
		display: none;
	}
	#sub15 .cnt1 .bh_pu .txt {
		width: calc(100% - 48px);
		padding-bottom: 144px;
	}
	#sub15 .cnt1 .bh_pu .save_btn2 {
		position: fixed;
		bottom: 44px;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub15 .cnt1 .bh2_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: none;
		transform: none;
		padding-bottom: 0;
		padding-top: 0;
		max-height: 100%;
	}
	#sub15 .cnt1 .bh2_pu .close_btn {
		display: none;
	}
	#sub15 .cnt1 .bh2_pu .tit {
		margin: 20px auto;
		width: calc(100% - 48px);
	}
	#sub15 .cnt1 .bh2_pu .tit h4 {
		display: none;
	}
	#sub15 .cnt1 .bh2_pu .txt {
		width: calc(100% - 48px);
		padding-bottom: 144px;
	}
	#sub15 .cnt1 .bh2_pu .save_btn {
		position: fixed;
		bottom: 44px;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub15 .cnt1 .degree_pu {
		width: 90%;
	}
	#sub15 .cnt1 .degree_pu .txt {
		width: 80%;
	}

	#sub15 .cnt1 .skill_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: none;
		transform: none;
		padding-bottom: 0;
		padding-top: 0;
	}
	#sub15 .cnt1 .skill_pu .close_btn {
		display: none;
	}
	#sub15 .cnt1 .skill_pu .pu_nav2 h4 {
		font-size: 14px;
	}
	#sub15 .cnt1 .skill_pu .tit {
		display: none;
	}
	#sub15 .cnt1 .skill_pu .form_cont {
		width: calc(100% - 48px);
		margin-top: 0;
	}
	#sub15 .cnt1 .skill_pu .des_box {
		width: 100%;
	}
	#sub15 .cnt1 .skill_pu .des_box ul li p {
		line-height: 16px;
	}
	#sub15 .cnt1 .skill_pu .skill_choice {
		width: calc(100% - 48px);
	}
	#sub15 .cnt1 .skill_pu .skill_list {
		width: 100%;
		height: 240px;
	}
	#sub15 .cnt1 .skill_pu .skill_list ul li p {
		padding-left: 24px;
	}
	#sub15 .cnt1 .skill_pu .skill_list ul li .add_btn {
		margin-right: 24px;
	}
	#sub15 .cnt1 .skill_pu .save_btn {
		position: fixed;
		bottom: 44px;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub15 .cnt1 .awd_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: none;
		transform: none;
		padding-bottom: 0;
		padding-top: 0;
	}
	#sub15 .cnt1 .awd_pu .close_btn {
		display: none;
	}
	#sub15 .cnt1 .awd_pu .tit {
		margin: 20px auto;
		width: calc(100% - 48px);
	}
	#sub15 .cnt1 .awd_pu .tit h4 {
		display: none;
	}
	#sub15 .cnt1 .awd_pu .delete_btn {
		top: 11px;
		right: 24px;
	}
	#sub15 .cnt1 .awd_pu .txt {
		width: calc(100% - 48px);
	}
	#sub15 .cnt1 .awd_pu .txt ul.depth1 > li .cal_wrap {
		width: 50%;
	}
	#sub15 .cnt1 .awd_pu .save_btn {
		position: fixed;
		bottom: 44px;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub15 .cnt1 .tsis_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: none;
		transform: none;
		padding-bottom: 0;
		padding-top: 0;
		max-height: 100%;
	}
	#sub15 .cnt1 .tsis_pu .close_btn {
		display: none;
	}
	#sub15 .cnt1 .tsis_pu .tit {
		margin: 20px auto;
		width: calc(100% - 48px);
	}
	#sub15 .cnt1 .tsis_pu .tit h4 {
		display: none;
	}
	#sub15 .cnt1 .tsis_pu .delete_btn {
		top: 11px;
		right: 24px;
	}
	#sub15 .cnt1 .tsis_pu .txt {
		width: calc(100% - 48px);
		padding-bottom: 144px;
	}
	#sub15 .cnt1 .tsis_pu .txt ul.depth1 > li .cal_wrap {
		width: 50%;
	}
	#sub15 .cnt1 .tsis_pu .save_btn {
		position: fixed;
		bottom: 44px;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub15 .cnt1 .pat_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: none;
		transform: none;
		padding-bottom: 0;
		padding-top: 0;
	}
	#sub15 .cnt1 .pat_pu .close_btn {
		display: none;
	}
	#sub15 .cnt1 .pat_pu .tit {
		margin: 20px auto;
		width: calc(100% - 48px);
	}
	#sub15 .cnt1 .pat_pu .tit h4 {
		display: none;
	}
	#sub15 .cnt1 .pat_pu .delete_btn {
		top: 11px;
		right: 24px;
	}
	#sub15 .cnt1 .pat_pu .txt {
		width: calc(100% - 48px);
		padding-bottom: 144px;
	}
	#sub15 .cnt1 .pat_pu .txt ul.depth1 > li .cal_wrap {
		width: 50%;
	}
	#sub15 .cnt1 .pat_pu .save_btn {
		position: fixed;
		bottom: 44px;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub15 .cnt1 .fl_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: none;
		transform: none;
		padding-bottom: 0;
		padding-top: 0;
	}
	#sub15 .cnt1 .fl_pu .close_btn {
		display: none;
	}
	#sub15 .cnt1 .fl_pu .tit {
		margin: 20px auto;
		width: calc(100% - 48px);
	}
	#sub15 .cnt1 .fl_pu .tit h4 {
		display: none;
	}
	#sub15 .cnt1 .fl_pu .delete_btn {
		top: 11px;
		right: 24px;
	}
	#sub15 .cnt1 .fl_pu .txt {
		width: calc(100% - 48px);
	}
	#sub15 .cnt1 .fl_pu .txt ul.depth1 > li .cal_wrap {
		width: 50%;
	}
	#sub15 .cnt1 .fl_pu .save_btn {
		position: fixed;
		bottom: 44px;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub15 .cnt1 .fl2_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: none;
		transform: none;
		padding-bottom: 0;
		padding-top: 0;
		overflow: auto;
	}
	#sub15 .cnt1 .fl2_pu .close_btn2 {
		display: none;
	}
	#sub15 .cnt1 .fl2_pu .tit {
		margin: 20px auto;
		width: calc(100% - 48px);
	}
	#sub15 .cnt1 .fl2_pu .tit h4 {
		display: none;
	}
	#sub15 .cnt1 .fl2_pu .txt {
		width: calc(100% - 48px);
		padding-bottom: 100px;
	}
	#sub15 .cnt1 .fl2_pu .save_btn {
		position: fixed;
		bottom: 44px;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub15 .cnt1 .link_pu {
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		-webkit-transform: none;
		transform: none;
		padding-bottom: 0;
		padding-top: 0;
	}
	#sub15 .cnt1 .link_pu .close_btn {
		display: none;
	}
	#sub15 .cnt1 .link_pu .tit {
		margin: 20px auto;
		width: calc(100% - 48px);
	}
	#sub15 .cnt1 .link_pu .tit h4 {
		display: none;
	}
	#sub15 .cnt1 .link_pu .delete_btn {
		top: 11px;
		right: 24px;
	}
	#sub15 .cnt1 .link_pu .txt {
		width: calc(100% - 48px);
	}
	#sub15 .cnt1 .link_pu .save_btn {
		position: fixed;
		bottom: 44px;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}

	#sub15 .cnt1 .area_pu {
		top: auto;
		bottom: -90%;
		left: 0;
		width: 100%;
		height: 90%;
		-webkit-border-radius: 32px 32px 0 0;
		-moz-border-radius: 32px 32px 0 0;
		border-radius: 32px 32px 0 0;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		padding-bottom: 0;
		overflow: hidden;
		max-height: 90%;
	}
	#sub15 .cnt1 .area_pu .tit {
		display: none;
	}
	#sub15 .cnt1 .area_pu .close_btn {
		display: none;
	}
	#sub15 .cnt1 .area_pu .pu_nav {
		border-bottom: 0;
	}
	#sub15 .cnt1 .area_pu .form_cont {
		margin-top: 0;
		width: 100%;
	}
	#sub15 .cnt1 .area_pu .form_cont input {
		width: 100%;
		padding-left: 24px;
	}
	#sub15 .cnt1 .area_pu .form_cont label {
		left: 24px;
	}
	#sub15 .cnt1 .area_pu .area_list {
		margin-top: 10px;
		width: 100%;
		height: calc(100% - 193px);
	}
	#sub15 .cnt1 .area_pu .area_list ul li p {
		padding-left: 24px;
	}
	#sub15 .cnt1 .area_pu .save_btn2 {
		position: fixed;
		bottom: 44px;
		left: 0;
		margin-top: 0;
		width: 100%;
		height: 56px;
		line-height: 56px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		font-size: 18px;
		letter-spacing: -0.5px;
	}
	#sub17 .cnt1 {
		padding-bottom: 0;
	}
	#sub18 .cnt1 {
		padding-bottom: 0;
	}
}

@media all and (max-width: 400px) {
	#main .cnt10 .area .c10_2 .tit h3 br {
		display: block;
	}

	#main2 .cnt3 .area .tit h5 {
		font-size: 16px;
	}
	#main2 .cnt3 .area .tit h3 {
		font-size: 32px;
	}

	#sub15 .cnt1 .area .pf_info1 .box ul li p {
		width: 230px;
	}
}
