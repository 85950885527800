@charset "utf-8";

@font-face {
	font-family: SC1;
	src: url('../fonts/scdream1-webfont.woff2') format('woff2'), url('../fonts/scdream1-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: SC2;
	src: url('../fonts/scdream2-webfont.woff2') format('woff2'), url('../fonts/scdream2-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: SC3;
	src: url('../fonts/scdream3-webfont.woff2') format('woff2'), url('../fonts/scdream3-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: SC4;
	src: url('../fonts/scdream4-webfont.woff2') format('woff2'), url('../fonts/scdream4-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: SC5;
	src: url('../fonts/scdream5-webfont.woff2') format('woff2'), url('../fonts/scdream5-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: SC6;
	src: url('../fonts/scdream6-webfont.woff2') format('woff2'), url('../fonts/scdream6-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: SC7;
	src: url('../fonts/scdream7-webfont.woff2') format('woff2'), url('../fonts/scdream7-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: SC8;
	src: url('../fonts/scdream8-webfont.woff2') format('woff2'), url('../fonts/scdream8-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: SC9;
	src: url('../fonts/scdream9-webfont.woff2') format('woff2'), url('../fonts/scdream9-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: PTDl;
	src: url('../fonts/Pretendard-Light.eot');
	src: local('※'), url('../fonts/Pretendard-Light.woff') format('woff'), url('../fonts/Pretendard-Light.ttf') format('truetype');
}

@font-face {
	font-family: PTDr;
	src: url('../fonts/Pretendard-Regular.eot');
	src: local('※'), url('../fonts/Pretendard-Regular.woff') format('woff'), url('../fonts/Pretendard-Regular.ttf') format('truetype');
}

@font-face {
	font-family: PTDb;
	src: url('../fonts/Pretendard-Bold.eot');
	src: local('※'), url('../fonts/Pretendard-Bold.woff') format('woff'), url('../fonts/Pretendard-Bold.ttf') format('truetype');
}

@font-face {
	font-family: MaBuB;
	src: url('../fonts/MaruBuri-Bold.eot');
	src: local('※'), url('../fonts/MaruBuri-Bold.woff') format('woff'), url('../fonts/MaruBuri-Bold.ttf') format('truetype');
}

body {
	font-size: 12px;
	font-family: SC4, SC5, 'Noto Sans KR', sans-serif;
	color: #000;
	-webkit-text-size-adjust: none;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
p,
button,
input,
td {
	margin: 0;
	padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	font-weight: normal;
	font-family: inherit;
}
ul {
	list-style: none;
}
a {
	text-decoration: none;
}
a:link,
a:visited,
a:hover,
a:active {
	color: inherit;
	text-decoration: none;
}
button {
	border: 0px;
	background: none;
	cursor: pointer;
	font-family: inherit;
}
img {
	border: 0px;
	vertical-align: top;
}
address {
	font-style: normal;
}
header,
nav,
section,
article,
footer {
	display: block;
}
legend {
	position: absolute;
	margin: 0;
	padding: 0;
	font-size: 0;
	line-height: 0;
	text-indent: -9999em;
	overflow: hidden;
}
label,
input,
button,
select {
	vertical-align: middle;
}
input,
textarea {
	-webkit-appearance: none;
	-webkit-border-radius: 0;
}
hr {
	display: none;
}
pre {
	overflow-x: scroll;
	font-size: 1.1em;
}
table {
	border-spacing: 0px;
	border-collapse: collapse;
}
em {
	font-style: normal;
}
fieldset {
	border: 0;
}
select::-ms-expand {
	display: none;
}
input:focus {
	outline: none;
}

input[type='date'] {
	position: relative;
	padding-right: 20px;
	background: url('../images/cal_arrow.png') no-repeat right 10px center;
	padding-left: 0;
}
input[type='date']::-webkit-clear-button,
input[type='date']::-webkit-inner-spin-button {
	display: none;
}
input[type='date']::-webkit-calendar-picker-indicator {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: transparent;
	color: transparent;
	cursor: pointer;
}
input[type='date']::before {
	content: attr(data-placeholder);
	width: 100%;
}
input[type='date']:valid::before {
	display: none;
}
input[data-placeholder]::before {
	color: #999;
	font-size: 13px;
}

.clear:after {
	display: block;
	content: '';
	clear: both;
}
.area {
	width: 1080px;
	margin: 0 auto;
}

.cmn_blk {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.6;
	filter: Alpha(opacity=60);
}
.blk,
#black10 {
	z-index: 10;
}
#black,
#blkGnb,
#black2,
#black3 {
	z-index: 12;
}
#black4 {
	z-index: 13;
}

#header {
	width: 100%;
	height: 99px;
	border-bottom: 1px solid #e5e5e5;
}
#header .area {
	width: 1544px;
	height: 100%;
	position: relative;
	display: -webkit-flex;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
#header .area .logo {
	width: 293px;
	height: 41px;
	/* position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%); */
}
#header .area .logo a {
	display: block;
	width: 100%;
	height: 100%;
}
#header .area .logo a img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	display: block;
}
#header .area .gnb {
	height: 100%;
	margin-left: 87px;
	margin-right: auto;
}
#header .area .gnb ul {
	height: 100%;
}
#header .area .gnb ul li {
	position: relative;
	float: left;
	height: 100%;
	margin-left: 74px;
}
#header .area .gnb ul li:first-child {
	margin-left: 0;
}
#header .area .gnb ul li a {
	font-family: 'Spoqa Han Sans Neo', 'sans-serif';
	line-height: 99px;
	display: block;
	font-size: 18px;
	font-weight: 500;
}
#header .area .gnb ul li a span {
	display: none;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 2px;
	background: #000;
}
#header .area .gnb ul li.on a span {
	display: block;
}
#header .area .link {
	padding: 10px 25px 11px;
	border-radius: 999px;
	background: #3e75ba;
	color: #fff;
	font-size: 15px;
	font-family: 'Spoqa Han Sans Neo', 'sans-serif';
	font-weight: 700;
}
#header .area .util {
	/* float: right; */
	margin-top: 27px;
}
#header .area .util ul li {
	float: left;
	height: 49px;
	line-height: 49px;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	border-radius: 25px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
#header .area .util ul li a {
	display: block;
	width: 100%;
	height: 100%;
	font-size: 13px;
	font-family: 'Spoqa Han Sans Neo', 'sans-serif';
}
#header .area .util ul li.arm_btn {
	border: 0;
	-webkit-border-radius: px;
	-moz-border-radius: px;
	border-radius: 0px;
	margin-right: 22px;
}
#header .area .util ul li.arm_btn a {
	position: relative;
	width: 27px;
	display: block;
}
#header .area .util ul li.arm_btn a img {
	width: 100%;
	height: auto;
	margin-top: 12px;
}
#header .area .util ul li.arm_btn a .new {
	position: absolute;
	top: 9px;
	right: 1px;
	width: 11px;
	height: 11px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	background: #ff0000;
}
#header .area .util ul li.req_btn {
	border: 1px solid #ececec;
	width: 162px;
	margin-right: 40px;
}
#header .area .util ul li.req_btn a {
	font-size: 18px;
	font-weight: 500;
}
#header .area .util ul li.req_btn a img {
	width: 25px;
	height: auto;
	margin-top: 11px;
	margin-left: 18px;
	margin-right: 7px;
}
#header .area .util ul li.m_gnb {
	margin-top: 9px;
	width: 44px;
	height: 32px;
}
#header .area .util ul li.m_gnb #nav-icon1 {
	width: 100%;
	height: 100%;
	position: relative;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.5s ease-in-out;
	-moz-transition: 0.5s ease-in-out;
	-o-transition: 0.5s ease-in-out;
	transition: 0.5s ease-in-out;
	cursor: pointer;
}
#header .area .util ul li.m_gnb #nav-icon1 span {
	display: block;
	position: absolute;
	height: 3px;
	width: 100%;
	background: #000;
	border-radius: 9px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.25s ease-in-out;
	-moz-transition: 0.25s ease-in-out;
	-o-transition: 0.25s ease-in-out;
	transition: 0.25s ease-in-out;
}
#header .area .util ul li.m_gnb #nav-icon1 span:nth-child(1) {
	top: 0px;
}
#header .area .util ul li.m_gnb #nav-icon1 span:nth-child(2) {
	top: 14px;
}
#header .area .util ul li.m_gnb #nav-icon1 span:nth-child(3) {
	top: 28px;
}
#header .area .util ul li.m_gnb #nav-icon1.open span:nth-child(1) {
	top: 8px;
	-webkit-transform: rotate(135deg);
	-moz-transform: rotate(135deg);
	-o-transform: rotate(135deg);
	transform: rotate(135deg);
}
#header .area .util ul li.m_gnb #nav-icon1.open span:nth-child(2) {
	opacity: 0;
	left: -60px;
}
#header .area .util ul li.m_gnb #nav-icon1.open span:nth-child(3) {
	top: 8px;
	-webkit-transform: rotate(-135deg);
	-moz-transform: rotate(-135deg);
	-o-transform: rotate(-135deg);
	transform: rotate(-135deg);
}

.logout_pu {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 344px;
	background: #fff;
	-webkit-border-radius: px;
	-moz-border-radius: px;
	border-radius: 24px;
	z-index: 13;
	padding-bottom: 36px;
}
.logout_pu h2 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	line-height: 22px;
	text-align: center;
	padding: 36px;
}
.logout_pu .btn_wrap {
	width: 246px;
	margin: 0 auto;
}
.logout_pu .btn_wrap button {
	float: left;
	width: 120px;
	height: 48px;
	-webkit-border-radius: px;
	-moz-border-radius: px;
	border-radius: 24px;
	color: #fff;
	font-size: 16px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
.logout_pu .btn_wrap button.cancel {
	background: #666;
}
.logout_pu .btn_wrap button.ok {
	background: #5774db;
	margin-left: 6px;
}

#mHd {
	position: fixed;
	top: 0;
	right: -40%;
	width: 40%;
	height: 100%;
	background: #fff;
	z-index: 12;
}
#mHd .mHd_wrap {
	overflow: auto;
	height: 100%;
}
#mHd .mHd_wrap .mHd_1 {
	height: 74px;
	line-height: 76px;
	border-bottom: 8px solid #f4f4f4;
	overflow: hidden;
}
#mHd .mHd_wrap .mHd_1 > a {
	display: block;
	padding-left: 24px;
	font-family: PTDb, PTDr, 'Noto Sans KR', sans-serif;
	font-size: 15px;
}
#mHd .mHd_wrap .mHd_1 .mHd_1_login {
	display: none;
}
#mHd .mHd_wrap .mHd_1 .mHd_1_login > * {
	float: left;
}
#mHd .mHd_wrap .mHd_1 .mHd_1_login .pf_img {
	width: 30px;
	height: 30px;
	margin-top: 22px;
	margin-left: 24px;
	margin-right: 10px;
}
#mHd .mHd_wrap .mHd_1 .mHd_1_login .pf_img img {
	width: 100%;
	height: auto;
}
#mHd .mHd_wrap .mHd_1 .mHd_1_login p {
	font-size: 14px;
	font-family: PTDr, PTDb, 'Noto Sans KR', sans-serif;
}
#mHd .mHd_wrap .mHd_1 .mHd_1_login p span {
	font-family: PTDb, PTDr, 'Noto Sans KR', sans-serif;
}
#mHd .mHd_wrap .mHd_1 .mHd_1_login .edit_btn {
	width: 14px;
	height: 14px;
	margin-top: 31px;
	margin-left: 20px;
}
#mHd .mHd_wrap .mHd_1 .mHd_1_login .edit_btn img {
	width: 100%;
	height: auto;
}
#mHd .mHd_wrap > div ul li {
	height: 48px;
	overflow: hidden;
}
#mHd .mHd_wrap > div ul li a {
	display: block;
	padding-left: 24px;
}
#mHd .mHd_wrap > div ul li a > * {
	float: left;
}
#mHd .mHd_wrap > div ul li a .icon {
	width: 20px;
	height: 20px;
	margin-right: 10px;
	margin-top: 13px;
}
#mHd .mHd_wrap > div ul li a .icon img {
	width: 100%;
	height: auto;
}
#mHd .mHd_wrap > div ul li a p {
	font-size: 15px;
	line-height: 48px;
}
#mHd .mHd_wrap > div ul li .logout_btn {
	display: block;
	width: 100%;
	height: 100%;
	font-size: 11px;
	color: #999;
	text-align: center;
}
#mHd .mHd_wrap .mHd_2 {
	border-bottom: 8px solid #f4f4f4;
}
#mHd .mHd_wrap .mHd_3 {
	border-bottom: 8px solid #f4f4f4;
}
#mHd .mHd_5 {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 56px;
	z-index: 10;
	background: #fff;
	box-shadow: 5px -5px 10px rgba(255, 255, 255, 0.8);
}
#mHd .mHd_5 .logout_btn {
	width: 100%;
	height: 100%;
	font-size: 11px;
	color: #999;
	border-top: 1px solid #f2f2f2;
}

.floating_menu {
	display: none;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 44px;
	z-index: 14;
	background: #fff;
	border-top: 1px solid rgba(204, 204, 204, 0.3);
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.floating_menu ul {
	height: 100%;
}
.floating_menu ul li {
	float: left;
	width: 25%;
	height: 100%;
}
.floating_menu ul li a {
	position: relative;
	display: block;
	width: 100%;
	height: 48px;
	padding-top: 6px;
}
.floating_menu ul li .bar {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 2px;
	background: #222;
}
.floating_menu ul li .icon {
	display: block;
	width: 18px;
	height: 18px;
	margin: 0 auto;
}
.floating_menu ul li .icon img {
	width: 100%;
	height: auto;
}
.floating_menu ul li h4 {
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
	text-align: center;
	font-size: 10px;
	letter-spacing: -0.5px;
	padding-top: 1px;
	color: #666;
}
.floating_menu ul li.on .bar {
	display: block;
}
.floating_menu ul li.fm_m_gnb .icon {
	margin-top: 6px;
}

#footer {
	width: 100%;
	height: 265px;
	background: #000;
	font-family: 'Spoqa Han Sans Neo', 'sans-serif';
}
#footer .area {
	width: 1400px;
	margin: 0 auto;
}
#footer .area .left {
	float: left;
	padding-left: 98px;
	padding-top: 48px;
}
#footer .area .left ul li {
	padding-top: 12px;
}
#footer .area .left ul li:first-child {
	padding-top: 0;
}
#footer .area .left ul li p {
	color: #999;
	font-size: 14px;
	float: left;
	padding-left: 14px;
	margin-left: 12px;
	background: url('../images/ft_bar.gif') no-repeat left center;
}
#footer .area .left ul li p:first-child {
	padding-left: 0;
	margin-left: 0;
	background: none;
}
#footer .area .right {
	float: right;
	margin-top: 48px;
	margin-right: 103px;
}
#footer .area .right ul {
	display: flex;
	justify-content: flex-end;
}
#footer .area .right ul li {
	margin-left: 23px;
}
#footer .area .right ul li:first-child {
	margin-left: 0;
}
#footer .area .right ul li a {
	color: #fff;
	font-size: 16px;
	opacity: 0.5;
}
#footer .area .right .ft_logo {
	margin-top: 12px;
}
#privacy .cnt1 .area .wrap {
	padding-top: 100px;
}
#privacy .cnt1 .area .wrap h2 {
	font-size: 32px;
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
	padding-bottom: 50px;
	text-align: center;
}
#privacy .cnt1 .area .wrap .pvc {
	margin-bottom: 40px;
}
#privacy .cnt1 .area .wrap .pvc h3 {
	font-size: 15px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#privacy .cnt1 .area .wrap .pvc p {
	font-size: 15px;
	line-height: 1.7;
	margin-bottom: 10px;
	margin-top: 10px;
	color: #666;
}
#privacy .cnt1 .area .wrap .pvc p.ml {
	padding-left: 10px;
	margin-bottom: 3px;
	margin-top: 0;
}
#privacy .cnt1 .area .wrap .pvc p.ml2 {
	padding-left: 20px;
}
#privacy .cnt1 .area .wrap > p {
	font-size: 15px;
	padding-bottom: 20px;
}

#terms .cnt1 .area .wrap {
	padding-top: 100px;
}
#terms .cnt1 .area .wrap h2 {
	font-size: 32px;
	font-family: SC7, SC8, 'Noto Sans KR', sans-serif;
	padding-bottom: 50px;
	text-align: center;
}
#terms .cnt1 .area .wrap .toc {
	margin-bottom: 70px;
}
#terms .cnt1 .area .wrap .toc p {
	font-size: 16px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	padding-top: 15px;
}
#terms .cnt1 .area .wrap .toc p:first-child {
	padding-top: 0;
}
#terms .cnt1 .area .wrap .tms {
	margin-bottom: 40px;
}
#terms .cnt1 .area .wrap .tms h3 {
	font-size: 15px;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	color: #333;
	margin-bottom: 15px;
}
#terms .cnt1 .area .wrap .tms h3 strong {
	font-size: 18px;
	display: block;
	padding-bottom: 10px;
	color: #000;
}
#terms .cnt1 .area .wrap .tms p {
	font-size: 15px;
	line-height: 1.7;
	margin-bottom: 5px;
	color: #666;
}
#terms .cnt1 .area .wrap .tms .ml_wrap {
	margin-bottom: 20px;
}
#terms .cnt1 .area .wrap .tms .ml_wrap p.ml {
	padding-left: 20px;
	margin-bottom: 3px;
	margin-top: 0;
}
#terms .cnt1 .area .wrap > p {
	font-size: 15px;
	padding-bottom: 20px;
}

@media all and (max-width: 1544px) {
	#header .area {
		width: 92%;
	}
}

@media all and (max-width: 1400px) {
	#header .area .logo {
		width: 230px;
		height: auto;
	}
	#header .area .gnb {
		margin-left: 40px;
	}
	#header .area .gnb ul li {
		margin-left: 40px;
	}
	#header .area .gnb ul li a {
		font-size: 17px;
	}
	#footer .area {
		width: 96%;
	}
	#footer .area .left {
		padding-left: 0;
	}
	#footer .area .right {
		margin-right: 0;
	}
}

@media all and (max-width: 1280px) {
	.area {
		width: 96%;
	}
}

@media all and (max-width: 1080px) {
	#header {
		height: 80px;
	}
	#header .area .gnb ul li a {
		line-height: 80px;
	}
	#header .area .util ul li.req_btn {
		display: none;
	}
	#header .area .util {
		margin-top: 16px;
	}
	#header .area .util ul li.m_gnb {
		width: 36px;
		height: 22px;
		margin-top: 13px;
	}
	#header .area .util ul li.m_gnb #nav-icon1 span {
		height: 2px;
	}
	#header .area .util ul li.m_gnb #nav-icon1 span:nth-child(2) {
		top: 10px;
	}
	#header .area .util ul li.m_gnb #nav-icon1 span:nth-child(3) {
		top: 20px;
	}
	#footer {
		padding-top: 50px;
	}
	#footer .area .left {
		float: none;
	}
	#footer .area .right {
		float: none;
		margin-top: 0;
	}
}

@media all and (max-width: 750px) {
	#header {
		height: 65px;
	}
	#header .area .logo {
		width: 180px;
	}
	#header .area .gnb {
		margin-left: 30px;
	}
	#header .area .gnb ul li a {
		font-size: 14px;
		line-height: 70px;
	}
	#header .area .link {
		padding: 7px 17px 8px;
		font-size: 12px;
		font-weight: 500;
	}
	#header .area .util ul li.arm_btn {
		height: 22px;
		margin-top: 8px;
	}
	#header .area .util ul li.arm_btn a {
		width: 22px;
	}
	#header .area .util ul li.arm_btn a img {
		margin-top: 0;
	}
	#header .area .util ul li.arm_btn a .new {
		top: -3px;
		width: 8px;
		height: 8px;
	}
	#header .area .util ul li.m_gnb {
		width: 30px;
		height: 22px;
		margin-top: 9px;
	}
	#header .area .util ul li.m_gnb #nav-icon1 span:nth-child(2) {
		top: 8px;
	}
	#header .area .util ul li.m_gnb #nav-icon1 span:nth-child(3) {
		top: 16px;
	}
	#mHd {
		right: -77%;
		width: 77%;
	}
	#footer {
		height: auto;
		padding-top: 40px;
		padding-bottom: 40px;
	}
	#footer .area {
		width: 90%;
	}
	#footer .area .left {
		padding-top: 30px;
	}
	#footer .area .left ul li {
		padding-top: 0;
	}
	#footer .area .left ul li p {
		float: none;
		margin-left: 0;
		padding-left: 0;
		background: none;
		margin-top: 12px;
	}
	#footer .area .left ul li p.ft_p1 {
		display: inline-block;
		padding-right: 10px;
	}
	#footer .area .right .ft_logo {
		display: none;
	}
}

@media all and (max-width: 550px) {
	#header {
		height: 52px;
		border-bottom: 0;
	}
	#header .area .gnb ul li a {
		line-height: 57px;
		font-size: 12px;
	}
	#mHd .mHd_1 a {
		font-size: 14px;
	}
	#mHd > div ul li a p {
		font-size: 13px;
	}
	#mHd .mHd_1 .mHd_1_login p {
		font-size: 14px;
	}
	#mHd .mHd_5 {
		bottom: 44px;
	}
	.floating_menu {
		/* display: block; */
	}
}

@media all and (max-width: 450px) {
	#header .area .gnb {
		margin-left: auto;
		margin-right: 0;
	}
	#header .area .link {
		display: none;
	}
	.logout_pu {
		width: 280px;
	}
	#privacy .cnt1 .area .wrap {
		padding-top: 80px;
		width: 90%;
		margin: 0 auto;
	}
	#privacy .cnt1 .area .wrap h2 {
		font-size: 22px;
	}
	#terms .cnt1 .area .wrap {
		padding-top: 80px;
		width: 90%;
		margin: 0 auto;
	}
	#terms .cnt1 .area .wrap h2 {
		font-size: 22px;
	}
	#terms .cnt1 .area .wrap .toc p {
		font-size: 14px;
	}
}
