/* <!-- 구글로 시작하기 a태그 제거 */
#sub6 .cnt1 .area .wrap ul li button {
	display: block;
	width: 100%;
	height: 100%;
}

#sub6 .cnt1 .area .wrap ul li button .icon {
	width: 24px;
	margin-left: 24px;
	margin-right: 12px;
}

#sub6 .cnt1 .area .wrap ul li button > * {
	float: left;
}

#sub6 .cnt1 .area .wrap ul li button .icon img {
	width: 100%;
	height: auto;
	margin-top: 12px;
}

#sub6 .cnt1 .area .wrap ul li button h3 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 14px;
	letter-spacing: -0.5px;
}

/* 구글로 시작하기 a태그 제거 --/> */

/* <!-- 로그아웃 레이어는 useState로 컨트롤할것이라 default를 show로 변경 */
.logout_pu {
	display: block;
}
/* 로그아웃 레이어는 useState로 컨트롤할것이라 default를 show로 변경 --/> */

/* <!-- register/Page2.js 활동명 중복 레이어 팝업 */
.cnfm_pu {
	display: block;
}

.cnfm_ok_pu {
	display: block;
}

.chk_pu {
	display: block;
}
/* register/Page2.js 활동명 중복 레이어 팝업  --/> */

/* <!-- owl carousel에서 원본 도트 제거, main.css에서 custom하게 만들어 줌 */

.owl-theme .owl-dots .owl-dot span {
	display: none !important;
}

.owl-nav.disabled {
	/* display: block !important; */
}

.owl-dots.disabled {
	display: block !important;
}
/* owl carousel에서 원본 도트 제거, main.css에서 custom하게 만들어 줌  --/> */

/* <!-- 모바일 로그인시 "천재소녀님 반갑습니다." 보여주는 부분 */
#mHd .mHd_wrap .mHd_1 .mHd_1_login {
	display: block !important;
}
/* 모바일 로그인시 "천재소녀님 반갑습니다." 보여주는 부분 --/> */
/* <!-- 우측 drawer z-index가 1200으로 그보다 앞에 위치해야함 */
.floating_menu {
	z-index: 1300;
}
/* 우측 drawer z-index가 1200으로 그보다 앞에 위치해야함 --/> */
/* <!-- register/Page8.js 연차 선택 레이어 팝업 */
.range_pu {
	display: block !important;
}
/* register/Page8.js 연차 선택 레이어 팝업  --/> */
/* <!-- register/Page9.js 직무 선택 레이어 팝업 */
#sub12_2 .cnt1 .field_pu {
	display: block !important;
	max-height: 100% !important;
}
#sub12_2 .cnt1 .field_list {
	display: block !important;
}
#sub12_2 .cnt1 .field_pu .drp {
	height: 40px;
	line-height: 40px;
}
#sub12_2 .cnt1 .field_pu .drp p {
	font-size: 13px;
	width: 400px;
	margin: 0 auto;
}

#sub12_2 .cnt1 .field_pu {
	display: block !important;
	max-height: 100% !important;
}

#sub12_3 .cnt1 .car1_pu {
	display: block !important;
	max-height: 100% !important;
}
#sub12_3 .cnt1 .car1_pu .close_btn {
	display: block !important;
}
#sub12_3 .cnt1 .car1_pu .tit {
	display: block !important;
}
#sub12_3 .cnt1 .car1_pu .car1_list {
	display: block !important;
}

#sub12_4 .cnt1 .edu1_pu {
	display: block !important;
	max-height: 100% !important;
}
#sub12_4 .cnt1 .edu1_pu .close_btn {
	display: block !important;
}
#sub12_4 .cnt1 .edu1_pu .tit {
	display: block !important;
}
#sub12_4 .cnt1 .edu1_pu .edu1_list {
	display: block !important;
	overflow: auto;
	height: 160px;
}

#sub12_4 .cnt1 .degree_pu {
	display: block !important;
	max-height: 100% !important;
}

#sub12_4 .cnt1 .edu2_pu {
	display: block !important;
	max-height: 100% !important;
}
#sub12_4 .cnt1 .edu2_pu .close_btn {
	display: block !important;
}
#sub12_4 .cnt1 .edu2_pu .tit {
	display: block !important;
}
#sub12_4 .cnt1 .edu2_pu .edu2_list {
	display: block !important;
	overflow: auto;
	height: 160px;
}

/* register/Page9.js 직무 선택 레이어 팝업  --/> */
/* <!-- Page13.js */
/* <!-- 동의 화면내 팝업 */
#sub7 .cnt1 .terms_pu {
	display: block;
}
#sub7 .cnt1 .pvc_pu {
	display: block;
}
/* 동의 화면내 팝업 --/> */
/* <!-- 소개 팝업 */
#sub12_6 .cnt1 .area .intd_pu.intd_pu_on {
	display: block;
}
/* 소개 팝업 --/> */
/* <!-- 경력 팝업 */
#sub12_6 .cnt1 .area .intd2_pu_df {
	display: block;
}

#sub12_6 .cnt1 .area .car1_pu {
	display: block;
	max-height: 100% !important;
}
#sub12_6 .cnt1 .area .car1_pu .car1_list {
	display: block;
}
#sub12_6 .cnt1 .emp_pu {
	display: block !important;
	max-height: 100% !important;
}
#sub12_6 .cnt1 .area .indus_pu {
	display: block;
	max-height: 100% !important;
}
#sub12_6 .cnt1 .area .bh_pu {
	display: block;
}

#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .add_btn {
	position: absolute;
	top: 0;
	right: 0;
	width: 16px;
	height: 16px;
	background: #5774db;
	-webkit-border-radius: 11px;
	-moz-border-radius: 11px;
	border-radius: 11px;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .add_btn img {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 10px;
	height: auto;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .info_box {
	width: 100%;
	margin: 0 auto;
	border: 1px solid #eee;
	background: #fff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	font-family: SC5, SC6, 'Noto Sans KR', sans-serif;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .info_box ul {
	width: calc(100% - 40px);
	margin: 0 auto;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .info_box ul li {
	position: relative;
	padding: 18px 0;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .info_box ul li .ib_top > * {
	float: left;
	color: #444;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .info_box ul li .ib_top h6 {
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .info_box ul li .ib_top p {
	font-family: SC4, SC5, 'Noto Sans KR', sans-serif;
	padding-left: 5px;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .info_box ul li .ib_btm {
	margin-top: 4px;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .info_box ul li .ib_btm > * {
	float: left;
	color: #444;
	padding-left: 5px;
	font-family: SC4, SC5, 'Noto Sans KR', sans-serif;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .info_box ul li .ib_btm p:first-child {
	padding-left: 0;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .info_box ul li .edit_btn {
	position: absolute;
	top: 18px;
	right: 0;
	width: 16px;
}
#sub12_6 .cnt1 .area .intd2_pu .txt ul.depth1 > li .info_box ul li .edit_btn img {
	width: 100%;
	height: auto;
}
/* 경력 팝업 --/> */
/* <!-- 경력-업무이력 팝업창 */
/* 경력-업무이력 팝업 --/> */

/* <!-- 학력 팝업창 */
#sub12_6 .cnt1 .area .bh2_pu {
	display: block;
}

#sub12_6 .cnt1 .area .edu1_pu {
	display: block !important;
	max-height: 100% !important;
}
#sub12_6 .cnt1 .area .edu1_pu .close_btn {
	display: block !important;
}
#sub12_6 .cnt1 .area .edu1_pu .tit {
	display: block !important;
}
#sub12_6 .cnt1 .area .edu1_pu .edu1_list {
	display: block !important;
	overflow: auto;
	height: 160px;
}

#sub12_6 .cnt1 .area .degree_pu {
	display: block !important;
	max-height: 100% !important;
}

/* 학력 팝업창 --/> */
/* <!-- 보유기술 팝업창 */
#sub12_6 .cnt1 .area .skill_pu {
	display: block !important;
	max-height: 100% !important;
}

#sub12_6 .cnt1 .area .skill_pu .skill_list {
	display: block !important;
	overflow: auto;
	height: 160px;
}
/* 보유기술 팝업창 --/> */
/* <!-- 수상 팝업창 */
#sub12_6 .cnt1 .area .awd_pu {
	display: block !important;
}
/* 수상 팝업창 --/> */
/* <!-- 논문 팝업창 */
#sub12_6 .cnt1 .area .tsis_pu {
	display: block !important;
}
/* 논문 팝업창 --/> */
/* <!-- 특허 팝업창 */
#sub12_6 .cnt1 .area .pat_pu {
	display: block !important;
}
/* 특허 팝업창 --/> */
/* <!-- 외국어 팝업창 */
#sub12_6 .cnt1 .area .fl_pu {
	display: block !important;
}
/* 외국어 팝업창 --/> */
/* <!-- 외국어-어학시험 팝업창 */
#sub12_6 .cnt1 .area .fl2_pu {
	display: block !important;
}
/* 외국어-어학시험 팝업창 --/> */
/* <!-- 지역 추가 버튼 */
#sub12_6 .cnt1 .area .txt .join_info2 .ji2_6.on .add_btn {
	display: block !important;
}
/* 지역 추가 버튼 --/> */
/* <!-- 링크 팝업창 */
#sub12_6 .cnt1 .area .link_pu {
	display: block !important;
}
/* 링크 팝업창 --/> */
/* <!-- 지역 팝업창 */
#sub12_6 .cnt1 .area .area_pu {
	display: block !important;
}

#sub12_6 .cnt1 .area .area_pu {
	max-height: 100% !important;
}
#sub12_6 .cnt1 .area .area_pu .area_list {
	display: block !important;
}
/* 지역 팝업창 --/> */

/* <!-- 소개 수정 버튼 */
#sub12_6 .cnt1 .area .txt .join_info1 .box .edit_btn {
	display: block !important;
}
/* 소개 수정 버튼 --/> */

/* Page13.js --/> */

/* <!-- 댓글 입력 팝업창 */
.cnfm_ok_pu button {
	display: block;
	width: 160px;
	height: 48px;
	line-height: 48px;
	border-radius: 24px;
	margin: 0 auto;
	font-family: SC6, SC7, 'Noto Sans KR', sans-serif;
	font-size: 16px;
	color: #fff;
	text-align: center;
}
/* 댓글 입력 팝업창 --/> */

/* <!-- 답글 입력 */
#sub4 .cnt1 .area .wrap .comts > ul > li .btn_wrap .comts_write1_fc {
	display: block;
}
/* 답글 입력 --/> */

/* <!-- 내 프로필 */
#sub15 .cnt1 .car_pu {
	display: block;
}
#sub15 .cnt1 .field0_pu {
	display: block;
}

#sub15 .cnt1 .field_pu {
	display: block;
	max-height: 100% !important;
}

#sub15 .cnt1 .field_pu .field_list {
	display: block;
}

#sub15 .cnt1 .kd_pu {
	display: block;
}
#sub15 .cnt1 .rep_pu {
	display: block;
}

/* 내 프로필 --/> */
/* <!-- 서비스 의뢰 */
#sub1 .cnt1 .pvc_pu {
	display: block;
}

#sub1 .area .wrap .box .txt .btm ul li .form_cont {
	height: auto;
}

/* #sub1 .area .wrap .box .txt .top .form_cont {
	border: 0px;
	overflow: visible;
} */
/* 서비스 의뢰 --/> */
/* <!-- input type number 화살표 제거 */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
/* input type number 화살표 제거 --/> */

/* <!-- blah */
/* blah --/> */
